<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <v-btn @click="dateFilterBlockBack" x-small icon dark :disabled="loading"
        ><v-icon>first_page</v-icon></v-btn
      >
      <v-btn @click="dateFilterBack" x-small icon dark :disabled="loading"
        ><v-icon>chevron_left</v-icon></v-btn
      >
      <v-btn
        @click="openDateFilter"
        x-small
        outlined
        color="white"
        :disabled="loading"
        >{{ formatDate(dateFilter.startDate) }} -
        {{ formatDate(dateFilter.endDate) }}</v-btn
      >
      <v-btn @click="dateFilterForward" x-small icon dark :disabled="loading"
        ><v-icon>chevron_right</v-icon></v-btn
      >
      <v-btn
        @click="dateFilterBlockForward"
        x-small
        icon
        dark
        :disabled="loading"
        ><v-icon>last_page</v-icon></v-btn
      >

      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>
        <div class="max-width-150px mx-1">
          <v-select
            @change="filterAssignments()"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-switch
        @change="filterAssignments()"
        class="mr-3"
        dark
        dense
        v-model="showNotSentOnly"
        label="Only Not Sent"
      ></v-switch>

      <!-- Refresh -->
      <div>
        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>
        <div class="max-width-500px mx-1">
          <v-select
            @change="filterAssignments"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-switch
        @change="filterAssignments"
        class="mr-3"
        dark
        dense
        v-model="showNotSentOnly"
        label="Only Not Sent"
      ></v-switch>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- Date Filter -->
          <v-list-item @click="openDateFilter" link>
            <v-list-item-icon>
              <v-icon color="blue">date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Date filter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Refresh -->
          <v-list-item @click="getAssignments" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog v-model="employeeFilterDialog" persistent max-width="450px">
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- DATE FILTER DIALOG-->
    <v-dialog v-model="dateFilterDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="mx-3 mb-2 font-abel-24px text-center">
          Select Date Range
        </div>

        <div class="d-flex justify-center px-10">
          <!-- Start Date -->
          <v-menu
            v-model="showStartCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedStartDate"
                label="Start"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedStartDate"
              no-title
              @input="showStartCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex justify-center px-10">
          <!-- End Date -->
          <v-menu
            v-model="showEndCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedEndDate"
                label="End"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedEndDate"
              @input="showEndCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex justify-end align-center">
          <v-btn @click="saveDateFilter" small dark color="green">save</v-btn>
        </div>
      </div>
    </v-dialog>

    <!--NOTIFICATION  INFORMATION DIALOG -->
    <v-dialog
      v-model="showNotificationInformationDialog"
      persistent
      max-width="800px"
    >
      <div v-if="assignment" class="blue-frame">
        <div class="d-flex justify-space-between align-center font-abel-20px">
          <div class="d-flex align-center">
            <h2 class="font-weight-bold">
              Assignment
            </h2>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="handleHideNotificationInformationDialog"
                small
                icon
                class="ml-3"
              >
                <i class="fa-solid fa-xmark"></i>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <v-divider class="my-3"></v-divider>

        <div v-if="assignment.type === 'Flight'">
          <h4>
            {{ formatFullFlightSnapshot(assignment.flight) }}
          </h4>

          <div class="d-flex align-center my-1" v-if="assignment.employee">
            <h2 class="font-weight-bold">
              {{
                `${assignment.employee.surname}, ${assignment.employee.givenName}`
              }}
            </h2>
            <h3 class="font-weight-bold indigo--text ml-3">
              {{
                `(${assignment.employee.companyName} - ${assignment.employee.employeeId})`
              }}
            </h3>
          </div>

          <div>
            <span>Phone: </span>
            <span class="font-abel-14px-bold ml-1">
              {{ assignment.employee.mobilePhone }}
            </span>
          </div>

          <div>
            <span>Flight Role:</span
            ><span class="font-abel-16px-bold ml-2">{{
              getFlightRole(assignment.role).description
            }}</span>
          </div>

          <div>
            <span>Assigned by:</span
            ><span class="ml-1 font-weight-bold">{{
              assignment.assignedBy
            }}</span>
          </div>
          <div>
            <span>Last Updated:</span
            ><span class="ml-1 font-weight-bold">{{
              formatDateTime(assignment.updatedAt)
            }}</span>
          </div>

          <v-divider class="my-1"></v-divider>

          <h5>Remarks</h5>

          <div class="remarks">
            <p
              v-if="assignment.remarks"
              class="user-select-text font-abel-14px  indigo--text mt-2"
            >
              {{ assignment.remarks }}
            </p>
          </div>

          <v-divider class="my-1"></v-divider>
          <h3>Notification</h3>
          <div v-if="assignment.notification">
            <div>
              <span>Sent by:</span
              ><span class="ml-1 font-weight-bold">{{
                assignment.notification.sentBy
              }}</span>
            </div>
            <div>
              <span>Sent on:</span
              ><span class="ml-1 font-weight-bold">{{
                `${formatDateTime(assignment.notification.sentOn)} Z`
              }}</span>
            </div>
            <div v-if="assignment.notification.acknowledged">
              <span>Acknowledged on:</span
              ><span class="ml-1 font-weight-bold green--text">
                {{
                  `${formatDateTime(assignment.notification.acknowledgedOn)} Z`
                }}
              </span>
            </div>
            <div v-else>
              <span class="font-weight-bold red--text">Not Acknowledged</span>
            </div>
            <div
              v-if="assignment.notificationStatus === 'acknowledged-changed'"
            >
              <span>Warning:</span
              ><span class="ml-1 font-weight-bold red--text">
                Assignment changed since last notification
              </span>
            </div>
          </div>
          <div v-else>
            <span class="font-weight-bold red--text">No notification sent</span>
          </div>
        </div>
        <div v-else>
          <div class="d-flex align-center my-1">
            <h2 class="font-weight-bold">
              {{
                `${assignment.employee.surname}, ${assignment.employee.givenName}`
              }}
            </h2>
            <h3 class="font-weight-bold indigo--text ml-3">
              {{
                `(${assignment.employee.companyName} - ${assignment.employee.employeeId})`
              }}
            </h3>
          </div>

          <div class="d-flex justify-space-between align-center mb-2">
            <div class="font-abel-20px-bold">
              {{ `${formatGroundAssignmentSnapshot(assignment)}` }}
            </div>
          </div>

          <v-divider class="my-1"></v-divider>

          <div>
            <span>Period:</span
            ><span class="font-abel-16px-bold ml-2">
              {{
                `${formatDateTime(assignment.startTime)} -  ${formatDateTime(
                  assignment.endTime
                )}`
              }}</span
            >
          </div>

          <div>
            <span>Employee Phone:</span
            ><span class="font-abel-16px-bold ml-2">{{
              assignment.employee.mobilePhone
            }}</span>
          </div>

          <div>
            <span>Assigned by:</span
            ><span class="font-abel-16px-bold ml-2">{{
              assignment.assignedBy
            }}</span>
          </div>
          <div>
            <span>Last updated:</span
            ><span class="font-abel-16px-bold ml-2">{{
              formatDateTime(assignment.updatedAt)
            }}</span>
          </div>

          <v-divider class="my-1"></v-divider>

          <h5>Remarks</h5>
          <div class="remarks">
            <p
              v-if="assignment.remarks"
              class="user-select-text font-abel-14px  indigo--text mt-2"
            >
              {{ assignment.remarks }}
            </p>
          </div>
          <v-divider class="my-1"></v-divider>
          <h3>Notification</h3>
          <div v-if="assignment.notification">
            <div>
              <span>Sent by:</span
              ><span class="ml-1 font-weight-bold">{{
                assignment.notification.sentBy
              }}</span>
            </div>
            <div>
              <span>Sent on:</span
              ><span class="ml-1 font-weight-bold">{{
                `${formatDateTime(assignment.notification.sentOn)} Z`
              }}</span>
            </div>
            <div v-if="assignment.notification.acknowledged">
              <span>Acknowledged on:</span
              ><span class="ml-1 font-weight-bold green--text">
                {{
                  `${formatDateTime(assignment.notification.acknowledgedOn)} Z`
                }}
              </span>
            </div>
            <div v-else>
              <span class="font-weight-bold red--text">Not Acknowledged</span>
            </div>
            <div
              v-if="assignment.notificationStatus === 'acknowledged-changed'"
            >
              <span>Warning:</span
              ><span class="ml-1 font-weight-bold red--text">
                Assignment changed since last notification
              </span>
            </div>
          </div>
          <div v-else>
            <span class="font-weight-bold red--text">No notification sent</span>
          </div>
        </div>
      </div>
    </v-dialog>

    <div class="bottom-frame">
      <v-container fluid>
        <!-- Search by last name -->
        <div class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="loading"
                @click="handleOpenEmployeeFilter"
                small
                icon
              >
                <i class="fa-solid fa-filter"></i>
              </v-btn>
            </template>
            <span>Filter Employees by Job Title</span>
          </v-tooltip>
          <v-text-field
            v-model="lastName"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :style="{
              maxWidth: '200px',
              marginLeft: '10px',
              marginBottom: '10px',
            }"
          ></v-text-field>
        </div>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="filteredAssignments"
            v-model="selectedAssignments"
            :search="lastName"
            :footer-props="{
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
            @click:row="handleShowNotificationInformationDialog"
            show-select
            item-key="_id"
          >
            <template v-slot:[`item._id`]="{ item }">
              <span>{{ formatAssignmentSnapshot(item) }}</span>
            </template>

            <template v-slot:[`item.notification`]="{ item }">
              <v-btn icon>
                <v-icon :color="getNotificationStatusColor(item)"
                  >notifications</v-icon
                >
              </v-btn>
            </template>

            <template v-slot:[`item.sentOn`]="{ item }">
              <span>{{ formatNotificationSentOn(item) }}</span>
            </template>
          </v-data-table>
          <div class="d-flex justify-end">
            <v-btn
              @click="sendNotifications"
              small
              dark
              color="blue"
              class="mr-3 my-3"
              :disabled="selectedAssignments.length === 0"
              >Notify</v-btn
            >
          </div>
        </v-col>
      </v-container>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import EmployeeFilter from "../components/EmployeeFilter.vue";

export default {
  name: "notifications",
  components: {
    AutoLogout,
    Navbar,
    EmployeeFilter,
  },
  data() {
    return {
      loading: false,
      employeeFilterDialog: false,
      isMobile: false,
      notificationSearch: "",
      headers: [
        {
          text: "Last Name",
          align: "start",
          value: "employee.surname",
        },
        {
          sortable: true,
          text: "First Name",
          align: "start",
          value: "employee.givenName",
        },
        {
          sortable: true,
          text: "Company",
          align: "start",
          value: "employee.companyName",
        },
        {
          sortable: true,
          text: "Job Title",
          align: "start",
          value: "employee.jobTitle",
        },
        {
          sortable: false,
          text: "Assignment",
          align: "start",
          value: "_id",
        },
        {
          sortable: false,
          text: "Status",
          align: "start",
          value: "notification",
        },
        {
          sortable: true,
          text: "Sent",
          align: "start",
          value: "sentOn",
        },
      ],
      dateFilterDialog: false,
      selectedStartDate: "",
      selectedEndDate: "",
      employees: [],
      filteredAssignments: [],
      assignments: [],
      showStartCalendar: false,
      showEndCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      selectedAssignments: [],
      lastName: "",
      showNotSentOnly: true,
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      selectedCompany: "",
      assignment: null,
      showNotificationInformationDialog: false,
    };
  },
  mixins: [mixin],
  created() {
    this.selectedCompany = this.user.securityGroup.companies[0];
    this.selectedBoardType = this.board.type;
    this.getAssignments();
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    board() {
      return this.$store.getters.board;
    },
  },

  methods: {
    getNotificationStatus(assignment) {
      let status = "not-sent";

      if (assignment.notification) {
        let etdChanged;
        let etaChanged;
        let originChanged;
        let destinationChanged;

        if (assignment.type === "Flight") {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.flight.estimatedDepartureTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.flight.estimatedArrivalTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.flight.originIata !==
              assignment.notification.originIata;
            destinationChanged =
              assignment.flight.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              status = "acknowledged-changed";
            } else {
              status = "acknowledged-unchanged";
            }
          } else {
            status = "sent-not-acknowledged";
          }
        } else {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.startTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.endTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.originIata !== assignment.notification.originIata;
            destinationChanged =
              assignment.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              status = "acknowledged-changed";
            } else {
              status = "acknowledged-unchanged";
            }
          } else {
            status = "sent-not-acknowledged";
          }
        }
      }

      return status;
    },
    handleShowNotificationInformationDialog(assignment) {
      const status = this.getNotificationStatus(assignment);

      this.assignment = { ...assignment, notificationStatus: status };

      this.showNotificationInformationDialog = true;
    },

    handleHideNotificationInformationDialog() {
      this.showNotificationInformationDialog = false;
      this.assignment = null;
    },

    formatNotificationSentOn(item) {
      if (item.notification) {
        return `${this.formatDateTime(item.notification.sentOn)}`;
      } else {
        return "---";
      }
    },

    getNotificationStatusColor(assignment) {
      const status = this.getNotificationStatus(assignment);

      switch (status) {
        case "sent-not-acknowledged":
          return "blue";

        case "acknowledged-changed":
          return "orange";

        case "acknowledged-unchanged":
          return "green";

        default:
          return "red";
      }
    },
    //----------------------Employee Filter
    handleOpenEmployeeFilter() {
      this.employeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.employeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.employeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        this.$store.commit("this.loading = false;");
        return;
      }

      this.loading = false;
      this.getAssignments();
    },

    handleChangeBoardType(boardType) {
      let board = { ...this.board };
      board.type = boardType;
      this.$store.commit("updateBoard", board);
      this.filterAssignments();
    },
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },
    async getAssignments() {
      this.loading = true;
      this.assignments = [];
      this.filteredAssignments = [];
      this.selectedAssignments = [];

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/assignments/notifications/combine", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            filterStart: this.dateFilter.start,
            filterEnd: this.dateFilter.end,
            boardType: this.board.type,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.assignments = res.data.assignments;

        this.filterAssignments();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async sendNotifications() {
      let snapshot;

      let employeeMap = new Map();
      let employee;

      this.selectedAssignments.forEach((item) => {
        if (item.type === "Flight") {
          snapshot = this.formatFlightAssignmentNotification(item);
        } else {
          snapshot = this.formatGroundAssignmentNotification(item);
        }

        if (employeeMap.has(item.employee._id)) {
          employee = employeeMap.get(item.employee._id);
          employee.assignments.push({
            employeeId: item.employee._id,
            type: item.type,
            _id: item._id,
            snapshot,
          });
        } else {
          employeeMap.set(item.employee._id, {
            mail: item.employee.mail,
            secondaryMail: item.employee.secondaryMail,
            assignments: [
              {
                employeeId: item.employee._id,
                type: item.type,
                _id: item._id,
                snapshot,
              },
            ],
          });
        }
      });

      let employees = [];

      employeeMap.forEach((value) => {
        employees.push(value);
      });

      this.loading = true;

      const res = await this.$store.dispatch(
        "sendAssignmentNotifications",
        employees
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.snackbarColor = "#66BB6A";
      this.snackbarText = res.data.message;
      this.snackbarVisible = true;

      this.getAssignments();
    },

    filterAssignments() {
      let filterOne = [];
      let filterTwo = [];

      if (this.selectedCompany !== "All Companies") {
        filterOne = this.assignments.filter((assignment) => {
          return this.selectedCompany === assignment.employee.companyName;
        });
      } else {
        filterOne = [...this.assignments];
      }

      switch (this.board.type) {
        case "Cargo":
          filterTwo = filterOne.filter((assignment) => {
            return this.cargoJobTitles.includes(assignment.employee.jobTitle);
          });
          break;
        case "Passenger":
          filterTwo = filterOne.filter((assignment) => {
            return this.passengerJobTitles.includes(
              assignment.employee.jobTitle
            );
          });
          break;

        default:
          filterTwo = [...filterOne];
          break;
      }

      if (this.showNotSentOnly) {
        this.filteredAssignments = filterTwo.filter((assignment) => {
          return !assignment.notification;
        });
      } else {
        this.filteredAssignments = [...filterTwo];
      }
    },

    openDateFilter() {
      this.selectedStartDate = this.dateFilter.startDate.slice();
      this.selectedEndDate = this.dateFilter.endDate.slice();
      this.dateFilterDialog = true;
    },

    saveDateFilter() {
      this.dateFilter.startDate = this.selectedStartDate.slice();
      this.dateFilter.endDate = this.selectedEndDate.slice();

      let yy;
      let mm;
      let dd;

      const dt1 = new Date(this.dateFilter.startDate);
      yy = dt1.getUTCFullYear();
      mm = dt1.getUTCMonth();
      dd = dt1.getUTCDate();
      const filterStart = new Date(Date.UTC(yy, mm, dd, 0, 0, 0, 0)).getTime();

      const dt2 = new Date(this.dateFilter.endDate);
      yy = dt2.getUTCFullYear();
      mm = dt2.getUTCMonth();
      dd = dt2.getUTCDate();
      const filterEnd = new Date(
        Date.UTC(yy, mm, dd, 23, 59, 59, 999)
      ).getTime();

      const numberOfDays = Math.ceil(
        (filterEnd - filterStart) / 1000 / 60 / 60 / 24
      );

      if (numberOfDays < 5 || numberOfDays > 31) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please select a range between 5 and 31 days";
        this.snackbarVisible = true;
        return;
      }

      this.dateFilterDialog = false;
      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });
      this.getAssignments();
    },

    dateFilterBlockBack() {
      const numberOfDays = 14;
      const filterStart =
        new Date(this.dateFilter.start).getTime() -
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    dateFilterBack() {
      const numberOfDays = 7;
      const filterStart =
        new Date(this.dateFilter.start).getTime() -
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    dateFilterForward() {
      const numberOfDays = 7;
      const filterStart =
        new Date(this.dateFilter.start).getTime() +
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    dateFilterBlockForward() {
      const numberOfDays = 14;

      const filterStart =
        new Date(this.dateFilter.start).getTime() +
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },
  },
};
</script>
