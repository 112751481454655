var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar'),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.snackbarTimeout,"multi-line":"","transition":"scroll-x-transition","centered":""},model:{value:(_vm.snackbarVisible),callback:function ($$v) {_vm.snackbarVisible=$$v},expression:"snackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('div',{staticClass:"main-header"},[_c('div'),_c('div',{staticClass:"d-flex justify-space-between align-center mr-1 py-2"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"small":"","icon":""},on:{"click":_vm.getAssignments}},[_c('i',{staticClass:"fa-solid fa-arrows-rotate "})])],1)]),_c('div',{staticClass:"main-frame"},[_c('v-dialog',{attrs:{"max-width":"300","persistent":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"size":60,"width":7,"color":"#6495ED","indeterminate":""}})],1)]),(!_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.assignments,"footer-props":{
            itemsPerPageOptions: [20, 50, 100, 200],
          }},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatAssignmentSnapshot(item)))])]}},{key:"item.notification",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":_vm.getNotificationStatusColor(item)}},[_vm._v("notifications")])],1)]}},{key:"item.sentOn",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNotificationSentOn(item)))])]}}],null,true)}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-3 my-3",attrs:{"small":"","dark":"","color":"blue","disabled":_vm.assignments.length === 0},on:{"click":_vm.handleAcknowledgeAssignments}},[_vm._v("Acknowledge")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }