<template>
  <div>
    <div class="blue-frame">
      <div class="d-flex justify-space-between">
        <div class="font-abel-24px-bold mb-2">
          Special Airports
        </div>
        <v-btn @click="hide" small icon><v-icon>close</v-icon></v-btn>
      </div>

      <v-container>
        <v-row dense fluid>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="d-flex justify-center px-10">
              <!-- Start Date -->
              <v-menu
                v-model="showStartCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="targetDate"
                    label="Check Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="targetDate"
                  no-title
                  @input="changeCheckDate"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
        </v-row>

        <v-row fluid>
          <v-col cols="12">
            <v-data-table
              dense
              :headers="headers"
              :items="airports"
              :search="search"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 50, 100, 200, -1],
              }"
            >
              <template v-slot:[`item.covid19Periods`]="{ item }">
                <span>{{ formatCovid19Status(item) }}</span>
              </template>

              <template v-slot:[`item.hazardPeriods`]="{ item }">
                <span>{{ formatHazardStatus(item) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";

export default {
  name: "specialAirports",

  mixins: [mixin],

  data() {
    return {
      search: "",
      showStartCalendar: false,
      airports: [],
      headers: [
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "iata",
        },
        {
          text: "Country",
          align: "start",
          sortable: true,
          value: "country",
        },
        {
          text: "COVID-19",
          align: "start",
          sortable: false,
          value: "covid19Periods",
        },
        {
          text: "Hazard",
          align: "start",
          sortable: false,
          value: "hazardPeriods",
        },
      ],
      targetDate: new Date().toISOString().substring(0, 10),
    };
  },

  mounted() {
    this.$nextTick(function() {
      this.airports = this.companyAirports.filter((airport) => {
        return airport.covid19Periods.length || airport.hazardPeriods.length;
      });

      this.targetDate = new Date().toISOString().substring(0, 10);
    });
  },

  computed: {
    companyAirports() {
      return this.$store.getters.companyAirports;
    },
  },

  methods: {
    hide() {
      this.$emit("hide");
    },
    formatCovid19Status(airport) {
      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      if (this.overlaps(startTime, endTime, airport.covid19Periods)) {
        return "YES";
      } else {
        return "NO";
      }
    },
    formatHazardStatus(airport) {
      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      if (this.overlaps(startTime, endTime, airport.hazardPeriods)) {
        return "YES";
      } else {
        return "NO";
      }
    },
    changeCheckDate() {
      this.showStartCalendar = false;
      this.airports = this.companyAirports.filter((airport) => {
        return airport.covid19Periods.length || airport.hazardPeriods.length;
      });
    },
  },
};
</script>
