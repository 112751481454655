var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar'),(_vm.user)?_c('AutoLogout',{on:{"logout":_vm.logout}}):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.snackbarTimeout,"multi-line":"","transition":"scroll-x-transition","centered":""},model:{value:(_vm.snackbarVisible),callback:function ($$v) {_vm.snackbarVisible=$$v},expression:"snackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-dialog',{attrs:{"max-width":"300","persistent":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"size":60,"width":7,"color":"#6495ED","indeterminate":""}})],1)]),_c('v-dialog',{attrs:{"persistent":"","max-width":"450px"},model:{value:(_vm.employeeFilterDialog),callback:function ($$v) {_vm.employeeFilterDialog=$$v},expression:"employeeFilterDialog"}},[_c('div',{staticClass:"yellow-frame"},[_c('employee-filter',{on:{"hide":function($event){return _vm.handleCloseEmployeeFilter()},"save":function($event){return _vm.handleSaveEmployeeFilter($event)}}})],1)]),_c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},model:{value:(_vm.showWarningsDialog),callback:function ($$v) {_vm.showWarningsDialog=$$v},expression:"showWarningsDialog"}},[_c('div',{staticClass:"red-frame"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"font-abel-24px-bold mb-2"},[_vm._v(" Warnings ")])]),_c('v-text-field',{style:({
          maxWidth: '200px',
          marginLeft: '10px',
          marginBottom: '10px',
        }),attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.warningsSearch),callback:function ($$v) {_vm.warningsSearch=$$v},expression:"warningsSearch"}}),(_vm.warnings)?_c('v-data-table',{attrs:{"dense":"","headers":_vm.warningsHeaders,"search":_vm.warningsSearch,"items":_vm.warnings,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, -1],
        }}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center font-abel-16px my-3"},[_c('div'),_c('v-btn',{attrs:{"small":"","dark":"","color":"blue"},on:{"click":_vm.handleAcknowledgeWarnings}},[_vm._v("acknowledge")])],1)],1)]),(!_vm.isMobile)?_c('div',{staticClass:"r-toolbar font-abel-16px"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"max-width-100px mx-1"},[_c('v-select',{attrs:{"dense":"","dark":"","items":_vm.boardTypes},on:{"change":_vm.handleChangeBoardType},model:{value:(_vm.selectedBoardType),callback:function ($$v) {_vm.selectedBoardType=$$v},expression:"selectedBoardType"}})],1),_c('div',{staticClass:"max-width-150px mx-1"},[_c('v-select',{attrs:{"dense":"","dark":"","items":_vm.userCompanies},on:{"change":_vm.filterEmployees},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1)]),_c('v-badge',{staticClass:"mr-5",attrs:{"content":_vm.warnings.length,"value":_vm.warnings.length,"color":"red","overlap":""}},[_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.loading,"small":"","icon":""},on:{"click":_vm.handleshowWarningsDialog}},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation",class:{ 'warning-state': _vm.warnings.length }})])],1),_c('v-spacer'),_c('div',{staticClass:"font-abel-12px mr-2"},[_vm._v(" "+_vm._s(_vm.employeeCountText)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"small":"","icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.getEmployees()}}},on),[_c('i',{staticClass:"fa-regular fa-rotate"})])]}}],null,false,153352845)},[_c('span',[_vm._v("Get Employees")])]),(_vm.enableCreateEmployee)?_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","disabled":_vm.loading},on:{"click":_vm.addEmployee}},[_c('i',{staticClass:"fa-solid fa-user-plus"})]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","disabled":_vm.loading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,4148897341)},[_c('v-list',{attrs:{"dense":"","color":"#D2D6D7"}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.printWarnings}},[_c('v-list-item-icon',[_c('i',{staticClass:"fa-solid fa-print"})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Print Warnings")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.getVaccinationRecordsCsv}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("vaccines")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Get Vaccination Records")])],1)],1)],1)],1)],1):_c('div',{staticClass:"r-toolbar font-abel-16px justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"max-width-100px mx-1"},[_c('v-select',{attrs:{"dense":"","dark":"","items":_vm.boardTypes},on:{"change":_vm.handleChangeBoardType},model:{value:(_vm.selectedBoardType),callback:function ($$v) {_vm.selectedBoardType=$$v},expression:"selectedBoardType"}})],1),_c('div',{staticClass:"max-width-150px mx-1"},[_c('v-select',{attrs:{"dense":"","dark":"","items":_vm.userCompanies},on:{"change":_vm.filterEmployees},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1)]),_c('v-badge',{staticClass:"mr-5",attrs:{"content":_vm.warnings.length,"value":_vm.warnings.length,"color":"red","overlap":""}},[_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":_vm.loading,"small":"","icon":""},on:{"click":_vm.handleshowWarningsDialog}},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation",class:{ 'warning-state': _vm.warnings.length }})])],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","disabled":_vm.loading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',{attrs:{"dense":"","color":"#98DBFA"}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.printWarnings}},[_c('v-list-item-icon',[_c('i',{staticClass:"fa-solid fa-print"})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Print Warnings")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.getEmployees()}}},[_c('v-list-item-icon',[_c('i',{staticClass:"fa-regular fa-rotate"})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Active Employees")])],1)],1),(_vm.enableCreateEmployee)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addEmployee}},[_c('v-list-item-icon',[_c('i',{staticClass:"fa-solid fa-plus"})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New Employee")])],1)],1):_vm._e()],1)],1)],1),(!_vm.loading)?_c('div',[_c('div',{staticClass:"bottom-frame-no-toolbar"},[_c('div',{staticClass:"d-flex justify-start align-center mt-5 py-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"small":"","icon":""},on:{"click":_vm.handleOpenEmployeeFilter}},on),[_c('i',{staticClass:"fa-solid fa-filter"})])]}}],null,false,3480675463)},[_c('span',[_vm._v("Filter Employees by Job Title")])]),_c('v-text-field',{style:({
            maxWidth: '200px',
            marginLeft: '10px',
            marginBottom: '10px',
          }),attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"ml-5"},[_c('v-checkbox',{attrs:{"label":"Show active employees only"},on:{"change":function($event){return _vm.getEmployees()}},model:{value:(_vm.activeOnly),callback:function ($$v) {_vm.activeOnly=$$v},expression:"activeOnly"}})],1)],1),(_vm.filteredEmployees.length)?_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.filteredEmployees,"search":_vm.search,"footer-props":{
                itemsPerPageOptions: [50, 100, 200],
              },"item-key":"_id"},on:{"click:row":_vm.navigateToEmployeeUpdate},scopedSlots:_vm._u([{key:"item.warnings",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.warnings.length))])]}}],null,true)})],1)],1)],1):_c('div',{staticClass:"bottom-frame font-abel-16px"},[_c('div',{staticClass:"r-frame-rounded-yellow red--text"},[_vm._v(" No employees found with the selected filters. ")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }