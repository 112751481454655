<template>
  <div class="r-dialog">
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <div v-if="loading">
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>
    <div v-else>
      <div v-if="state === 'view'" class="assignment-frame">
        <div class="d-flex justify-space-between align-center font-abel-20px">
          <div class="d-flex justify-start align-center">
            <h2 class="header-title mr-3">STATION ASSIGNMENT</h2>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list dense color="#D2D6D7">
                <!-- EDIT ASSIGNMENT -->
                <v-list-item
                  v-if="enableUpdateGroundAssignment"
                  @click="handleBeginEdit"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-pen"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- SEND NOTIFICATION -->
                <v-list-item
                  v-if="
                    groundAssignment.employee &&
                      enableCreateAssignmentNotification
                  "
                  @click="handleSendAssignmentNotification()"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-envelope"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Send Notification</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- DELETE ASSIGNMENT -->
                <v-list-item
                  v-if="enableDeleteGroundAssignment"
                  @click="handleDeleteStationAssignment()"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-trash-can"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="close" small icon class="ml-3">
                <i class="fa-solid fa-xmark"></i>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <div class="d-flex align-center my-1" v-if="groundAssignment.employee">
          <h2 class="font-weight-bold">
            {{
              `${groundAssignment.employee.surname}, ${groundAssignment.employee.givenName}`
            }}
          </h2>
          <h3 class="font-weight-bold indigo--text ml-3">
            {{
              `(${groundAssignment.employee.companyName} - ${groundAssignment.employee.employeeId})`
            }}
          </h3>
        </div>
        <div v-else>
          <div class="d-flex justify-start align-center">
            <h2 class="font-weight-bold">
              <span>Assigned to:</span>
              <span class="ml-1 red--text">{{
                ` ${groundAssignment.assignedToCompany}`
              }}</span>
            </h2>
          </div>

          <h3 v-if="groundAssignment.unableToCover" class="font-weight-bold">
            <span>Unable to cover by:</span>
            <span class="ml-1 red--text">{{
              ` ${groundAssignment.unableToCover.companyName}`
            }}</span>
          </h3>
        </div>

        <v-divider class="my-1"></v-divider>

        <div class="d-flex justify-space-between align-centermb-2">
          <div class="font-abel-20px-bold">
            {{ `${formatStationAssignmentSnapshot(groundAssignment)}` }}
          </div>
        </div>
        <v-divider class="my-1"></v-divider>

        <div
          v-for="flight in groundAssignment.flights"
          :key="flight.flightId"
          class="font-abel-16px"
        >
          {{
            formatStationAssignmentFlight(
              flight,
              groundAssignment.group.stationIata
            )
          }}
        </div>

        <v-divider class="my-1"></v-divider>

        <div>
          <span>Period:</span
          ><span class="font-abel-16px-bold ml-2">
            {{
              `${formatDateTime(
                groundAssignment.startTime
              )} -  ${formatDateTime(groundAssignment.endTime)}`
            }}</span
          >
        </div>

        <div v-if="groundAssignment.employee">
          <span>Employee Phone:</span
          ><span class="font-abel-16px-bold ml-2">{{
            groundAssignment.employee.mobilePhone
          }}</span>
        </div>

        <div>
          <span>Assigned by:</span
          ><span class="font-abel-16px-bold ml-2">{{
            groundAssignment.assignedBy
          }}</span>
        </div>
        <div v-if="groundAssignment.warnings.length">
          <div class="assignment-warning" v-if="showAssignmentWarnings">
            <div class="flex-space-between">
              <div>
                The following warnings were active when the assignment was made:
              </div>
              <i
                @click="showAssignmentWarnings = false"
                class="fa-solid fa-xmark"
              ></i>
            </div>
            <ul>
              <li
                v-for="(warning, index) in groundAssignment.warnings"
                :key="index"
              >
                {{ warning.message }}
              </li>
            </ul>
          </div>
          <div v-else>
            <i
              @click="showAssignmentWarnings = true"
              class="fa-solid fa-triangle-exclamation mr-2"
            ></i>
            <span class="red--text font-weight-bold"
              >Assigned with Warnings</span
            >
          </div>
        </div>

        <div>
          <span>Last updated:</span
          ><span class="font-abel-16px-bold ml-2">{{
            formatDateTime(groundAssignment.updatedAt)
          }}</span>
        </div>

        <div
          v-if="!editRemarks && enableUpdateGroundAssignment"
          class="remarks"
          @click="handleEnableEditRemarks"
        >
          <p
            v-if="groundAssignment.remarks"
            class="user-select-text font-abel-14px  indigo--text mt-2"
          >
            {{ groundAssignment.remarks }}
          </p>
          <p v-else></p>
        </div>
        <div v-else>
          <v-textarea
            v-model="groundAssignmentRemarks"
            no-resize
            rows="3"
            label="Remarks"
          ></v-textarea>
          <div class="d-flex justify-end">
            <v-btn @click="handleSaveRemarks" small dark color="green"
              >save remarks</v-btn
            >
            <v-btn
              @click="handleCancelEditRemarks"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else-if="state === 'confirm'" class="red-frame">
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelDelete" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="my-5">
          <h2>
            Removing a Ground Assignment cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>
          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn @click="handleConfirmDelete" small dark color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelDelete"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div class="edit-frame" v-if="!warnings">
          <div class="d-flex justify-space-between">
            <h2 class="header-title">STATION ASSIGNMENT</h2>
            <v-btn @click="handleCancelEdit" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>

          <div
            class="d-flex align-center my-1"
            v-if="groundAssignment.employee"
          >
            <h2 class="font-weight-bold">
              {{
                `${groundAssignment.employee.surname}, ${groundAssignment.employee.givenName}`
              }}
            </h2>
            <h3 class="font-weight-bold indigo--text ml-3">
              {{
                `(${groundAssignment.employee.companyName} - ${groundAssignment.employee.employeeId})`
              }}
            </h3>
          </div>
          <div v-else>
            <div class="d-flex justify-start align-center">
              <h2 class="font-weight-bold">
                <span>Assigned to:</span>
                <span class="ml-1 red--text">{{
                  ` ${groundAssignment.assignedToCompany}`
                }}</span>
              </h2>
            </div>

            <h3 v-if="groundAssignment.unableToCover" class="font-weight-bold">
              <span>Unable to cover by:</span>
              <span class="ml-1 red--text">{{
                ` ${groundAssignment.unableToCover.companyName}`
              }}</span>
            </h3>
          </div>

          <v-divider class="my-1"></v-divider>

          <div class="d-flex justify-space-between align-center my-2">
            <h2>
              {{ `${groundAssignment.group.stationIata}` }}
            </h2>
          </div>

          <v-form v-model="isValid">
            <v-divider class="my-3"></v-divider>

            <div class="px-3">
              <div
                class="flex-space-between"
                v-for="flight in groundAssignmentFlights"
                :key="flight.flightId"
              >
                <div>
                  {{
                    formatStationAssignmentFlight(
                      flight,
                      groundAssignment.group.stationIata
                    )
                  }}
                </div>
                <div @click="handleRemoveFlight(flight)">
                  <i class="fa-solid fa-trash-can"></i>
                </div>
              </div>
            </div>

            <v-container>
              <v-form ref="form" v-model="isValid">
                <!-- STATION ASSIGNMENT ROLE -->
                <v-row dense>
                  <v-col cols="6">
                    <v-select
                      v-model="stationRole"
                      :items="stationRoles"
                      label="Assignment Role"
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- START DATE AND TIME -->
                <v-row dense>
                  <v-col cols="6">
                    <v-menu
                      v-model="showStationAssignmentStartCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="stationAssignmentStartDate"
                          label="Start Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="stationAssignmentStartDate"
                        no-title
                        @input="showStationAssignmentStartCalendar = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="stationAssignmentStartTime"
                      label="Start Time"
                      type="time"
                      suffix="UTC"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- END DATE AND TIME -->
                <v-row dense>
                  <v-col cols="6">
                    <v-menu
                      v-model="showStationAssignmentEndCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="stationAssignmentEndDate"
                          label="End Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="stationAssignmentEndDate"
                        no-title
                        @input="showStationAssignmentEndCalendar = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="stationAssignmentEndTime"
                      label="End Time"
                      type="time"
                      suffix="UTC"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- ASSIGNMENT REMARKS -->
                <v-textarea
                  class="mt-2"
                  outlined
                  v-model="remarks"
                  :rules="remarksRules"
                  label="Remarks"
                  no-resize
                  rows="3"
                >
                </v-textarea>
              </v-form>
            </v-container>
          </v-form>

          <div class="d-flex justify-end align-center font-abel-16px">
            <v-btn
              :disabled="!stationRole"
              @click="handlePrecheckStationAssignment"
              small
              dark
              color="green"
              >save</v-btn
            >
            <v-btn
              @click="handleCancelEdit"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
        <div v-else class="red-frame">
          <!-- TITLE -->
          <div class="d-flex justify-space-between">
            <h2 class="red--text">
              Warnings
            </h2>
            <v-btn @click="handleCancelEdit" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>

          <!-- WARNINGS -->
          <v-text-field
            v-if="warnings.length > 1"
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :style="{
              maxWidth: '200px',
              marginLeft: '10px',
              marginBottom: '10px',
            }"
          ></v-text-field>

          <v-data-table
            dense
            :headers="headers"
            :search="search"
            :items="warnings"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
          </v-data-table>

          <div v-if="hardWarnings.length > 0" class="disclaimer-text">
            <div
              v-if="
                user.securityGroup.authorizations.groundAssignment
                  .overrideWarnings
              "
            >
              This assignment contains hard warnings.
            </div>
            <div v-else>
              This assignment contains hard warnings and cannot be saved.
            </div>
          </div>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <div class="d-flex justify-center font-abel-16px my-2">
              <span class="mr-2">Atlas Air Online Training Center</span>
              <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
            </div>

            <div v-if="hardWarnings.length > 0">
              <div
                v-if="
                  user.securityGroup.authorizations.groundAssignment
                    .overrideWarnings
                "
              >
                <v-btn @click="handleSaveAssignment" small dark color="orange"
                  >Acknowledge and Continue</v-btn
                >
              </div>
              <div v-else>
                <v-btn @click="handleCancelEdit" small dark color="grey"
                  >cancel</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-btn @click="handleSaveAssignment" small dark color="blue"
                >Acknowledge and Continue</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";

export default {
  name: "stationAssignmentInformation",
  mixins: [mixin],
  props: ["groundAssignment"],
  data() {
    return {
      loading: false,
      warnings: null,
      isValid: true,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      dialog: true,
      editRemarks: false,
      groundAssignmentRemarks: "",
      showStationAssignmentStartCalendar: false,
      showStationAssignmentEndCalendar: false,
      stationAssignmentStartDate: new Date().toISOString().substring(0, 10),
      stationAssignmentStartTime: "00:00",
      stationAssignmentEndDate: new Date().toISOString().substring(0, 10),
      stationAssignmentEndTime: "23:59",
      groundAssignmentFlights: [],
      stationRole: null,
      stationRoles: [],
      state: "view",
      remarks: " ",
      search: "",
      assignedToCompany: "Atlas Air",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      stationRoleRules: [(v) => !!v || "Role is required"],
      assignedToCompanyRules: [(v) => !!v || "Company is required"],
      companies: ["Atlas Air", "AGS", "Aviablu", "Flightline"],
      notify: false,
      showAssignmentWarnings: false,
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },

    employee() {
      return this.$store.getters.employee;
    },

    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
  },

  methods: {
    handleBeginEdit() {
      if (this.groundAssignment.employee) {
        if (
          !this.user.securityGroup.companies.includes(
            this.groundAssignment.employee.companyName
          )
        ) {
          this.snackbarColor = "#F44336";
          this.snackbarText =
            "Not authorized. Please contact your administrator.";
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;

          return;
        }
      } else {
        if (
          !this.user.securityGroup.companies.includes(
            this.groundAssignment.assignedToCompany
          )
        ) {
          this.snackbarColor = "#F44336";
          this.snackbarText =
            "Not authorized. Please contact your administrator.";
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;

          return;
        }
      }

      this.groundAssignmentFlights = [...this.groundAssignment.flights];

      //Set up available roles based on airctraft type
      const cargoFlights = this.groundAssignmentFlights.filter((item) => {
        return item.aircraft.cargoOnly;
      });

      const passengerFlights = this.groundAssignmentFlights.filter((item) => {
        return !item.aircraft.cargoOnly;
      });

      let cargoStationRoles = [];
      let passengerStationRoles = [];

      if (cargoFlights.length) {
        cargoStationRoles = [
          ...this.user.securityGroup.groundAssignmentCargoRoles,
        ];
      }

      if (passengerFlights.length) {
        cargoStationRoles = [
          ...this.user.securityGroup.groundAssignmentPassengerRoles,
        ];
      }

      this.assignedToCompany = this.groundAssignment.assignedToCompany.slice();
      this.stationRoles = [...cargoStationRoles, ...passengerStationRoles];

      this.stationRole = this.groundAssignment.subCategory.slice();

      const d1 = new Date(this.groundAssignment.startTime);
      const d2 = new Date(this.groundAssignment.endTime);

      this.stationAssignmentStartDate = d1.toISOString().substring(0, 10);
      this.stationAssignmentStartTime = d1.toISOString().substring(11, 16);

      this.stationAssignmentEndDate = d2.toISOString().substring(0, 10);
      this.stationAssignmentEndTime = d2.toISOString().substring(11, 16);

      this.remarks = this.groundAssignment.remarks.slice();

      this.warnings = null;
      this.state = "edit";
    },

    handleCancelEdit() {
      this.state = "view";
    },

    handleRemoveFlight(flight) {
      if (this.groundAssignmentFlights.length > 1) {
        const i = this.groundAssignmentFlights.findIndex((item) => {
          return item._id === flight._id;
        });

        if (i >= 0) {
          this.groundAssignmentFlights.splice(i, 1);
        }
      } else {
        this.snackbarColor = "#F44336";
        this.snackbarText = "The assignment must cover at least one flight.";
        this.snackbarVisible = true;
      }
    },

    async handlePrecheckStationAssignment() {
      const assignmentStartDateTimeUnix = new Date(
        `${this.stationAssignmentStartDate}T${this.stationAssignmentStartTime}:00.000+00:00`
      ).getTime();
      const assignmentEndDateTimeUnix = new Date(
        `${this.stationAssignmentEndDate}T${this.stationAssignmentEndTime}:00.000+00:00`
      ).getTime();

      //Check that the selected period hits all the flights in the assignment
      let count = 0;
      let timeOut;
      let timeIn;
      this.groundAssignmentFlights.forEach((item) => {
        timeOut = new Date(item.effectiveTimeOut).getTime();
        timeIn = new Date(item.effectiveTimeIn).getTime();
        if (
          timeOut <= assignmentEndDateTimeUnix &&
          timeIn >= assignmentStartDateTimeUnix
        ) {
          count += 1;
        }
      });
      if (count !== this.groundAssignmentFlights.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "The selected times do not cover the required period for the assigned flights, please check times.";
        this.snackbarVisible = true;
        return;
      }
      let duration = Math.ceil(
        (assignmentEndDateTimeUnix - assignmentStartDateTimeUnix) / 60 / 1000
      );
      //48 Hours
      if (duration > 2881) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Maximum duty time is 48 hours, please check times";
        this.snackbarVisible = true;
        return;
      }

      let flightIds = [];

      this.groundAssignmentFlights.forEach((item) => {
        flightIds.push(item.flightId);
      });

      this.updatedStationAssignment = {
        groupId: this.groundAssignment.group.id,
        role: this.stationRole,
        assignedToCompany: this.assignedToCompany,
        stationIata: this.groundAssignment.group.stationIata,
        startTime: new Date(assignmentStartDateTimeUnix).toISOString(),
        endTime: new Date(assignmentEndDateTimeUnix).toISOString(),
        duration,
        flightIds,
        remarks: this.remarks.slice(),
      };

      //If there is an employee then assign to employee.
      if (this.groundAssignment.employee) {
        this.loading = true;

        this.updatedStationAssignment.employee_id = this.groundAssignment.employee._id;

        const res = await this.$store.dispatch(
          "precheckStationAssignment",
          this.updatedStationAssignment
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        if (res.data.warnings.length) {
          this.warnings = res.data.warnings;
          return;
        } else {
          this.handleSaveAssignment();
        }
      } else {
        this.handleSaveAssignment();
      }
    },

    async handleSaveAssignment() {
      this.loading = true;

      const res = await this.$store.dispatch("updateStationAssignment", {
        stationAssignment: this.updatedStationAssignment,
        warnings: this.warnings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("close", { refreshData: true });
    },

    async handleSendAssignmentNotification() {
      let payload = {
        assignment: {
          type: "Ground",
          employeeId: this.groundAssignment.employee._id,
          _id: this.groundAssignment._id,
          snapshot: this.formatStationAssignmentNotification(
            this.groundAssignment
          ),
        },
        emailAddress: this.groundAssignment.employee.mail,
        secondaryMailAddress: this.groundAssignment.employee.secondaryMail,
        emailSubject: "Station Assignment",
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "sendAssignmentNotification",
        payload
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.snackbarColor = "#66BB6A";
      this.snackbarText = res.data.message;
      this.snackbarVisible = true;
    },

    async handleCancelDelete() {
      this.state = "view";
    },

    async handleConfirmDelete() {
      this.loading = true;

      const res = await this.$store.dispatch(
        "deleteStationAssignment",
        this.groundAssignment.group.id
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.notify) {
        let payload = {
          assignment: {
            type: "Ground",
            employeeId: this.groundAssignment.employee._id,
            _id: this.groundAssignment._id,
            snapshot: this.formatStationAssignmentNotification(
              this.groundAssignment
            ),
          },
          emailAddress: this.groundAssignment.employee.mail,
          secondaryMailAddress: this.groundAssignment.employee.secondaryMail,
          emailSubject: "Station Assignment Removed",
        };

        this.loading = true;

        const res = await this.$store.dispatch(
          "sendAssignmentNotification",
          payload
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      this.$emit("close", { refreshData: true });
    },

    handleDeleteStationAssignment() {
      this.state = "confirm";
    },

    handleEnableEditRemarks() {
      let company;

      if (this.groundAssignment.employee) {
        company = this.groundAssignment.employee.companyName;
      } else {
        company = this.groundAssignment.assignedToCompany;
      }
      if (!this.user.securityGroup.companies.includes(company)) {
        this.$store.commit("updateError", {
          message: "Not authorized. Please contact your administrator.",
          isVisible: true,
        });
        return;
      }

      if (this.groundAssignment.remarks) {
        this.groundAssignmentRemarks = this.groundAssignment.remarks.slice();
      } else {
        this.groundAssignmentRemarks = " ";
      }

      this.editRemarks = true;
    },

    async handleSaveRemarks() {
      const groundAssignment = {
        ...this.groundAssignment,
        remarks: this.groundAssignmentRemarks,
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "saveGroundAssignmentRemarks",
        groundAssignment
      );

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.loading = false;
      this.editRemarks = false;

      this.$emit("close", true);
    },

    handleCancelEditRemarks() {
      this.editRemarks = false;
    },

    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style scoped>
.assignment-frame {
  border-style: solid;
  border-color: var(--brown);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

.disclaimer-text {
  border: 1px solid var(--red);
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--red);
}

.edit-frame {
  border-style: solid;
  border-color: var(--amber);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

.remarks {
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.red-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.fa-trash-can {
  color: var(--red);
}

.fa-envelope {
  color: var(--blue);
}

.header-title {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: var(--dark-blue);
}

p {
  width: 100%;
  min-height: 100px;
}

i {
  cursor: pointer;
}

.fa-pen {
  color: var(--green);
}

.fa-triangle-exclamation {
  color: var(--amber);
  cursor: pointer;
}

.assignment-warning {
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 5px;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  background-color: var(--amber);
  color: #000;
}

.assignment-warning div {
  font-size: 1.1rem;
}

.assignment-warning ul {
  list-style-type: decimal-leading-zero;
}
</style>
