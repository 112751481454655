<template>
  <div class="frame">
    <h1>{{ flightAssignment.employee.surname }}</h1>
    <div class="information-dialog font-abel-16px">
      <div class="d-flex justify-space-between align-center font-abel-20px">
        <div class="d-flex align-center">
          <h3 class="font-weight-bold  blue--text mr-2">
            {{ `FLIGHT ASSIGNMENT` }}
          </h3>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="edit" small icon>
                <i class="fa-solid fa-pen"></i>
              </v-btn>
            </template>
            <span>Edit Flight Assignment</span>
          </v-tooltip>
        </div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="close" small icon>
              <i class="fa-solid fa-xmark"></i>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
      </div>
      <v-divider></v-divider>

      <h4>
        {{ formatFullFlightSnapshot(this.flightAssignment.flight) }}
      </h4>

      <div class="d-flex align-center" v-if="flightAssignment.employee">
        <h2 class="font-weight-bold">
          {{
            `${flightAssignment.employee.surname}, ${flightAssignment.employee.givenName}`
          }}
        </h2>
        <h3 class="font-weight-bold indigo--text ml-3">
          {{
            `(${flightAssignment.employee.companyName} - ${flightAssignment.employee.employeeId})`
          }}
        </h3>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="ml-3"
              @click="notify"
              small
              icon
              v-if="enableCreateAssignmentNotification"
            >
              <i class="fa-solid fa-envelope"></i>
            </v-btn>
          </template>
          <span>Send Notification Email</span>
        </v-tooltip>
      </div>

      <div v-else>
        <h2 class="font-weight-bold">
          <span>Assigned to:</span>
          <span class="ml-1 red--text">{{
            ` ${flightAssignment.assignedToCompany}`
          }}</span>
        </h2>
        <h3 v-if="flightAssignment.unableToCover" class="font-weight-bold">
          <span>Unable to cover by:</span>
          <span class="ml-1 red--text">{{
            ` ${flightAssignment.unableToCover.companyName}`
          }}</span>
        </h3>
      </div>

      <div>
        <span>Flight Role:</span
        ><span class="font-abel-16px-bold ml-2">{{
          getFlightRole(flightAssignment.role).description
        }}</span>
      </div>

      <div>
        <span>Assigned by:</span
        ><span class="ml-1 font-weight-bold">{{
          flightAssignment.assignedBy
        }}</span>
      </div>
      <div>
        <span>Last Updated:</span
        ><span class="ml-1 font-weight-bold">{{
          formatDateTime(flightAssignment.updatedAt)
        }}</span>
      </div>

      <v-divider class="my-1"></v-divider>

      <div class="user-select-text">
        <p
          v-if="flightAssignment.remarks"
          class="font-abel-14px  indigo--text mt-2"
        >
          {{ flightAssignment.remarks }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightAssignmentEdit",
  data() {
    return {};
  },
  mixins: [mixin],
  computed: {
    flightAssignment() {
      return this.$store.getters.flightAssignment;
    },
  },
};
</script>

<style scoped>
.frame {
  width: 90%;
  max-width: 600px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
  border: 1px solid #bebebe;
  background-color: white;
}
</style>
