<template>
  <div>
    <Navbar />
    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <!-- Date Filter  -->
      <div class="d-flex align-center">
        <v-btn
          @click="dateFilterBlockBack"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>first_page</v-icon></v-btn
        >
        <v-btn @click="dateFilterBack" x-small icon dark :disabled="loading"
          ><v-icon>chevron_left</v-icon></v-btn
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="openDateFilter"
              small
              icon
              :disabled="loading"
            >
              <i class="fa-solid fa-calendar-days"></i>
            </v-btn>
          </template>
          <span
            >{{ formatDate(dateFilter.startDate) }} -
            {{ formatDate(dateFilter.endDate) }}</span
          >
        </v-tooltip>
        <v-btn @click="dateFilterForward" x-small icon dark :disabled="loading"
          ><v-icon>chevron_right</v-icon></v-btn
        >
        <v-btn
          @click="dateFilterBlockForward"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>last_page</v-icon></v-btn
        >
      </div>
      <!--Filters and  Warnings -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <v-badge
          v-if="enableSeeAssignmentWarnings"
          :content="warnings.length"
          :value="warnings.length"
          color="red"
          overlap
        >
          <v-btn
            :disabled="loading"
            @click="getWarnings(true)"
            small
            icon
            class="ml-1"
          >
            <i
              class="fa-solid fa-triangle-exclamation"
              :class="{ 'warning-state': warnings.length }"
            ></i>
          </v-btn>
        </v-badge>
      </div>

      <v-spacer></v-spacer>

      <v-slider
        v-if="boardStyle === 'Board'"
        @change="scrollBoard"
        class="ml-1"
        v-model="sliderX"
        :max="boardWidth"
        :min="0"
        hide-details
      >
      </v-slider>

      <v-spacer></v-spacer>

      <div class="font-abel-12px  mr-2">
        {{
          `${this.filteredAircrafts.length} aircraft - ${this.filteredFlights.length} flight(s)`
        }}
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="openFilters" v-on="on" small icon :disabled="loading">
            <v-icon :color="filterIconColor">filter_list</v-icon>
          </v-btn>
        </template>
        <span>Flight Board Filters</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="getFlights" v-on="on" small icon :disabled="loading">
            <v-icon color="green">refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh Flight Board</span>
      </v-tooltip>

      <div v-if="boardStyle === 'Board'">
        <v-btn @click="zoomIn" small icon class="ml-1" :disabled="loading">
          <v-icon color="blue">zoom_in</v-icon>
        </v-btn>

        <v-btn @click="zoomOut" small icon :disabled="loading">
          <v-icon color="white">zoom_out</v-icon>
        </v-btn>
      </div>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#D2D6D7">
          <!-- TRIP -->
          <v-list-item
            v-if="boardStyle === 'Board' && enableCreateTripAssignments"
            @click="handleInitTrip"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Trip Builder</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- STATION ASSIGNMENT  -->
          <v-list-item
            v-if="boardStyle === 'Board' && enableCreateGroundAssignments"
            @click="initStationAssignment"
            link
          >
            <v-list-item-icon>
              <v-icon>apps</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Station Assignment</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- SWITCH TO BOARD -->
          <v-list-item
            v-if="boardStyle !== 'Board'"
            @click="switchToBoardMode"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to Board</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- SWITCH TO LIST -->
          <v-list-item
            v-if="boardStyle === 'Board'"
            @click="switchToListMode"
            link
          >
            <v-list-item-icon>
              <v-icon>list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="openAircraftFilter" link>
            <v-list-item-icon>
              <v-icon>flight</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Aircraft Filter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-if="boardStyle === 'Board'"
            :disabled="loading"
            @click="captureFlightBoard"
            link
          >
            <v-list-item-icon>
              <v-icon>print</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Print Flight Board</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Schedule Conflicts -->
          <v-list-item
            v-if="enableGetAircraftScheduleConflicts"
            @click="handleGetAircraftScheduleConflicts"
            link
          >
            <v-list-item-icon>
              <i class="fa-solid fa-calendar-circle-exclamation"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Schedule Conflicts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="openUnitConverter" link>
            <v-list-item-icon>
              <v-icon>calculate</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Weight Conversion</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <!-- Date Filter  -->
      <div class="d-flex align-center">
        <v-btn
          @click="dateFilterBlockBack"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>first_page</v-icon></v-btn
        >
        <v-btn @click="dateFilterBack" x-small icon dark :disabled="loading"
          ><v-icon>chevron_left</v-icon></v-btn
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="openDateFilter"
              small
              icon
              :disabled="loading"
            >
              <i class="fa-solid fa-calendar-days"></i>
            </v-btn>
          </template>
          <span
            >{{ formatDate(dateFilter.startDate) }} -
            {{ formatDate(dateFilter.endDate) }}</span
          >
        </v-tooltip>
        <v-btn @click="dateFilterForward" x-small icon dark :disabled="loading"
          ><v-icon>chevron_right</v-icon></v-btn
        >
        <v-btn
          @click="dateFilterBlockForward"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>last_page</v-icon></v-btn
        >
      </div>
      <!--Filters and  Warnings -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <v-badge
          v-if="enableSeeAssignmentWarnings"
          :content="warnings.length"
          :value="warnings.length"
          color="red"
          overlap
        >
          <v-btn
            :disabled="loading"
            @click="getWarnings(true)"
            small
            icon
            class="ml-1"
          >
            <i
              class="fa-solid fa-triangle-exclamation"
              :class="{ 'warning-state': warnings.length }"
            ></i>
          </v-btn>
        </v-badge>
      </div>

      <v-spacer></v-spacer>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- TRIP -->
          <v-list-item
            v-if="boardStyle === 'Board' && enableCreateTripAssignments"
            @click="handleInitTrip"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Trip Builder</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- STATION ASSIGNMENT -->
          <v-list-item
            v-if="boardStyle === 'Board' && enableCreateGroundAssignments"
            @click="initStationAssignment"
            link
          >
            <v-list-item-icon>
              <v-icon>apps</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Station Assignment</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Refresh -->
          <v-list-item @click="getFlights" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Flight Filters -->
          <v-list-item @click="openFilters" link>
            <v-list-item-icon>
              <v-icon :color="filterIconColor">filter_list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Flight Filters</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom In -->
          <v-list-item v-if="boardStyle === 'Board'" @click="zoomIn" link>
            <v-list-item-icon>
              <v-icon color="blue">zoom_in</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom Out -->
          <v-list-item v-if="boardStyle === 'Board'" @click="zoomOut" link>
            <v-list-item-icon>
              <v-icon color="white">zoom_out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Change Board View -->
          <v-list-item
            v-if="boardStyle !== 'Board'"
            @click="switchToBoardMode"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to Board</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="boardStyle === 'Board'"
            @click="switchToListMode"
            link
          >
            <v-list-item-icon>
              <v-icon>list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="openAircraftFilter" link>
            <v-list-item-icon>
              <v-icon>flight</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Aircraft Filter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-if="boardStyle === 'Board'"
            :disabled="loading"
            @click="captureFlightBoard"
            link
          >
            <v-list-item-icon>
              <v-icon>print</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Print Flight Board</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Schedule Conflicts -->
          <v-list-item
            v-if="enableGetAircraftScheduleConflicts"
            @click="handleGetAircraftScheduleConflicts"
            link
          >
            <v-list-item-icon>
              <i class="fa-solid fa-calendar-circle-exclamation"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Schedule Conflicts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="openUnitConverter" link>
            <v-list-item-icon>
              <v-icon>calculate</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Weight Conversion</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- CONFIRM-CONTINUE -->
    <v-dialog v-model="showConfirmDialog" persistent max-width="750px">
      <div v-if="confirmState === 'delete-flight-assignment'" class="red-frame">
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelConfirm" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="my-5">
          <h2>
            Removing a Flight Assignment cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn
              @click="handleConfirmDeleteFlightAssignment"
              small
              dark
              color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelConfirm"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div
        v-if="confirmState === 'delete-station-assignment'"
        class="red-frame"
      >
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelConfirm" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="my-5">
          <h2>
            Removing a Ground Assignment cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn
              @click="handleConfirmDeleteStationAssignment"
              small
              dark
              color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelConfirm"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="confirmState === 'remove-employee'" class="red-frame">
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelRemoveEmployee" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="my-5">
          <h2>
            Removing an employee cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn @click="handleConfirmRemoveEmployee" small dark color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelRemoveEmployee"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- TRIP BUILDER-->
    <div v-if="trip" class="trip-frame font-abel-16px">
      <div v-if="trip.flights.length" class="d-flex justify-start mb-3">
        <div
          class="trip-flight"
          @click="removeFlightFromTrip(flight)"
          v-for="flight in trip.flights"
          :key="flight.flightId"
        >
          {{ `${flight.originIata} - ${flight.destinationIata}` }}
        </div>
      </div>
      <div v-else class="mb-3">
        <h3>Select flights from the board</h3>
      </div>

      <div class="d-flex justify-space-between align-end">
        <div class="d-flex align-center font-abel-14px my-3">
          <!-- <h3>
              {{ `Segments: ${trip.flights.length}` }}
            </h3> -->
          <h3 v-if="trip.flights.length">
            {{
              `Segments: ${
                trip.flights.length
              },  Total Duty: ${formatElapsedTime(trip.durationInMinutes)}`
            }}
          </h3>

          <v-btn
            v-if="trip.flights.length"
            @click="handleResetTrip"
            class="ml-2 text-capitalize"
            x-small
            dark
            outlined
            >reset</v-btn
          >
        </div>

        <div class="d-flex justify-end my-3">
          <v-btn
            :disabled="!trip.flights.length"
            @click="handleAssignTrip"
            small
            dark
            color="blue"
            >Assign</v-btn
          >
          <v-btn class="ml-1" @click="trip = null" small dark color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
    </div>

    <!-- STATION ASSIGNMENT BUILDER-->
    <div
      v-if="stationAssignment"
      class="station-assignment-frame font-abel-16px"
    >
      <div class="r-dialog">
        <div
          v-if="stationAssignment.flights.length"
          class="d-flex justify-start mb-3"
        >
          <div
            class="trip-flight"
            @click="removeFlightFromStationAssignment(flight)"
            v-for="flight in stationAssignment.flights"
            :key="flight.flightId"
          >
            {{ `${flight.originIata} - ${flight.destinationIata}` }}
          </div>
        </div>
        <div v-else class="mb-3">
          <h3>Select flights from the board</h3>
        </div>

        <div class="d-flex justify-space-between align-end">
          <div class="d-flex align-center font-abel-14px">
            <v-select
              :disabled="stationAssignment.flights.length > 0"
              class="mr-3"
              dark
              dense
              v-model="stationAssignment.stationIata"
              :items="user.settings.filters.station.stationCodes"
              label="Station"
            ></v-select>
            <h3>
              {{ `Assignments: ${stationAssignment.flights.length}` }}
            </h3>

            <v-btn
              v-if="
                stationAssignment.flights.length &&
                  stationAssignment.stationIata
              "
              @click="resetStationAssignment"
              class="ml-2 text-capitalize"
              x-small
              dark
              outlined
              >reset</v-btn
            >
          </div>

          <div class="d-flex justify-end my-3">
            <v-btn
              :disabled="!stationAssignment.flights.length"
              @click="assignStationAssignment"
              small
              dark
              color="blue"
              >Assign</v-btn
            >
            <v-btn
              class="ml-1"
              @click="stationAssignment = null"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </div>

    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-model="showFlightAssignmentInformationDialog"
      persistent
      max-width="780px"
    >
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </v-dialog>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-if="showStationAssignmentInformationDialog"
      v-model="showStationAssignmentInformationDialog"
      max-width="780px"
    >
      <station-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </v-dialog>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <v-dialog v-model="specialAirportsDialog" persistent max-width="800px">
      <SpecialAirports @hide="specialAirportsDialog = false" />
    </v-dialog>

    <!-- AIRPORT INFORMATION DIALOG -->
    <v-dialog
      v-model="showAirportInformationDialog"
      persistent
      max-width="450px"
      transition="slide-x-transition"
    >
      <div v-if="airport" class="blue-frame font-abel-16px">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-30px">
            {{ `${airport.iata} - ${airport.icao}` }}
          </div>
          <v-btn @click="hideAirportInformationDialog" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>
        <v-divider></v-divider>

        <div>Airport Name: {{ `  ${airport.name}` }}</div>
        <div>Country: {{ ` ${airport.country}` }}</div>
        <v-divider></v-divider>
        <div>Min. Loadmaster Required Exemptions:</div>
        <div v-if="airport.loadmasterExemptions.length">
          <div
            class="exemption-row"
            v-for="(exemption, index) in airport.loadmasterExemptions"
            :key="index"
          >
            {{
              `${exemption.aircraftType} - ${formatExemptionValidPeriod(
                exemption
              )}`
            }}
          </div>
        </div>

        <div v-else>This airport has no exemptions</div>
      </div>
    </v-dialog>

    <!-- WARNING DIALOG -->
    <v-dialog
      v-if="warningDialog"
      v-model="warningDialog"
      persistent
      max-width="1200px"
    >
      <div class="red-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Warnings
          </div>
          <v-btn @click="warningDialog = false" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="d-flex justify-space-between align-center px-5">
          <div>
            <v-text-field
              v-model="warningsSearch"
              dense
              append-icon="search"
              label="Search"
              single-line
              hide-details
              :style="{
                maxWidth: '200px',
                marginLeft: '10px',
                marginBottom: '10px',
              }"
            ></v-text-field>
          </div>

          <div>
            <v-select
              @change="filterWarnings"
              label="Loadmaster Required"
              dense
              :items="loadmasterMissingOptions"
              v-model="selectedLoadmasterMissingWarningLevel"
            >
            </v-select>
          </div>
        </div>

        <v-data-table
          dense
          :headers="warningHeaders"
          :search="warningsSearch"
          :items="filteredWarnings"
          :footer-props="{
            itemsPerPageOptions: [50, 100, 200],
          }"
          @click:row="getFlightFromWarning"
          item-key="_id"
        >
        </v-data-table>

        <div class="d-flex justify-end font-abel-16px mt-2">
          <v-btn
            @click="warningDialog = false"
            class="ml-1"
            small
            dark
            color="grey"
            >close</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- PAYLOAD WARNING DIALOG -->
    <v-dialog
      v-if="payloadWarningDialog"
      v-model="payloadWarningDialog"
      persistent
      max-width="600px"
    >
      <div class="r-dialog-red font-abel-16px">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Payload Errors
          </div>
          <v-btn @click="payloadWarningDialog = false" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <!-- PAYLOAD ERRORS -->
        <div v-if="payloadErrors.length">
          <div
            class="r-tile"
            v-for="(payloadError, index) in payloadErrors"
            :key="index"
          >
            {{
              `${payloadError.description}. If you override this warning and continue the applicable maximum payload will be displayed.`
            }}
          </div>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox
            v-model="overrideWarnings"
            label="Override Warnings"
          ></v-checkbox>

          <div class="d-flex justify-end align-center font-abel-16px">
            <v-btn
              :disabled="!overrideWarnings"
              @click="savePayload"
              small
              dark
              color="blue"
              >continue</v-btn
            >
            <v-btn
              @click="payloadWarningDialog = false"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- CARGO PAYLOAD DIALOG-->
    <v-dialog v-if="flight" v-model="cargoPayloadDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Cargo Payload</div>
          <v-btn @click="cargoPayloadDialog = false" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="cargo-payload">
          <v-form ref="cargoForm" v-model="cargoPayloadValid">
            <div class="flex-center">
              <div class="input-frame">
                <h5>Estimated Payload</h5>
                <div class="flex-start">
                  <input
                    type="number"
                    step="1000"
                    min="0"
                    max="99999"
                    required
                    v-model="cargo"
                  />

                  <div class="text-left dark-blue--text">
                    {{ flight.aircraft.weightUnit }}
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </div>

        <div class="d-flex justify-end align-center mt-2">
          <v-btn
            :disabled="!cargoPayloadValid"
            @click="saveCargoPayload"
            small
            dark
            color="green"
            >save</v-btn
          >
          <v-btn
            class="ml-1"
            @click="cargoPayloadDialog = false"
            small
            dark
            color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- FLIGHT DETAIL -->
    <v-dialog v-model="flightDialog" persistent max-width="800px">
      <div v-if="flight" class="r-dialog-green-lighten3 font-abel-14px">
        <!-- FLIGHT NUMBER -->
        <div class="d-flex justify-space-between font-abel-20px">
          <div>
            {{ flight.flightNumber }} - {{ flight.aircraftRegistration }} -
            {{ flight.aircraft.cargoOnly ? "CARGO" : "PAX"
            }}<i v-if="flight.isVip" class="fa-solid fa-star"></i>
          </div>
          <v-btn @click="hideFlightDialog(false)" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <!-- CUSTOMER / STATUS -->
        <div class="d-flex justify-space-between align-center font-abel-20px">
          <p>Customer: {{ flight.customerName }}</p>
          <p>Status: {{ flight.status }}</p>
        </div>

        <!-- ORIGIN / PAYLOAD / DESTINATION -->

        <div class="d-flex justify-space-between">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" @click="handleGetPreviousFlight">
                <i class="fa-solid fa-left"></i>
              </div>
            </template>
            <span>Previous Flight</span>
          </v-tooltip>

          <!-- Origin -->
          <div class="mx-auto">
            <div
              @click="getAirport(flight.originIata)"
              class="text-center font-abel-20px-bold cursor-hand"
            >
              {{ flight.originIata }}
            </div>
            <div class="text-center">
              {{ formatDate(flight.effectiveTimeOut) }}
            </div>
            <div class="text-center font-abel-20px-bold grey--text">
              {{ formatTime(flight.effectiveTimeOut) }} Z
            </div>
          </div>

          <v-divider vertical></v-divider>

          <!-- Payload -->
          <div class="mx-auto">
            <div v-if="!flight.aircraft.cargoOnly">
              <div v-if="flight.payload" class="mx-auto cursor-hand">
                <div @click="openPayloadCalculator">
                  <div class="text-center">
                    Passengers:
                    <span class=" font-abel-20px-bold">{{
                      flight.payload.numberOfPassengers
                    }}</span>
                  </div>
                  <div class="text-center">
                    Payload:
                    <span class="font-abel-20px-bold">
                      {{
                        `${formatWeight(flightPayload)} ${
                          flight.aircraft.weightUnit
                        }`
                      }}
                    </span>
                  </div>
                  <div class="text-center">
                    SWA:
                    <span class="font-abel-20px-bold">
                      {{
                        `${formatWeight(flight.payload.swaCategory.value)} ${
                          flight.aircraft.weightUnit
                        }`
                      }}
                    </span>
                    <span>{{ ` - ${flight.payload.swaCategory.name}` }}</span>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center">
                  <div class="font-abel-14px mr-5 grey--text">
                    Estimated Paxs. and Weights.
                  </div>
                  <v-btn class="ml-10" @click="showMaximumPayload" small icon
                    ><v-icon small color="blue">info</v-icon></v-btn
                  >
                </div>
              </div>
              <div v-else class="mx-auto cursor-hand">
                <div @click="openPayloadCalculator">
                  <div class="text-center">
                    Passengers:
                    <span class=" font-abel-20px-bold">{{ 0 }}</span>
                  </div>
                  <div class="text-center">
                    Payload:
                    <span class="font-abel-20px-bold">
                      {{ `${formatWeight(0)} ${flight.aircraft.weightUnit}` }}
                    </span>
                  </div>
                  <div class="text-center">
                    SWA:
                    <span class="font-abel-20px-bold">
                      {{ `${formatWeight(0)} ${flight.aircraft.weightUnit}` }}
                    </span>
                  </div>
                  <div class="d-flex justify-space-between align-center">
                    <div class="font-abel-14px mr-5 grey--text">
                      Estimated Paxs. and Weights.
                    </div>
                    <v-btn class="ml-10" @click="showMaximumPayload" small icon
                      ><v-icon small color="blue">info</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="flight.payload" class="mx-auto cursor-hand">
                <v-spacer></v-spacer>
                <div @click="openCargoPayloadCalculator">
                  <div class="text-center">
                    Payload:
                    <span class="font-abel-20px-bold">
                      {{
                        `${formatWeight(flight.payload.cargo)} ${
                          flight.aircraft.weightUnit
                        }`
                      }}
                    </span>
                  </div>
                </div>

                <div class="d-flex justify-space-between align-center">
                  <div class="font-abel-14px mr-5 grey--text">
                    Estimated Weight
                  </div>
                  <v-btn @click="showMaximumPayload" small icon
                    ><v-icon small color="blue">info</v-icon></v-btn
                  >
                </div>
              </div>
              <div v-else class="mx-auto cursor-hand">
                <div @click="openCargoPayloadCalculator">
                  <div class="text-center">
                    Payload:
                    <span class="font-abel-20px-bold">
                      {{ `${formatWeight(0)} ${flight.aircraft.weightUnit}` }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center">
                  <div class="font-abel-14px mr-5 grey--text">
                    Estimated Weight
                  </div>
                  <v-btn @click="showMaximumPayload" small icon
                    ><v-icon small color="blue">info</v-icon></v-btn
                  >
                </div>
              </div>
            </div>
          </div>
          <v-divider vertical></v-divider>

          <!-- Destination -->
          <div class="mx-auto">
            <div
              @click="getAirport(flight.destinationIata)"
              class="text-center font-abel-20px-bold cursor-hand"
            >
              {{ flight.destinationIata }}
            </div>
            <div class="text-center">
              {{ formatDate(flight.effectiveTimeIn) }}
            </div>
            <div class="text-center font-abel-20px-bold grey--text">
              {{ formatTime(flight.effectiveTimeIn) }} Z
            </div>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" @click="handleGetNextFlight">
                <i class="fa-solid fa-right"></i>
              </div>
            </template>
            <span>Next Flight</span>
          </v-tooltip>
        </div>

        <v-divider></v-divider>

        <!-- CREWMEMBERS -->
        <div class="mb-1">
          <div class="d-flex align-center">
            <div class="black--text font-weight-bold">Flight Crew</div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="enableCreateFlightAssignments"
                  class="ml-1"
                  v-on="on"
                  small
                  icon
                  @click="handleGetFlightCrewmember"
                  ><v-icon color="blue">add</v-icon></v-btn
                >
              </template>
              <span>Add Crewmember</span>
            </v-tooltip>
          </div>
          <!-- FLIGHT CREWMEMBERS -->
          <div v-if="flight.crewmembers && flight.crewmembers.length > 0">
            <div v-for="crewmember in flight.crewmembers" :key="crewmember._id">
              <div
                v-if="crewmember.employee"
                class="d-flex justify-space-between align-center"
              >
                <div class="d-flex justify-start align-center">
                  <div>{{ getFlightRole(crewmember.role).description }}:</div>
                  <div>
                    <v-btn
                      @click="
                        handleShowFlightAssignmentInformationDialog(crewmember)
                      "
                      class="ml-1 text-capitalize font-weight-bold"
                      color="indigo"
                      small
                      text
                    >
                      {{ crewmember.employee.surname }},
                      {{ crewmember.employee.givenName }}
                    </v-btn>
                  </div>
                </div>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense color="#D2D6D7">
                    <!-- SEND NOTIFICATION -->
                    <v-list-item
                      v-if="enableCreateAssignmentNotification"
                      @click="handleSendAssignmentNotification(crewmember)"
                      link
                    >
                      <v-list-item-icon>
                        <i class="fa-solid fa-envelope"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Send Notification</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- REMOVE EMPLOYEE -->
                    <v-list-item
                      v-if="enableUpdateFlightAssignment"
                      @click="handleRemoveEmployee(crewmember)"
                      link
                    >
                      <v-list-item-icon>
                        <i class="fa-solid fa-user-slash"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Remove Employee</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- DELETE ASSIGNMENT -->
                    <v-list-item
                      v-if="enableUpdateFlightAssignment"
                      @click="handleDeleteFlightAssignment(crewmember)"
                      link
                    >
                      <v-list-item-icon>
                        <i class="fa-solid fa-trash-can"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Delete Assignment</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div v-else class="d-flex justify-space-between align-center">
                <div class="d-flex justify-start align-center">
                  <div>{{ getFlightRole(crewmember.role).description }}:</div>
                  <div>
                    <v-btn
                      @click="
                        handleShowFlightAssignmentInformationDialog(crewmember)
                      "
                      class="ml-1 text-capitalize font-weight-bold"
                      color="red"
                      small
                      text
                    >
                      {{ crewmember.assignedToCompany }}
                    </v-btn>
                  </div>
                </div>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense color="#D2D6D7">
                    <!-- ASSIGN EMPLOYEE -->
                    <v-list-item
                      v-if="enableUpdateFlightAssignment"
                      @click="handleGetFlightCrewmember"
                      link
                    >
                      <v-list-item-icon>
                        <i class="fa-solid fa-person-walking-luggage"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Assign Employee</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- UNABLE TO COVER -->
                    <v-list-item
                      v-if="
                        enableUpdateFlightAssignment &&
                          crewmember.assignedToCompany !== 'Atlas Air'
                      "
                      @click="handleUnableToCoverFlightAssignment(crewmember)"
                      link
                    >
                      <v-list-item-icon>
                        <i class="fa-solid fa-circle-exclamation"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Unable to Cover</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <!-- DELETE ASSIGNMENT -->
                    <v-list-item
                      v-if="enableUpdateFlightAssignment"
                      @click="handleDeleteFlightAssignment(crewmember)"
                      link
                    >
                      <v-list-item-icon>
                        <i class="fa-solid fa-trash-can"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Delete Assignment</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <v-divider></v-divider>
            </div>
          </div>
          <div v-else>
            <span class="red--text">No Crewmembers Assigned</span>
            <v-divider></v-divider>
          </div>
        </div>

        <!-- GROUND CREWMEMBERS -->
        <div class="d-flex align-center">
          <div class="brown--text font-weight-bold">Ground Crew</div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!originStationAssignmentEnabled"
                class="ml-2"
                v-on="on"
                x-small
                color="blue"
                outlined
                @click="
                  initSingleFlightStationAssignment({
                    flight,
                    station: 'origin',
                  })
                "
                >{{ flight.originIata }}</v-btn
              >
            </template>
            <span>{{ `Add Ground Crewmember in ${flight.originIata}` }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="!destinationStationAssignmentEnabled"
                class="ml-1"
                v-on="on"
                x-small
                color="blue"
                outlined
                @click="
                  initSingleFlightStationAssignment({
                    flight,
                    station: 'destination',
                  })
                "
                >{{ flight.destinationIata }}</v-btn
              >
            </template>
            <span>{{
              `Add Ground Crewmember in ${flight.destinationIata}`
            }}</span>
          </v-tooltip>
        </div>
        <div
          class="mb-1"
          v-if="flight.groundCrewmembers && flight.groundCrewmembers.length > 0"
        >
          <div
            v-for="groundCrewmember in flight.groundCrewmembers"
            :key="groundCrewmember._id"
          >
            <div
              v-if="groundCrewmember.employee"
              class="d-flex justify-space-between align-center"
            >
              <div class="d-flex justify-start align-center">
                <div>
                  {{
                    `${groundCrewmember.subCategory} (${groundCrewmember.originIata})`
                  }}:
                </div>
                <div>
                  <v-btn
                    @click="
                      handleShowStationAssignmentInformationDialog(
                        groundCrewmember
                      )
                    "
                    class="ml-1 text-capitalize font-weight-bold"
                    color="indigo"
                    small
                    text
                  >
                    {{ groundCrewmember.employee.surname }},
                    {{ groundCrewmember.employee.givenName }}
                  </v-btn>
                </div>
              </div>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense color="#D2D6D7">
                  <!-- SEND NOTIFICATION -->
                  <v-list-item
                    v-if="enableCreateAssignmentNotification"
                    @click="
                      handleSendStationAssignmentNotification(groundCrewmember)
                    "
                    link
                  >
                    <v-list-item-icon>
                      <i class="fa-solid fa-envelope"></i>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Send Notification</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- DELETE ASSIGNMENT -->
                  <v-list-item
                    v-if="enableUpdateFlightAssignment"
                    @click="handleDeleteStationAssignment(groundCrewmember)"
                    link
                  >
                    <v-list-item-icon>
                      <i class="fa-solid fa-trash-can"></i>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Delete Assignment</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else class="d-flex justify-space-between align-center">
              <div class="d-flex justify-start align-center">
                <div>
                  {{
                    `${groundCrewmember.subCategory} (${groundCrewmember.originIata})`
                  }}:
                </div>
                <div>
                  <v-btn
                    @click="
                      handleShowStationAssignmentInformationDialog(
                        groundCrewmember
                      )
                    "
                    class="ml-1 text-capitalize font-weight-bold"
                    color="indigo"
                    small
                    text
                  >
                    {{ groundCrewmember.assignedToCompany }}
                  </v-btn>
                </div>
              </div>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list dense color="#D2D6D7">
                  <!-- UNABLE TO COVER -->
                  <v-list-item
                    v-if="
                      enableUpdateGroundAssignment &&
                        groundCrewmember.assignedToCompany !== 'Atlas Air'
                    "
                    @click="
                      handleUnableToCoverStationAssignment(groundCrewmember)
                    "
                    link
                  >
                    <v-list-item-icon>
                      <i class="fa-solid fa-circle-exclamation"></i>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Unable to Cover</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- DELETE ASSIGNMENT -->
                  <v-list-item
                    v-if="enableDeleteGroundAssignment"
                    @click="handleDeleteStationAssignment(groundCrewmember)"
                    link
                  >
                    <v-list-item-icon>
                      <i class="fa-solid fa-trash-can"></i>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Delete Assignment</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-divider></v-divider>
          </div>
        </div>
        <div v-else>
          <span class="red--text">No Ground Crewmembers Assigned</span>
          <v-divider></v-divider>
        </div>

        <!-- AIMS Crew -->
        <div class="blue--text mt-1">AIMS Crew</div>
        <div class="r-frame-vertical-scroll">
          <div v-if="flight.crew && flight.crew.length > 0">
            <div v-for="c in flight.crew" :key="c._id">
              <span
                >{{ c.positionCode.length > 0 ? c.positionCode : "JS" }}
              </span>
              <span class="ml-1">
                {{ c.name }}
              </span>
              <v-divider></v-divider>
            </div>
          </div>

          <!-- AIMS Jumpseaters-->
          <div v-if="flight.jumpseaters && flight.jumpseaters.length > 0">
            <div v-for="j in flight.jumpseaters" :key="j._id">
              <span>{{ j.departmentCode ? j.departmentCode : "JS" }}: </span>
              <span class="ml-1">
                {{ j.name }}
              </span>
            </div>
            <v-divider></v-divider>
          </div>
        </div>

        <!-- Remarks -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="my-1"
              :disabled="!enableUpdateFlightRemarks"
              v-on="on"
              small
              outlined
              color="indigo"
              @click="handleEnableEditFlightRemarks"
              >Remarks</v-btn
            >
          </template>
          <span>Add or Edit Flight Remarks</span>
        </v-tooltip>

        <div v-if="!editRemarks" class="remarks">
          <p
            v-if="flight.remarks"
            class="user-select-text font-abel-14px  indigo--text mt-2"
          >
            {{ flight.remarks.text }}
          </p>
          <p v-else></p>
        </div>
        <div v-else>
          <v-textarea v-model="flightRemarks" no-resize rows="3"></v-textarea>
          <div class="d-flex justify-end">
            <v-btn
              @click="handleSaveFlightRemarks"
              small
              dark
              color="green"
              :disabled="snackbarVisible"
              >save remarks</v-btn
            >
            <v-btn
              @click="handleCancelEditFlightRemarks"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>

        <div class="flight-id mt-1">{{ flight.flightId }}</div>
      </div>
    </v-dialog>

    <!-- PAYLOAD DIALOG-->
    <v-dialog v-if="flight" v-model="payloadDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Payload Calculator</div>
          <v-btn @click="hidePayloadDialog" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="payload-calculator">
          <v-form
            v-if="!showWeightConverter && !showReverseCalculator"
            ref="form"
            v-model="payloadValid"
          >
            <h3>
              {{ `Enter all Weights in ${flight.aircraft.weightUnit}` }}
            </h3>

            <div class="flex-start mb-5">
              <div class="input-frame">
                <h5>Passengers</h5>
                <input
                  type="number"
                  step="10"
                  min="1"
                  max="999"
                  required
                  v-model="numberOfPassengers"
                />
              </div>

              <div class="input-frame">
                <h5>Weight</h5>
                <div class="flex-start">
                  <input
                    type="number"
                    step="100"
                    min="0"
                    max="999"
                    required
                    v-model="estimatedWeightPerPassenger"
                  />
                  <div class="text-left dark-blue--text">
                    {{ flight.aircraft.weightUnit }}
                  </div>
                </div>
              </div>
            </div>
          </v-form>

          <div v-if="!showReverseCalculator && !showWeightConverter">
            <div class="flex-space-between my-2">
              <h4>Preliminary Payload</h4>

              <div class="flex-end  text-right dark-blue--text">
                <div>
                  {{ formatWeight(preliminaryWeight) }}
                </div>
                <div>
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>

            <div class="flex-space-between my-2">
              <div class="flex-start">
                <h4>Buffer Weight</h4>

                <select v-model="selectedBufferWeight" required>
                  <option
                    v-for="weight in availableBufferWeights"
                    :key="weight"
                    :value="weight"
                  >
                    {{ formatWeight(weight) }}
                  </option>
                </select>
              </div>
              <div class="flex-end  text-right dark-blue--text">
                <div>
                  {{ formatWeight(selectedBufferWeight) }}
                </div>
                <div>
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>

            <div class="flex-space-between my-2">
              <div class="flex-start">
                <h4>SWA Category</h4>
                <select v-model="swaCategory" required>
                  <option
                    v-for="category in flight.aircraft.swaCategories"
                    :key="category.name"
                    :value="category"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <div class="flex-end  text-right dark-blue--text">
                <div>
                  {{ formatWeight(swaCategory.value) }}
                </div>
                <div>
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>

            <div class="flex-space-between my-2">
              <h4>Final Estimated Payload</h4>
              <div class="flex-end  text-right dark-blue--text">
                <div>
                  {{ formatWeight(finalEstimatedPayload) }}
                </div>
                <div>
                  {{ flight.aircraft.weightUnit }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="showReverseCalculator && !showWeightConverter">
            <!-- REVERSE CALCULATOR -->
            <div class="reverse-calculator">
              <div class="d-flex justify-space-between">
                <div>Reverse Calculator</div>
                <v-btn @click="showReverseCalculator = false" small icon
                  ><i class="fa-solid fa-xmark"></i
                ></v-btn>
              </div>
              <div class="flex-center my-5">
                <div class="input-frame">
                  <h4>Final Estimated Payload</h4>
                  <div class="flex-start">
                    <input
                      type="number"
                      step="1000"
                      :min="selectedBufferWeight"
                      max="99999"
                      required
                      v-model="reverseCalculatorPayload"
                    />
                    <div class="text-left dark-blue--text">
                      {{ flight.aircraft.weightUnit }}
                    </div>
                  </div>
                </div>
              </div>
              <p>
                {{
                  `This reverse calculator determines the average passenger weight, considering the final estimated payload, which includes the combined weight of all passengers and an additional buffer weight.`
                }}
              </p>

              <div class="flex-end">
                <v-btn
                  @click="calculateAveragePassengerWeight"
                  small
                  dark
                  color="blue"
                  >calculate</v-btn
                >
              </div>
            </div>
          </div>

          <div class="my-5" v-if="showWeightConverter">
            <div class="basic-frame">
              <div class="d-flex justify-space-between">
                <div>Weight Converter</div>
                <v-btn @click="showWeightConverter = false" small icon
                  ><i class="fa-solid fa-xmark"></i
                ></v-btn>
              </div>
              <div class="weight-converter" dense>
                <div
                  class="d-flex justify-center align-center font-abel-20px my-2"
                >
                  <div class="input-frame">
                    <h5>Weight</h5>
                    <input
                      type="number"
                      step="1000"
                      min="0"
                      max="99999"
                      required
                      v-model="weight"
                    />
                  </div>

                  <div class="input-frame">
                    <h5>Unit</h5>
                    <select v-model="units" required>
                      <option
                        v-for="unit in convertFromOptions"
                        :key="unit"
                        :value="unit"
                      >
                        {{ unit }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="d-flex justify-space-between align-center mx-1 ">
                  <v-checkbox
                    dense
                    class="mr-5"
                    v-model="rounded"
                    label="Round Up"
                  ></v-checkbox>

                  <div
                    class="d-flex justify-center align-center font-abel-30px "
                  >
                    {{ convertedWeight }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="!showWeightConverter && !showReverseCalculator"
            class=" my-5 d-flex justify-space-between"
          >
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="handleShowWeightConverter"
                    class="text-capitalize"
                    small
                    icon
                    ><i class="fa-solid fa-calculator"></i
                  ></v-btn>
                </template>
                <span>Weight Conversion</span>
              </v-tooltip>
            </div>

            <div>
              <v-btn
                @click="handleShowReverseCalculator"
                class="text-capitalize"
                small
                outlined
                dark
                color="blue"
                >Reverse Calculator</v-btn
              >
            </div>
          </div>
        </div>

        <div
          v-if="!showWeightConverter && !showReverseCalculator"
          class="d-flex justify-space-between align-center mt-2"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn @click="deletePayload" small dark color="grey" v-on="on"
                >reset</v-btn
              >
            </template>
            <span>Reset and close Payload Calculator</span>
          </v-tooltip>

          <div class="d-flex justify-end">
            <v-btn
              :disabled="!payloadValid"
              @click="savePayload"
              small
              dark
              color="green"
              >save</v-btn
            >
            <v-btn
              class="ml-1"
              @click="hidePayloadDialog"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- UNIT CONVERTER DIALOG-->
    <v-dialog v-model="unitConverterDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-5 ml-2">
          <div class="font-abel-24px">Weight Conversion Tool</div>
          <v-btn @click="unitConverterDialog = false" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <div class="d-flex justify-center align-center font-abel-20px my-2">
          <div class="input-frame">
            <h5>Weight</h5>
            <input
              type="number"
              step="1000"
              min="0"
              max="99999"
              required
              v-model="weight"
            />
          </div>

          <div class="input-frame">
            <h5>Unit</h5>
            <select v-model="units" required>
              <option
                v-for="unit in convertFromOptions"
                :key="unit"
                :value="unit"
              >
                {{ unit }}
              </option>
            </select>
          </div>
        </div>

        <div class="d-flex justify-space-between align-center mx-1 ">
          <v-checkbox
            dense
            class="mr-5"
            v-model="rounded"
            label="Round Up"
          ></v-checkbox>

          <div class="d-flex justify-center align-center font-abel-30px ">
            {{ convertedWeight }}
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- AIRCRAFT INFORMATION DIALOG -->
    <v-dialog
      v-model="aircraftDialog"
      max-width="450px"
      transition="slide-x-transition"
    >
      <div v-if="aircraft" class="blue-frame font-abel-16px">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-30px">{{ aircraft.registration }}</div>
          <v-btn @click="aircraftDialog = false" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>
        <v-divider></v-divider>
        <div class="mt-2">Type: {{ aircraft.type }}</div>

        <div v-if="!aircraft.cargoOnly">
          <div>
            Max. Payload:
            {{
              `${formatWeight(aircraft.maximumPayload)} ${aircraft.weightUnit}`
            }}
          </div>
          <v-divider></v-divider>
          <div>SWA Categories:</div>
          <div v-for="category in aircraft.swaCategories" :key="category.name">
            {{
              `${category.name}: ${formatWeight(category.value)} ${
                aircraft.weightUnit
              }`
            }}
          </div>
        </div>

        <div v-if="aircraft.cabinConfig.length">
          <v-divider></v-divider>
          <div>Config: {{ `${formatCabinConfig(aircraft.cabinConfig)}` }}</div>
        </div>
      </div>
    </v-dialog>

    <!-- AIRCRAFT FILTER DIALOG -->
    <v-dialog v-model="aircraftFilterDialog" max-width="700px">
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Aircraft Filter</div>
          <v-btn @click="aircraftFilterDialog = false" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <v-data-table
          dense
          v-model="selectedAircrafts"
          show-select
          :headers="aircraftHeaders"
          :items="company.aircrafts"
          item-key="registration"
          :footer-props="{
            itemsPerPageOptions: [10, 20],
          }"
        >
          <template v-slot:[`item.cargoOnly`]="{ item }">
            <span>{{ item.cargoOnly ? "Cargo" : "Passenger" }}</span>
          </template>
        </v-data-table>

        <div class="d-flex justify-space-between align-center my-2">
          <div>
            <v-btn
              @click="selectAircraftAll"
              small
              dark
              color="blue "
              class="text-capitalize"
              >Select All</v-btn
            >
            <v-btn
              @click="selectAircraftNone"
              small
              dark
              color="blue darken-2"
              class="text-capitalize ml-1"
              >Select None</v-btn
            >
          </div>

          <div>
            <v-btn
              :disabled="selectedAircrafts.length === 0"
              @click="saveAircraftFilter"
              small
              dark
              color="green"
              >save</v-btn
            >
            <v-btn
              class="ml-1"
              @click="aircraftFilterDialog = false"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- FILTERS DIALOG -->
    <v-dialog v-model="filtersDialog" max-width="700px">
      <div v-if="filtersDialog" class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Flight Board Filters</div>
          <v-btn @click="hideFiltersDialog" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <v-container>
          <!-- Crew Status -->
          <v-row v-if="enableUpdateFlightAssignment">
            <v-col cols="12" sm="2">
              <v-checkbox
                v-model="userSettings.filters.crewStatus.active"
                label="Crew"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="userSettings.filters.crewStatus.companyName"
                :items="companyNameOptions"
                label="Company"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                class="ml-2"
                v-model="userSettings.filters.crewStatus.crewStatus"
                :items="crewStatusOptions"
                label="Crew Status"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Stations -->
          <v-row align-center>
            <v-col cols="12" sm="2">
              <v-checkbox
                v-model="userSettings.filters.station.active"
                label="Station"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="userSettings.filters.station.station"
                :items="stationOptions"
                label="Match"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="userSettings.filters.station.stationCodes"
                :items="companyAirports"
                label="IATA Codes"
                item-text="iata"
                item-value="iata"
                chips
                small-chips
                clearable
                deletable-chips
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- Aircrafts with at least one flight -->
          <v-row align-center>
            <v-col cold="12">
              <v-checkbox
                v-model="userSettings.filters.aircraftWithFlights.active"
                label="Show aircraft with at least one flight in the selected time period"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>

        <div class="d-flex justify-space-between mt-1">
          <v-btn @click="handleClearFilters" small dark color="blue"
            >clear filters</v-btn
          >
          <div class="d-flex justify-end">
            <v-btn @click="handleSaveFilters" small dark color="green"
              >save</v-btn
            >
            <v-btn
              class="ml-1"
              @click="hideFiltersDialog"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- DATE FILTER DIALOG-->
    <v-dialog v-model="dateFilterDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="mx-3 mb-2 font-abel-24px text-center">
          Select Date Range
        </div>

        <div class="d-flex justify-center px-10">
          <!-- Start Date -->
          <v-menu
            v-model="showStartCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedStartDate"
                label="Start"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedStartDate"
              no-title
              @input="showStartCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex justify-center px-10">
          <!-- End Date -->
          <v-menu
            v-model="showEndCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedEndDate"
                label="End"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedEndDate"
              @input="showEndCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex justify-end align-center">
          <v-btn @click="saveDateFilter" small dark color="green">save</v-btn>
        </div>
      </div>
    </v-dialog>

    <!-- FLIGHT BOARD -->
    <div ref="flightBoard" v-if="boardStyle === 'Board'">
      <div
        class="top-frame font-abel-16px"
        :style="{ top: `${flightBoardY + 88}px` }"
      >
        <div class="top-panel-left">Aircraft</div>
        <div class="top-panel-right">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${boardWidth}px`,
              left: `${scrollX}px`,
            }"
          >
            <div
              v-for="(day, index) in dateFilter.days"
              :key="index"
              class="top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}

              <div v-if="scale < 8" class="d-flex font-abel-10px">
                <div
                  v-bind:style="{
                    width: `${30 / scale}px`,
                  }"
                ></div>

                <div
                  v-for="tick in timeTicks"
                  :key="tick.index"
                  v-bind:style="{
                    width: `${60 / scale}px`,
                  }"
                >
                  <div>{{ tick.text }}</div>
                </div>
              </div>
            </div>

            <div
              v-if="currentTimeX > 0"
              class="current-time-box d-flex justify-center align-center font-abel-16px"
              v-bind:style="{
                left: `${currentTimeX - 21}px`,
                width: '42px',
              }"
            >
              {{ formatTime(new Date()) }}
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-frame" :style="{ top: `${flightBoardY + 128}px` }">
        <div class="bottom-panel-left">
          <div
            v-for="aircraft in filteredAircrafts"
            :key="aircraft._id"
            class="bottom-panel-left-row"
          >
            <div class="bottom-panel-left-row-section font-abel-20px">
              {{ aircraft.registration }}
            </div>
            <div
              @click="showAircraftInformation(aircraft)"
              class="bottom-panel-left-row-section"
              :style="getAircraftTypeStyle(aircraft)"
            >
              {{ aircraft.type }}
            </div>
          </div>
        </div>

        <div
          ref="bottomPanelRight"
          class="bottom-panel-right"
          @scroll="scrollHorizontal"
        >
          <div
            class="bottom-panel-right-inner"
            :style="{
              width: `${boardWidth}px`,
              height: `${boardHeight}px`,
            }"
          >
            <div
              v-for="aircraft in filteredAircrafts"
              :key="aircraft._id"
              class="bottom-panel-right-row"
              :style="{
                width: `${boardWidth}px`,
                backgroundColor: boardColor,
              }"
            >
              <div
                v-for="(day, index) in dateFilter.days"
                :key="index"
                class="bottom-panel-day"
                :style="{
                  width: `${1440 / scale}px`,
                  left: `${index * (1440 / scale)}px`,
                }"
              >
                <div
                  class="hour-line"
                  v-for="tick in timeTicks"
                  :key="tick.index"
                  v-bind:style="{
                    width: `${60 / scale}px`,
                  }"
                ></div>
              </div>

              <div
                v-if="currentTimeX > 0"
                class="current-time"
                v-bind:style="{
                  left: `${currentTimeX}px`,
                }"
              ></div>

              <div
                v-for="flight in aircraft.flights"
                :key="flight._id"
                class="flight-label-frame"
                :style="{
                  width: `${flight.effectiveBlockTime / scale}px`,
                  left: `${getLocationLeft(
                    flight.effectiveTimeOut,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightLabel
                  :flight="flight"
                  :aircraft="aircraft"
                  @clicked="getFlight($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FLIGHT LIST -->
    <div
      class="bottom-frame"
      v-if="boardStyle === 'List'"
      :style="{ backgroundColor: '#bebebe' }"
    >
      <v-container>
        <div class="d-flex justify-space-between align-center ">
          <v-text-field
            v-model="flightSearch"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :style="{
              maxWidth: '200px',
              marginLeft: '10px',
              marginBottom: '10px',
            }"
          ></v-text-field>

          <div class="d-flex justify-space-between align-center ">
            <v-checkbox
              class="mr-2"
              v-model="printGroundCrewmembers"
              label="Ground Crew"
            ></v-checkbox>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="printFlightBoardList"
                  v-on="on"
                  class="mx-2"
                  small
                  icon
                >
                  <v-icon color="blue">print</v-icon></v-btn
                >
              </template>
              <span>Print Flight Board List</span>
            </v-tooltip>
          </div>
        </div>

        <div>
          <v-data-table
            dense
            :headers="flightHeaders"
            :items="filteredFlights"
            :search="flightSearch"
            :footer-props="{
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
            @click:row="getFlightFromList"
            item-key="_id"
          >
            <template v-slot:[`item.effectiveTimeOut`]="{ item }">
              <span>{{ formatDateTime(item.effectiveTimeOut) }}</span>
            </template>

            <template v-slot:[`item.effectiveTimeIn`]="{ item }">
              <span>{{ formatDateTime(item.effectiveTimeIn) }}</span>
            </template>
          </v-data-table>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import FlightLabel from "../components/FlightLabel";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import SpecialAirports from "../components/SpecialAirports.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import serveStatic from "serve-static";

export default {
  name: "flightBoard",
  components: {
    FlightAssignmentInformation,
    StationAssignmentInformation,
    FlightLabel,
    AutoLogout,
    Navbar,
    SpecialAirports,
  },
  data() {
    return {
      loading: false,
      flight: null,
      groundAssignment: null,
      stationAssignmentRemarksDialog: false,
      printGroundCrewmembers: true,
      boardStyle: "Board",
      boardColor: "white",
      boardHeightAdjustment: 0,
      stationAssignment: null,
      trip: null,
      maximumTripSegments: 10,
      maximumTripDuration: 192,
      maximumGroundAssignments: 20,
      isMobile: false,
      getDataTimer: null,
      showReverseCalculator: false,
      reverseCalculatorPayload: 0,
      sliderX: 0,
      showAirportInformationDialog: false,
      airport: null,
      filtersDialog: false,
      crewStatusOptions: [
        "Complete",
        "Empty",
        "Employee Assigned",
        "Needs Employee",
        "Unable to Cover",
      ],
      companyNameOptions: ["All", "Atlas Air", "AGS", "Aviablu", "Flightline"],
      stationOptions: ["Origin or Destination", "Origin", "Destination"],
      warnings: [],
      filteredWarnings: [],
      warningHeaders: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      warningsSearch: "",
      warningDialog: false,
      overrideWarnings: false,
      payloadWarningDialog: false,
      numberOfPassengers: "0",
      cargo: "0",
      cargoPayloadDialog: false,
      cargoPayloadValid: false,
      estimatedWeightPerPassenger: "0",
      swaCategory: { name: "", value: 0 },
      payloadDialog: false,
      payloadErrors: [],
      rules: {
        required: (value) => !!value || "Required.",
        max3: (value) => value.length <= 3 || "Max 3 characters",
        max6: (value) => value.length <= 6 || "Max 6 characters",
      },
      payloadValid: false,
      unitConverterDialog: false,
      weight: 0,
      units: "Kgs",
      convertFromOptions: ["Kgs", "Lbs"],
      rounded: true,
      scrollX: 0,
      timer: null,
      aircraftDialog: false,
      flightAssignment: null,
      flightDialog: false,
      dateFilterDialog: false,
      aircraftFilterDialog: false,
      showStartCalendar: false,
      showEndCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      scale: 4,
      currentTime: "",
      selectedFlight: null,
      favoriteAircrafts: [],
      filteredAircrafts: [],
      selectedAircrafts: [],
      flights: [],
      filteredFlights: [],
      flightSearch: "",
      flightHeaders: [
        {
          text: "Registration",
          align: "start",
          sortable: true,
          value: "aircraftRegistration",
        },
        {
          text: "From",
          align: "center",
          sortable: true,
          value: "originIata",
        },
        {
          text: "To",
          align: "center",
          sortable: true,
          value: "destinationIata",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "OUT",
          align: "center",
          sortable: true,
          value: "effectiveTimeOut",
        },
        {
          text: "IN",
          align: "center",
          sortable: true,
          value: "effectiveTimeIn",
        },
      ],
      editRemarks: false,
      flightRemarks: "",
      aircraftHeaders: [
        {
          text: "Registration",
          align: "start",
          sortable: true,
          value: "registration",
        },
        {
          text: "Passenger/Cargo",
          align: "start",
          sortable: true,
          value: "cargoOnly",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
      ],
      selectedStartDate: "",
      selectedEndDate: "",
      specialAirportsDialog: false,
      specialAiportsHeaders: [
        {
          text: "Category",
          align: "start",
          sortable: true,
          value: "category",
        },
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "code",
        },
      ],
      crewmember: null,
      groundCrewmember: null,
      psrRoles: [
        "SPSR",
        "SPSR2",
        "CPSR",
        "CPSR2",
        "LPSR",
        "LPSR2",
        "LPSR3",
        "LPSR4",
        "LPSR5",
        "PSR",
        "PSR2",
        "PSR3",
        "PSR4",
        "PSR5",
      ],
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      showWeightConverter: false,
      showFlightAssignmentInformationDialog: false,
      showGroundAssignmentInformationDialog: false,
      showStationAssignmentInformationDialog: false,
      availableBufferWeights: [5000, 8000],
      selectedBufferWeight: 5000,
      userSettings: null,
      showConfirmDialog: false,
      confirmState: "",
      notify: false,
      selectedLoadmasterMissingWarningLevel: "All Warnings",
      loadmasterMissingOptions: [
        "All Warnings",
        "Departure Only",
        "Arrival Only",
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.selectedBoardType = this.board.type;
    this.userSettings = {
      filters: {
        crewStatus: { ...this.user.settings.filters.crewStatus },
        station: { ...this.user.settings.filters.station },
        aircraftWithFlights: {
          ...this.user.settings.filters.aircraftWithFlights,
        },
      },
    };
    this.getFlights();
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  destroyed() {
    this.resetTimer();
  },

  computed: {
    originStationAssignmentEnabled() {
      if (this.enableCreateGroundAssignments) {
        if (
          this.user.securityGroup.authorizations.flight.read.filterByGateway
        ) {
          let codes;
          if (this.user.subGatewayCodes) {
            codes = [...this.user.subGatewayCodes, this.user.gatewayCode];
          } else {
            codes = [this.user.gatewayCode];
          }

          return codes.includes(this.flight.originIata);
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    destinationStationAssignmentEnabled() {
      if (this.enableCreateGroundAssignments) {
        if (
          this.user.securityGroup.authorizations.flight.read.filterByGateway
        ) {
          let codes;
          if (this.user.subGatewayCodes) {
            codes = [...this.user.subGatewayCodes, this.user.gatewayCode];
          } else {
            codes = [this.user.gatewayCode];
          }

          return codes.includes(this.flight.destinationIata);
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    specialAirports() {
      let airports = [];

      return airports;
    },
    board() {
      return this.$store.getters.board;
    },

    flightBoardY() {
      if (this.trip || this.stationAssignment) {
        return 100;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardHeight() {
      return this.filteredAircrafts.length * 60 + this.boardHeightAdjustment;
    },

    company() {
      return this.$store.getters.company;
    },

    aircraft() {
      return this.$store.getters.aircraft;
    },

    filterIconColor() {
      if (
        this.user.settings.filters.crewStatus.active ||
        this.user.settings.filters.aircraftWithFlights.active ||
        this.user.settings.filters.station.active
      ) {
        return "yellow";
      } else {
        return "#bebebe";
      }
    },

    flightPayload() {
      if (this.payloadExceededBy > 0) {
        return this.flight.aircraft.maximumPayload;
      } else {
        return this.finalEstimatedPayload;
      }
    },

    convertedWeight() {
      let w = 0;

      if (this.units === "Lbs") {
        if (this.rounded) {
          w = Math.ceil(this.weight / 2.20462);
        } else {
          w = (this.weight / 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Kgs`;
      } else {
        if (this.rounded) {
          w = Math.ceil(this.weight * 2.20462);
        } else {
          w = (this.weight * 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Lbs`;
      }
    },

    preliminaryWeight() {
      if (
        parseInt(this.numberOfPassengers) > 999 ||
        parseInt(this.estimatedWeightPerPassenger) > 999
      ) {
        return 0;
      }

      let w =
        parseInt(this.numberOfPassengers) *
        parseInt(this.estimatedWeightPerPassenger);

      if (isNaN(w)) {
        return 0;
      } else {
        return w;
      }
    },

    finalEstimatedPayload() {
      const w =
        parseInt(this.preliminaryWeight) + parseInt(this.selectedBufferWeight);

      if (isNaN(w)) {
        return 0;
      } else {
        return w;
      }
    },

    payloadExceededBy() {
      const w =
        parseInt(this.finalEstimatedPayload) -
        parseInt(this.flight.aircraft.maximumPayload);

      if (isNaN(w)) {
        return 0;
      } else {
        return w;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },
    errors() {
      return this.$store.getters.errors;
    },
  },

  methods: {
    formatExemptionValidPeriod(exemption) {
      if (exemption.isPermanent) {
        return "Permanent";
      } else {
        return `Valid from: ${this.formatDateTime(
          exemption.start
        )} to ${this.formatDateTime(exemption.end)} `;
      }
    },
    filterWarnings() {
      switch (this.selectedLoadmasterMissingWarningLevel) {
        case "Departure Only":
          this.filteredWarnings = this.warnings.filter((item) => {
            return (
              item.type === "Loadmaster Required at Departure Station" ||
              item.type !== "Loadmaster Required at Arrival Station"
            );
          });

          break;
        case "Arrival Only":
          this.filteredWarnings = this.warnings.filter((item) => {
            return (
              item.type === "Loadmaster Required at Arrival Station" ||
              item.type !== "Loadmaster Required at Departure Station"
            );
          });

          break;

        default:
          this.filteredWarnings = [...this.warnings];
          break;
      }
    },

    async handleGetAircraftScheduleConflicts() {
      this.loading = true;

      const res = await this.$store.dispatch("getAircraftScheduleConflicts");

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      const aircraftScheduleConflicts = res.aircraftScheduleConflicts;

      if (!aircraftScheduleConflicts.length) {
        this.snackbarColor = "#66BB6A";
        this.snackbarText = "No conflicts found";
        this.snackbarVisible = true;
        return;
      }

      try {
        const formatConflictSnapshot = (item) => {
          return `${item.aircraft} . ${item.conflict.flight.flightNumber} . ${
            item.conflict.flight.originIata
          }-${item.conflict.flight.destinationIata} . ${this.formatDateTime(
            item.conflict.flight.effectiveTimeOut
          )} . Flight Id ${item.conflict.flight.flightId}`;
        };
        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        let text;
        let textWidth;
        text = `Aircraft Schedule Conflicts`;
        pdfDoc.setTitle(text);
        pdfDoc.addPage();
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let item;
        let rowHeight = 0;
        let options = {
          x: 50,
          y: 775,
          size: 20,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        };
        const pageCenter = parseInt(page.getWidth() / 2);

        textWidth = helveticaBoldFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        //Printed On
        options.y -= 18;
        options.size = 10;
        options.font = helveticaFont;
        text = `Printed: ${this.formatDateTime(new Date())}`;
        textWidth = helveticaFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        options.y -= 40;
        options.x = 50;

        for (let i = 0; i < aircraftScheduleConflicts.length; i++) {
          item = aircraftScheduleConflicts[i];

          //---------------------------------------------------ROW 1
          options.x = 50;
          options.font = helveticaFont;
          options.color = rgb(0, 0, 1); //Blue
          options.size = 12;
          page.drawText(`${item.conflict.type}`, options);

          options.size = 10;
          options.y -= 18;
          rowHeight = 15;

          //---------------------------------------------------ROW 2
          options.color = rgb(0, 0, 0); //Black
          page.drawText(`${formatConflictSnapshot(item)}`, options);

          //---------------------------------------------------Separator
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          options.y -= 10;
          options.x = 50;
          page.drawText(
            `__________________________________________________________________________________`,
            options
          );
          options.y -= 20;
          //If last page
          if (i === this.filteredFlights.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }
          if (options.y - 70 < rowHeight && i <= this.filteredFlights.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );
            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);
            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.color = rgb(0.15, 0.09, 0.81);
            options.x = 50;
            options.y = 775;
          }
        }
        options.x = 50;
        options.y = 50;
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);
        options.x = 500;
        options.color = rgb(0, 0, 0); //Black
        page.drawText(`page ${pdfDoc.pageCount}`, options);
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleDeleteFlightAssignment(crewmember) {
      this.crewmember = { ...crewmember };
      this.confirmState = "delete-flight-assignment";
      this.showConfirmDialog = true;
    },
    async handleConfirmDeleteFlightAssignment() {
      this.showConfirmDialog = false;
      this.loading = true;

      let res = await this.$store.dispatch(
        "deleteFlightAssignment",
        this.crewmember
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.crewmember.employee && this.notify) {
        const flightAssignment = {
          updatedAt: this.crewmember.updatedAt,
          assignedBy: this.crewmember.assignedBy,
          role: this.crewmember.role,
          employee: { ...this.crewmember.employee },
          flight: { ...this.flight },
        };

        let payload = {
          assignment: {
            type: "Flight",
            employeeId: flightAssignment.employee._id,
            _id: flightAssignment._id,
            snapshot: this.formatFlightAssignmentNotification(flightAssignment),
          },
          emailAddress: flightAssignment.employee.mail,
          secondaryMailAddress: flightAssignment.employee.secondaryMail,
          emailSubject: "Flight Assignment Removed",
        };

        this.loading = true;

        res = await this.$store.dispatch("sendAssignmentNotification", payload);

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      const { flightId } = this.flight;

      this.flight = null;
      res = await this.$store.dispatch("getFlight", flightId);

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      this.getFlights();
    },

    handleCancelConfirm() {
      this.showConfirmDialog = false;
    },

    async handleConfirmRemoveEmployee() {
      this.showConfirmDialog = false;
      this.loading = true;

      let res = await this.$store.dispatch(
        "removeEmployeeFromFlightAssignment",
        this.crewmember
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.crewmember.employee && this.notify) {
        const flightAssignment = {
          updatedAt: this.crewmember.updatedAt,
          assignedBy: this.crewmember.assignedBy,
          role: this.crewmember.role,
          employee: { ...this.crewmember.employee },
          flight: { ...this.flight },
        };

        let payload = {
          assignment: {
            type: "Flight",
            employeeId: flightAssignment.employee._id,
            _id: flightAssignment._id,
            snapshot: this.formatFlightAssignmentNotification(flightAssignment),
          },
          emailAddress: flightAssignment.employee.mail,
          secondaryMailAddress: flightAssignment.employee.secondaryMail,
          emailSubject: "Flight Assignment Cancelled",
        };

        this.loading = true;

        res = await this.$store.dispatch("sendAssignmentNotification", payload);

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      const { flightId } = this.flight;

      this.flight = null;

      res = await this.$store.dispatch("getFlight", flightId);

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      this.getFlights();
    },

    handleCancelRemoveEmployee() {
      this.showConfirmDialog = false;
    },

    handleRemoveEmployee(crewmember) {
      this.crewmember = { ...crewmember };
      this.confirmState = "remove-employee";
      this.showConfirmDialog = true;
    },

    async handleSendAssignmentNotification(crewmember) {
      const flightAssignment = {
        ...crewmember,
        flight: { ...this.flight },
      };

      let payload = {
        assignment: {
          type: "Flight",
          employeeId: crewmember.employee._id,
          _id: flightAssignment._id,
          snapshot: this.formatFlightAssignmentNotification(flightAssignment),
        },
        emailAddress: flightAssignment.employee.mail,
        secondaryMailAddress: flightAssignment.employee.secondaryMail,
        emailSubject: "Flight Assignment",
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "sendAssignmentNotification",
        payload
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.snackbarColor = "#66BB6A";
      this.snackbarText = res.data.message;
      this.snackbarVisible = true;
    },

    async handleSendStationAssignmentNotification(crewmember) {
      let payload = {
        assignment: {
          type: "Ground",
          employeeId: crewmember.employee._id,
          _id: crewmember._id,
          snapshot: this.formatStationAssignmentNotification(crewmember),
        },
        emailAddress: crewmember.employee.mail,
        secondaryMailAddress: crewmember.employee.secondaryMail,
        emailSubject: "Station Assignment",
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "sendAssignmentNotification",
        payload
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.snackbarColor = "#66BB6A";
      this.snackbarText = res.data.message;
      this.snackbarVisible = true;
    },

    handleDeleteStationAssignment(crewmember) {
      this.crewmember = { ...crewmember };
      this.confirmState = "delete-station-assignment";
      this.showConfirmDialog = true;

      // this.loading = true;

      // let res = await this.$store.dispatch(
      //   "deleteStationAssignment",
      //   crewmember.group.id
      // );

      // this.loading = false;

      // if (res.status !== "OK") {
      //   this.loading = false;
      //   this.snackbarColor = "#F44336";
      //   this.snackbarText = res.message;
      //   this.snackbarVisible = true;
      //   return;
      // }

      // if (notify) {
      //   let payload = {
      //     assignment: {
      //       type: "Ground",
      //       employeeId: crewmember.employee._id,
      //       _id: crewmember._id,
      //       snapshot: this.formatStationAssignmentNotification(crewmember),
      //     },
      //     emailAddress: crewmember.employee.mail,
      //     emailSubject: "Station Assignment Removed",
      //   };

      //   this.loading = true;

      //   const res = await this.$store.dispatch(
      //     "sendAssignmentNotification",
      //     payload
      //   );

      //   this.loading = false;

      //   if (res.status !== "OK") {
      //     this.loading = false;
      //     this.snackbarColor = "#F44336";
      //     this.snackbarText = res.message;
      //     this.snackbarVisible = true;
      //     return;
      //   }
      // }

      // const { flightId } = this.flight;

      // this.flight = null;
      // res = await this.$store.dispatch("getFlight", flightId);

      // if (res.status !== "OK") {
      //   this.loading = false;
      //   this.snackbarColor = "#F44336";
      //   this.snackbarText = res.message;
      //   this.snackbarVisible = true;
      //   return;
      // }

      // this.flight = res.data.flight;

      // this.getFlights();
    },

    async handleConfirmDeleteStationAssignment() {
      this.showConfirmDialog = false;
      this.loading = true;

      let res = await this.$store.dispatch(
        "deleteStationAssignment",
        this.crewmember.group.id
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.crewmember.employee && this.notify) {
        let payload = {
          assignment: {
            type: "Ground",
            employeeId: this.crewmember.employee._id,
            _id: this.crewmember._id,
            snapshot: this.formatStationAssignmentNotification(this.crewmember),
          },
          emailAddress: this.crewmember.employee.mail,
          secondaryMailAddress: this.crewmember.employee.secondaryMail,
          emailSubject: "Station Assignment Removed",
        };

        this.loading = true;

        const res = await this.$store.dispatch(
          "sendAssignmentNotification",
          payload
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      const { flightId } = this.flight;

      this.flight = null;
      res = await this.$store.dispatch("getFlight", flightId);

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      this.getFlights();
    },

    async handleUnableToCoverFlightAssignment(crewmember) {
      this.loading = true;

      let res = await this.$store.dispatch(
        "unableToCoverFlightAssignment",
        crewmember
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      const { flightId } = this.flight;

      this.flight = null;
      res = await this.$store.dispatch("getFlight", flightId);

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      this.getFlights();
    },

    handleEnableEditFlightRemarks() {
      if (!this.enableUpdateFlightRemarks) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.remarks) {
        this.flightRemarks = this.flight.remarks.text.slice();
      } else {
        this.flightRemarks = null;
      }

      this.editRemarks = true;
    },

    async handleSaveFlightRemarks() {
      this.loading = true;

      const res = await this.$store.dispatch("updateFlightRemarks", {
        flightId: this.flight.flightId,
        remarks: this.flightRemarks,
      });

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo.toString();
        } else {
          this.cargo = "0";
        }

        this.numberOfPassengers = "0";
        this.estimatedWeightPerPassenger = "0";
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
          this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = "0";
          this.estimatedWeightPerPassenger = "0";
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = "0";
      }

      this.loading = false;

      this.editRemarks = false;

      this.getFlights();
    },

    handleCancelEditFlightRemarks() {
      this.editRemarks = false;
    },

    async handleGetPreviousFlight() {
      this.loading = true;

      const res = await this.$store.dispatch(
        "getPreviousFlight",
        this.flight.flightId
      );

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo.toString();
        } else {
          this.cargo = "0";
        }

        this.numberOfPassengers = "0";
        this.estimatedWeightPerPassenger = "0";
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
          this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = "0";
          this.estimatedWeightPerPassenger = "0";
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = "0";
      }

      this.loading = false;
    },

    async handleGetNextFlight() {
      this.loading = true;

      const res = await this.$store.dispatch(
        "getNextFlight",
        this.flight.flightId
      );

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo.toString();
        } else {
          this.cargo = "0";
        }

        this.numberOfPassengers = "0";
        this.estimatedWeightPerPassenger = "0";
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
          this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = "0";
          this.estimatedWeightPerPassenger = "0";
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = "0";
      }

      this.loading = false;
    },

    //-----------------------Flight Assignment
    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.flightAssignment = {
        ...flightAssignment,
        flight: { ...this.flight },
      };
      this.showFlightAssignmentInformationDialog = true;
    },

    async hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;

      if (e.refreshData) {
        const { flightId } = this.flight;

        this.flight = null;

        const res = await this.$store.dispatch("getFlight", flightId);

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.flight = res.flight;

        this.getFlights();
      }
    },

    //-----------------------Ground Assignment
    async handleShowStationAssignmentInformationDialog(groundAssignment) {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/flights/assignment-flights", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            flightIds: groundAssignment.group.flightIds,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        const flights = res.data.flights;

        this.groundAssignment = {
          ...groundAssignment,
          flights,
        };

        this.showStationAssignmentInformationDialog = true;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async hideStationAssignmentInformationDialog(e) {
      this.showStationAssignmentInformationDialog = false;

      if (e.refreshData) {
        const { flightId } = this.flight;

        this.flight = null;
        const res = await this.$store.dispatch("getFlight", flightId);

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.flight = res.flight;

        this.getFlights();
      }
    },

    handleChangeBoardType() {
      let board = { ...this.board };
      board.type = this.selectedBoardType;
      this.$store.commit("updateBoard", board);

      this.getFlights();
    },
    formatCabinConfig(cabinConfig) {
      let s = "";
      cabinConfig.forEach((item) => {
        s += `${item.seats}${item.class} / `;
      });

      return s.substring(0, s.length - 2);
    },
    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }

      const board = {
        ...this.board,
        aircraftScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }

      const board = {
        ...this.board,
        aircraftScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    resetStationAssignment() {
      this.stationAssignment.flights = [];
    },

    handleGetFlightCrewmember() {
      this.flightDialog = false;
      this.$store.commit("updateFlightId", this.flight.flightId);
      this.$router.push({ name: "flightAssignmentBoard" });
    },

    //---------------------------------Trip
    handleInitTrip() {
      this.stationAssignment = null;
      this.trip = {
        flights: [],
        route: "Empty",
        durationInMinutes: 0,
      };
    },
    handleResetTrip() {
      this.trip = {
        flights: [],
        route: "Empty",
        durationInMinutes: 0,
      };
    },
    removeFlightFromTrip(flight) {
      const _flights = this.trip.flights.filter((item) => {
        return item.flightId !== flight.flightId;
      });

      this.trip.flights = [..._flights];
    },

    handleAssignTrip() {
      if (this.trip.flights.length > 1) {
        //Check discontinuity starting on the second flight

        let priorFlight = this.trip.flights[0];
        let flight;

        let warnings = [];

        for (let i = 1; i < this.trip.flights.length; i++) {
          flight = this.trip.flights[i];

          if (flight.originIata !== priorFlight.destinationIata) {
            warnings.push(
              `Route discontinuity. Flight ${flight.flightNumber} starts in ${flight.originIata} , prior flight ended in ${priorFlight.destinationIata}`
            );
          }

          priorFlight = { ...flight };
        }

        if (warnings.length) {
          this.snackbarColor = "#F44336";
          this.snackbarText = warnings[0];
          this.snackbarVisible = true;
          return;
        }
      }

      let flightIds = [];

      this.trip.flights.forEach((item) => {
        flightIds.push(item.flightId);
      });

      this.$store.commit("updateTripFlightIds", flightIds);
      this.trip = null;
      this.$router.push({ name: "tripAssignmentBoard" });
    },

    //-----------------------------Station Assignment

    initStationAssignment() {
      if (
        this.user.settings.filters.station.active &&
        this.user.settings.filters.station.stationCodes.length
      ) {
        this.trip = null;
        this.stationAssignment = {
          flights: [],
          durationInMinutes: 0,
          remarks: "",
        };

        //Default to the first code in the list
        this.stationAssignment.stationIata = this.user.settings.filters.station.stationCodes[0].slice();
      } else {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Please set the Flight Board filter for the station you would like to assign`;
        this.snackbarVisible = true;
      }
    },

    initSingleFlightStationAssignment({ flight, station }) {
      this.trip = null;
      const stationAssignment = {
        flights: [flight],
        durationInMinutes: 0,
        remarks: "",
      };

      if (station === "origin") {
        stationAssignment.startTime = new Date(
          new Date(flight.effectiveTimeOut).getTime() - 3 * 60 * 60 * 1000
        );
        stationAssignment.endTime = new Date(
          new Date(flight.effectiveTimeOut).getTime() + 1 * 60 * 60 * 1000
        );
        stationAssignment.stationIata = flight.originIata;
      } else {
        stationAssignment.startTime = new Date(
          new Date(flight.effectiveTimeIn).getTime() - 1 * 60 * 60 * 1000
        );
        stationAssignment.endTime = new Date(
          new Date(flight.effectiveTimeIn).getTime() + 1 * 60 * 60 * 1000
        );
        stationAssignment.stationIata = flight.destinationIata;
      }

      stationAssignment.durationInMinutes =
        (stationAssignment.endTime.getTime() -
          stationAssignment.startTime.getTime()) /
        (60 * 1000);

      this.$store.commit("updateStationAssignment", stationAssignment);

      this.$router.push({ name: "stationAssignmentBoard" });
    },

    // async handleGetGroundCrewmember(groundCrewmember) {

    //   this.loading = true;

    //   try {
    //     const token = await aad.getIdTokenSilent();

    //     const res = await api.get("/flights/assignment-flights", {
    //       headers: {
    //         Authorization: "Bearer " + token.idToken.rawIdToken,
    //       },
    //       params: {
    //         flightIds: groundCrewmember.group.flightIds,
    //       },
    //     });

    //     this.loading = false;

    //     this.stationAssignment = {
    //       stationIata: groundCrewmember.originIata.slice(),
    //       flights: res.data.flights,
    //       startTime: new Date(groundCrewmember.startTime),
    //       endTime: new Date(groundCrewmember.endTime),
    //       durationInMinutes: groundCrewmember.duration,
    //       remarks: groundCrewmember.remarks,
    //     };

    //     this.$store.commit("updateStationAssignment", {
    //       ...this.stationAssignment,
    //     });
    //     this.stationAssignment = null;
    //     this.$router.push({ name: "stationAssignmentBoard" });
    //   } catch (error) {
    //     this.loading = false;
    //     this.snackbarColor = "#F44336";
    //     this.snackbarText = error;
    //     this.snackbarVisible = true;
    //   }
    // },

    async handleUnableToCoverStationAssignment(groundCrewmember) {
      this.loading = true;

      let res = await this.$store.dispatch(
        "unableToCoverStationAssignment",
        groundCrewmember
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      const { flightId } = this.flight;

      this.flight = null;
      res = await this.$store.dispatch("getFlight", flightId);

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.flight = res.flight;

      this.getFlights();
    },

    removeFlightFromStationAssignment(flight) {
      const _flights = this.stationAssignment.flights.filter((item) => {
        return item.flightId !== flight.flightId;
      });

      this.stationAssignment.flights = [..._flights];
    },

    assignStationAssignment() {
      this.$store.commit("updateStationAssignment", null);
      const firstFlight = this.stationAssignment.flights[0];
      const lastFlight = this.stationAssignment.flights[
        this.stationAssignment.flights.length - 1
      ];

      if (firstFlight.originIata === this.stationAssignment.stationIata) {
        //The first flight is a departure, start 3 hours before ETD
        this.stationAssignment.startTime = new Date(
          new Date(firstFlight.effectiveTimeOut).getTime() - 3 * 60 * 60 * 1000
        );
      } else {
        //The first flight is an arrivel, start 1 hours before ETA
        this.stationAssignment.startTime = new Date(
          new Date(firstFlight.effectiveTimeIn).getTime() - 1 * 60 * 60 * 1000
        );
      }

      if (lastFlight.originIata === this.stationAssignment.stationIata) {
        //The last flight is a departure, end 1 hour after ETD
        this.stationAssignment.endTime = new Date(
          new Date(lastFlight.effectiveTimeOut).getTime() + 1 * 60 * 60 * 1000
        );
      } else {
        //The last flight is an arrival, end 1 hour after ETA
        this.stationAssignment.endTime = new Date(
          new Date(lastFlight.effectiveTimeIn).getTime() + 1 * 60 * 60 * 1000
        );
      }

      this.stationAssignment.durationInMinutes =
        (this.stationAssignment.endTime.getTime() -
          this.stationAssignment.startTime.getTime()) /
        (60 * 1000);

      // Max 48 Hours
      if (this.stationAssignment.durationInMinutes > 2881) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Maximum duty time is 48 hours. Last assignment was not added`;

        this.stationAssignment.flights.splice(
          this.stationAssignment.flights.length - 1,
          1
        );

        this.snackbarVisible = true;
        return;
      }

      this.$store.commit("updateStationAssignment", {
        ...this.stationAssignment,
      });
      this.stationAssignment = null;
      this.$router.push({ name: "stationAssignmentBoard" });
    },

    compareStationFlights(a, b) {
      //Flights need to be order based on if they are an arrival or departure assignment
      let aRefTime;
      let bRefTime;

      if (a.originIata === this.stationAssignment.stationIata) {
        aRefTime = new Date(a.effectiveTimeOut).getTime();
      } else if (a.destinationIata === this.stationAssignment.stationIata) {
        aRefTime = new Date(a.effectiveTimeIn).getTime();
      }

      if (b.originIata === this.stationAssignment.stationIata) {
        bRefTime = new Date(b.effectiveTimeOut).getTime();
      } else if (b.destinationIata === this.stationAssignment.stationIata) {
        bRefTime = new Date(b.effectiveTimeIn).getTime();
      }

      if (aRefTime < bRefTime) {
        return -1;
      }
      if (bRefTime < aRefTime) {
        return 1;
      }
      return 0;
    },

    onResize() {
      this.isMobile = window.innerWidth < 750;
    },

    setTimer() {
      this.getDataTimer = setTimeout(this.getFlights, 30 * 60 * 1000); //30 Minutes
    },

    resetTimer() {
      clearTimeout(this.getDataTimer);
    },

    handleShowReverseCalculator() {
      const numberOfPassengers = parseInt(this.numberOfPassengers);

      if (
        isNaN(numberOfPassengers) ||
        numberOfPassengers < 1 ||
        numberOfPassengers > 500
      ) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Please enter a valid number of passenger`;
        return (this.snackbarVisible = true);
      }

      this.reverseCalculatorPayload = this.selectedBufferWeight;

      this.showReverseCalculator = true;
    },

    handleShowWeightConverter() {
      this.showWeightConverter = true;
    },

    calculateAveragePassengerWeight() {
      if (isNaN(this.numberOfPassengers)) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Please enter a valid number of passenger`;
        return (this.snackbarVisible = true);
      }

      if (isNaN(this.reverseCalculatorPayload)) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Please enter a valid number for Final Estimated Payload`;
        return (this.snackbarVisible = true);
      }

      const bufferWeight = parseInt(this.selectedBufferWeight);
      const numberOfPassengers = parseInt(this.numberOfPassengers);
      const reverseCalculatorPayload = parseInt(this.reverseCalculatorPayload);

      const minWeight = bufferWeight + numberOfPassengers;

      if (reverseCalculatorPayload < minWeight) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Final Estimated Payload cannot be lesss than
         the buffer weight plus the number of passengers`;
        return (this.snackbarVisible = true);
      }

      const p = reverseCalculatorPayload - bufferWeight;

      this.estimatedWeightPerPassenger = Math.ceil(
        p / numberOfPassengers
      ).toString();
      this.showReverseCalculator = false;
    },

    async captureFlightBoard() {
      if (!this.loading) {
        this.loading = true;
        try {
          this.boardHeightAdjustment = 120;
          const flightBoard = this.$refs.flightBoard;

          this.bottomPanelRight.scrollLeft = 0;

          const options = {
            type: "dataURL",
          };

          const dataUri = await this.$html2canvas(flightBoard, options);

          const pdfDoc = await PDFDocument.create();

          const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

          const title = `Monarch- Flight Board`;
          pdfDoc.setTitle(title);

          const pngImage = await pdfDoc.embedPng(dataUri);
          const pngDims = pngImage.scale(0.5);

          pdfDoc.addPage([pngDims.width + 100, pngDims.height + 100]);

          let pages = pdfDoc.getPages();
          let page = pages[0];

          let pdfOptions = {
            x: 50,
            y: 50,
            size: 16,
            font: helveticaFont,
            color: rgb(0, 0, 0),
          };

          page.drawImage(pngImage, {
            x: pdfOptions.x,
            y: pdfOptions.y,
            width: pngDims.width,
            height: pngDims.height,
          });

          const pdfUrl = URL.createObjectURL(
            new Blob([await pdfDoc.save()], { type: "application/pdf" })
          );
          window.open(pdfUrl, "_blank");
        } catch (error) {
          this.snackbarColor = "#F44336";
          this.snackbarText = error;
          this.snackbarVisible = true;
        } finally {
          this.loading = false;
          this.boardHeightAdjustment = 0;
        }
      }
    },

    async printFlightBoardList() {
      const formatFlight = (f) => {
        return `${f.aircraftRegistration} . ${f.flightNumber} . ${
          f.originIata
        }-${f.destinationIata} . ${this.formatDate(
          f.effectiveTimeOut
        )} . ETD ${f.effectiveTimeOut.substring(
          11,
          16
        )}z / ETA ${f.effectiveTimeIn.substring(11, 16)}z`;
      };
      try {
        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        let text;
        let textWidth;
        text = `Flight Schedule ${this.formatDate(
          this.dateFilter.start
        )} - ${this.formatDate(this.dateFilter.end)}`;
        pdfDoc.setTitle(text);
        pdfDoc.addPage();
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let flight;
        let crewmember;
        let flightRole;
        let rowHeight = 0;
        let options = {
          x: 50,
          y: 775,
          size: 20,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        };
        const pageCenter = parseInt(page.getWidth() / 2);

        textWidth = helveticaBoldFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        //Printed On
        options.y -= 18;
        options.size = 10;
        options.font = helveticaFont;
        text = `Printed: ${this.formatDateTime(new Date())}`;
        textWidth = helveticaFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        options.y -= 40;
        options.x = 50;
        let flightCrewmembers;
        let groundCrewmembers;
        for (let i = 0; i < this.filteredFlights.length; i++) {
          flight = this.filteredFlights[i];
          flightCrewmembers = [];
          groundCrewmembers = [];
          //-------------------------------------------Flight Crewmembers
          if (flight.crewmembers && flight.crewmembers.length > 0) {
            for (let c = 0; c < flight.crewmembers.length; c++) {
              crewmember = flight.crewmembers[c];

              flightRole = this.getFlightRole(crewmember.role).description;

              if (crewmember.employee) {
                //The role is assigned to an employee
                if (
                  this.user.securityGroup.companies.includes(
                    crewmember.employee.companyName
                  )
                ) {
                  flightCrewmembers.push(
                    `${flightRole} - ${
                      crewmember.employee.surname
                    }, ${crewmember.employee.givenName.substring(
                      0,
                      1
                    )} - Phone: ${crewmember.employee.mobilePhone}`
                  );
                }
              } else {
                //The role is assigned to a compoany
                if (
                  this.user.securityGroup.companies.includes(
                    crewmember.assignedToCompany
                  )
                ) {
                  flightCrewmembers.push(
                    `${flightRole} -${crewmember.assignedToCompany}`
                  );
                }
              }
            }
          }
          //-------------------------------------------Ground Crewmembers
          if (this.printGroundCrewmembers) {
            if (
              flight.groundCrewmembers &&
              flight.groundCrewmembers.length > 0
            ) {
              for (let c = 0; c < flight.groundCrewmembers.length; c++) {
                crewmember = flight.groundCrewmembers[c];

                if (crewmember.employee) {
                  if (
                    this.user.securityGroup.companies.includes(
                      crewmember.employee.companyName
                    )
                  ) {
                    groundCrewmembers.push(
                      `Ground Crew: ${crewmember.subCategory} (${
                        crewmember.originIata
                      }) - ${
                        crewmember.employee.surname
                      }, ${crewmember.employee.givenName.substring(
                        0,
                        1
                      )} - Phone: ${crewmember.employee.mobilePhone}`
                    );
                  }
                } else {
                  if (
                    this.user.securityGroup.companies.includes(
                      crewmember.assignedToCompany
                    )
                  ) {
                    groundCrewmembers.push(
                      `Ground Crew: ${crewmember.subCategory} (${crewmember.originIata}) - ${crewmember.assignedToCompany}`
                    );
                  }
                }
              }
            }
            if (flightCrewmembers.length || groundCrewmembers.length) {
              //---------------------------------------------------ROW 1
              options.x = 50;
              options.font = helveticaFont;
              options.color = rgb(0, 0, 1); //Blue
              options.size = 12;
              page.drawText(`${formatFlight(flight)}`, options);
              //---------------------------------------------------Crewmembers
              //Job Titles / Roles
              // "Loadmaster",
              // "Check Loadmaster",
              // "Senior Loadmaster",
              // "PSR",
              // "Check PSR",
              // "Lead PSR",
              // "Senior PSR",
              // "PAA",
              // "Mechanic",
              // "Contract Mechanic",
              options.size = 10;
              options.y -= 3;
              rowHeight = 15;
              //-------------------------------------------Flight Crewmembers
              flightCrewmembers.forEach((item) => {
                options.color = rgb(0.17, 0.24, 0.31); //Gray
                rowHeight += 15;
                options.y -= 12;
                page.drawText(`${item}`, options);
              });
              //-------------------------------------------Ground Crewmembers
              groundCrewmembers.forEach((item) => {
                options.color = rgb(0.52, 0.27, 0.03); //Brown
                rowHeight += 15;
                options.y -= 12;
                page.drawText(`${item}`, options);
              });
              //---------------------------------------------------Separator
              options.color = rgb(0.17, 0.24, 0.31); //Gray
              options.y -= 10;
              options.x = 50;
              page.drawText(
                `__________________________________________________________________________________`,
                options
              );
              options.y -= 20;
              //If last page
              if (i === this.filteredFlights.length - 1) {
                options.x = 350;
                page.drawText(
                  `Printed: ${this.formatDateTime(new Date())}`,
                  options
                );
              }
              if (
                options.y - 70 < rowHeight &&
                i <= this.filteredFlights.length
              ) {
                options.x = 50;
                options.y = 50;
                options.color = rgb(0, 0, 0);
                page.drawText(
                  `Monarch - Powered by Raiden Solutions Inc.`,
                  options
                );
                options.x = 500;
                options.color = rgb(0, 0, 0);
                page.drawText(`page ${pages.length}`, options);
                pdfDoc.addPage();
                pages = pdfDoc.getPages();
                page = pages[pages.length - 1];
                options.color = rgb(0.15, 0.09, 0.81);
                options.x = 50;
                options.y = 775;
              }
            }
          }
        }
        options.x = 50;
        options.y = 50;
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);
        options.x = 500;
        options.color = rgb(0, 0, 0); //Black
        page.drawText(`page ${pdfDoc.pageCount}`, options);
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.employee = null;
      }
    },

    async getAirport(code) {
      this.airport = this.companyAirports.find((airport) => {
        return airport.iata === code;
      });

      if (this.airport) {
        const exemptions = this.company.loadmasterRequirementExemptions.filter(
          (item) => {
            return item.airportCode === this.airport.iata;
          }
        );

        this.airport.loadmasterExemptions = exemptions;

        this.showAirportInformationDialog = true;
      } else {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Sorry we couln't find an airport with IATA code ${code}`;
        this.snackbarVisible = true;
      }
    },

    getFlightFromWarning({ flight }) {
      this.flight = { ...flight };

      const aircraft = this.company.aircrafts.find((a) => {
        return a.registration === flight.aircraftRegistration;
      });

      this.$store.commit("updateAircraft", aircraft);

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo.toString();
        } else {
          this.cargo = "0";
        }

        this.numberOfPassengers = "0";
        this.estimatedWeightPerPassenger = "0";
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
          this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = "0";
          this.estimatedWeightPerPassenger = "0";
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = "0";
      }

      this.flightDialog = true;
    },

    getFlightFromList(flight) {
      this.flight = { ...flight };

      if (this.flight.aircraft.cargoOnly) {
        if (this.flight.payload) {
          this.cargo = this.flight.payload.cargo.toString();
        } else {
          this.cargo = "0";
        }

        this.numberOfPassengers = "0";
        this.estimatedWeightPerPassenger = "0";
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      } else {
        if (this.flight.payload) {
          this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
          this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
          this.swaCategory = this.flight.payload.swaCategory;
          this.selectedBufferWeight = this.flight.payload.bufferWeight;
        } else {
          this.numberOfPassengers = "0";
          this.estimatedWeightPerPassenger = "0";
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        }

        this.cargo = "0";
      }

      this.flightDialog = true;
    },

    async getFlight(flight) {
      const board = {
        ...this.board,
        aircraftScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      let i;

      if (this.stationAssignment) {
        if (!this.stationAssignment.stationIata) {
          this.snackbarColor = "#F44336";
          this.snackbarText = `Please select a station for this assignment.`;
          this.snackbarVisible = true;
          return;
        }

        //Check if either origin or destination match assignment station
        if (
          this.stationAssignment.stationIata !== flight.originIata &&
          this.stationAssignment.stationIata !== flight.destinationIata
        ) {
          this.snackbarColor = "#F44336";
          this.snackbarText = `Assignment must arrive or depart from ${this.stationAssignment.stationIata}`;
          this.snackbarVisible = true;
          return;
        }

        if (this.stationAssignment.flights.length) {
          //Check for duplicates
          i = this.stationAssignment.flights.findIndex((item) => {
            return item.flightId === flight.flightId;
          });

          if (i >= 0) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `Flight ${flight.flightNumber} is already in this group`;
            return (this.snackbarVisible = true);
          }

          if (
            this.stationAssignment.flights.length ===
            this.maximumGroundAssignments
          ) {
            this.snackbarColor = "#F44336";
            this.snackbarText =
              "Maximum number of assignments reached, unable to add flight";
            return (this.snackbarVisible = true);
          }

          this.stationAssignment.flights.push(flight);

          const sortedFlights = this.stationAssignment.flights.sort(
            this.compareStationFlights
          );

          this.stationAssignment.flights = [...sortedFlights];
        } else {
          this.stationAssignment.flights.push(flight);
        }
      } else if (this.trip) {
        if (this.trip.flights.length) {
          i = this.trip.flights.findIndex((item) => {
            return item.flightId === flight.flightId;
          });

          if (i >= 0) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `Flight ${flight.flightNumber} is already in this trip`;
            return (this.snackbarVisible = true);
          }

          if (this.trip.flights.length === this.maximumTripSegments) {
            this.snackbarColor = "#F44336";
            this.snackbarText =
              "Maximum number of segments reached, unable to add flight";
            return (this.snackbarVisible = true);
          }

          const previousFlight = this.trip.flights[
            this.trip.flights.length - 1
          ];

          if (flight.originIata !== previousFlight.destinationIata) {
            this.snackbarColor = "#F44336";
            this.snackbarText = "Route discontinuity, unable to add flight";
            return (this.snackbarVisible = true);
          }

          if (
            new Date(flight.effectiveTimeOut).getTime() <=
            new Date(previousFlight.effectiveTimeIn).getTime()
          ) {
            this.snackbarColor = "#F44336";
            this.snackbarText =
              "ETA / ETD times conflict, unable to add flight";
            return (this.snackbarVisible = true);
          }

          const t1 = new Date(this.trip.flights[0].effectiveTimeOut).getTime();
          const t2 = new Date(flight.effectiveTimeIn).getTime();

          const durationInMinutes = (t2 - t1) / 1000 / 60;

          //Max trip duration 72 hours
          if (durationInMinutes > this.maximumTripDuration * 60) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `Maximum trip duration is ${this.maximumTripDuration} hours`;
            return (this.snackbarVisible = true);
          }

          this.trip.flights.push(flight);
          this.trip.route += `-${flight.destinationIata}`;
          this.trip.durationInMinutes = durationInMinutes;
        } else {
          const t1 = new Date(flight.effectiveTimeOut).getTime();
          const t2 = new Date(flight.effectiveTimeIn).getTime();

          this.trip.flights.push(flight);
          this.trip.route = `${flight.originIata}-${flight.destinationIata}`;
          this.trip.durationInMinutes = (t2 - t1) / 1000 / 60;
        }
      } else {
        this.loading = true;

        const res = await this.$store.dispatch("getFlight", flight.flightId);

        this.loading = false;

        if (res.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.flight = res.flight;

        if (this.flight.aircraft.cargoOnly) {
          if (this.flight.payload) {
            this.cargo = this.flight.payload.cargo.toString();
          } else {
            this.cargo = "0";
          }

          this.numberOfPassengers = "0";
          this.estimatedWeightPerPassenger = "0";
          this.swaCategory = this.flight.aircraft.swaCategories[0];
          this.selectedBufferWeight = 5000;
        } else {
          if (this.flight.payload) {
            this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
            this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
            this.swaCategory = this.flight.payload.swaCategory;
            this.selectedBufferWeight = this.flight.payload.bufferWeight;
          } else {
            this.numberOfPassengers = "0";
            this.estimatedWeightPerPassenger = "0";
            this.swaCategory = this.flight.aircraft.swaCategories[0];
            this.selectedBufferWeight = 5000;
          }

          this.cargo = "0";
        }

        this.flightDialog = true;
      }
    },

    async getFlights() {
      this.resetTimer();

      if (!this.loading) {
        this.loading = true;
        this.flights = [];
        this.favoriteAircrafts = [];
        this.filteredAircrafts = [];
        this.filteredFlights = [];

        try {
          const token = await aad.getIdTokenSilent();

          const res = await api.get("/flights", {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
            params: {
              filterStart: this.dateFilter.start,
              filterEnd: this.dateFilter.end,
            },
          });
          serveStatic;

          const flights = res.data.flights;
          const conflicts = res.data.conflicts;
          this.flights = res.data.flights;
          this.filteredFlights = this.filterFlights(flights, conflicts);

          let aircrafts = [];

          if (this.user.settings.filters.aircrafts.length > 0) {
            switch (this.board.type) {
              case "Cargo":
                aircrafts = this.company.aircrafts.filter((aircraft) => {
                  return (
                    aircraft.cargoOnly &&
                    this.user.settings.filters.aircrafts.includes(
                      aircraft.registration
                    )
                  );
                });

                break;
              case "Passenger":
                aircrafts = this.company.aircrafts.filter((aircraft) => {
                  return (
                    !aircraft.cargoOnly &&
                    this.user.settings.filters.aircrafts.includes(
                      aircraft.registration
                    )
                  );
                });
                break;

              default:
                aircrafts = this.company.aircrafts.filter((aircraft) => {
                  return this.user.settings.filters.aircrafts.includes(
                    aircraft.registration
                  );
                });
                break;
            }
          } else {
            switch (this.board.type) {
              case "Cargo":
                aircrafts = this.company.aircrafts.filter((aircraft) => {
                  return aircraft.cargoOnly;
                });

                break;
              case "Passenger":
                aircrafts = this.company.aircrafts.filter((aircraft) => {
                  return !aircraft.cargoOnly;
                });
                break;

              default:
                aircrafts = [...this.company.aircrafts];
                break;
            }
          }

          aircrafts = aircrafts.sort(this.compareAircrafts);

          aircrafts.forEach((aircraft) => {
            this.favoriteAircrafts.push({
              ...aircraft,
              flights: this.filteredFlights.filter(
                (flight) =>
                  flight.aircraftRegistration === aircraft.registration
              ),
            });
          });

          if (this.user.settings.filters.aircraftWithFlights.active) {
            this.filteredAircrafts = this.favoriteAircrafts.filter(
              (aircraft) => {
                return aircraft.flights.length;
              }
            );
          } else {
            this.filteredAircrafts = [...this.favoriteAircrafts];
          }

          this.getWarnings(false);
        } catch (error) {
          this.snackbarColor = "#F44336";
          this.snackbarText = error;
          this.snackbarVisible = true;
        } finally {
          this.loading = false;
          this.setTimer();
          this.$nextTick(() => {
            window.scrollTo(0, this.board.aircraftScrollY);
            const panel = this.$refs["bottomPanelRight"];
            if (panel) {
              panel.scrollLeft = Math.abs(this.board.scrollX);
              this.scale = this.board.scale;
            }
          });
        }
      }
    },

    scrollBoard() {
      //This fires when the slider in the toolbar is used
      this.bottomPanelRight.scrollLeft = this.sliderX;
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },

    hideAirportInformationDialog() {
      this.airport = null;
      this.showAirportInformationDialog = false;
    },

    async handleSaveFilters() {
      if (
        this.userSettings.filters.station.active &&
        !this.userSettings.filters.station.stationCodes.length
      ) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Please select at least one IATA code to filter by station";
        return (this.snackbarVisible = true);
      }

      this.filtersDialog = false;

      this.loading = true;

      let settings = {
        filters: {
          aircrafts: [...this.userSettings.filters.aircrafts],
          employees: { ...this.userSettings.filters.employees },
          crewStatus: { ...this.userSettings.filters.crewStatus },
          station: { ...this.userSettings.filters.station },
          aircraftWithFlights: {
            ...this.userSettings.filters.aircraftWithFlights,
          },
        },
      };

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        this.loading = false;
        return;
      }

      this.getFlights();
    },

    async handleClearFilters() {
      this.filtersDialog = false;

      this.loading = true;

      let settings = {
        filters: {
          aircrafts: [...this.userSettings.filters.aircrafts],
          employees: { ...this.userSettings.filters.employees },
          crewStatus: {
            active: false,
            companyName: "All",
            crewStatus: "Needs Employee",
          },
          station: {
            active: false,
            station: "Origin or Destination",
            stationCodes: [],
          },
          aircraftWithFlights: {
            active: true,
          },
        },
      };

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        this.loading = false;
        return;
      }

      this.getFlights();
    },

    filterFlights(flights, conflicts) {
      let filter0 = []; //Crew Status
      let filter1 = []; //Origin/Destination
      let filter2 = []; //Board Type

      let count = 0;
      let required = 0;

      //Add conflict flag
      flights.forEach((flight) => {
        if (conflicts.includes(flight.flightId)) {
          flight.hasConflict = true;
        }
      });

      //Filter by Crew Status
      if (this.user.settings.filters.crewStatus.active) {
        switch (this.user.settings.filters.crewStatus.crewStatus) {
          case "Empty":
            //Show only flights with an empty crew list
            filter0 = flights.filter((flight) => {
              if (flight.crewmembers && flight.groundCrewmembers) {
                return (
                  flight.crewmembers.length === 0 &&
                  flight.groundCrewmembers.length === 0
                );
              } else {
                return true;
              }
            });

            break;

          case "Employee Assigned":
            flights.forEach((flight) => {
              count = 0;

              //Flight Crewmembers
              if (flight.crewmembers) {
                flight.crewmembers.forEach((item) => {
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    item.employee
                  ) {
                    count += 1;
                  } else if (
                    this.user.settings.filters.crewStatus.companyName ===
                      item.assignedToCompany &&
                    item.employee
                  ) {
                    count += 1;
                  }
                });
              }

              //Ground Assignments
              if (flight.groundCrewmembers) {
                flight.groundCrewmembers.forEach((item) => {
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    item.employee
                  ) {
                    count += 1;
                  } else if (
                    this.user.settings.filters.crewStatus.companyName ===
                      item.assignedToCompany &&
                    item.employee
                  ) {
                    count += 1;
                  }
                });
              }

              if (count > 0) {
                filter0.push(flight);
              }
            });

            break;

          case "Complete":
            flights.forEach((flight) => {
              count = 0;
              required = 0;
              //Flight Crewmembers
              if (flight.crewmembers) {
                flight.crewmembers.forEach((item) => {
                  required += 1;
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    item.employee
                  ) {
                    count += 1;
                  } else if (
                    this.user.settings.filters.crewStatus.companyName ===
                      item.assignedToCompany &&
                    item.employee
                  ) {
                    count += 1;
                  }
                });
              }

              //Ground Assignments
              if (flight.groundCrewmembers) {
                flight.groundCrewmembers.forEach((item) => {
                  required += 1;
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    item.employee
                  ) {
                    count += 1;
                  } else if (
                    this.user.settings.filters.crewStatus.companyName ===
                      item.assignedToCompany &&
                    item.employee
                  ) {
                    count += 1;
                  }
                });
              }

              if (required > 0 && count === required) {
                filter0.push(flight);
              }
            });

            break;

          case "Needs Employee":
            flights.forEach((flight) => {
              count = 0;
              //Flight Crewmembers
              if (flight.crewmembers) {
                flight.crewmembers.forEach((item) => {
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    !item.employee
                  ) {
                    count += 1;
                  } else if (
                    this.user.settings.filters.crewStatus.companyName ===
                      item.assignedToCompany &&
                    !item.employee
                  ) {
                    count += 1;
                  }
                });
              }

              //Ground Assignments
              if (flight.groundCrewmembers) {
                flight.groundCrewmembers.forEach((item) => {
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    !item.employee
                  ) {
                    count += 1;
                  } else if (
                    this.user.settings.filters.crewStatus.companyName ===
                      item.assignedToCompany &&
                    !item.employee
                  ) {
                    count += 1;
                  }
                });
              }

              if (count > 0) {
                filter0.push(flight);
              }
            });

            break;

          case "Unable to Cover":
            flights.forEach((flight) => {
              count = 0;
              //Flight Crewmembers
              if (flight.crewmembers) {
                flight.crewmembers.forEach((item) => {
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    item.unableToCover
                  ) {
                    count += 1;
                  } else if (
                    item.unableToCover &&
                    item.unableToCover.companyName ===
                      this.user.settings.filters.crewStatus.companyName
                  ) {
                    count += 1;
                  }
                });
              }

              //Ground Assignments
              if (flight.groundCrewmembers) {
                flight.groundCrewmembers.forEach((item) => {
                  //Determine required by company
                  if (
                    this.user.settings.filters.crewStatus.companyName ===
                      "All" &&
                    item.unableToCover
                  ) {
                    count += 1;
                  } else if (
                    item.unableToCover &&
                    item.unableToCover.companyName ===
                      this.user.settings.filters.crewStatus.companyName
                  ) {
                    count += 1;
                  }
                });
              }

              if (count > 0) {
                filter0.push(flight);
              }
            });

            break;

          default:
            break;
        }
      } else {
        filter0 = [...flights];
      }

      //Filter by Station
      if (this.user.settings.filters.station.active) {
        switch (this.user.settings.filters.station.station) {
          case "Origin":
            filter1 = filter0.filter((flight) => {
              return this.user.settings.filters.station.stationCodes.includes(
                flight.originIata
              );
            });
            break;

          case "Destination":
            filter1 = filter0.filter((flight) => {
              return this.user.settings.filters.station.stationCodes.includes(
                flight.destinationIata
              );
            });
            break;
          default:
            filter1 = filter0.filter((flight) => {
              return (
                this.user.settings.filters.station.stationCodes.includes(
                  flight.originIata
                ) ||
                this.user.settings.filters.station.stationCodes.includes(
                  flight.destinationIata
                )
              );
            });
            break;
        }
      } else {
        filter1 = [...filter0];
      }

      //Filter by board type
      switch (this.board.type) {
        case "Cargo":
          filter2 = filter1.filter((flight) => {
            return flight.aircraft.cargoOnly;
          });

          break;
        case "Passenger":
          filter2 = filter1.filter((flight) => {
            return !flight.aircraft.cargoOnly;
          });

          break;

        default:
          filter2 = [...filter1];

          break;
      }

      return filter2;
    },

    openFilters() {
      this.userSettings = {
        filters: {
          aircrafts: [...this.user.settings.filters.aircrafts],
          employees: { ...this.user.settings.filters.employees },
          crewStatus: { ...this.user.settings.filters.crewStatus },
          station: { ...this.user.settings.filters.station },
          aircraftWithFlights: {
            ...this.user.settings.filters.aircraftWithFlights,
          },
        },
      };
      this.filtersDialog = true;
    },

    hideFiltersDialog() {
      this.filtersDialog = false;
    },

    hideFlightDialog(refreshData) {
      if (refreshData) {
        this.getFlights();
      }

      this.flightDialog = false;
    },

    extractLastName(fullname) {
      if (fullname.length) {
        const res = fullname.split(" ");
        return res[0];
      } else {
        return "";
      }
    },

    getWarnings(showDialog) {
      this.warnings = [];

      let generalWarnings = [];
      let cargoWarnings = [];
      let paxWarnings = [];

      if (this.filteredFlights.length) {
        let psrCount; // PSR Count is used to check that at least one PSR is assigned if the flight needs one (pax aircraft only)
        let flightLoadmasterCount; // Loadmaster Count is used to check that at least one Loadmaster is assigned if the flight needs one (cargo aircraft only)
        let originLoadmasterCount;
        let destinationLoadmasterCount;
        let addDepartureWarning;
        let addArrivalWarning;
        let exemption;

        this.filteredFlights.forEach((flight) => {
          // Catch to avoid reading flights without an aircraft (due to aircraft assigned was generic)
          if (flight.aircraft) {
            psrCount = 0;
            flightLoadmasterCount = 0;
            originLoadmasterCount = 0;
            destinationLoadmasterCount = 0;
            addDepartureWarning = false;
            addArrivalWarning = false;
            exemption = null;

            //Trigger General Warnings
            //  Check Flight Crewmembers
            if (flight.crewmembers && flight.crewmembers.length) {
              flight.crewmembers.forEach((crewmember) => {
                //Employee assigned
                if (crewmember.employee) {
                  // Get Crewmembers Not Listed in Aims
                  if (flight.jumpseaters && flight.jumpseaters.length) {
                    const i = flight.jumpseaters.findIndex((js) => {
                      return (
                        this.extractLastName(js.name) ===
                        crewmember.employee.surname.toUpperCase()
                      );
                    });

                    if (i < 0) {
                      generalWarnings.push({
                        flight,
                        type: "AIMS Listing",
                        message: `${this.formatFullFlightSnapshot(flight)} - ${
                          crewmember.employee.surname
                        }, ${
                          crewmember.employee.givenName
                        } is not listed in AIMS`,
                      });
                    }
                  } else {
                    generalWarnings.push({
                      flight,
                      type: "AIMS Listing",
                      message: `${this.formatFullFlightSnapshot(flight)} - ${
                        crewmember.employee.surname
                      }, ${
                        crewmember.employee.givenName
                      }  is not listed in AIMS`,
                    });
                  }
                }
                //Open assignment
                else {
                  if (crewmember.unableToCover) {
                    generalWarnings.push({
                      flight,
                      type: "Open Assignment - Unable to Cover",
                      message: `Unable to cover by ${
                        crewmember.unableToCover.companyName
                      } - ${
                        this.getFlightRole(crewmember.role).description
                      } - ${this.formatFullFlightSnapshot(flight)}`,
                    });
                  } else {
                    generalWarnings.push({
                      flight,
                      type: "Open Assignment",
                      message: `${crewmember.assignedToCompany} - ${
                        this.getFlightRole(crewmember.role).description
                      } - ${this.formatFullFlightSnapshot(flight)}`,
                    });
                  }
                }
              });
            }

            //Trigger Cargo Warnings
            if (flight.aircraft.cargoOnly) {
              //Check Flight Crewmembers for Loadmasters
              if (flight.crewmembers && flight.crewmembers.length) {
                flight.crewmembers.forEach((crewmember) => {
                  //Employee assigned
                  if (crewmember.employee) {
                    if (this.loadmasterRoles.includes(crewmember.role)) {
                      flightLoadmasterCount++;
                    }
                  }
                });
              }

              //Check ground crewmembers only if no flight loadmaster found
              if (flightLoadmasterCount === 0) {
                // No loadmaster on-board,
                // check Ground Crewmembers for Loadmasters in both departure and arrival stations
                if (
                  flight.groundCrewmembers &&
                  flight.groundCrewmembers.length
                ) {
                  flight.groundCrewmembers.forEach((groundCrewmember) => {
                    if (groundCrewmember.employee) {
                      if (
                        this.loadmasterRoles.includes(
                          groundCrewmember.subCategory
                        )
                      ) {
                        //Loadmaster at Origin
                        if (groundCrewmember.originIata === flight.originIata) {
                          originLoadmasterCount++;
                        }

                        //Loadmaster at Destination
                        if (
                          groundCrewmember.originIata === flight.destinationIata
                        ) {
                          destinationLoadmasterCount++;
                        }
                      }
                    }
                  });
                }

                if (originLoadmasterCount === 0) {
                  //At this point no loadmaster was found on-board and
                  //there is no loadmaster at the departure station

                  // Origin / Departure
                  exemption = this.company.loadmasterRequirementExemptions.find(
                    (item) => {
                      return (
                        item.airportCode === flight.originIata &&
                        item.aircraftType === flight.aircraft.type
                      );
                    }
                  );

                  if (!exemption) {
                    addDepartureWarning = true;
                  } else {
                    if (!exemption.isPermanent) {
                      const flightStart = new Date(
                        flight.effectiveTimeOut
                      ).getTime();

                      if (flightStart < new Date(exemption.start).getTime()) {
                        addDepartureWarning = true;
                      } else {
                        if (flightStart > new Date(exemption.end).getTime()) {
                          addDepartureWarning = true;
                        }
                      }
                    }
                  }

                  if (addDepartureWarning) {
                    //Trigger warning if origin and aircraft type are not exempted
                    cargoWarnings.push({
                      flight,
                      type: "Loadmaster Required at Departure Station",
                      message: `${this.formatFullFlightSnapshot(
                        flight
                      )} - Loadmaster Required at ${flight.originIata}`,
                    });
                  }
                }

                if (destinationLoadmasterCount === 0) {
                  //At this point no loadmaster was found on-board and
                  //there is no loadmaster at the arrival station

                  //Arrival / Destination
                  exemption = this.company.loadmasterRequirementExemptions.find(
                    (item) => {
                      return (
                        item.airportCode === flight.destinationIata &&
                        item.aircraftType === flight.aircraft.type
                      );
                    }
                  );

                  if (!exemption) {
                    addArrivalWarning = true;
                  } else {
                    if (!exemption.isPermanent) {
                      const flightEnd = new Date(
                        flight.effectiveTimeIn
                      ).getTime();

                      if (flightEnd < new Date(exemption.start).getTime()) {
                        addArrivalWarning = true;
                      } else {
                        if (flightEnd > new Date(exemption.end).getTime()) {
                          addArrivalWarning = true;
                        }
                      }
                    }
                  }

                  if (addArrivalWarning) {
                    //Trigger warning if origin and aircraft type are not exempted
                    cargoWarnings.push({
                      flight,
                      type: "Loadmaster Required at Arrival Station",
                      message: `${this.formatFullFlightSnapshot(
                        flight
                      )} - Loadmaster Required at ${flight.destinationIata}`,
                    });
                  }
                }
              }
            } else {
              //  Check Flight Crewmembers for PSRs
              if (flight.crewmembers && flight.crewmembers.length) {
                flight.crewmembers.forEach((crewmember) => {
                  //Employee assigned
                  if (crewmember.employee) {
                    if (this.psrRoles.includes(crewmember.role)) {
                      psrCount++;
                    }
                  }
                });
              }

              //  Check Ground Crewmembers for PSRs
              if (flight.groundCrewmembers && flight.groundCrewmembers.length) {
                flight.groundCrewmembers.forEach((groundCrewmember) => {
                  if (groundCrewmember.employee) {
                    if (this.psrRoles.includes(groundCrewmember.subCategory)) {
                      psrCount++;
                    }
                  }
                });
              }

              //Trigger Pax Warnings
              if (psrCount < 1) {
                paxWarnings.push({
                  flight,
                  type: "PSR - Crew Requirements",
                  message: `${this.formatFullFlightSnapshot(
                    flight
                  )} - PSR - Minimum crew requirements not met.`,
                });
              }
            }
          }
        });

        if (this.selectedBoardType === "Cargo") {
          this.warnings = [...generalWarnings, ...cargoWarnings];
        } else if (this.selectedBoardType === "Passenger") {
          this.warnings = [...generalWarnings, ...paxWarnings];
        } else {
          this.warnings = [
            ...generalWarnings,
            ...cargoWarnings,
            ...paxWarnings,
          ];
        }
      }

      this.filterWarnings();

      if (this.filteredWarnings.length > 0) {
        this.warningDialog = showDialog;
      }
    },

    showMaximumPayload() {
      this.snackbarColor = "#2196F3";
      this.snackbarText = `${
        this.flight.aircraft.registration
      } maximum payload is ${this.formatWeight(
        this.flight.aircraft.maximumPayload
      )} ${this.flight.aircraft.weightUnit}`;
      this.snackbarVisible = true;
    },

    openPayloadCalculator() {
      if (!this.enableUpdateFlight) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.payload) {
        this.numberOfPassengers = this.flight.payload.numberOfPassengers.toString();
        this.estimatedWeightPerPassenger = this.flight.payload.estimatedWeightPerPassenger.toString();
        this.swaCategory = this.flight.payload.swaCategory;
        this.selectedBufferWeight = this.flight.payload.bufferWeight;
      } else {
        this.numberOfPassengers = "0";
        this.estimatedWeightPerPassenger = "0";
        this.swaCategory = this.flight.aircraft.swaCategories[0];
        this.selectedBufferWeight = 5000;
      }

      this.cargo = "0";
      this.payloadDialog = true;
    },

    openCargoPayloadCalculator() {
      if (!this.enableUpdateFlight) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flight.payload) {
        this.cargo = this.flight.payload.cargo.toString();
      } else {
        this.cargo = "0";
      }

      this.numberOfPassengers = "0";
      this.estimatedWeightPerPassenger = "0";
      this.swaCategory = this.flight.aircraft.swaCategories[0];
      this.selectedBufferWeight = 5000;
      this.cargoPayloadDialog = true;
    },

    async savePayload() {
      if (!this.numberOfPassengers) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please enter as valid number of passengers.";
        this.snackbarVisible = true;
        return;
      }

      if (!this.estimatedWeightPerPassenger) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Please enter as valid estimated Weight per Passenger value.";
        this.snackbarVisible = true;
        return;
      }

      this.$refs.form.validate();

      if (!this.payloadValid) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please correct highlighted values";
        this.snackbarVisible = true;
        return;
      }

      this.payloadErrors = [];

      const over = this.payloadExceededBy;

      if (over > 0) {
        this.payloadErrors.push({
          type: "Maximum exceeded",
          description: `Maximum payload exceeded by ${this.formatWeight(
            over
          )} ${this.flight.aircraft.weightUnit}`,
        });
      }

      if (this.numberOfPassengers > 0 && this.estimatedWeightPerPassenger < 1) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Estimated Weight per Passenger cannot be zero`;
        return (this.snackbarVisible = true);
      }

      if (!this.overrideWarnings && this.payloadErrors.length) {
        return (this.payloadWarningDialog = true);
      }

      this.payloadWarningDialog = false;

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const payload = {
          flightId: this.flight.flightId,
          aircraftRegistration: this.flight.aircraft.registration,
          numberOfPassengers: parseInt(this.numberOfPassengers),
          cargo: 0,
          estimatedWeightPerPassenger: parseInt(
            this.estimatedWeightPerPassenger
          ),
          preliminaryWeight: this.preliminaryWeight,
          bufferWeight: this.selectedBufferWeight,
          finalEstimatedPayload: this.finalEstimatedPayload,
          swaCategory: this.swaCategory,
        };

        const res = await api.put(
          `/payloads/${this.flight.flightId}`,
          payload,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        //TODO catch

        this.flight = res.data.flight;
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.payloadDialog = false;
        this.showReverseCalculator = false;
        this.overrideWarnings = false;
      }
    },

    async deletePayload() {
      this.payloadErrors = [];

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        await api.delete(`/payloads/${this.flight.flightId}`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        await this.$store.dispatch("getFlight", this.flight.flightId);
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.payloadDialog = false;
        this.showReverseCalculator = false;
        this.overrideWarnings = false;
      }
    },

    hidePayloadDialog() {
      this.payloadDialog = false;
      (this.showReverseCalculator = false), (this.overrideWarnings = false);
    },

    async saveCargoPayload() {
      this.$refs.cargoForm.validate();

      if (!this.cargoPayloadValid) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please correct highlighted values";
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const payload = {
          flightId: this.flight.flightId,
          aircraftRegistration: this.flight.aircraft.registration,
          cargo: parseInt(this.cargo),
          estimatedWeightPerPassenger: 0,
          preliminaryWeight: 0,
          bufferWeight: 0,
          finalEstimatedPayload: 0,
          swaCategory: this.swaCategory,
        };

        const res = await api.put(
          `/payloads/${this.flight.flightId}`,
          payload,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;
        this.cargoPayloadDialog = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.flight = res.data.flight;
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    openUnitConverter() {
      this.unitConverterDialog = true;
    },

    switchToBoardMode() {
      this.boardStyle = "Board";
    },

    switchToListMode() {
      this.boardStyle = "List";
    },

    openDateFilter() {
      this.selectedStartDate = this.dateFilter.startDate.slice();
      this.selectedEndDate = this.dateFilter.endDate.slice();
      this.dateFilterDialog = true;
    },

    saveDateFilter() {
      this.dateFilter.startDate = this.selectedStartDate.slice();
      this.dateFilter.endDate = this.selectedEndDate.slice();

      let yy;
      let mm;
      let dd;

      const dt1 = new Date(this.dateFilter.startDate);
      yy = dt1.getUTCFullYear();
      mm = dt1.getUTCMonth();
      dd = dt1.getUTCDate();
      const filterStart = new Date(Date.UTC(yy, mm, dd, 0, 0, 0, 0)).getTime();

      const dt2 = new Date(this.dateFilter.endDate);
      yy = dt2.getUTCFullYear();
      mm = dt2.getUTCMonth();
      dd = dt2.getUTCDate();
      const filterEnd = new Date(
        Date.UTC(yy, mm, dd, 23, 59, 59, 999)
      ).getTime();

      const numberOfDays = Math.ceil(
        (filterEnd - filterStart) / 1000 / 60 / 60 / 24
      );

      if (numberOfDays < 5 || numberOfDays > 31) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please select a range between 5 and 31 days";
        this.snackbarVisible = true;
        return;
      }

      this.dateFilterDialog = false;
      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getFlights();
    },

    dateFilterBlockBack() {
      const numberOfDays = 14;
      const filterStart =
        new Date(this.dateFilter.start).getTime() -
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getFlights();
    },

    dateFilterBack() {
      const numberOfDays = 7;
      const filterStart =
        new Date(this.dateFilter.start).getTime() -
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getFlights();
    },

    dateFilterForward() {
      const numberOfDays = 7;
      const filterStart =
        new Date(this.dateFilter.start).getTime() +
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getFlights();
    },

    dateFilterBlockForward() {
      const numberOfDays = 14;
      const filterStart =
        new Date(this.dateFilter.start).getTime() +
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getFlights();
    },

    showAircraftInformation(aircraft) {
      const board = {
        ...this.board,
        aircraftScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
      this.$store.commit("updateAircraft", aircraft);
      this.aircraftDialog = true;
    },

    getAircraftTypeStyle({ type, cargoOnly }) {
      let style = {
        backgroundColor: "white",
        paddingLeft: "5px",
        border: "1px solid #bebebe",
        borderRadius: "5px",
        fontFamily: "Abel, Arial, Helvetica, sans-serif",
        fontSize: "12px",
        textAlign: "center",
        cursor: "pointer",
      };

      if (cargoOnly) {
        style.borderRight = "3px solid #696969";
        style.borderBottom = "2px solid #696969";
      }

      // 738	#31abb2
      // 744	#03abdb
      // 748	#a1a1e6
      // 763	#ff9900
      // 74Y	#cc9900
      // 76F	#fcb003
      // 77F	#0d8512

      switch (type) {
        case "738":
          style.backgroundColor = "#31abb2";
          break;
        case "744":
          style.backgroundColor = "#03abdb";
          break;
        case "748":
          style.backgroundColor = "#a1a1e6";
          break;
        case "763":
          style.backgroundColor = "#ff9900";
          break;
        case "74Y":
          style.backgroundColor = "#cc9900";
          break;
        case "76F":
          style.backgroundColor = "#fcb003";
          break;
        case "77F":
          style.backgroundColor = "#0d8512";
          break;

        default:
          break;
      }

      return style;
    },

    selectAircraftAll() {
      this.selectedAircrafts = [...this.company.aircrafts];
    },

    selectAircraftNone() {
      this.selectedAircrafts = [];
    },

    async openAircraftFilter() {
      this.selectedAircrafts = [...this.favoriteAircrafts];
      this.aircraftFilterDialog = true;
    },

    async saveAircraftFilter() {
      this.aircraftFilterDialog = false;

      this.loading = true;

      let favoriteAircrafts = [];

      this.selectedAircrafts.forEach((aircraft) => {
        favoriteAircrafts.push(aircraft.registration);
      });

      let settings = { ...this.user.settings };
      settings.filters.aircrafts = [...favoriteAircrafts];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        this.loading = false;
        return;
      }

      this.loading = false;
      this.getFlights();
    },
  },
};
</script>

<style scoped>
.exemption-row {
  display: block;
  font-family: "Abel", Times, serif;
  font-size: 0.75rem;
  border-bottom: 1px solid gray;
  padding: 0;
}
.remarks {
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
}

p {
  width: 100%;
  min-height: 100px;
}

.payload-exceeded {
  color: red;
}

.cargo-payload {
  border-radius: 5px;
  margin: 0 10px 0 10px;
  padding: 15px;
}

.cargo-payload input {
  font-size: 1.3rem;
  width: 150px;
}

.payload-calculator {
  border-radius: 5px;
  margin: 0 10px 0 10px;
  padding: 15px;
}

.payload-calculator h3 {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--dark-gray);
}

.payload-calculator h4 {
  font-size: 1rem;
  font-weight: 100;
  min-width: 110px;
}

.remove-icon:hover {
  color: red;
}

.trip-frame {
  position: fixed;
  top: 88px;
  /* display: flex; */
  min-height: 100px;
  width: 100%;
  border: 1px;
  border-color: black;
  border-style: solid;
  background-color: #414958;
  color: var(--white);
  padding: 5px;
  align-items: center;
  z-index: 100;
  user-select: none;
  -webkit-user-select: none;
}

.trip-frame h3 {
  color: var(--white);
}

.station-assignment-frame {
  position: fixed;
  top: 88px;
  display: flex;
  min-height: 100px;
  width: 100%;
  border: 1px;
  border-color: var(--black);
  border-style: solid;
  background-color: rgb(136, 96, 43);
  color: var(--white);
  padding: 5px;
  align-items: center;
  z-index: 100;
  user-select: none;
  -webkit-user-select: none;
}

.station-assignment-frame h3 {
  color: var(--white);
}

.trip-flight {
  border: 1px solid var(--white);
  border-radius: 3px;
  background-color: #616164;
  color: var(--white);
  margin-right: 5px;
  padding: 3px 5px;
  text-align: center;
  cursor: pointer;
}

.r-dialog {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5px 0;
  user-select: none;
  -webkit-user-select: none;
}

.fa-left {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-left:hover {
  color: #1d8de9;
}

.fa-right {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-right:hover {
  color: #1d8de9;
}

.fa-star {
  font-size: 1.1rem;
  color: #d4af37;
  padding: 0 1rem;
}

.fa-add:hover {
  color: var(--blue);
}

.fa-close {
  font-size: 1.1rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.fa-close:hover {
  color: var(--red);
}

.fa-calculator {
  font-size: 2rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.fa-calendar-circle-exclamation {
  font-size: 1.5rem;
  color: var(--red);
}

.fa-calculator:hover {
  color: var(--blue);
}

.fa-user-slash {
  color: var(--amber);
}

.fa-trash-can {
  color: var(--red);
}

.fa-envelope {
  color: var(--blue);
}

.fa-circle-exclamation {
  color: var(--amber);
}

.fa-person-walking-luggage {
  color: var(--blue);
}

.selected-row {
  color: var(--red);
}

.items-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* .items-table tr:hover {
  background-color: #ddd;
} */

.items-table th {
  padding: 3px 10px;
  text-align: left;
  background-color: var(--gray);
  color: var(--black);
}

.items-table td {
  padding: 0 10px;
}

.reverse-calculator {
  border: 1px solid var(--gray);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  column-gap: 20px;
  align-items: center;
  padding: 5px 10px;
  margin: 5px 0;
}

.reverse-calculator input {
  font-size: 1.3rem;
}

.reverse-calculator p {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  color: var(--black);
  padding: 0 5px;
  margin: 0;
}

.weight-converter {
  align-items: center;
  padding: 5px 10px;
}

.loadmaster-exemptions-header {
  display: flex;
  width: auto;
  justify-content: space-between;
  column-gap: 20px;
  background-color: var(--gray);
  align-items: center;
  padding: 5px 10px;
}

.loadmaster-exemptions-header div {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: var(--white);
}

.fa-trash-check {
  color: var(--purple);
}

.exemption-add {
  border: 1px solid var(--gray);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  width: auto;
  justify-content: space-between;
  column-gap: 20px;
  align-items: center;
  margin: 0.5rem;
  padding: 5px 10px;
}

.exemption-row {
  border-bottom: 1px solid var(--gray);
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
}

.exemption-item {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: var(--dark-blue);
}

.exemption-item i {
  cursor: pointer;
  color: var(--gray);
}

.exemption-item i:hover {
  font-size: 1rem;
  color: var(--red);
}

.loadmaster-exemptions {
  border: 1px solid var(--gray);
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
}

.item-frame {
  border: 1px solid var(--gray);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  width: auto;
  justify-content: flex-start;
  column-gap: 20px;
  align-items: center;
  margin: 0.3rem 0.5rem;
  padding: 5px 10px;
}

select {
  text-align: right;
  max-width: 100px;
}

.crew-requirement-frame {
  border-style: solid;
  border-color: var(--amber);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

/* mt-2 text-right font-abel-14px indigo--text */

.flight-id {
  text-align: right;
  font-size: 1rem;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  color: indigo;
  user-select: text;
}
</style>
