<template>
  <div class="flight-information-dialog font-abel-16px">
    <div class="d-flex justify-space-between align-center font-abel-20px">
      <div class="d-flex align-center">
        <h2 class="font-weight-bold">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </h2>
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="close" small icon class="ml-3">
            <i class="fa-solid fa-xmark"></i>
          </v-btn>
        </template>
        <span>Close</span>
      </v-tooltip>
    </div>

    <v-divider class="my-3"></v-divider>
    <div>
      <span>Company:</span
      ><span class="font-abel-16px-bold ml-2 indigo--text">{{
        employee.companyName
      }}</span>
    </div>
    <div>
      <span>Employee ID:</span
      ><span class="font-abel-16px-bold ml-2 indigo--text">{{
        employee.employeeId
      }}</span>
    </div>
    <div>
      <span>Gateway:</span
      ><span class="font-abel-16px-bold ml-2 indigo--text">{{
        employee.gatewayCode
      }}</span>
    </div>
    <div>
      <span>Job Title:</span
      ><span class="ml-1 font-weight-bold ml-2 indigo--text">{{
        employee.jobTitle
      }}</span>
    </div>
    <div>
      <span>Email:</span
      ><span class="ml-1 font-weight-bold ml-2 indigo--text">{{
        employee.mail
      }}</span>
    </div>
    <div>
      <span>Secondary Email:</span
      ><span class="ml-1 font-weight-bold ml-2 indigo--text">{{
        employee.secondaryMail
      }}</span>
    </div>
    <div>
      <span>Phone:</span
      ><span class="ml-1 font-weight-bold ml-2 indigo--text">{{
        employee.mobilePhone
      }}</span>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "employeeInformation",
  mixins: [mixin],
  data() {
    return {
      dialog: true,
    };
  },
  props: ["employee"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
