var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"blue-frame"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"font-abel-24px-bold mb-2"},[_vm._v(" Special Airports ")]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.hide}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-container',[_c('v-row',{attrs:{"dense":"","fluid":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex justify-center px-10"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Check Date","prepend-icon":"event","readonly":""},model:{value:(_vm.targetDate),callback:function ($$v) {_vm.targetDate=$$v},expression:"targetDate"}},on))]}}]),model:{value:(_vm.showStartCalendar),callback:function ($$v) {_vm.showStartCalendar=$$v},expression:"showStartCalendar"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.changeCheckDate},model:{value:(_vm.targetDate),callback:function ($$v) {_vm.targetDate=$$v},expression:"targetDate"}})],1)],1)])],1),_c('v-row',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.airports,"search":_vm.search,"footer-props":{
              itemsPerPageOptions: [10, 20, 50, 100, 200, -1],
            }},scopedSlots:_vm._u([{key:"item.covid19Periods",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCovid19Status(item)))])]}},{key:"item.hazardPeriods",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatHazardStatus(item)))])]}}],null,true)})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }