<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog v-model="employeeFilterDialog" persistent max-width="450px">
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- WARNINGS -->
    <v-dialog v-model="showWarningsDialog" persistent max-width="1200px">
      <div class="red-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Warnings
          </div>
        </div>

        <v-text-field
          v-model="warningsSearch"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          :style="{
            maxWidth: '200px',
            marginLeft: '10px',
            marginBottom: '10px',
          }"
        ></v-text-field>

        <!-- WARNINGS -->
        <v-data-table
          v-if="warnings"
          dense
          :headers="warningsHeaders"
          :search="warningsSearch"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div
          class="d-flex justify-space-between align-center font-abel-16px my-3"
        >
          <div></div>
          <v-btn @click="handleAcknowledgeWarnings" small dark color="blue"
            >acknowledge</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <!--Filters -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>
      <v-badge
        class="mr-5"
        :content="warnings.length"
        :value="warnings.length"
        color="red"
        overlap
      >
        <v-btn
          :disabled="loading"
          @click="handleshowWarningsDialog"
          small
          icon
          class="ml-1"
        >
          <i
            class="fa-solid fa-triangle-exclamation"
            :class="{ 'warning-state': warnings.length }"
          ></i>
        </v-btn>
      </v-badge>
      <v-spacer></v-spacer>

      <div class="font-abel-12px mr-2">
        {{ employeeCountText }}
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="getEmployees()"
            small
            icon
            class="mx-1"
            :disabled="loading"
          >
            <i class="fa-regular fa-rotate"></i>
          </v-btn>
        </template>
        <span>Get Employees</span>
      </v-tooltip>

      <v-btn
        class="mx-2"
        v-if="enableCreateEmployee"
        @click="addEmployee"
        small
        icon
        :disabled="loading"
      >
        <i class="fa-solid fa-user-plus"></i>
      </v-btn>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#D2D6D7">
          <v-list-item @click="printWarnings" link>
            <v-list-item-icon>
              <i class="fa-solid fa-print"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Print Warnings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="getVaccinationRecordsCsv" link>
            <v-list-item-icon>
              <v-icon color="blue">vaccines</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Get Vaccination Records</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <!--Filters -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>
      <v-badge
        class="mr-5"
        :content="warnings.length"
        :value="warnings.length"
        color="red"
        overlap
      >
        <v-btn
          :disabled="loading"
          @click="handleshowWarningsDialog"
          small
          icon
          class="ml-1"
        >
          <i
            class="fa-solid fa-triangle-exclamation"
            :class="{ 'warning-state': warnings.length }"
          ></i>
        </v-btn>
      </v-badge>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <v-list-item @click="printWarnings" link>
            <v-list-item-icon>
              <i class="fa-solid fa-print"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Print Warnings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="getEmployees()" link>
            <v-list-item-icon>
              <i class="fa-regular fa-rotate"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Active Employees</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="enableCreateEmployee" @click="addEmployee" link>
            <v-list-item-icon>
              <i class="fa-solid fa-plus"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>New Employee</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div v-if="!loading">
      <div class="bottom-frame-no-toolbar">
        <div class="d-flex justify-start align-center mt-5 py-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="loading"
                @click="handleOpenEmployeeFilter"
                small
                icon
              >
                <i class="fa-solid fa-filter"></i>
              </v-btn>
            </template>
            <span>Filter Employees by Job Title</span>
          </v-tooltip>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :style="{
              maxWidth: '200px',
              marginLeft: '10px',
              marginBottom: '10px',
            }"
          ></v-text-field>

          <div class="ml-5">
            <v-checkbox
              @change="getEmployees()"
              v-model="activeOnly"
              label="Show active employees only"
            ></v-checkbox>
          </div>
        </div>
        <div v-if="filteredEmployees.length">
          <v-container fluid>
            <v-col cols="12">
              <v-data-table
                dense
                :headers="headers"
                :items="filteredEmployees"
                :search="search"
                :footer-props="{
                  itemsPerPageOptions: [50, 100, 200],
                }"
                @click:row="navigateToEmployeeUpdate"
                item-key="_id"
              >
                <template v-slot:[`item.warnings`]="{ item }">
                  <span>{{ item.warnings.length }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-container>
        </div>
        <div v-else class="bottom-frame font-abel-16px">
          <div class="r-frame-rounded-yellow red--text">
            No employees found with the selected filters.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
//import WarningDialog from "../components/WarningDialog";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { json2csv } from "json-2-csv";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar.vue";
import EmployeeFilter from "../components/EmployeeFilter.vue";
export default {
  name: "administration",
  mixins: [mixin],
  components: { AutoLogout, Navbar, EmployeeFilter },
  data() {
    return {
      loading: false,
      isMobile: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      employeeFilterDialog: false,
      filteredEmployees: [],
      employees: [],
      headers: [
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "surname",
        },
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "givenName",
        },

        {
          text: "Job Title",
          align: "start",
          sortable: true,
          value: "jobTitle",
        },
        {
          text: "Company",
          align: "start",
          sortable: true,
          value: "companyName",
        },
        {
          text: "Security Group",
          align: "start",
          sortable: true,
          value: "securityGroup.name",
        },
        {
          text: "Gateway",
          align: "start",
          sortable: true,
          value: "gatewayCode",
        },
        {
          text: "Mobile",
          align: "start",
          sortable: true,
          value: "mobilePhone",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "mail",
        },
        {
          text: "Documents Warnings",
          align: "start",
          sortable: true,
          value: "warnings",
        },
      ],
      showWarningsDialog: false,
      search: "",
      warnings: [],
      warningsSearch: "",
      warningsHeaders: [
        {
          text: "Employee",
          align: "start",
          sortable: true,
          value: "employeeName",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      activeOnly: true,
      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
    };
  },
  created() {
    this.selectedCompany = this.user.securityGroup.companies[0];
    this.selectedBoardType = this.board.type;
    this.getEmployees();
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },

    user() {
      return this.$store.getters.user;
    },
    employeeCountText() {
      if (this.activeOnly) {
        return `${this.filteredEmployees.length} Active Employee(s)`;
      } else {
        return `${this.filteredEmployees.length} Employee(s)`;
      }
    },

    board() {
      return this.$store.getters.board;
    },
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    handleChangeBoardType() {
      let board = { ...this.board };
      board.type = this.selectedBoardType;
      this.$store.commit("updateBoard", board);
      this.filterEmployees();
    },

    handleOpenEmployeeFilter() {
      this.employeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.employeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.employeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getEmployees();
    },

    filterEmployees() {
      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];

      if (this.selectedCompany !== "All Companies") {
        filterOne = this.employees.filter((employee) => {
          return this.selectedCompany === employee.companyName;
        });
      } else {
        filterOne = [...this.employees];
      }

      if (this.search.length) {
        filterTwo = filterOne.filter((employee) => {
          return employee.surname
            .toUpperCase()
            .startsWith(this.search.toUpperCase());
        });
      } else {
        filterTwo = [...filterOne];
      }

      switch (this.board.type) {
        case "Cargo":
          filterThree = filterTwo.filter((item) => {
            return this.cargoJobTitles.includes(item.jobTitle);
          });
          break;
        case "Passenger":
          filterThree = filterTwo.filter((item) => {
            return this.passengerJobTitles.includes(item.jobTitle);
          });
          break;

        default:
          filterThree = [...filterTwo];
          break;
      }

      filterThree.forEach((employee) => {
        employee.warnings = this.warnings.filter((item) => {
          return item.employeeId === employee._id;
        });
      });

      this.filteredEmployees = [...filterThree];
    },

    handleshowWarningsDialog() {
      if (this.warnings.length) {
        this.showWarningsDialog = true;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async printWarnings() {
      if (!this.warnings.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No warnings to print";
        return (this.snackbarVisible = true);
      }
      try {
        const pdfDoc = await PDFDocument.create();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );
        let text;
        let textWidth;
        text = `Employee Warnings Report`;
        pdfDoc.setTitle(text);
        pdfDoc.addPage();
        let warning;
        let rowHeight = 70;
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let options = {
          x: 50,
          y: 775,
          size: 20,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        };
        const pageCenter = parseInt(page.getWidth() / 2);
        textWidth = helveticaBoldFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        //Printed On
        options.y -= 18;
        options.size = 10;
        options.font = helveticaFont;
        text = `Printed: ${this.formatDateTime(new Date())}`;
        textWidth = helveticaFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        options.y -= 40;
        options.x = 50;
        for (let i = 0; i < this.warnings.length; i++) {
          warning = this.warnings[i];
          options.color = rgb(0.15, 0.09, 0.81);
          // Employee Name.
          //---------------------------------------------------ROW 1
          options.x = 50;
          options.font = helveticaFont;
          options.color = rgb(0, 0, 1); //Blue
          options.size = 12;
          page.drawText(`${warning.employeeName}`, options);
          options.size = 10;
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          //---------------------------------------------------ROW 2
          // Type
          options.y -= 15;
          page.drawText(`${warning.type}`, options);
          //---------------------------------------------------ROW 3
          options.y -= 15;
          //Message
          page.drawText(`${warning.message}`, options);
          //---------------------------------------------------Separator
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          options.y -= 10;
          page.drawText(
            `__________________________________________________________________________________`,
            options
          );
          options.y -= 20;

          if (options.y - 70 < rowHeight && i <= this.warnings.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );
            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);
            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.color = rgb(0.15, 0.09, 0.81);
            options.x = 50;
            options.y = 775;
          }
        }
        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0); //Black
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);
        options.x = 450;
        page.drawText(`page ${pages.length} of ${pdfDoc.pageCount}`, options);
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleAcknowledgeWarnings() {
      //NEXT handle acknoledgement
      this.showWarningsDialog = false;
    },

    async getEmployees() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.warnings = [];

      let activeOnly = "yes";

      if (!this.activeOnly) {
        activeOnly = "no";
      }

      const res = await this.$store.dispatch("getEmployees", activeOnly);

      "RESPONSE IN ADMIN", res;

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.data.message;
        this.snackbarVisible = true;
        return;
      }

      this.employees = res.employees;
      this.warnings = res.warnings;

      this.filterEmployees();
    },

    downloadCsvFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getVaccinationRecordsCsv() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/employees/reports/vaccines", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            propername: "Covid-19",
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        let employees = res.data.employees;

        let csvRows = [];
        let csvRow;
        let vaccine;

        employees.forEach((employee) => {
          csvRow = {
            EmployeeID: employee.employeeId,
            FirstName: employee.givenName,
            LastName: employee.surname,
            Email: employee.mail,
            Phone: employee.mobilePhone,
          };

          for (let i = 0; i < 10; i++) {
            vaccine = employee.vaccines[i];

            switch (i) {
              case 0:
                if (vaccine) {
                  csvRow.Vaccine1ProperName = vaccine.properName;
                  csvRow.Vaccine1Type = vaccine.type;
                  csvRow.Vaccine1Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine1ProperName = "";
                  csvRow.Vaccine1Type = "";
                  csvRow.Vaccine1Date = "";
                }

                break;

              case 1:
                if (vaccine) {
                  csvRow.Vaccine2ProperName = vaccine.properName;
                  csvRow.Vaccine2Type = vaccine.type;
                  csvRow.Vaccine2Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine2ProperName = "";
                  csvRow.Vaccine2Type = "";
                  csvRow.Vaccine2Date = "";
                }

                break;

              case 2:
                if (vaccine) {
                  csvRow.Vaccine3ProperName = vaccine.properName;
                  csvRow.Vaccine3Type = vaccine.type;
                  csvRow.Vaccine3Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine3ProperName = "";
                  csvRow.Vaccine3Type = "";
                  csvRow.Vaccine3Date = "";
                }

                break;
              case 3:
                if (vaccine) {
                  csvRow.Vaccine4ProperName = vaccine.properName;
                  csvRow.Vaccine4Type = vaccine.type;
                  csvRow.Vaccine4Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine4ProperName = "";
                  csvRow.Vaccine4Type = "";
                  csvRow.Vaccine4Date = "";
                }

                break;
              case 4:
                if (vaccine) {
                  csvRow.Vaccine5ProperName = vaccine.properName;
                  csvRow.Vaccine5Type = vaccine.type;
                  csvRow.Vaccine5Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine5ProperName = "";
                  csvRow.Vaccine5Type = "";
                  csvRow.Vaccine5Date = "";
                }

                break;
              case 5:
                if (vaccine) {
                  csvRow.Vaccine6ProperName = vaccine.properName;
                  csvRow.Vaccine6Type = vaccine.type;
                  csvRow.Vaccine6Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine6ProperName = "";
                  csvRow.Vaccine6Type = "";
                  csvRow.Vaccine6Date = "";
                }

                break;
              case 6:
                if (vaccine) {
                  csvRow.Vaccine7ProperName = vaccine.properName;
                  csvRow.Vaccine7Type = vaccine.type;
                  csvRow.Vaccine7Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine7ProperName = "";
                  csvRow.Vaccine7Type = "";
                  csvRow.Vaccine7Date = "";
                }

                break;
              case 7:
                if (vaccine) {
                  csvRow.Vaccine8ProperName = vaccine.properName;
                  csvRow.Vaccine8Type = vaccine.type;
                  csvRow.Vaccine8Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine8ProperName = "";
                  csvRow.Vaccine8Type = "";
                  csvRow.Vaccine8Date = "";
                }

                break;
              case 8:
                if (vaccine) {
                  csvRow.Vaccine9ProperName = vaccine.properName;
                  csvRow.Vaccine9Type = vaccine.type;
                  csvRow.Vaccine9Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine9ProperName = "";
                  csvRow.Vaccine9Type = "";
                  csvRow.Vaccine9Date = "";
                }

                break;
              case 9:
                if (vaccine) {
                  csvRow.Vaccine10ProperName = vaccine.properName;
                  csvRow.Vaccine10Type = vaccine.type;
                  csvRow.Vaccine10Date = this.formatDate(vaccine.date);
                } else {
                  csvRow.Vaccine10ProperName = "";
                  csvRow.Vaccine10Type = "";
                  csvRow.Vaccine10Date = "";
                }

                break;

              default:
                break;
            }
          }

          csvRows.push(csvRow);
        });

        let options = {
          delimiter: {
            wrap: '"', // Double Quote (") character
            field: ",", // Comma field delimiter
            eol: "\n", // Newline delimiter
          },
          trimHeaderFields: true,
          trimFieldValues: true,
          keys: [
            "EmployeeID",
            "FirstName",
            "LastName",
            "Email",
            "Phone",
            "Vaccine1ProperName",
            "Vaccine1Type",
            "Vaccine1Date",
            "Vaccine2ProperName",
            "Vaccine2Type",
            "Vaccine2Date",
            "Vaccine3ProperName",
            "Vaccine3Type",
            "Vaccine3Date",
            "Vaccine4ProperName",
            "Vaccine4Type",
            "Vaccine4Date",
            "Vaccine5ProperName",
            "Vaccine5Type",
            "Vaccine5Date",
            "Vaccine6ProperName",
            "Vaccine6Type",
            "Vaccine6Date",
            "Vaccine7ProperName",
            "Vaccine7Type",
            "Vaccine7Date",
            "Vaccine8ProperName",
            "Vaccine8Type",
            "Vaccine8Date",
            "Vaccine9ProperName",
            "Vaccine9Type",
            "Vaccine9Date",
            "Vaccine10ProperName",
            "Vaccine10Type",
            "Vaccine10Date",
          ],
        };

        json2csv(
          csvRows,
          (error, csv) => {
            if (error) {
              this.snackbarColor = "#F44336";
              this.snackbarText = error;
              this.snackbarVisible = true;
            } else {
              this.downloadCsvFile(csv);
            }
          },
          options
        );
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    addEmployee() {
      this.$router.push({ name: "employeeCreate" });
    },

    navigateToEmployeeUpdate(employee) {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: employee.id },
      });
    },
  },
};
</script>

<style scoped></style>
