<template>
  <div>
    <Navbar />

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <div class="main-header">
      <div>
        <!-- {{ user.displayName }} -->
      </div>

      <div class="d-flex justify-space-between align-center mr-1 py-2">
        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <i class="fa-solid fa-arrows-rotate "></i>
        </v-btn>
      </div>
    </div>
    <div class="main-frame">
      <!-- LOADING -->
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>

      <v-container fluid v-if="!loading">
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="assignments"
            :footer-props="{
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
          >
            <template v-slot:[`item._id`]="{ item }">
              <span>{{ formatAssignmentSnapshot(item) }}</span>
            </template>

            <template v-slot:[`item.notification`]="{ item }">
              <v-btn icon>
                <v-icon :color="getNotificationStatusColor(item)"
                  >notifications</v-icon
                >
              </v-btn>
            </template>

            <template v-slot:[`item.sentOn`]="{ item }">
              <span>{{ formatNotificationSentOn(item) }}</span>
            </template>
          </v-data-table>
          <div class="d-flex justify-end">
            <v-btn
              @click="handleAcknowledgeAssignments"
              small
              dark
              color="blue"
              class="mr-3 my-3"
              :disabled="assignments.length === 0"
              >Acknowledge</v-btn
            >
          </div>
        </v-col>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import api from "../apis/monarchApi";
import Navbar from "../components/Navbar";

export default {
  name: "notificationAcknowledgement",
  mixins: [mixin],
  components: { Navbar },
  data() {
    return {
      // user: { displayName: "TODO" },
      loading: false,
      isMobile: false,
      headers: [
        {
          text: "Last Name",
          align: "start",
          value: "employee.surname",
        },
        {
          sortable: true,
          text: "First Name",
          align: "start",
          value: "employee.givenName",
        },
        {
          sortable: true,
          text: "Company",
          align: "start",
          value: "employee.companyName",
        },
        {
          sortable: true,
          text: "Job Title",
          align: "start",
          value: "employee.jobTitle",
        },
        {
          sortable: false,
          text: "Assignment",
          align: "start",
          value: "_id",
        },
        {
          sortable: false,
          text: "Status",
          align: "start",
          value: "notification",
        },
        {
          sortable: true,
          text: "Sent",
          align: "start",
          value: "sentOn",
        },
      ],
      selectedStartDate: "",
      selectedEndDate: "",
      assignments: [],
      showStartCalendar: false,
      showEndCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      lastName: "",
      flightAssignment: null,
      groundAssignment: null,
      groundAssignmentStation: null,
    };
  },
  created() {
    this.getAssignments();
  },
  mounted() {
    this.onResize();

    window.addEventListener("ize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("ize", this.onResize, { passive: true });
  },
  computed: {},
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },
    async getAssignments() {
      this.loading = true;

      try {
        const token = this.$router.currentRoute.params.token;

        const res = await api.get(`/assignments/notifications/token/${token}`);

        this.loading = false;

        this.assignments = res.data.assignments;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleAcknowledgeAssignments() {
      this.loading = true;

      try {
        const token = this.$router.currentRoute.params.token;

        const res = await api.put(`/assignments/notifications/token/${token}`);

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Success";
        this.snackbarVisible = true;

        this.getAssignments();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatNotificationSentOn(item) {
      if (item.notification) {
        return `${this.formatDateTime(item.notification.sentOn)}`;
      } else {
        return "---";
      }
    },

    getNotificationStatusColor(assignment) {
      let color = "red";

      if (assignment.notification) {
        let etdChanged;
        let etaChanged;
        let originChanged;
        let destinationChanged;

        if (assignment.type === "Flight") {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.flight.estimatedDepartureTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.flight.estimatedArrivalTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.flight.originIata !==
              assignment.notification.originIata;
            destinationChanged =
              assignment.flight.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            color = "blue";
          }
        } else {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.startTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.endTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.originIata !== assignment.notification.originIata;
            destinationChanged =
              assignment.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            color = "blue";
          }
        }
      }

      return color;
    },
  },
};
</script>

<style scoped>
.main-header {
  position: relative;
  top: 48px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: var(--dark-gray);
  color: var(--white);
}

.main-frame {
  position: relative;
  top: 58px;
  width: 100%;
  background-color: white;
}

.fa-arrows-rotate {
  font-size: 1.5rem;
  color: var(--white);
}

.fa-arrows-rotate:hover {
  color: var(--green);
}
</style>
