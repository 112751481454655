import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import FlightBoard from "../views/FlightBoard.vue";
import Administration from "../views/Administration.vue";
import EmployeeCreate from "../views/EmployeeCreate.vue";
import EmployeeUpdate from "../views/EmployeeUpdate.vue";
import FlightAssignmentBoard from "../views/FlightAssignmentBoard.vue";
import TripAssignmentBoard from "../views/TripAssignmentBoard.vue";
import StationAssignmentBoard from "../views/StationAssignmentBoard.vue";
import Bidlines from "../views/Bidlines.vue";
import AssignmentBoard from "../views/AssignmentBoard.vue";
import Notifications from "../views/Notifications.vue";
import MyAssignments from "../views/MyAssignments.vue";
import MyNotifications from "../views/MyNotifications.vue";
import NotificationAcknowledgement from "../views/NotificationAcknowledgement.vue";
import Reporting from "../views/Reporting.vue";
import CompanyAirports from "../views/CompanyAirports.vue";
import SecurityGroups from "../views/SecurityGroups.vue";
import FlightAssignmentEdit from "../views/FlightAssignmentEdit.vue";
import CrewRequirements from "../views/CrewRequirements.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/notification-acknowledgement/:token",
    name: "notificationAcknowledgement",
    component: NotificationAcknowledgement,
    props: true,
  },
  {
    path: "/flight-assignment-edit",
    name: "flightAssignmentEdit",
    component: FlightAssignmentEdit,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/airports",
    name: "airports",
    component: CompanyAirports,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/crew-requirements",
    name: "crewRequirements",
    component: CrewRequirements,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/security-groups",
    name: "securityGroups",
    component: SecurityGroups,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },

  {
    path: "/reporting",
    name: "reporting",
    component: Reporting,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/flight-board",
    name: "flightBoard",
    component: FlightBoard,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/flight-assign",
    name: "flightAssignmentBoard",
    component: FlightAssignmentBoard,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/trip-assign",
    name: "tripAssignmentBoard",
    component: TripAssignmentBoard,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/station-assign",
    name: "stationAssignmentBoard",
    component: StationAssignmentBoard,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/assignment-board",
    name: "assignmentBoard",
    component: AssignmentBoard,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },

  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/my-assignments",
    name: "myAssignments",
    component: MyAssignments,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/my-notifications",
    name: "myNotifications",
    component: MyNotifications,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/bidlines",
    name: "bidlines",
    component: Bidlines,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/administration",
    name: "administration",
    component: Administration,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/employee-create",
    name: "employeeCreate",
    component: EmployeeCreate,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
  {
    path: "/employee-update/:id",
    name: "employeeUpdate",
    component: EmployeeUpdate,
    beforeEnter: (to, from, next) => {
      if (store.state.user) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
