<template>
  <div>
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- CONFIRMATION DIALOG -->
    <v-dialog v-model="confirmationDialog" persistent max-width="300px">
      <ConfirmDelete
        v-if="confirmation"
        :message="confirmation.message"
        @confirm="confirm"
        @cancel="cancelConfirmation"
      />
    </v-dialog>

    <!-- WARNINGS -->
    <v-dialog v-model="showWarningsDialog" persistent max-width="1200px">
      <div class="red-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Warnings
          </div>
        </div>

        <v-text-field
          v-model="warningsSearch"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          :style="{
            maxWidth: '200px',
            marginLeft: '10px',
            marginBottom: '10px',
          }"
        ></v-text-field>

        <!-- WARNINGS -->
        <v-data-table
          v-if="warnings"
          dense
          :headers="warningsHeaders"
          :search="warningsSearch"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div
          class="d-flex justify-space-between align-center font-abel-16px my-3"
        >
          <div></div>
          <v-btn @click="handleAcknowledgeWarnings" small dark color="blue"
            >acknowledge</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- EMPLOYEE IMAGE DIALOG -->
    <v-dialog
      v-if="employee"
      v-model="showEmployeeImageDialog"
      max-width="350px"
    >
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Employee Image</div>
          <v-btn @click="showEmployeeImageDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <v-divider></v-divider>

        <div>
          <v-file-input
            prepend-icon="add_a_photo"
            v-model="profileImageFile"
            :rules="profileImageFileRules"
            accept="image/jpg, image/jpeg, image/png"
            label="Upload an image (1 MB Max.)"
            hint="Allowed file types are: jpg, jpeg, png"
          ></v-file-input>
        </div>

        <div class="d-flex justify-end align-center mt-3">
          <v-btn
            @click="saveEmployeeImage"
            small
            dark
            color="blue"
            :disabled="!profileImageFile"
            >upload</v-btn
          >
          <v-btn
            class="ml-1"
            @click="showEmployeeImageDialog = false"
            small
            dark
            color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- EMPLOYEE ID DIALOG -->
    <v-dialog
      v-if="employee"
      v-model="showEmployeeIdImageDialog"
      max-width="350px"
    >
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Employee ID</div>
          <v-btn @click="showEmployeeIdImageDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <v-divider></v-divider>

        <div class="my-2" v-if="!showEmployeeIdImageSelect">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div
                class="employee-id-image"
                v-on="on"
                @click="showEmployeeIdImageSelect = true"
              >
                <img
                  :src="employeeIdImageUrl"
                  alt="Employee ID Image"
                  height="300"
                />
              </div>
            </template>
            <span>Click to upload an image</span>
          </v-tooltip>
        </div>

        <div v-if="enableUpdateEmployee && showEmployeeIdImageSelect">
          <div>
            <v-file-input
              prepend-icon="add_a_photo"
              v-model="employeeIdImageFile"
              :rules="employeeIdImageFileRules"
              accept="image/jpg, image/jpeg, image/png"
              label="Upload an image (1 MB Max.)"
              hint="Allowed file types are: jpg, jpeg, png"
            ></v-file-input>
          </div>

          <div class="d-flex justify-end align-center mt-3">
            <v-btn
              @click="saveEmployeeIdImage"
              small
              dark
              color="blue"
              :disabled="!employeeIdImageFile"
              >upload</v-btn
            >
            <v-btn
              class="ml-1"
              @click="showEmployeeIdImageSelect = false"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- TOOLBAR -->
    <div class="r-toolbar font-abel-16px">
      <v-badge
        class="mr-5"
        :content="warnings.length"
        :value="warnings.length"
        color="red"
        overlap
      >
        <v-btn @click="handleShowWarningDialog" small icon class="ml-1">
          <i
            class="fa-solid fa-triangle-exclamation"
            :class="{ 'warning-state': warnings.length }"
          ></i>
        </v-btn>
      </v-badge>
    </div>

    <!-- FORM -->
    <div class="bottom-frame">
      <v-container v-if="employee" fluid class="font-abel-14px r-container">
        <!-- General Information from AD -->
        <v-form ref="form" v-model="valid">
          <v-card class="mb-2" elevation="2" outlined shaped tile>
            <v-card-text>
              <div class="d-flex justify-space-between align-center mb-2">
                <div class="d-flex align-center">
                  <div class="font-abel-24px indigo--text ">
                    {{ formatName(employee.givenName, employee.surname, "l") }}
                  </div>
                  <div v-if="enableUpdateEmployeeAdvanced">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="getUserFromActiveDirectory"
                          ><v-icon color="green">refresh</v-icon></v-btn
                        >
                      </template>
                      <span
                        >Update items marked with * with information from Active
                        Directory</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div
                      class="employee-image"
                      v-on="on"
                      @click="openEmployeeImageDialog"
                    >
                      <img
                        :src="employeeImageUrl"
                        alt="Profile Image"
                        height="150"
                      />
                    </div>
                  </template>
                  <span>Click to upload an image</span>
                </v-tooltip>
              </div>

              <!-- ROW 1 -->
              <v-row dense align="start">
                <!-- EMPLOYEE ID -->
                <v-col cols="10" sm="3">
                  <v-text-field
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    label="Employee ID"
                    :rules="employeeIdRules"
                    v-model="employee.employeeId"
                  ></v-text-field>
                </v-col>
                <!-- EMPLOYEE ID IMAGE -->
                <v-col cols="12" sm="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="!enableUpdateEmployeeAdvanced"
                        @click="openEmployeeIdDialog"
                        large
                        icon
                        v-on="on"
                        ><v-icon>assignment_ind</v-icon></v-btn
                      >
                    </template>
                    <span>View and edit Employee ID image</span>
                  </v-tooltip>
                </v-col>
                <!-- EMPLOYEE ID EXPIRATION DATE -->
                <v-col cols="6" sm="3">
                  <div>
                    <v-menu
                      v-model="showEmployeeIdCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="employee.employeeIdExpirationDate"
                          :disabled="!enableUpdateEmployeeAdvanced"
                          outlined
                          dense
                          label="Expiration Date"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="employee.employeeIdExpirationDate"
                        no-title
                        :min="minDate"
                        :max="maxDate"
                        @input="showEmployeeIdCalendar = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

                <!-- SECURITY GROUP -->
                <v-col cols="12" sm="5">
                  <v-select
                    v-if="enableUpdateEmployeeSecurityGroup"
                    outlined
                    dense
                    label="Security Group"
                    item-text="name"
                    item-value="id"
                    v-model="employee.securityGroupId"
                    :items="securityGroups"
                    required
                  ></v-select>
                  <v-select
                    v-else
                    disabled
                    outlined
                    dense
                    label="Security Group"
                    item-text="name"
                    item-value="id"
                    v-model="employee.securityGroupId"
                    :items="securityGroups"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <!-- ROW 2 -->
              <v-row dense align="center">
                <!-- FIRST NAME -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    dense
                    label="First Name *"
                    v-model="employee.givenName"
                    disabled
                  ></v-text-field>
                </v-col>

                <!-- LAST NAME -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    dense
                    label="Last Name *"
                    v-model="employee.surname"
                    disabled
                  ></v-text-field>
                </v-col>

                <!-- JOB TITLE -->
                <v-col cols="12" sm="4">
                  <v-select
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    label="Job Title *"
                    v-model="employee.jobTitle"
                    :items="user.securityGroup.jobTitles"
                    :rules="jobTitleRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <!-- ROW 3 -->
              <v-row dense align="center">
                <!-- COMPANY NAME -->
                <v-col cols="12" sm="3">
                  <v-select
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    label="Company Name"
                    v-model="employee.companyName"
                    :items="userCompanies"
                    :rules="companyNameRules"
                    required
                  ></v-select>
                </v-col>

                <!-- GATEWAY -->
                <v-col cols="12" sm="1">
                  <v-text-field
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    label="Gateway"
                    v-model="employee.gatewayCode"
                    :rules="gatewayCodeRules"
                    required
                  ></v-text-field>
                </v-col>

                <!-- SUB-GATEWAYS -->
                <v-col cols="12" sm="2">
                  <v-text-field
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    label="Sub-Gateways"
                    v-model="subGatewayCodesString"
                  ></v-text-field>
                </v-col>

                <!-- SENIORITY NUMBER -->
                <v-col cols="12" sm="2">
                  <v-text-field
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    type="number"
                    label="Seniority No."
                    v-model="employee.seniorityNumber"
                    :rules="seniorityNumberRules"
                    required
                  ></v-text-field>
                </v-col>

                <!-- DATE OF HIRE  -->
                <v-col cols="12" sm="2">
                  <div>
                    <v-menu
                      v-model="showDateOfHireCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="!enableUpdateEmployeeAdvanced"
                          v-model="employee.dateOfHire"
                          outlined
                          dense
                          label="Date of Hire"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="employee.dateOfHire"
                        no-title
                        @input="showDateOfHireCalendar = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

                <!-- STATUS -->
                <v-col cols="12" sm="2">
                  <v-select
                    :disabled="!enableUpdateEmployeeAdvanced"
                    outlined
                    dense
                    label="Status"
                    v-model="employee.status"
                    :items="statusOptions"
                    :rules="employeeStatusRules"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <!-- ROW 4 -->
              <v-row dense align="center">
                <!-- EMAIL ADDRESS -->
                <v-col cols="12" sm="5">
                  <v-text-field
                    outlined
                    dense
                    label="Email *"
                    v-model="employee.mail"
                    disabled
                  ></v-text-field>
                </v-col>

                <!-- SECONDARY EMAIL ADDRESS -->
                <v-col cols="12" sm="5">
                  <v-text-field
                    outlined
                    dense
                    label="Secondary Email"
                    v-model="employee.secondaryMail"
                  ></v-text-field>
                </v-col>

                <!-- MOBILE PHONE -->
                <v-col cols="12" sm="2">
                  <v-text-field
                    outlined
                    dense
                    label="Mobile Phone"
                    v-model="employee.mobilePhone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- ROW 5 -->
              <v-row dense align="center">
                <!-- EMERGENCVY CONTACT NAME -->
                <v-col cols="12" sm="8">
                  <v-text-field
                    outlined
                    dense
                    label="Emergency Contact Name"
                    v-model="employee.emergencyContact.name"
                  ></v-text-field>
                </v-col>

                <!-- EMERGENCY CONTACT NUMBER -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    outlined
                    dense
                    label="Emergency Contact Number"
                    v-model="employee.emergencyContact.number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- ROW 6 -->
              <v-row dense align="center">
                <!-- STREET ADDRESS -->
                <v-col cols="12" sm="5">
                  <v-text-field
                    outlined
                    dense
                    label="Street Address"
                    v-model="employee.address.street"
                  ></v-text-field>
                </v-col>

                <!-- CITY -->
                <v-col cols="12" sm="3">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    v-model="employee.address.city"
                  ></v-text-field>
                </v-col>

                <!-- ZIP CODE -->
                <v-col cols="12" sm="2">
                  <v-text-field
                    outlined
                    dense
                    label="ZIP"
                    v-model="employee.address.postalCode"
                  ></v-text-field>
                </v-col>

                <!-- STATE -->
                <v-col cols="12" sm="2">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    v-model="employee.address.state"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- ROW 7 -->
              <v-row dense align="center">
                <!-- COUNTRY -->
                <v-col cols="8">
                  <v-select
                    :items="countries"
                    outlined
                    dense
                    label="Country"
                    v-model="employee.address.country"
                  ></v-select>
                </v-col>
              </v-row>

              <div
                v-if="activeDirectoryEmployee"
                class="font-abel-12px  grey-text"
              >
                {{
                  `UPN: ${activeDirectoryEmployee.userPrincipalName} - ID: ${activeDirectoryEmployee.id}`
                }}
              </div>
            </v-card-text>
          </v-card>

          <!-- EXPANSION PANELS -->

          <v-expansion-panels>
            <!-- Passports -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">Passports</span>
                  <span v-if="employee.passports.length" class="indigo--text">
                    {{ `${formatPassports(employee.passports)}` }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no Passports.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="enableUpdateEmployeeAdvanced" dense align="start">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      outlined
                      maxlength="4"
                      dense
                      label="Last 4 Numbers"
                      v-model="passport.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-select
                      :items="countries"
                      outlined
                      dense
                      label="Country of Issue"
                      v-model="passport.country"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <div>
                      <v-menu
                        v-model="showPassportCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="passport.expirationDate"
                            outlined
                            dense
                            label="Expiration Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="passport.expirationDate"
                          no-title
                          :min="minDate"
                          :max="maxDate"
                          @input="showPassportCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="1">
                    <div>
                      <v-btn
                        :disabled="
                          passport.number.length !== 4 || !passport.country
                        "
                        @click="addPassport"
                        small
                        dark
                        color="blue"
                      >
                        add</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-data-table
                  dense
                  :headers="passportHeaders"
                  :items="employee.passports"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20],
                  }"
                >
                  <template v-slot:[`item.expirationDate`]="{ item }">
                    <span>{{ formatDate(item.expirationDate) }}</span>
                  </template>

                  <template v-slot:[`item._id`]="{ item }">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="deletePassport(item)"
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Visas -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">Visas</span>
                  <span v-if="employee.visas.length" class="indigo--text">
                    {{ `${formatVisas(employee.visas)}` }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no visas.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense v-if="enableUpdateEmployeeAdvanced" align="start">
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      maxlength="4"
                      dense
                      label="Last 4 Numbers"
                      v-model="visa.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-select
                      :items="countries"
                      outlined
                      dense
                      label="Country of Issue"
                      v-model="visa.country"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <div>
                      <v-menu
                        v-model="showVisaCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="visa.expirationDate"
                            outlined
                            dense
                            label="Expiration Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="visa.expirationDate"
                          no-title
                          :min="minDate"
                          :max="maxDate"
                          @input="showVisaCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <div>
                      <v-btn
                        :disabled="!visa.number.length || !visa.country"
                        @click="addVisa"
                        small
                        dark
                        color="blue"
                      >
                        add</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-data-table
                  dense
                  :headers="visaHeaders"
                  :items="employee.visas"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20],
                  }"
                >
                  <template v-slot:[`item.expirationDate`]="{ item }">
                    <span>{{ formatDate(item.expirationDate) }}</span>
                  </template>

                  <template v-slot:[`item._id`]="{ item }">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="deleteVisa(item)"
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Vaccines -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">Vaccines</span>
                  <span v-if="employee.vaccines.length" class="indigo--text">
                    {{ `${formatVaccines(employee.vaccines)}` }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no vaccines.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="enableUpdateEmployeeAdvanced" dense align="start">
                  <v-col cols="12" sm="4">
                    <v-select
                      @input="getVaccineTypes"
                      :items="vaccineProperNames"
                      outlined
                      dense
                      label="Proper Name"
                      v-model="vaccine.properName"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-select
                      :items="vaccineTypes"
                      outlined
                      dense
                      label="Type"
                      v-model="vaccine.type"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <div>
                      <v-menu
                        v-model="showVaccineCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="vaccine.date"
                            outlined
                            dense
                            label="Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="vaccine.date"
                          no-title
                          @input="showVaccineCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <div class="d-flex jsutify-end">
                      <v-btn
                        :disabled="!isValidVaccine"
                        @click="addVaccine"
                        small
                        dark
                        color="blue"
                      >
                        enter</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-data-table
                  dense
                  :headers="vaccineHeaders"
                  :items="employee.vaccines"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20],
                  }"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    <span>{{ formatDate(item.date) }}</span>
                  </template>

                  <template v-slot:[`item._id`]="{ item }">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      class="remove-icon"
                      @click="deleteVaccine(item)"
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>

                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      class="edit-icon"
                      @click="editVaccine(item)"
                      icon
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- SIDA Badges -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">SIDA Badges</span>
                  <span v-if="employee.sidaBadges.length" class="indigo--text">
                    {{ `${formatSidaBadges(employee.sidaBadges)}` }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no SIDA badges.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="enableUpdateEmployeeAdvanced" dense align="start">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      outlined
                      maxlength="4"
                      dense
                      label="Airport"
                      v-model="sidaBadge.airportOfIssue"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      outlined
                      maxlength="4"
                      dense
                      label="Last 4 Numbers"
                      v-model="sidaBadge.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <div>
                      <v-menu
                        v-model="showSidaBadgeCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="sidaBadge.expirationDate"
                            outlined
                            dense
                            label="Expiration Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="sidaBadge.expirationDate"
                          no-title
                          :min="minDate"
                          :max="maxDate"
                          @input="showSidaBadgeCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="1">
                    <div>
                      <v-btn
                        :disabled="
                          sidaBadge.number.length !== 4 ||
                            sidaBadge.airportOfIssue.length !== 3
                        "
                        @click="addSidaBadge"
                        small
                        dark
                        color="blue"
                      >
                        add</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-data-table
                  dense
                  :headers="sidaBadgeHeaders"
                  :items="employee.sidaBadges"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20],
                  }"
                >
                  <template v-slot:[`item.expirationDate`]="{ item }">
                    <span>{{ formatDate(item.expirationDate) }}</span>
                  </template>

                  <template v-slot:[`item._id`]="{ item }">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="deleteSidaBadge(item)"
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- CAC Card -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">CAC Card</span>
                  <span v-if="employee.cacCard" class="indigo--text">
                    {{
                      `${employee.cacCard.number} - Expiration: ${formatDate(
                        employee.cacCard.expirationDate
                      )}`
                    }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no CAC Card.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="employee.cacCard" dense align="start">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      :disabled="!enableUpdateEmployeeAdvanced"
                      outlined
                      maxlength="4"
                      dense
                      label="Last 4 Numbers"
                      :rules="cacCardNumberRules"
                      v-model="employee.cacCard.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <div>
                      <v-menu
                        v-model="showCacCardCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="!enableUpdateEmployeeAdvanced"
                            v-model="employee.cacCard.expirationDate"
                            outlined
                            dense
                            label="Expiration Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="employee.cacCard.expirationDate"
                          no-title
                          :min="minDate"
                          :max="maxDate"
                          @input="showCacCardCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="1">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="deleteCacCard"
                      icon
                      ><v-icon class="remove-icon">delete</v-icon></v-btn
                    >
                  </v-col>
                </v-row>

                <div v-else class="d-flex justify-end">
                  <div>
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="addCacCard"
                      small
                      icon
                      ><v-icon color="blue">add</v-icon></v-btn
                    >
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- KCM Badge -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">KCM Badge</span>
                  <span v-if="employee.kcmBadge" class="indigo--text">
                    {{
                      `${employee.kcmBadge.number} - Expiration: ${formatDate(
                        employee.kcmBadge.expirationDate
                      )}`
                    }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no KCM Badge.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="employee.kcmBadge" dense align="start">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      :disabled="!enableUpdateEmployeeAdvanced"
                      outlined
                      maxlength="4"
                      dense
                      label="Last 4 Numbers"
                      :rules="kcmBadgeNumberRules"
                      v-model="employee.kcmBadge.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <div>
                      <v-menu
                        v-model="showKcmBadgeCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :disabled="!enableUpdateEmployeeAdvanced"
                            v-model="employee.kcmBadge.expirationDate"
                            outlined
                            dense
                            label="Expiration Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="employee.kcmBadge.expirationDate"
                          no-title
                          :min="minDate"
                          :max="maxDate"
                          @input="showKcmBadgeCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="1">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="deleteKcmBadge"
                      icon
                      ><v-icon class="remove-icon">delete</v-icon></v-btn
                    >
                  </v-col>
                </v-row>

                <div v-else class="d-flex justify-end">
                  <div>
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="addKcmBadge"
                      small
                      icon
                      ><v-icon color="blue">add</v-icon></v-btn
                    >
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Driver Licenses -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">Drivers Licenses</span>
                  <span
                    v-if="employee.driversLicenses.length"
                    class="indigo--text"
                  >
                    {{ `${formatDriversLicenses(employee.driversLicenses)}` }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no Drivers Licenses.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="enableUpdateEmployeeAdvanced" dense align="start">
                  <v-col cols="12" sm="3">
                    <v-text-field
                      outlined
                      maxlength="4"
                      dense
                      label="Last 4 Numbers"
                      v-model="driversLicense.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-select
                      :items="states"
                      outlined
                      dense
                      label="State"
                      v-model="driversLicense.state"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <div>
                      <v-menu
                        v-model="showDriversLicenseCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="driversLicense.expirationDate"
                            outlined
                            dense
                            label="Expiration Date"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="driversLicense.expirationDate"
                          no-title
                          :min="minDate"
                          :max="maxDate"
                          @input="showDriversLicenseCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="1">
                    <div>
                      <v-btn
                        :disabled="
                          driversLicense.number.length !== 4 ||
                            !driversLicense.state
                        "
                        @click="addDriversLicense"
                        small
                        dark
                        color="blue"
                      >
                        add</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>

                <v-data-table
                  dense
                  :headers="driversLicenseHeaders"
                  :items="employee.driversLicenses"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 20],
                  }"
                >
                  <template v-slot:[`item.expirationDate`]="{ item }">
                    <span>{{ formatDate(item.expirationDate) }}</span>
                  </template>

                  <template v-slot:[`item._id`]="{ item }">
                    <v-btn
                      :disabled="!enableUpdateEmployeeAdvanced"
                      @click="deleteDriversLicense(item)"
                      icon
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Training Certificates -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <span class="mr-2">Training Certificates</span>
                  <span
                    v-if="trainingCertificates.length > 0"
                    class="indigo--text"
                  >
                    {{ `${trainingCertificates.length}` }}
                  </span>
                  <span v-else class="red--text">
                    This employee has no Training Certificates.
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-start flex-wrap align-baseline">
                  <v-row dense align="start">
                    <v-col cols="12" sm="5">
                      <v-text-field
                        :disabled="!enableUpdateEmployeeAdvanced"
                        outlined
                        dense
                        label="Pelesys Private Login Name"
                        :rules="pelesysReferenceRules"
                        v-model="employee.pelesysLoginName"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="5">
                      <v-text-field
                        :disabled="!enableUpdateEmployeeAdvanced"
                        class="ml-2"
                        outlined
                        dense
                        label="Pelesys Public Login Name"
                        v-model="employee.publicPelesysLoginName"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="1">
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-if="enableUpdateEmployee"
                            @click="getTrainingCertificates"
                            icon
                            v-on="on"
                            :disabled="employee.pelesysLoginName.length < 3"
                            ><v-icon color="blue"
                              >system_update_alt</v-icon
                            ></v-btn
                          >
                        </template>
                        <span>Get Training Certificates from Pelesys</span>
                      </v-tooltip>
                    </v-col>

                    <v-col cols="12" sm="1">
                      <v-switch
                        v-if="trainingCertificates.length"
                        @change="filterTrainingCertificates()"
                        class="ml-3"
                        v-model="showExpiredOnly"
                        label="Show only expired"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="filteredTrainingCertificates.length > 0">
                  <v-data-table
                    dense
                    :headers="trainingCertificateHeaders"
                    :items="filteredTrainingCertificates"
                    :footer-props="{
                      itemsPerPageOptions: [10, 20, 50],
                    }"
                  >
                    <template v-slot:[`item._id`]="{ item }">
                      <span>{{ getCertificateTitle(item.code) }}</span>
                    </template>

                    <template v-slot:[`item.code`]="{ item }">
                      <span>{{ item.code }}</span>
                    </template>

                    <template v-slot:[`item.completedDate`]="{ item }">
                      <span>{{
                        formatCertificateDate(item.completedDate)
                      }}</span>
                    </template>

                    <template v-slot:[`item.expirationDate`]="{ item }">
                      <span>{{
                        formatCertificateDate(item.expirationDate)
                      }}</span>
                    </template>

                    <template v-slot:[`item.graceExpirationDate`]="{ item }">
                      <span>{{
                        formatCertificateDate(item.graceExpirationDate)
                      }}</span>
                    </template>

                    <template v-slot:[`item.isExpired`]="{ item }">
                      <v-chip :color="formatIsExpiredColor(item)" dark>
                        {{ formatIsExpired(item) }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </div>
                <div v-else class="green--text">
                  No Expired Training Certificates found.
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
        <div
          class="d-flex justify-space-between align-center mt-2 font-abel-16px"
        >
          <div v-if="enableDeleteEmployee">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="showConfirmationDialog('deleteEmployee')"
                  v-on="on"
                  icon
                  ><v-icon class="remove-icon">delete</v-icon></v-btn
                >
              </template>
              <span>Delete Employee's Profile</span>
            </v-tooltip>
          </div>
          <div v-else></div>

          <div class="d-flex justify-end align-center">
            <v-btn @click="updateEmployee" small dark color="green">save</v-btn>
            <v-btn class="ml-1" @click="goBack()" small dark color="grey"
              >close</v-btn
            >
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import axios from "axios";
import ConfirmDelete from "../components/ConfirmDelete.vue";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import { nanoid } from "nanoid";

export default {
  name: "employeeUpdate",
  components: { ConfirmDelete, AutoLogout, Navbar },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      isMobile: false,
      statusOptions: ["Active", "Inactive"],
      showVaccineCalendar: false,
      confirmationDialog: false,
      confirmation: null,
      allowedFileTypes: ["image/jpg", "image/jpeg", "image/png"],
      showEmployeeIdImageSelect: false,
      showEmployeeIdImageDialog: false,
      showEmployeeIdCalendar: false,
      showEmployeeImageDialog: false,
      profileImageFile: null,
      profileImageFileRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          "File size should be less than 1 MB",
      ],
      employeeIdImageFile: null,
      employeeIdImageFileRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          "File size should be less than 1 MB",
      ],
      showWarningsDialog: false,
      search: "",
      warnings: [],
      warningsSearch: "",
      warningsHeaders: [
        {
          text: "Employee",
          align: "start",
          sortable: true,
          value: "employeeName",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      valid: true,
      employee: null,
      jobTitleRules: [(v) => !!v || "Job Title is required"],
      companyNameRules: [(v) => !!v || "Company Name is required"],
      employeeStatusRules: [(v) => !!v || "Employee Status is required"],
      employeeIdRules: [(v) => !!v || "Employee ID is required"],
      passport: {
        country: null,
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      },
      showPassportCalendar: false,
      seniorityNumberRules: [
        (v) => !!v || "Seniority number is required",
        (v) => (v && v >= 0) || "Seniority number should be above 0",
        (v) => (v && v <= 1000) || "Seniority numbershould not be above 1,000",
      ],
      gatewayCodeRules: [
        (v) => !!v || "Code is required",
        (v) => (v && v.length == 3) || "Enter a three letter Airport Code",
      ],
      showDateOfHireCalendar: false,
      cacCard: null,
      cacCardNumberRules: [
        (v) => !!v || "Number is required",
        (v) => (v && v.length == 4) || "Enter last 4 numbers",
      ],
      showCacCardCalendar: false,
      kcmBadge: null,
      kcmBadgeNumberRules: [
        (v) => !!v || "Number is required",
        (v) => (v && v.length == 4) || "Enter last 4 numbers",
      ],
      showKcmBadgeCalendar: false,
      showSidaBadgeCalendar: false,
      driversLicense: {
        state: null,
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      },
      showDriversLicenseCalendar: false,
      pelesysReferenceRules: [
        (v) => !!v || "Pelesys Login Name is required",
        (v) => (v && v.length >= 3) || "Minimum 3 characters",
        (v) => (v && v.length < 50) || "Maximum 50 characters",
      ],
      visa: {
        country: null,
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      },
      sidaBadge: {
        airportOfIssue: "",
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 1 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      },
      showVisaCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      minDate: new Date().toISOString().substring(0, 10),
      maxDate: new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10),
      trainingCertificates: [],
      filteredTrainingCertificates: [],
      showExpiredOnly: false,
      trainingCertificateHeaders: [
        {
          text: "Course Name",
          align: "start",
          sortable: true,
          value: "_id",
        },
        {
          text: "Course Code",
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: "Date Completed",
          align: "centert",
          sortable: true,
          value: "completedDate",
        },
        {
          text: "Expiration Date",
          align: "center",
          sortable: true,
          value: "expirationDate",
        },
        {
          text: "Grace Expiration Date",
          align: "center",
          sortable: true,
          value: "graceExpirationDate",
        },
        {
          text: "Certificate Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Expired",
          align: "center",
          sortable: false,
          value: "isExpired",
        },
      ],
      visaHeaders: [
        {
          text: "Country",
          align: "start",
          sortable: true,
          value: "country",
        },
        {
          text: "Number",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "Expiration Date",
          align: "start",
          sortable: true,
          value: "expirationDate",
        },
        {
          text: "Delete",
          align: "start",
          value: "_id",
        },
      ],
      passportHeaders: [
        {
          text: "Country",
          align: "start",
          sortable: true,
          value: "country",
        },
        {
          text: "Number",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "Expiration Date",
          align: "start",
          sortable: true,
          value: "expirationDate",
        },
        {
          text: "Delete",
          align: "start",
          value: "_id",
        },
      ],
      driversLicenseHeaders: [
        {
          text: "Number",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "State",
          align: "start",
          sortable: true,
          value: "state",
        },
        {
          text: "Expiration Date",
          align: "start",
          sortable: true,
          value: "expirationDate",
        },
        {
          text: "Delete",
          align: "start",
          value: "_id",
        },
      ],
      sidaBadgeHeaders: [
        {
          text: "Airport",
          align: "start",
          sortable: true,
          value: "airportOfIssue",
        },
        {
          text: "Number",
          align: "start",
          sortable: true,
          value: "number",
        },
        {
          text: "Expiration Date",
          align: "start",
          sortable: true,
          value: "expirationDate",
        },
        {
          text: "Delete",
          align: "start",
          value: "_id",
        },
      ],
      vaccineHeaders: [
        {
          text: "Proper Name",
          align: "start",
          sortable: true,
          value: "properName",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Date",
          align: "start",
          sortable: true,
          value: "date",
        },
        {
          text: "Delete / Edit",
          align: "start",
          value: "_id",
        },
      ],
      vaccineProperNames: [
        "Tetanus",
        "Diphtheria",
        "Pertussis",
        "Hepatitis A",
        "Hepatitis B",
        "Human Papillomavirus",
        "Measles",
        "Mumps",
        "Rubella",
        "Varicella",
        "Meningococcal ACWY",
        "Meningococcal B",
        "Influenza",
        "Pneumococcal conjugate",
        "Pneumococcal polysaccahride",
        "Zoster",
        "Hib",
        "Yellow Fever",
        "Covid-19",
      ],
      vaccineTypes: [],
      vaccine: {
        properName: null,
        type: null,
        date: new Date().toISOString().substring(0, 10),
      },
      activeDirectoryEmployee: null,
      subGatewayCodesString: "",
      securityGroups: [],
    };
  },
  created() {
    this.getEmployee();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    userCompanies() {
      let companies = [];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },

    employeeImageUrl() {
      const id = nanoid();
      return `${this.employeeProfileImagesBucketUrl}${this.employee.imageUrl}?${id}`;
    },

    employeeIdImageUrl() {
      const id = nanoid();
      return `${this.employeeProfileImagesBucketUrl}${this.employee.employeeIdImageUrl}?${id}`;
    },

    isValidVaccine() {
      return this.vaccine.properName && this.vaccine.type;
    },
  },

  methods: {
    isValidEmail(email) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.match(validRegex);
    },
    filterTrainingCertificates() {
      if (this.showExpiredOnly) {
        this.filteredTrainingCertificates = this.trainingCertificates.filter(
          (certificate) => {
            return certificate.expirationDate && certificate.isExpired;
          }
        );
      } else {
        this.filteredTrainingCertificates = [...this.trainingCertificates];
      }
    },
    formatCertificateDate(d) {
      if (d) {
        return new Date(d).toUTCString().substring(5, 16);
      } else {
        return "N/A";
      }
    },

    formatIsExpired(certificate) {
      if (certificate.expirationDate) {
        if (certificate.isExpired) {
          return "Yes";
        } else {
          return "No";
        }
      } else {
        return "No";
      }
    },

    formatIsExpiredColor(certificate) {
      if (certificate.expirationDate) {
        if (certificate.isExpired) {
          return "red";
        } else {
          return "green";
        }
      } else {
        return "green";
      }
    },

    handleShowWarningDialog() {
      if (this.warnings.length) {
        this.showWarningsDialog = true;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async getTrainingCertificates() {
      if (this.employee.pelesysLoginName === "login-name") {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please enter a valid Pelesys Private Login Name";
        return (this.snackbarVisible = true);
      }

      this.loading = true;
      this.trainingCertificates = [];

      let res;

      try {
        const token = await aad.getIdTokenSilent();
        res = await api.get(`/employees/training/certificates`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            pelesysLoginName: this.employee.pelesysLoginName,
            publicPelesysLoginName: this.employee.publicPelesysLoginName,
          },
        });

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.trainingCertificates = res.data.trainingCertificates;
        this.filterTrainingCertificates();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    getVaccineTypes() {
      switch (this.vaccine.properName) {
        case "Tetanus":
        case "Diphtheria":
        case "Pertussis":
          this.vaccineTypes = ["Tdap", "Td"];
          break;

        case "Hepatitis A":
          this.vaccineTypes = ["HepA", "HepA-HepB"];

          break;

        case "Hepatitis B":
          this.vaccineTypes = [
            "Engerix-B",
            "Recombi-vax HB",
            "Heplisav-B",
            "HepB",
            "HepA-HepB",
          ];

          break;

        case "Human Papillomavirus":
          this.vaccineTypes = ["HPV2", "HPV4", "HPV9"];

          break;

        case "Measles":
          this.vaccineTypes = ["MMR"];

          break;

        case "Mumps":
          this.vaccineTypes = ["MMR"];

          break;

        case "Rubella":
          this.vaccineTypes = ["MMR"];

          break;

        case "Varicella":
          this.vaccineTypes = ["chickenpox", "VAR"];

          break;

        case "Meningococcal ACWY":
          this.vaccineTypes = ["MeanACWY", "MPSV4"];

          break;

        case "Meningococcal B":
          this.vaccineTypes = ["MenB"];

          break;

        case "Influenza":
          this.vaccineTypes = [
            "IIV3",
            "IIV4",
            "ccIIV4",
            "RIV3",
            "RIV4",
            "LAIV4",
          ];

          break;

        case "Pneumococcal conjugate":
          this.vaccineTypes = ["PCV13"];

          break;

        case "Pneumococcal polysaccahride":
          this.vaccineTypes = ["PPSV23"];

          break;

        case "Zoster":
          this.vaccineTypes = ["shingles"];

          break;

        case "Hib":
          this.vaccineTypes = ["n/a"];

          break;

        case "Yellow Fever":
          this.vaccineTypes = ["YF-Vax"];

          break;

        case "Covid-19":
          this.vaccineTypes = ["Pfizer", "Moderna", "Johnson & Johnson"];

          break;

        default:
          this.vaccineTypes = ["None"];
          break;
      }

      this.vaccine.type = null;
    },

    showConfirmationDialog(action) {
      switch (action) {
        case "deleteEmployee":
          this.confirmation = {
            message: `Delete this employee's profile? This cannot be undone.`,
            action,
          };
          this.confirmationDialog = true;

          break;

        default:
          break;
      }
    },

    confirm() {
      switch (this.confirmation.action) {
        case "deleteEmployee":
          this.deleteEmployee();
          break;

        default:
          break;
      }
    },

    cancelConfirmation() {
      this.confirmation = null;
      this.confirmationDialog = false;
    },

    openEmployeeIdDialog() {
      this.showEmployeeIdImageDialog = true;
    },

    async saveEmployeeIdImage() {
      this.loading = true;
      this.employee.employeeIdmageUrl = "employee-id-default.png";

      try {
        if (this.employeeIdImageFile) {
          if (
            !this.allowedFileTypes.includes(this.employeeIdImageFile.type) ||
            this.employeeIdImageFile.size > 1000000
          ) {
            this.snackbarColor = "#F44336";
            this.snackbarText = "Please check image requirements";
            return (this.snackbarVisible = true);
          }

          const token = await aad.getIdTokenSilent();

          const res = await api.post(
            `/employees/images/documents/employeeid/${this.employee._id}`,
            {
              contentType: this.employeeIdImageFile.type,
            },
            {
              headers: {
                Authorization: "Bearer " + token.idToken.rawIdToken,
              },
            }
          );

          if (res.data.status !== "OK") {
            this.snackbarColor = "#F44336";
            this.snackbarText = res.data.message;
            this.snackbarVisible = true;
            return;
          }

          const s3url = res.data.url;
          const imageUrl = res.data.key;

          const s3Response = await axios.put(s3url, this.employeeIdImageFile, {
            headers: {
              "Content-Type": this.employeeIdImageFile.type,
            },
          });

          this.loading = false;

          if (s3Response.status === 200) {
            this.employee.employeeIdImageUrl = imageUrl;
            this.showEmployeeIdImageSelect = false;
          } else {
            this.snackbarColor = "#F44336";
            this.snackbarText = s3Response.statusText;
            this.snackbarVisible = true;
          }
        } else {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = "Please select an image";
          this.snackbarVisible = true;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    openEmployeeImageDialog() {
      this.showEmployeeImageDialog = true;
    },

    async saveEmployeeImage() {
      this.loading = true;
      this.employee.imageUrl = "profile-default.png";

      try {
        if (this.profileImageFile) {
          if (
            !this.allowedFileTypes.includes(this.profileImageFile.type) ||
            this.profileImageFile.size > 1000000
          ) {
            this.snackbarColor = "#F44336";
            this.snackbarText = "Please check image requirements";
            return (this.snackbarVisible = true);
          }

          const token = await aad.getIdTokenSilent();

          const res = await api.post(
            `/employees/images/profile/${this.employee._id}`,
            {
              contentType: this.profileImageFile.type,
            },
            {
              headers: {
                Authorization: "Bearer " + token.idToken.rawIdToken,
              },
            }
          );

          if (res.data.status !== "OK") {
            this.snackbarColor = "#F44336";
            this.snackbarText = res.data.message;
            this.snackbarVisible = true;
            return;
          }

          const s3url = res.data.url;
          const imageUrl = res.data.key;

          const s3Response = await axios.put(s3url, this.profileImageFile, {
            headers: {
              "Content-Type": this.profileImageFile.type,
            },
          });

          this.loading = false;

          if (s3Response.status === 200) {
            this.employee.imageUrl = imageUrl;
            this.showEmployeeImageDialog = false;
          } else {
            this.snackbarColor = "#F44336";
            this.snackbarText = s3Response.statusText;
            this.snackbarVisible = true;
          }
        } else {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = "Please select an image";
          this.snackbarVisible = true;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async getEmployee() {
      this.loading = true;

      try {
        let res;

        const token = await aad.getIdTokenSilent();

        res = await api.get("/security-groups", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.securityGroups = res.data.securityGroups;

        const id = this.$router.currentRoute.params.id;

        res = await api.get(`/employees/${id}`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status === "OK") {
          this.loading = false;

          const employee = res.data.employee;

          if (employee.subGatewayCodes.length) {
            this.subGatewayCodesString = employee.subGatewayCodes.toString();
          }

          employee.employeeIdExpirationDate = new Date(
            employee.employeeIdExpirationDate
          )
            .toISOString()
            .substring(0, 10);

          if (employee.cacCard) {
            employee.cacCard.expirationDate = new Date(
              employee.cacCard.expirationDate
            )
              .toISOString()
              .substring(0, 10);
          }

          if (employee.kcmBadge) {
            employee.kcmBadge.expirationDate = new Date(
              employee.kcmBadge.expirationDate
            )
              .toISOString()
              .substring(0, 10);
          }

          if (!employee.emergencyContact) {
            employee.emergencyContact = {
              name: "",
              number: "",
            };
          }

          employee.dateOfHire = new Date(employee.dateOfHire)
            .toISOString()
            .substring(0, 10);

          this.warnings = res.data.warnings;
          this.showWarningsDialog = this.warnings.length;

          this.employee = employee;
          this.trainingCertificates = res.data.trainingCertificates;
          this.filterTrainingCertificates();
        } else {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          setTimeout(() => {
            this.$router.go(-1);
          }, 2000);
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      }
    },

    async getUserFromActiveDirectory() {
      this.loading = true;

      try {
        const token = await aad.getAccessTokenSilent();

        const res = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${this.employee.userPrincipalName}`,
          {
            headers: {
              Authorization: "Bearer " + token.accessToken,
            },
          }
        );

        this.loading = false;

        this.activeDirectoryEmployee = {
          id: res.data.id,
          userPrincipalName: res.data.userPrincipalName,
        };

        this.employee.azureId = res.data.id;
        this.employee.userPrincipalName = res.data.userPrincipalName;
        this.employee.displayName = res.data.displayName;
        this.employee.givenName = res.data.givenName;
        this.employee.surname = res.data.surname;
        this.employee.jobTitle = this.user.securityGroup.jobTitles.includes(
          res.data.jobTitle
        )
          ? res.data.jobTitle
          : null;
        this.employee.mail = res.data.mail;
        this.employee.mobilePhone = res.data.mobilePhone;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async updateEmployee() {
      this.$refs.form.validate();

      if (!this.valid) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please correct highlighted values";
        return (this.snackbarVisible = true);
      }

      if (
        this.employee.secondaryMail &&
        !this.isValidEmail(this.employee.secondaryMail)
      ) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please enter a valid secondary email";
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        this.employee.employeeId = this.employee.employeeId.toUpperCase();
        this.employee.gatewayCode = this.employee.gatewayCode.toUpperCase();

        if (this.subGatewayCodesString.length) {
          //Sub-Gateway Codes
          const codes = this.subGatewayCodesString.split(",");
          let cleanCode;
          let cleanCodes = [];

          codes.forEach((item) => {
            cleanCode = item.trim().toUpperCase();

            if (
              cleanCode !== this.employee.gatewayCode &&
              cleanCode.length === 3
            ) {
              cleanCodes.push(cleanCode);
            }
          });

          const subGatewayCodes = [...new Set(cleanCodes)];

          if (subGatewayCodes.length > 10) {
            this.loading = false;
            this.snackbarColor = "#F44336";
            this.snackbarText = "The maximum number of Sub-Gateways is 10";
            this.snackbarVisible = true;
            return;
          }

          this.employee.subGatewayCodes = subGatewayCodes;
          this.subGatewayCodesString = subGatewayCodes.toString();
        } else {
          this.employee.subGatewayCodes = [];
        }

        //KCM Badge
        if (this.employee.kcmBadge) {
          const kcmBadge = { ...this.employee.kcmBadge };

          this.employee.kcmBadge = {
            number: kcmBadge.number.slice(),
            expirationDate: `${kcmBadge.expirationDate.substring(0, 10)}`,
          };
        }

        //Vaccines
        if (this.employee.vaccines.length) {
          const oldVaccines = [...this.employee.vaccines];

          this.employee.vaccines = [];

          oldVaccines.forEach((vaccine) => {
            this.employee.vaccines.push({
              properName: vaccine.properName.slice(),
              type: vaccine.type.slice(),
              date: `${vaccine.date.substring(0, 10)}`,
            });
          });
        }

        const res = await api.put(
          `/employees/${this.employee._id}`,
          this.employee,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = res.data.message;
        this.snackbarVisible = true;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async deleteEmployee() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(`/employees/${this.employee._id}`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.confirmation = null;
        this.confirmationDialog = false;
      }
    },

    handleAcknowledgeWarnings() {
      this.showWarningsDialog = false;
    },

    navigateToFlightBoard() {
      this.$router.push({ name: "flightBoard" });
    },

    navigateToAdministration() {
      this.$router.push({ name: "administration" });
    },

    //Passports
    addPassport() {
      if (this.passport.number.length > 4) {
        this.passport.number = this.passport.number.substring(0, 4);
      }

      if (this.employee.passports.length > 0) {
        const i = this.employee.passports.findIndex((passport) => {
          return passport.country === this.passport.country;
        });

        if (i >= 0) {
          this.employee.passports.splice(i, 1, { ...this.passport });
        } else {
          this.employee.passports.push({ ...this.passport });
        }
      } else {
        this.employee.passports.push({ ...this.passport });
      }

      this.passport = {
        country: null,
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      };
    },

    deletePassport(passport) {
      const i = this.employee.passports.findIndex((v) => {
        return v.country === passport.country;
      });
      this.employee.passports.splice(i, 1);
    },

    formatPassports(employeePassports) {
      let passports = [];

      employeePassports.forEach((passport) => {
        passports.push(
          `${passport.country} (${this.formatDate(passport.expirationDate)})`
        );
      });

      return passports.join(", ");
    },

    //SIDA Badge
    formatSidaBadges(employeeSidaBadges) {
      let badges = [];

      employeeSidaBadges.forEach((badge) => {
        badges.push(
          `${badge.airportOfIssue} (${this.formatDate(badge.expirationDate)})`
        );
      });

      return badges.join(", ");
    },

    addSidaBadge() {
      this.sidaBadge.airportOfIssue = this.sidaBadge.airportOfIssue.toUpperCase();

      if (this.employee.sidaBadges.length > 0) {
        const i = this.employee.sidaBadges.findIndex((sidaBadge) => {
          return sidaBadge.airportOfIssue === this.sidaBadge.airportOfIssue;
        });

        if (i >= 0) {
          this.employee.sidaBadges.splice(i, 1, { ...this.sidaBadge });
        } else {
          this.employee.sidaBadges.push({ ...this.sidaBadge });
        }
      } else {
        this.employee.sidaBadges.push({ ...this.sidaBadge });
      }

      this.sidaBadge = {
        airportOfIssue: "",
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      };
    },

    deleteSidaBadge(sidaBadge) {
      const i = this.employee.sidaBadges.findIndex((v) => {
        return v.airportOfIssue === sidaBadge.airportOfIssue;
      });
      this.employee.sidaBadges.splice(i, 1);
    },

    //CAC Card
    addCacCard() {
      const inFiveYears = new Date(
        new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
      );

      this.employee = {
        ...this.employee,
        cacCard: {
          number: "",
          expirationDate: inFiveYears.toISOString().substring(0, 10),
        },
      };
    },

    deleteCacCard() {
      this.employee.cacCard = null;
    },

    //KCM Badge
    addKcmBadge() {
      const inFiveYears = new Date(
        new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
      );

      this.employee = {
        ...this.employee,
        kcmBadge: {
          number: "",
          expirationDate: inFiveYears.toISOString().substring(0, 10),
        },
      };
    },

    deleteKcmBadge() {
      this.employee.kcmBadge = null;
    },

    //DriversLicenses
    addDriversLicense() {
      if (this.driversLicense.number.length > 4) {
        this.driversLicense.number = this.driversLicense.number.substring(0, 4);
      }

      if (this.employee.driversLicenses.length > 0) {
        const i = this.employee.driversLicenses.findIndex((driversLicense) => {
          return driversLicense.state === this.driversLicense.state;
        });

        if (i >= 0) {
          this.employee.driversLicenses.splice(i, 1, {
            ...this.driversLicense,
          });
        } else {
          this.employee.driversLicenses.push({ ...this.driversLicense });
        }
      } else {
        this.employee.driversLicenses.push({ ...this.driversLicense });
      }

      this.driversLicense = {
        state: null,
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      };
    },

    deleteDriversLicense(driversLicense) {
      const i = this.employee.driversLicenses.findIndex((v) => {
        return v.state === driversLicense.state;
      });
      this.employee.driversLicenses.splice(i, 1);
    },

    formatDriversLicenses(employeeDriversLicenses) {
      let driversLicenses = [];

      employeeDriversLicenses.forEach((driversLicense) => {
        driversLicenses.push(
          `${driversLicense.state} (${this.formatDate(
            driversLicense.expirationDate
          )})`
        );
      });

      return driversLicenses.join(", ");
    },

    //Visas
    formatVisas(employeeVisas) {
      let visas = [];

      employeeVisas.forEach((visa) => {
        visas.push(`${visa.country} (${this.formatDate(visa.expirationDate)})`);
      });

      return visas.join(", ");
    },

    addVisa() {
      if (this.employee.visas.length > 0) {
        const i = this.employee.visas.findIndex((visa) => {
          return visa.country === this.visa.country;
        });

        if (i >= 0) {
          this.employee.visas.splice(i, 1, { ...this.visa });
        } else {
          this.employee.visas.push({ ...this.visa });
        }
      } else {
        this.employee.visas.push({ ...this.visa });
      }

      this.visa = {
        country: null,
        number: "",
        expirationDate: new Date(
          new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .substring(0, 10),
      };
    },

    deleteVisa(visa) {
      const i = this.employee.visas.findIndex((v) => {
        return v.country === visa.country;
      });
      this.employee.visas.splice(i, 1);
    },

    //Vaccines

    formatVaccines(employeeVaccines) {
      let vaccines = [];

      employeeVaccines.forEach((vaccine) => {
        vaccines.push(`${vaccine.properName}`);
      });

      return vaccines.join(", ");
    },

    addVaccine() {
      this.vaccine._id = nanoid();
      this.employee.vaccines.push({ ...this.vaccine });

      this.employee.vaccines.sort(this.compareVaccines);

      this.vaccine = {
        properName: null,
        type: "",
        date: new Date().toISOString().substring(0, 10),
      };
    },

    deleteVaccine(vaccine) {
      const i = this.employee.vaccines.findIndex((v) => {
        return v._id === vaccine._id;
      });
      this.employee.vaccines.splice(i, 1);

      this.vaccine = {
        properName: null,
        type: null,
        date: new Date().toISOString().substring(0, 10),
      };
    },

    editVaccine(vaccine) {
      if (this.vaccine.type) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please enter current vaccine";
        return (this.snackbarVisible = true);
      }

      this.vaccine = {
        properName: vaccine.properName.slice(),
        date: new Date(vaccine.date).toISOString().substring(0, 10),
      };

      this.getVaccineTypes();

      this.vaccine.type = vaccine.type.slice();

      const i = this.employee.vaccines.findIndex((v) => {
        return v._id === vaccine._id;
      });
      this.employee.vaccines.splice(i, 1);
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.employee-image {
  cursor: pointer;
  border: 1px solid #bebebe;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.employee-id-image {
  cursor: pointer;
  border: 1px solid #bebebe;
  margin: auto;
  width: 190px;
  height: 300px;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}

.remove-icon:hover {
  color: red;
}

.edit-icon:hover {
  color: green;
}

.r-container {
  background-color: #bebebe;
}

.r-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bebebe;
  border-radius: 3px;
  padding: 3px 5px;
  margin-bottom: 2px;
  background-color: white;
}
</style>
