<template>
  <div class="r-dialog-red">
    <div class="d-flex justify-space-between">
      <div class="font-abel-20px">Are you sure?</div>
      <v-btn @click="cancel" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <v-divider> </v-divider>
    <p class="my-2 font-abel-14px-bold">{{ message }}</p>

    <div class="d-flex justify-end">
      <v-btn @click="confirm" small dark color="red">confirm</v-btn>
      <v-btn @click="cancel" class="ml-1" small dark color="grey">cancel</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmaDelete",
  props: ["message"],
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
