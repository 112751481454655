<template>
  <div class="home">
    <v-container fluid>
      <div class="horizontal-spacer"></div>

      <Navbar />

      <AutoLogout v-if="user" @logout="logout"></AutoLogout>

      <div class="uat-banner rotate" v-if="environment !== 'PROD'">
        <h1>{{ `${environment} ENVIRONMENT - TESTING ONLY` }}</h1>
      </div>

      <v-row dense align="end" class="mb-3">
        <v-col cols="1"> </v-col>

        <v-col cols="2">
          <div class="d-flex justify-start">
            <img
              v-if="!isMobile"
              src="https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_logo.png"
              alt="monarch logo"
              height="40"
            />
            <img
              v-else
              src="https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_logo.png"
              alt="monarch logo"
              height="20"
            />
          </div>
        </v-col>

        <v-col cols="6"> </v-col>

        <v-col cols="2" justify="end">
          <div class="d-flex justify-end">
            <img
              v-if="!isMobile"
              src="https://s3.amazonaws.com/monarch.atlasair.com.resources/atlas_logo_giant.png"
              alt="atlas logo"
              height="40"
            />
            <img
              v-else
              src="https://s3.amazonaws.com/monarch.atlasair.com.resources/atlas_logo_giant.png"
              alt="atlas logo"
              height="20"
            />
          </div>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>

      <v-row dense justify="space-around">
        <v-col cols="10">
          <v-img
            :max-height="600"
            :aspect-ratio="16 / 9"
            src="https://s3.amazonaws.com/monarch.atlasair.com.resources/landing_page.jpg"
            alt="B747-8"
          >
            <div
              v-if="!isMobile"
              class="font-abel-24px white--text text-center  mt-7"
            >
              Welcome to Atlas Air’s Ground Ops Scheduling
            </div>
            <div v-else class="font-abel-14px white--text text-center  mt-7">
              Welcome to Atlas Air’s Ground Ops Scheduling
            </div>
          </v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1"> </v-col>

        <v-col cols="10">
          <p v-if="!isMobile" class="font-abel-12px text-center">
            {{ disclaimer }}
          </p>
          <p v-else class="font-abel-10px text-center">{{ disclaimer }}</p>
        </v-col>

        <v-col cols="1"> </v-col>
      </v-row>

      <v-footer fixed>
        <v-col class="text-center font-abel-12px" cols="12">
          <span>©</span> {{ new Date().getFullYear() }} —
          <span
            >Atlas Air Worldwide Holdings, Inc. All Rights Reserved.
            Version-v</span
          >
          {{ `${version}` }}
          <v-btn
            v-if="!user"
            @click="getLoginManual"
            small
            text
            color="indigo"
            class="ml-2 text-capitalize"
            >Login Help</v-btn
          >
        </v-col>
      </v-footer>
    </v-container>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import { PDFDocument } from "pdf-lib";

export default {
  name: "home",
  components: { AutoLogout, Navbar },
  mixins: [mixin],
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      isMobile: false,
      disclaimer: `Flight schedule information and other related station and aircraft details are made available for the convenience of Atlas Air and Vendor Staff. Atlas Air cannot and does not guarantee that all information is current, complete or totally accurate at any given time. While we strive to provide complete, accurate and near real-time flight and schedule information, reliance on this software and the flight details herein is solely at the users own risk and Atlas Air disclaims any warranty or indemnity involved with the use of the software, whether express or implied.`,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async getLoginManual() {
      const url =
        "https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_login_help.pdf";
      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      pdfDoc.setTitle("Monarch Login Help");

      const pdfUrl = URL.createObjectURL(
        new Blob([await pdfDoc.save()], { type: "application/pdf" })
      );
      window.open(pdfUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.home {
  height: auto;
  background-color: #fff;
  margin-bottom: 60px;
}

.uat-banner {
  display: flex;
  align-items: center;
  z-index: 200;
  opacity: 0.4;
  position: fixed;
  top: 50px;
  display: flex;
  width: 100vw;
  height: calc(100vh - 150px);
  color: #000;
  margin: auto;
}
.rotate {
  transform: rotate(-45deg);
}
h1 {
  text-align: center;
  width: 100%;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
}
</style>
