<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: "App",
};
</script>

<style>
.confirmation-dialog {
  border-style: solid;
  border-color: #ff0000;
  border-width: 2px 2px 2px 10px;
  border-radius: 10x;
  background-color: #ffffff;
  padding: 10px;
}

.flight-information-dialog {
  border-style: solid;
  border-color: #2196f3;
  border-width: 3px 3px 3px 10px;
  border-radius: 10x;
  background-color: #ffffff;
  padding: 10px;
}

.ground-information-dialog {
  border-style: solid;
  border-color: var(--brown);
  border-width: 3px 3px 3px 10px;
  border-radius: 10x;
  background-color: #ffffff;
  padding: 10px;
}

.new-ground-assignment-dialog {
  border-style: solid;
  border-color: var(--brown);
  border-width: 3px 3px 3px 10px;
  border-radius: 10x;
  background-color: #ffffff;
  padding: 10px;
}

.remarks-dialog {
  border-style: solid;
  border-color: #66bb6a;
  border-width: 2px 2px 2px 10px;
  border-radius: 10x;
  background-color: #ffffff;
  padding: 10px;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.r-frame-vertical-scroll {
  border: 1px solid #bebebe;
  padding-left: 5px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.r-frame-rounded-grey {
  border: 1px solid #bebebe;
  border-radius: 3px;
  background-color: #bdc0c0;
  margin: 10px;
  padding: 5px 15px;
}

.r-frame-rounded-yellow {
  border-width: 1px 1px 1px 6px;
  border-style: solid;
  border-color: red;
  border-radius: 3px;
  background-color: #fff;
  margin: 10px;
  padding: 5px 15px;
}

/* -----------------------Scrolling Board------------------ */
.top-frame {
  position: fixed;
  display: flex;
  top: 88px;
  width: 100%;
  min-height: 41px;
  border: 1px solid #000;
  background-color: #ebf0f3;
  z-index: 100;
}

.top-panel-left {
  display: flex;
  position: static;
  align-items: center;
  padding: 0 1px 0 0;
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-width: 101px;
  max-width: 101px;
  user-select: none;
  -webkit-user-select: none;
}

.top-panel-right {
  position: static;
  display: flex;
  overflow: hidden;
}

.top-panel-right-inner {
  display: flex;
  position: relative;
  left: 100px;
  height: 39px;
}

.current-time-box {
  position: absolute;
  top: 10px;
  height: 29px;
  border: 1px solid red;
  background-color: white;
}

.top-panel-day {
  position: static;
  text-align: center;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;
  -webkit-user-select: none;
}

.bottom-frame {
  position: relative;
  display: flex;
  top: 128px;
  width: 100%;
  border: 1px solid #bebebe;
  background-color: white;
}

.bottom-frame-no-toolbar {
  position: relative;
  top: 48px;
  width: 100%;
  border: 1px solid #bebebe;
  background-color: white;
}

.bottom-panel-left {
  border-right: 1px solid black;
  background-color: #ebf0f3;
}

.bottom-panel-left-row {
  padding: 3px 0 3px 0;
  height: 60px;
  border-bottom: 1px solid black;
  width: 100px;
  user-select: none;
  -webkit-user-select: none;
}

.bottom-panel-left-row-section {
  margin: 0 3px 0 3px;
}

.bottom-panel-left-row-section:hover {
  color: blue;
}

.bottom-panel-right {
  background-color: white;
  overflow-x: scroll;
}

.bottom-panel-right-inner {
  position: relative;
  height: 39px;
  width: 1200px;
  overflow: hidden;
}

.bottom-panel-right-row {
  position: static;
  height: 60px;
  border-bottom: 1px solid black;
}

.bottom-panel-day {
  position: absolute;
  display: flex;
  height: 59px;
  border-right: 1px solid black;
}

.hour-line {
  border-right: 1px solid #f3efef;
}

.current-time {
  position: absolute;
  display: flex;
  width: 1px;
  height: 59px;
  border-right: 1px solid red;
}

.flight-label-frame {
  position: absolute;
  height: 60px;
}

/* .bottom-frame {
  position: relative;
  top: 88px;
  width: 100%;
  border: 1px solid #bebebe;
  background-color: white;
} */

/* END--------------Scrolling Board--------------------- */

#loading {
  display: flex;
  min-height: 120px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.navigation-drawer {
  z-index: 500;
}

.r-toolbar {
  position: fixed;
  top: 48px;
  display: flex;
  min-height: 40px;
  width: 100%;
  background-color: grey;
  color: white;
  padding: 0 5px;
  align-items: center;
  z-index: 100;
  user-select: none;
  -webkit-user-select: none;
}

.horizontal-spacer {
  min-height: 100px;
}

.max-width-50px {
  max-width: 50px;
}

.max-width-100px {
  max-width: 100px;
}

.max-width-150px {
  max-width: 150px;
}

.max-width-200px {
  max-width: 200px;
}

.max-width-300px {
  max-width: 300px;
}

.max-width-400px {
  max-width: 400px;
}

/* .color-atlas-blue{
    color: #002663;
  }

  .color-atlas-yellow {
    color : '#ffb608'
  } */

/* .r-dialog-yellow {
  border: 1px solid white;
  border-radius: 5px;
  min-height: 150px;
  padding: 10px;
  color: black;
  opacity: 1;
  background-color: #e9e78a;
  user-select: none;
  -webkit-user-select: none;
} */

.r-dialog-green-lighten3 {
  border: 1px solid white;
  border-radius: 5px;
  min-height: 150px;
  padding: 10px;
  color: black;
  background-color: #ebf8d2;
  user-select: none;
  -webkit-user-select: none;
}

.r-dialog-grey {
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  color: black;
  background-color: #e0e0e0;
  user-select: none;
  -webkit-user-select: none;
}

.r-dialog {
  color: black;
  user-select: none;
  -webkit-user-select: none;
}

.r-dialog-red {
  border: 1px solid white;
  border-radius: 5px;
  min-height: 150px;
  padding: 10px;
  color: white;
  background-color: #f7715a;
  user-select: none;
  -webkit-user-select: none;
}

.r-dialog-amber {
  border: 1px solid white;
  border-radius: 5px;
  min-height: 150px;
  padding: 10px;
  color: white;
  background-color: #f58742;
  user-select: none;
  -webkit-user-select: none;
}

.r-frame-yellow-lighten3 {
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
  background-color: #f6f7d3;
}

.user-select-text {
  user-select: text;
  -webkit-user-select: text;
}

.cursor-hand {
  cursor: pointer;
}

.font-abel-10px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 10px;
}

.font-abel-10px-bold {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 700;
}

.font-abel-12px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.font-abel-12px-bold {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.font-abel-14px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.font-abel-16px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.font-abel-16px-bold {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.font-abel-20px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 20px;
}

.font-abel-20px-bold {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.font-abel-24px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.font-abel-24px-bold {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.font-abel-30px {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 30px;
}

.font-abel-30px-bold {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
}

.fa-calendar-days {
  font-size: 1.3rem;
  color: #fff;
}
.fa-triangle-exclamation {
  font-size: 1.3rem;
  color: #bebebe;
}

.fa-filter {
  font-size: 1rem;
  color: #bebebe;
}

.fa-magnifying-glass-minus {
  font-size: 1.3rem;
  color: #fff;
}

.fa-magnifying-glass-plus {
  font-size: 1.3rem;
  color: #1d8de9;
}

.fa-rotate {
  font-size: 1.3rem;
  color: #66bb6a;
}

.fa-print {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-plus {
  font-size: 1.5rem;
  color: #1d8de9;
}

.fa-user-plus {
  font-size: 1.3rem;
  color: #1d8de9;
}

.fa-xmark {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-xmark:hover {
  color: var(--red);
}

.fa-pen {
  font-size: 1rem;
  color: #8e8e8e;
}

.fa-pen:hover {
  color: #66bb6a;
}

.fa-envelope {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-envelope:hover {
  color: #1d8de9;
}

.fa-trash-can {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-trash-check {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-trash-can:hover {
  color: red;
}

.fa-user-slash {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-user-slash:hover {
  color: red;
}

.fa-circle-exclamation {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-circle-exclamation:hover {
  color: orange;
}

.fa-thumbs-up {
  font-size: 1.5rem;
  color: #66bb6a;
}

.fa-diamond-exclamation {
  font-size: 1.5rem;
  color: red;
}

.fa-person-walking-luggage {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-person-walking-luggage:hover {
  color: #1d8de9;
}

.fa-arrow-left {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-arrow-left:hover {
  color: #1d8de9;
}

.fa-arrow-right {
  font-size: 1.3rem;
  color: #8e8e8e;
}

.fa-arrow-right:hover {
  color: #1d8de9;
}

.warning-state {
  color: orange;
}
</style>
