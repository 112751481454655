<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- AIRPORT INFORMATION DIALOG -->
    <v-dialog v-model="airportDialog" max-width="450px">
      <div v-if="airport" class="blue-frame font-abel-16px">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-30px">
            {{ `${airport.iata} - ${airport.icao}` }}
          </div>
          <v-btn @click="airportDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>
        <v-divider></v-divider>
        <div class="my-2">
          <div>Airport Name: {{ `  ${airport.name}` }}</div>
          <div>Country: {{ ` ${airport.country}` }}</div>
        </div>
        <v-data-table
          class="my-2"
          dense
          :headers="periodsHeaders"
          :items="airportPeriods"
          v-model="selectedPeriods"
          show-select
          item-key="_id"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100, 200, -1],
          }"
        >
        </v-data-table>
        <div class="d-flex justify-end align-center">
          <v-btn
            :disabled="!selectedPeriods.length"
            @click="deletePeriods"
            small
            dark
            color="red"
            >delete</v-btn
          >
          <v-btn
            class="mx-1"
            @click="airportDialog = false"
            small
            dark
            color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- EDIT DIALOG-->
    <v-dialog v-model="editDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="mx-3 mb-2 font-abel-20px text-center">
          Set COVID-19 / Hazard Status
        </div>

        <v-container fluid>
          <!-- COVID-19 -->
          <v-row align="center">
            <v-col cols="12" sm="2">
              <v-btn class="mx-2" fab dark small color="red">
                <v-icon dark>health_and_safety</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="5">
              <v-switch v-model="isCovid19" label="COVID-19" color="#FF0000">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="5">
              <v-switch
                v-if="isCovid19"
                v-model="isCovid19OpenEnded"
                label="Open-Ended"
                color="#FF0000"
              >
              </v-switch>
            </v-col>
          </v-row>

          <v-row v-if="isCovid19" align="center">
            <v-col cols="12" sm="6">
              <v-menu
                v-model="showCovid19StartCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedCovid19StartDate"
                    label="COVID-19 Start"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedCovid19StartDate"
                  no-title
                  @input="showCovid19StartCalendar = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <div v-if="!isCovid19OpenEnded">
                <v-menu
                  v-model="showCovid19EndCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="selectedCovid19EndDate"
                      label="COVID-19 End"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedCovid19EndDate"
                    @input="showCovid19EndCalendar = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-else>
                ACTIVE (Open-Ended)
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <!-- HAZARD -->
          <v-row align="center">
            <v-col cols="12" sm="2">
              <v-btn class="mx-2" fab dark small color="red">
                <v-icon dark>warning</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="5">
              <v-switch v-model="isHazard" label="HAZARDOUS" color="#FF0000">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="5">
              <v-switch
                v-if="isHazard"
                v-model="isHazardOpenEnded"
                label="Open-Ended"
                color="#FF0000"
              >
              </v-switch>
            </v-col>
          </v-row>

          <v-row v-if="isHazard" align="center">
            <v-col cols="12" sm="6">
              <v-menu
                v-model="showHazardStartCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedHazardStartDate"
                    label="Hazard Start"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedHazardStartDate"
                  no-title
                  @input="showHazardStartCalendar = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <div v-if="!isHazardOpenEnded">
                <v-menu
                  v-model="showHazardEndCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="selectedHazardEndDate"
                      label="Hazard End"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedHazardEndDate"
                    @input="showHazardEndCalendar = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-else>
                ACTIVE (Open-Ended)
              </div>
            </v-col>
          </v-row>
        </v-container>

        <div class="d-flex justify-end align-center">
          <v-btn @click="save" small dark color="green">save</v-btn>
          <v-btn
            class="mx-1"
            @click="editDialog = false"
            small
            dark
            color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <div class="frame">
      <div class="filter">
        <v-select
          @change="filterAirports"
          v-model="country"
          :items="companyCountries"
          label="Country"
        >
        </v-select>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>

        <div class="d-flex justify-center px-10">
          <!-- Start Date -->
          <v-menu
            v-model="showStartCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="targetDate"
                label="Check Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="targetDate"
              no-title
              @input="changeCheckDate"
            ></v-date-picker>
          </v-menu>
        </div>

        <v-checkbox
          @change="filterAirports"
          v-model="showOnlyIfActiveCovid"
          label="Active COVID-19"
        ></v-checkbox>

        <v-checkbox
          class="ml-5"
          @change="filterAirports"
          v-model="showOnlyIfActiveHazard"
          label="Active HAZARD"
        ></v-checkbox>
      </div>

      <v-divider class="mb-3"></v-divider>

      <v-data-table
        dense
        :headers="airportsHeaders"
        :items="filteredAirports"
        v-model="selectedAirports"
        :search="search"
        show-select
        item-key="_id"
        @click:row="showAirportDetail"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, 200, -1],
        }"
      >
        <template v-slot:[`item.covid19Periods`]="{ item }">
          <span>{{ formatCovid19Status(item) }}</span>
        </template>

        <template v-slot:[`item.hazardPeriods`]="{ item }">
          <span>{{ formatHazardStatus(item) }}</span>
        </template>
      </v-data-table>

      <div v-if="selectedAirports.length" class="actions-filter">
        <v-select
          v-model="selectedAction"
          :items="actionOptions"
          label="Apply to Selected Airports"
        >
        </v-select>

        <div class="d-flex justify-center px-10" v-if="showActionCalendar">
          <!-- Start Date -->
          <v-menu
            v-model="showFilterCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filterDate"
                label="End Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filterDate"
              no-title
              @input="showFilterCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <v-btn @click="handleSelectedAction" small dark color="blue"
          >enter</v-btn
        >
      </div>

      <v-divider class="my-3"></v-divider>

      <div class="d-flex justify-end align-center my-2 font-abel-16px">
        <v-btn class="mx-1" @click="goHome()" small dark color="grey"
          >close</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";

export default {
  name: "specialAiports",
  components: { AutoLogout, Navbar },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      actionOptions: [
        "Add COVID-19 / Hazard periods",
        "End COVID-19 periods on",
        "End HAZARD periods on",
        "Delete COVID-19 periods",
        "Delete HAZARD periods",
      ],
      selectedAction: "Add COVID-19 / Hazard periods",
      isMobile: false,
      airport: null,
      airportPeriods: [],
      airportDialog: false,
      selectedPeriods: [],
      editDialog: false,
      showOnlyIfActiveCovid: false,
      showOnlyIfActiveHazard: false,
      showCovid19StartCalendar: false,
      showCovid19EndCalendar: false,
      selectedCovid19StartDate: new Date().toISOString().substring(0, 10),
      selectedCovid19EndDate: new Date(
        new Date().getTime() + 365 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),
      showHazardStartCalendar: false,
      showHazardEndCalendar: false,
      selectedHazardStartDate: new Date().toISOString().substring(0, 10),
      selectedHazardEndDate: new Date(
        new Date().getTime() + 365 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10),

      selectedAirports: [],
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      companyCountries: [],
      filteredAirports: [],
      country: "",
      search: "",
      airportsHeaders: [
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "iata",
        },
        {
          text: "ICAO Code",
          align: "start",
          sortable: true,
          value: "icao",
        },
        {
          text: "Country",
          align: "start",
          sortable: true,
          value: "country",
        },
        {
          text: "COVID-19",
          align: "start",
          sortable: false,
          value: "covid19Periods",
        },
        {
          text: "Hazard",
          align: "start",
          sortable: false,
          value: "hazardPeriods",
        },
      ],
      periodsHeaders: [
        {
          text: "Category",
          align: "start",
          sortable: true,
          value: "category",
        },
        {
          text: "Period Start",
          align: "start",
          sortable: true,
          value: "start",
        },
        {
          text: "Period End",
          align: "start",
          sortable: true,
          value: "end",
        },
      ],
      isCovid19: false,
      isCovid19OpenEnded: false,
      isHazard: false,
      isHazardOpenEnded: false,
      targetDate: new Date().toISOString().substring(0, 10),
      filterDate: new Date().toISOString().substring(0, 10),
      showStartCalendar: false,
      showFilterCalendar: false,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });

    this.$nextTick(function() {
      let countries = [];
      this.targetDate = new Date().toISOString().substring(0, 10);

      this.companyAirports.forEach((airport) => {
        if (!countries.includes(airport.country)) {
          countries.push(airport.country);
        }
      });

      this.companyCountries = countries.sort(this.compareNames);
      this.companyCountries.unshift("All Countries");
      this.country = this.companyCountries[0];
      this.filterAirports();
    });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    companyAirports() {
      return this.$store.getters.companyAirports;
    },

    showActionCalendar() {
      return (
        this.selectedAction === "End COVID-19 periods on" ||
        this.selectedAction === "End HAZARD periods on"
      );
    },
  },

  methods: {
    handleSelectedAction() {
      switch (this.selectedAction) {
        case "Add COVID-19 / Hazard periods":
          this.editDialog = true;
          break;

        case "Delete COVID-19 periods":
          this.clearCovid19();
          break;

        case "Delete HAZARD periods":
          this.clearHazard();
          break;

        case "End COVID-19 periods on":
          this.handleEndCovidPeriods();
          break;

        case "End HAZARD periods on":
          this.handleEndHazardPeriods();
          break;

        default:
          break;
      }
    },

    async handleEndCovidPeriods() {
      this.editDialog = false;

      const covid19PeriodEnd = `${this.filterDate}T23:59:59.999Z`;

      const airports = this.selectedAirports.filter((item) => {
        return item.covid19Periods.length;
      });

      if (!airports.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `The selected Airports do not have any COVID-19 periods`;
        this.snackbarVisible = true;
        return;
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports`,
          {
            airports,
            covid19PeriodEnd,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        const companyAirports = res.data.airports;
        await this.$store.commit("updateCompanyAirports", companyAirports);

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.filterAirports();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleEndHazardPeriods() {
      this.editDialog = false;

      const hazardPeriodEnd = `${this.filterDate}T23:59:59.999Z`;

      const airports = this.selectedAirports.filter((item) => {
        return item.hazardPeriods.length;
      });

      if (!airports.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `The selected Airports do not have any Hazard periods`;
        this.snackbarVisible = true;
        return;
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports`,
          {
            airports,
            hazardPeriodEnd,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }
        const companyAirports = res.data.airports;

        await this.$store.commit("updateCompanyAirports", companyAirports);

        this.loading = false;
        this.filterAirports();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async save() {
      this.editDialog = false;
      let covid19Start;
      let covid19End;

      if (this.isCovid19) {
        covid19Start = `${this.selectedCovid19StartDate}T00:00:00.000Z`;

        if (this.isCovid19OpenEnded) {
          covid19End = "2100-01-01T23:59:59.999Z";
        } else {
          if (
            new Date(`${this.selectedCovid19EndDate}T23:59:59.999Z`).getTime() <
            new Date(`${this.selectedCovid19StartDate}T00:00:00.000Z`).getTime()
          ) {
            this.snackbarColor = "#F44336";
            this.snackbarText = "Please check dates";
            return (this.snackbarVisible = true);
          }

          covid19End = `${this.selectedCovid19EndDate}T23:59:59.999Z`;
        }
      }

      let hazardStart;
      let hazardEnd;

      if (this.isHazard) {
        hazardStart = `${this.selectedHazardStartDate}T00:00:00.000Z`;

        if (this.isHazardOpenEnded) {
          hazardEnd = "2100-01-01T23:59:59.999Z";
        } else {
          if (
            new Date(`${this.selectedHazardEndDate}T23:59:59.999Z`).getTime() <
            new Date(`${this.selectedHazardStartDate}T00:00:00.000Z`).getTime()
          ) {
            this.snackbarColor = "#F44336";
            this.snackbarText = "Please check dates";
            return (this.snackbarVisible = true);
          }

          hazardEnd = `${this.selectedHazardEndDate}T23:59:59.999Z`;
        }
      }

      let updatedAirports = [];
      let conflicts;
      let periodStart;
      let periodEnd;

      const newCovid19PeriodStart = new Date(covid19Start).getTime();
      const newCovid19PeriodEnd = new Date(covid19End).getTime();

      const newHazardPeriodStart = new Date(hazardStart).getTime();
      const newHazardPeriodEnd = new Date(hazardEnd).getTime();

      this.selectedAirports.forEach((airport) => {
        conflicts = 0;

        if (this.isCovid19) {
          if (airport.covid19Periods.length) {
            airport.covid19Periods.forEach((period) => {
              periodStart = new Date(period.start).getTime();
              periodEnd = new Date(period.end).getTime();

              if (
                newCovid19PeriodStart <= periodEnd &&
                newCovid19PeriodEnd >= periodStart
              ) {
                conflicts++;
              }
            });

            if (conflicts === 0) {
              airport.covid19Periods.push({
                start: new Date(newCovid19PeriodStart).toISOString(),
                end: new Date(newCovid19PeriodEnd).toISOString(),
              });
              updatedAirports.push(airport);
            }
          } else {
            airport.covid19Periods.push({
              start: new Date(newCovid19PeriodStart).toISOString(),
              end: new Date(newCovid19PeriodEnd).toISOString(),
            });

            updatedAirports.push(airport);
          }
        }

        if (this.isHazard) {
          if (airport.hazardPeriods.length) {
            airport.hazardPeriods.forEach((period) => {
              periodStart = new Date(period.start).getTime();
              periodEnd = new Date(period.end).getTime();

              if (
                newHazardPeriodStart <= periodEnd &&
                newHazardPeriodEnd >= periodStart
              ) {
                conflicts++;
              }
            });

            if (conflicts === 0) {
              airport.hazardPeriods.push({
                start: new Date(newHazardPeriodStart).toISOString(),
                end: new Date(newHazardPeriodEnd).toISOString(),
              });

              updatedAirports.push(airport);
            }
          } else {
            airport.hazardPeriods.push({
              start: new Date(newHazardPeriodStart).toISOString(),
              end: new Date(newHazardPeriodEnd).toISOString(),
            });

            updatedAirports.push(airport);
          }
        }
      });

      if (updatedAirports.length === 0) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Airports could not be saved due to conflicting dates`;
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports`,
          {
            airports: updatedAirports,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (updatedAirports.length == this.selectedAirports.length) {
          this.loading = false;
          this.snackbarColor = "#66BB6A";
          this.snackbarText = "Airports successfully updated";
          this.snackbarVisible = true;
        } else {
          this.loading = false;
          this.snackbarColor = "#2196F3";
          this.snackbarText = `${updatedAirports.length} Airports successfully updated. Some airports could not be saved due to conflicting dates`;
          this.snackbarVisible = true;
        }

        this.loading = true;
        const companyAirports = res.data.airports;
        await this.$store.commit("updateCompanyAirports", companyAirports);

        this.loading = false;
        this.filterAirports();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async deletePeriods() {
      let selectedCovid19Periods = [];
      let selectedHazardPeriods = [];

      this.selectedPeriods.forEach((period) => {
        if (period.category === "COVID-19") {
          selectedCovid19Periods.push(period._id);
        } else {
          selectedHazardPeriods.push(period._id);
        }
      });

      const newCovid19Periods = this.airport.covid19Periods.filter((period) => {
        return !selectedCovid19Periods.includes(period._id);
      });

      const newHazardPeriods = this.airport.hazardPeriods.filter((period) => {
        return !selectedHazardPeriods.includes(period._id);
      });

      const updatedAirport = {
        ...this.airport,
        covid19Periods: newCovid19Periods,
        hazardPeriods: newHazardPeriods,
      };

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports`,
          {
            airports: [updatedAirport],
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        const companyAirports = res.data.airports;

        await this.$store.commit("updateCompanyAirports", companyAirports);

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.loading = false;
        this.filterAirports();

        this.airportDialog = false;

        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Special Airports successfully updated";
        this.snackbarVisible = true;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async clearCovid19() {
      this.editDialog = false;
      let updatedAirports = [];

      this.selectedAirports.forEach((airport) => {
        airport.covid19Periods = [];
        updatedAirports.push(airport);
      });

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports`,
          {
            airports: updatedAirports,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.loading = false;
        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Airports successfully updated";
        this.snackbarVisible = true;

        const companyAirports = res.data.airports;

        this.loading = true;
        await this.$store.commit("updateCompanyAirports", companyAirports);

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.loading = false;
        this.filterAirports();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async clearHazard() {
      this.editDialog = false;
      let updatedAirports = [];

      this.selectedAirports.forEach((airport) => {
        airport.hazardPeriods = [];
        updatedAirports.push(airport);
      });

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/airports`,
          {
            airports: updatedAirports,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;
        this.snackbarColor = "#66BB6A";
        this.snackbarText = "Airports successfully updated";
        this.snackbarVisible = true;

        const companyAirports = res.data.airports;

        this.loading = true;
        await this.$store.commit("updateCompanyAirports", companyAirports);
        this.loading = false;
        this.filterAirports();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatCovid19Status(airport) {
      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      if (this.overlaps(startTime, endTime, airport.covid19Periods)) {
        return "YES";
      } else {
        return "NO";
      }
    },
    formatHazardStatus(airport) {
      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      if (this.overlaps(startTime, endTime, airport.hazardPeriods)) {
        return "YES";
      } else {
        return "NO";
      }
    },
    changeCheckDate() {
      this.showStartCalendar = false;
      this.filterAirports();
    },

    showAirportDetail(airport) {
      this.airport = { ...airport };
      this.airportPeriods = [];
      this.selectedAirports = [];
      this.selectedPeriods = [];

      if (airport.covid19Periods.length) {
        airport.covid19Periods.forEach((period) => {
          this.airportPeriods.push({
            category: "COVID-19",
            start: this.formatDate(period.start),
            end: this.formatDate(period.end),
            _id: period._id,
          });
        });
      }

      if (airport.hazardPeriods.length) {
        airport.hazardPeriods.forEach((period) => {
          this.airportPeriods.push({
            category: "Hazard",
            start: this.formatDate(period.start),
            end: this.formatDate(period.end),
            _id: period._id,
          });
        });
      }

      this.airportDialog = true;
    },
    filterAirports() {
      this.search = "";
      this.selectedAirports = [];

      const startTime = new Date(this.targetDate).getTime();
      const endTime = new Date(this.targetDate).getTime();

      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];

      if (this.country === "All Countries") {
        filterOne = [...this.companyAirports];
      } else {
        filterOne = this.companyAirports.filter((airport) => {
          return airport.country === this.country;
        });
      }

      if (this.showOnlyIfActiveCovid) {
        filterTwo = filterOne.filter((airport) => {
          return this.overlaps(startTime, endTime, airport.covid19Periods);
        });
      } else {
        filterTwo = [...filterOne];
      }

      if (this.showOnlyIfActiveHazard) {
        filterThree = filterTwo.filter((airport) => {
          return this.overlaps(startTime, endTime, airport.hazardPeriods);
        });
      } else {
        filterThree = [...filterTwo];
      }

      this.filteredAirports = [...filterThree];
    },

    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
.frame {
  position: relative;
  top: 48px;
  width: 100%;
  background-color: white;
  padding: 5px;
}

.filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 5px;
  padding: 3px;
}

.actions-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 3px;
}
</style>
