<template>
  <div class="outer-frame" :class="{ 'has-conflict': flight.hasConflict }">
    <div class="d-flex justify-start align-center text-truncate font-abel-10px">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div class="cursor-hand" v-on="on">{{ flight.flightNumber }}</div>
        </template>
        <div class="tooltip font-abel-12px">
          <div>
            {{ formatFullFlightSnapshot(flight) }}
          </div>

          <v-divider dark></v-divider>
          <div class="d-flex justify-space-between">
            <div>{{ flight.customerName }}</div>
            <div>Status: {{ flight.status }}</div>
          </div>

          <!-- Flight Crewmembers -->
          <v-divider dark></v-divider>

          <div v-if="flight.crewmembers && flight.crewmembers.length > 0">
            <div v-for="crewmember in flight.crewmembers" :key="crewmember._id">
              <span>{{ getFlightRole(crewmember.role).description }}:</span>
              <span v-if="crewmember.employee" class="ml-1">
                {{ crewmember.employee.surname }},
                {{ crewmember.employee.givenName }}
              </span>
              <span v-else>
                {{ crewmember.assignedToCompany }}
              </span>
            </div>
          </div>
          <div class="lime--text" v-else>
            No flight crewmembers assigned.
          </div>

          <!-- Ground Crewmembers -->
          <v-divider dark></v-divider>

          <div
            v-if="
              flight.groundCrewmembers && flight.groundCrewmembers.length > 0
            "
          >
            <div
              v-for="groundCrewmember in flight.groundCrewmembers"
              :key="groundCrewmember._id"
              class="yellow--text"
            >
              <span
                >{{
                  `Ground (${groundCrewmember.originIata}): ${groundCrewmember.subCategory} - `
                }}
              </span>
              <span v-if="groundCrewmember.employee">
                {{ groundCrewmember.employee.surname }},
                {{ groundCrewmember.employee.givenName }}
              </span>
              <span v-else>{{ groundCrewmember.assignedToCompany }}</span>
            </div>
          </div>
        </div>
      </v-tooltip>
    </div>

    <div
      class="inner-frame d-flex justify-center align-center"
      :style="getFlightLabelStyle()"
      @click="clicked()"
    >
      <div
        class="d-flex justify-center align-center text-truncate font-abel-14px"
      >
        {{ `${flight.originIata} - ${flight.destinationIata}` }}
      </div>
    </div>

    <div class="d-flex justify-space-between text-truncate font-abel-10px">
      <div>{{ flight.effectiveTimeOut.substring(11, 16) }}</div>
      <div>{{ flight.effectiveTimeIn.substring(11, 16) }}</div>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightLabel",
  mixins: [mixin],
  data() {
    return {};
  },
  props: ["flight"],
  methods: {
    clicked() {
      this.$emit("clicked", this.flight);
    },
    getFlightLabelStyle() {
      let style = {
        backgroundColor: "#FFFFFF",
        color: "#FF0000",
      };

      let crewFlag = "1px solid #bebebe";
      let groundCrewFlag = "1px solid #bebebe";
      let unassigned = [];

      if (this.flight.crewmembers.length) {
        unassigned = this.flight.crewmembers.filter((crewmember) => {
          return !crewmember.employee;
        });

        if (unassigned.length === 0) {
          crewFlag = "5px solid #4CAF50"; //green
        } else {
          crewFlag = "5px solid #FFC107"; //amber
        }
      } else {
        crewFlag = "5px solid #673AB7"; //deep purple
      }

      if (this.flight.groundCrewmembers.length) {
        groundCrewFlag = "6px solid #641E16"; //#00FFCC
      }

      switch (this.flight.status) {
        case "In":
        case "On":
          style = {
            backgroundColor: "#273FCB",
            color: "#FFFFFF",
          };
          break;
        case "Off":
        case "Out":
          style = {
            backgroundColor: "#228833",
            color: "#FFFFFF",
          };
          break;
        case "Delayed":
          if (this.flight.isVip) {
            style = {
              backgroundColor: "#d795d4",
              color: "#FFFFFF",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          } else {
            style = {
              backgroundColor: "#D92120",
              color: "#FFFFFF",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          }

          break;
        case "Scheduled":
          if (this.flight.isVip) {
            style = {
              backgroundColor: "#96b2d7",
              color: "#000000",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          } else {
            style = {
              backgroundColor: "#F7EE55",
              color: "#000000",
              borderLeft: crewFlag,
              borderBottom: groundCrewFlag,
            };
          }
          break;
        default:
          break;
      }

      return style;
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  height: 59px;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.has-conflict {
  border: 1px solid red;
  background-color: darksalmon;
}

.inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 30px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}
</style>
