<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <div class="main-header">
      <div>
        {{ user.displayName }}
      </div>

      <div class="d-flex justify-space-between align-center mr-1">
        <v-switch
          @change="filterAssignments()"
          class="mr-3"
          dark
          dense
          v-model="showPendingOnly"
          label="Show Only Pending"
        ></v-switch>

        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <i class="fa-solid fa-arrows-rotate"></i>
        </v-btn>
      </div>
    </div>
    <div class="main-frame">
      <!-- LOADING -->
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>

      <v-container fluid v-if="!loading">
        <!--NOTIFICATION  INFORMATION DIALOG -->
        <v-dialog
          v-model="showNotificationInformationDialog"
          persistent
          max-width="800px"
        >
          <div v-if="assignment" class="blue-frame">
            <div
              class="d-flex justify-space-between align-center font-abel-20px"
            >
              <div class="d-flex align-center">
                <h2 class="font-weight-bold">
                  Assignment
                </h2>
              </div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="handleHideNotificationInformationDialog"
                    small
                    icon
                    class="ml-3"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </div>

            <v-divider class="my-3"></v-divider>

            <div v-if="assignment.type === 'Flight'">
              <h4>
                {{ formatFullFlightSnapshot(assignment.flight) }}
              </h4>

              <div class="d-flex align-center my-1" v-if="assignment.employee">
                <h2 class="font-weight-bold">
                  {{
                    `${assignment.employee.surname}, ${assignment.employee.givenName}`
                  }}
                </h2>
                <h3 class="font-weight-bold indigo--text ml-3">
                  {{
                    `(${assignment.employee.companyName} - ${assignment.employee.employeeId})`
                  }}
                </h3>
              </div>

              <div>
                <span>Phone: </span>
                <span class="font-abel-14px-bold ml-1">
                  {{ assignment.employee.mobilePhone }}
                </span>
              </div>

              <div>
                <span>Flight Role:</span
                ><span class="font-abel-16px-bold ml-2">{{
                  getFlightRole(assignment.role).description
                }}</span>
              </div>

              <div>
                <span>Assigned by:</span
                ><span class="ml-1 font-weight-bold">{{
                  assignment.assignedBy
                }}</span>
              </div>
              <div>
                <span>Last Updated:</span
                ><span class="ml-1 font-weight-bold">{{
                  formatDateTime(assignment.updatedAt)
                }}</span>
              </div>

              <v-divider class="my-1"></v-divider>

              <h5>Remarks</h5>

              <div class="remarks">
                <p
                  v-if="assignment.remarks"
                  class="user-select-text font-abel-14px  indigo--text mt-2"
                >
                  {{ assignment.remarks }}
                </p>
              </div>

              <v-divider class="my-1"></v-divider>
              <h3>Notification</h3>
              <div v-if="assignment.notification">
                <div>
                  <span>Sent by:</span
                  ><span class="ml-1 font-weight-bold">{{
                    assignment.notification.sentBy
                  }}</span>
                </div>
                <div>
                  <span>Sent on:</span
                  ><span class="ml-1 font-weight-bold">{{
                    `${formatDateTime(assignment.notification.sentOn)} Z`
                  }}</span>
                </div>
                <div v-if="assignment.notification.acknowledged">
                  <span>Acknowledged on:</span
                  ><span class="ml-1 font-weight-bold green--text">
                    {{
                      `${formatDateTime(
                        assignment.notification.acknowledgedOn
                      )} Z`
                    }}
                  </span>
                </div>
                <div v-else>
                  <span class="font-weight-bold red--text"
                    >Not Acknowledged</span
                  >
                </div>
                <div
                  v-if="
                    assignment.notificationStatus === 'acknowledged-changed'
                  "
                >
                  <span>Warning:</span
                  ><span class="ml-1 font-weight-bold red--text">
                    Assignment changed since last notification
                  </span>
                </div>
              </div>
              <div v-else>
                <span class="font-weight-bold red--text"
                  >No notification sent</span
                >
              </div>
            </div>
            <div v-else>
              <div class="d-flex align-center my-1">
                <h2 class="font-weight-bold">
                  {{
                    `${assignment.employee.surname}, ${assignment.employee.givenName}`
                  }}
                </h2>
                <h3 class="font-weight-bold indigo--text ml-3">
                  {{
                    `(${assignment.employee.companyName} - ${assignment.employee.employeeId})`
                  }}
                </h3>
              </div>

              <div class="d-flex justify-space-between align-center mb-2">
                <div class="font-abel-20px-bold">
                  {{ `${formatGroundAssignmentSnapshot(assignment)}` }}
                </div>
              </div>

              <v-divider class="my-1"></v-divider>

              <div>
                <span>Period:</span
                ><span class="font-abel-16px-bold ml-2">
                  {{
                    `${formatDateTime(
                      assignment.startTime
                    )} -  ${formatDateTime(assignment.endTime)}`
                  }}</span
                >
              </div>

              <div>
                <span>Employee Phone:</span
                ><span class="font-abel-16px-bold ml-2">{{
                  assignment.employee.mobilePhone
                }}</span>
              </div>

              <div>
                <span>Assigned by:</span
                ><span class="font-abel-16px-bold ml-2">{{
                  assignment.assignedBy
                }}</span>
              </div>
              <div>
                <span>Last updated:</span
                ><span class="font-abel-16px-bold ml-2">{{
                  formatDateTime(assignment.updatedAt)
                }}</span>
              </div>

              <v-divider class="my-1"></v-divider>

              <h5>Remarks</h5>
              <div class="remarks">
                <p
                  v-if="assignment.remarks"
                  class="user-select-text font-abel-14px  indigo--text mt-2"
                >
                  {{ assignment.remarks }}
                </p>
              </div>
              <v-divider class="my-1"></v-divider>
              <h3>Notification</h3>
              <div v-if="assignment.notification">
                <div>
                  <span>Sent by:</span
                  ><span class="ml-1 font-weight-bold">{{
                    assignment.notification.sentBy
                  }}</span>
                </div>
                <div>
                  <span>Sent on:</span
                  ><span class="ml-1 font-weight-bold">{{
                    `${formatDateTime(assignment.notification.sentOn)} Z`
                  }}</span>
                </div>
                <div v-if="assignment.notification.acknowledged">
                  <span>Acknowledged on:</span
                  ><span class="ml-1 font-weight-bold green--text">
                    {{
                      `${formatDateTime(
                        assignment.notification.acknowledgedOn
                      )} Z`
                    }}
                  </span>
                </div>
                <div v-else>
                  <span class="font-weight-bold red--text"
                    >Not Acknowledged</span
                  >
                </div>
                <div
                  v-if="
                    assignment.notificationStatus === 'acknowledged-changed'
                  "
                >
                  <span>Warning:</span
                  ><span class="ml-1 font-weight-bold red--text">
                    Assignment changed since last notification
                  </span>
                </div>
              </div>
              <div v-else>
                <span class="font-weight-bold red--text"
                  >No notification sent</span
                >
              </div>
            </div>
          </div>
        </v-dialog>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="filteredAssignments"
            v-model="selectedAssignments"
            :search="lastName"
            :footer-props="{
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
            @click:row="handleShowNotificationInformationDialog"
            show-select
            item-key="_id"
          >
            <template v-slot:[`item._id`]="{ item }">
              <span>{{ formatAssignmentSnapshot(item) }}</span>
            </template>

            <template v-slot:[`item.notification`]="{ item }">
              <v-btn icon>
                <v-icon :color="getNotificationStatusColor(item)"
                  >notifications</v-icon
                >
              </v-btn>
            </template>

            <template v-slot:[`item.sentOn`]="{ item }">
              <span>{{ formatNotificationSentOn(item) }}</span>
            </template>
          </v-data-table>
          <div class="d-flex justify-end">
            <v-btn
              :disabled="!selectedAssignments.length"
              @click="handleAcknowledgeAssignments"
              small
              dark
              color="blue"
              class="mr-3 my-3"
              >Acknowledge</v-btn
            >
          </div>
        </v-col>
      </v-container>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";

export default {
  name: "myNotifications",
  components: {
    AutoLogout,
    Navbar,
  },

  data() {
    return {
      loading: false,
      isMobile: false,
      headers: [
        {
          text: "Last Name",
          align: "start",
          value: "employee.surname",
        },
        {
          sortable: true,
          text: "First Name",
          align: "start",
          value: "employee.givenName",
        },
        {
          sortable: true,
          text: "Company",
          align: "start",
          value: "employee.companyName",
        },
        {
          sortable: true,
          text: "Job Title",
          align: "start",
          value: "employee.jobTitle",
        },
        {
          sortable: false,
          text: "Assignment",
          align: "start",
          value: "_id",
        },
        {
          sortable: false,
          text: "Status",
          align: "start",
          value: "notification",
        },
        {
          sortable: true,
          text: "Sent",
          align: "start",
          value: "sentOn",
        },
      ],
      selectedStartDate: "",
      selectedEndDate: "",
      filteredAssignments: [],
      assignments: [],
      showStartCalendar: false,
      showEndCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      selectedAssignments: [],
      lastName: "",
      showPendingOnly: false,
      flightAssignment: null,
      groundAssignment: null,
      groundAssignmentStation: null,
      assignment: null,
      showNotificationInformationDialog: false,
    };
  },
  mixins: [mixin],
  created() {
    this.getAssignments();
  },
  mounted() {
    this.onResize();

    window.addEventListener("ize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("ize", this.onResize, { passive: true });
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    pending() {
      if (this.assignments.length) {
        return this.assignments.filter((assignment) => {
          return !assignment.notification.acknowledged;
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    getNotificationStatus(assignment) {
      let status = "not-sent";

      if (assignment.notification) {
        let etdChanged;
        let etaChanged;
        let originChanged;
        let destinationChanged;

        if (assignment.type === "Flight") {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.flight.estimatedDepartureTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.flight.estimatedArrivalTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.flight.originIata !==
              assignment.notification.originIata;
            destinationChanged =
              assignment.flight.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              status = "acknowledged-changed";
            } else {
              status = "acknowledged-unchanged";
            }
          } else {
            status = "sent-not-acknowledged";
          }
        } else {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.startTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.endTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.originIata !== assignment.notification.originIata;
            destinationChanged =
              assignment.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              status = "acknowledged-changed";
            } else {
              status = "acknowledged-unchanged";
            }
          } else {
            status = "sent-not-acknowledged";
          }
        }
      }

      return status;
    },
    handleShowNotificationInformationDialog(assignment) {
      const status = this.getNotificationStatus(assignment);

      this.assignment = { ...assignment, notificationStatus: status };

      this.showNotificationInformationDialog = true;
    },

    handleHideNotificationInformationDialog() {
      this.showNotificationInformationDialog = false;
      this.assignment = null;
    },
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },
    async getAssignments() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/assignments/notifications/my", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.assignments = res.data.assignments;

        this.filterAssignments();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleAcknowledgeAssignments() {
      const pending = this.selectedAssignments.filter((item) => {
        return !item.notification.acknowledged;
      });

      if (!pending.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Every notification you've selected has already been acknowledged.";
        this.snackbarVisible = true;
        return;
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        let assignments = [];

        this.selectedAssignments.forEach((item) => {
          assignments.push({
            type: item.type,
            _id: item.id,
          });
        });

        const res = await api.put(
          `/assignments/notifications/acknowledge`,
          {
            assignments,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = res.data.message;
        this.snackbarVisible = true;

        this.selectedAssignments = [];
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatNotificationSentOn(item) {
      if (item.notification) {
        return `${this.formatDateTime(item.notification.sentOn)}`;
      } else {
        return "---";
      }
    },

    getNotificationStatusColor(assignment) {
      let color = "red";

      if (assignment.notification) {
        let etdChanged;
        let etaChanged;
        let originChanged;
        let destinationChanged;

        if (assignment.type === "Flight") {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.flight.estimatedDepartureTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.flight.estimatedArrivalTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.flight.originIata !==
              assignment.notification.originIata;
            destinationChanged =
              assignment.flight.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            color = "blue";
          }
        } else {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.startTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.endTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.originIata !== assignment.notification.originIata;
            destinationChanged =
              assignment.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            color = "blue";
          }
        }
      }

      return color;
    },

    filterAssignments() {
      if (this.showPendingOnly) {
        this.filteredAssignments = this.assignments.filter((assignment) => {
          return !assignment.notification.acknowledged;
        });
      } else {
        this.filteredAssignments = [...this.assignments];
      }
    },
  },
};
</script>

<style scoped>
.main-header {
  position: relative;
  top: 48px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: var(--dark-gray);
  color: var(--white);
}

.main-frame {
  position: relative;
  top: 58px;
  width: 100%;
  background-color: white;
}

.fa-arrows-rotate {
  font-size: 1.5rem;
  color: var(--white);
}

.fa-arrows-rotate:hover {
  color: var(--green);
}
</style>
