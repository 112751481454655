<template>
  <div>
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <!-- LOADING -->
    <div v-if="loading">
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>

    <div v-else>
      <div
        v-if="state === 'view'"
        class="flight-information-dialog font-abel-16px"
      >
        <div class="d-flex justify-space-between align-center font-abel-20px">
          <div class="d-flex align-center">
            <h3 class="font-weight-bold  blue--text mr-2">
              {{ `FLIGHT ASSIGNMENT` }}
            </h3>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list dense color="#D2D6D7">
                <!-- SEND NOTIFICATION -->
                <v-list-item
                  v-if="
                    flightAssignment.employee &&
                      enableCreateAssignmentNotification
                  "
                  @click="handleSendAssignmentNotification()"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-envelope"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Send Notification</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- REMOVE EMPLOYEE -->
                <v-list-item
                  v-if="
                    flightAssignment.employee && enableUpdateFlightAssignment
                  "
                  @click="handleRemoveEmployee()"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-user-slash"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Remove Employee</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- ASSIGN EMPLOYEE -->
                <v-list-item
                  v-if="
                    !flightAssignment.employee && enableUpdateFlightAssignment
                  "
                  @click="handleAssignFlight"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-person-walking-luggage"></i>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Assign Employee</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- DELETE ASSIGNMENT -->
                <v-list-item
                  v-if="enableUpdateFlightAssignment"
                  @click="handleDeleteFlightAssignment()"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-trash-can"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Delete Assignment</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="close" small icon class="ml-3">
                <i class="fa-solid fa-xmark"></i>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <v-divider class="my-3"></v-divider>

        <h4>
          {{ formatFullFlightSnapshot(this.flightAssignment.flight) }}
        </h4>

        <div class="d-flex align-center my-1" v-if="flightAssignment.employee">
          <h2 class="font-weight-bold">
            {{
              `${flightAssignment.employee.surname}, ${flightAssignment.employee.givenName}`
            }}
          </h2>
          <h3 class="font-weight-bold indigo--text ml-3">
            {{
              `(${flightAssignment.employee.companyName} - ${flightAssignment.employee.employeeId})`
            }}
          </h3>
        </div>
        <div v-else>
          <div class="d-flex justify-start align-center">
            <h2 class="font-weight-bold">
              <span>Assigned to:</span>
              <span class="ml-1 red--text">{{
                ` ${flightAssignment.assignedToCompany}`
              }}</span>
            </h2>
          </div>

          <h3 v-if="flightAssignment.unableToCover" class="font-weight-bold">
            <span>Unable to cover by:</span>
            <span class="ml-1 red--text">{{
              ` ${flightAssignment.unableToCover.companyName}`
            }}</span>
          </h3>
        </div>
        <div v-if="flightAssignment.employee">
          <span>Phone: </span>
          <span class="font-abel-14px-bold ml-1">
            {{ flightAssignment.employee.mobilePhone }}
          </span>
        </div>

        <div>
          <span>Flight Role:</span
          ><span class="font-abel-16px-bold ml-2">{{
            getFlightRole(flightAssignment.role).description
          }}</span>
        </div>

        <div>
          <span>Assigned by:</span
          ><span class="ml-1 font-weight-bold">{{
            flightAssignment.assignedBy
          }}</span>
        </div>
        <div v-if="flightAssignment.warnings.length">
          <div class="assignment-warning" v-if="showAssignmentWarnings">
            <div class="flex-space-between">
              <div>
                The following warnings were active when the assignment was made:
              </div>
              <i
                @click="showAssignmentWarnings = false"
                class="fa-solid fa-xmark"
              ></i>
            </div>
            <ul>
              <li
                v-for="(warning, index) in flightAssignment.warnings"
                :key="index"
              >
                {{ warning.message }}
              </li>
            </ul>
          </div>
          <div v-else>
            <i
              @click="showAssignmentWarnings = true"
              class="fa-solid fa-triangle-exclamation mr-2"
            ></i>
            <span class="red--text font-weight-bold"
              >Assigned with Warnings</span
            >
          </div>
        </div>
        <div>
          <span>Last Updated:</span
          ><span class="ml-1 font-weight-bold">{{
            formatDateTime(flightAssignment.updatedAt)
          }}</span>
        </div>

        <v-divider class="my-1"></v-divider>

        <h5>Remarks</h5>

        <div v-if="!editRemarks">
          <div class="remarks" @click="handleEnableEditRemarks">
            <p
              v-if="flightAssignment.remarks"
              class="user-select-text font-abel-14px  indigo--text mt-2"
            >
              {{ flightAssignment.remarks }}
            </p>
            <p v-else></p>
          </div>
        </div>
        <div v-else>
          <v-textarea
            v-model="flightAssignmentRemarks"
            no-resize
            rows="3"
          ></v-textarea>
          <div class="d-flex justify-end">
            <v-btn
              @click="handleSaveRemarks"
              small
              dark
              color="green"
              :disabled="snackbarVisible"
              >save remarks</v-btn
            >
            <v-btn
              @click="handleCancelEditRemarks"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else-if="state === 'confirm-delete'" class="red-frame">
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelDelete" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="my-5">
          <h2>
            Removing a Flight Assignment cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn @click="handleConfirmDelete" small dark color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelDelete"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else class="red-frame">
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelRemoveEmployee" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="my-5">
          <h2>
            Removing an employee cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn @click="handleConfirmRemoveEmployee" small dark color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelRemoveEmployee"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightAssignmentInformation",
  mixins: [mixin],
  props: ["flightAssignment"],

  data() {
    return {
      loading: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      dialog: true,
      editRemarks: false,
      flightAssignmentRemarks: "",
      confirmationDialog: false,
      confirmationAction: null,
      confirmationMessage: "",
      sendNotification: true,
      state: "view",
      notify: false,
      showAssignmentWarnings: false,
    };
  },

  methods: {
    async handleSendAssignmentNotification() {
      let payload = {
        assignment: {
          type: "Flight",
          employeeId: this.flightAssignment.employee._id,
          _id: this.flightAssignment._id,
          snapshot: this.formatFlightAssignmentNotification(
            this.flightAssignment
          ),
        },
        emailAddress: this.flightAssignment.employee.mail,
        secondaryMailAddress: this.flightAssignment.employee.secondaryMail,
        emailSubject: "Flight Assignment",
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "sendAssignmentNotification",
        payload
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.snackbarColor = "#66BB6A";
      this.snackbarText = res.data.message;
      this.snackbarVisible = true;
    },

    async handleConfirmDelete() {
      this.loading = true;

      const res = await this.$store.dispatch(
        "deleteFlightAssignment",
        this.flightAssignment
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.notify) {
        let payload = {
          assignment: {
            type: "Flight",
            employeeId: this.flightAssignment.employee._id,
            _id: this.flightAssignment._id,
            snapshot: this.formatFlightAssignmentNotification(
              this.flightAssignment
            ),
          },
          emailAddress: this.flightAssignment.employee.mail,
          secondaryMailAddress: this.flightAssignment.employee.secondaryMail,
          emailSubject: "Flight Assignment Removed",
        };

        this.loading = true;

        const res = await this.$store.dispatch(
          "sendAssignmentNotification",
          payload
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      this.$emit("close", { refreshData: true });
    },

    handleCancelDelete() {
      this.state = "view";
    },

    handleDeleteFlightAssignment() {
      this.state = "confirm-delete";
    },

    async handleConfirmRemoveEmployee() {
      this.loading = true;

      const res = await this.$store.dispatch(
        "removeEmployeeFromFlightAssignment",
        this.flightAssignment
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.notify) {
        let payload = {
          assignment: {
            type: "Flight",
            employeeId: this.flightAssignment.employee._id,
            _id: this.flightAssignment._id,
            snapshot: this.formatFlightAssignmentNotification(
              this.flightAssignment
            ),
          },
          emailAddress: this.flightAssignment.employee.mail,
          secondaryMailAddress: this.flightAssignment.employee.secondaryMail,
          emailSubject: "Flight Assignment Cancelled",
        };

        this.loading = true;

        const res = await this.$store.dispatch(
          "sendAssignmentNotification",
          payload
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      this.$emit("close", { refreshData: true });
    },

    handleRemoveEmployee() {
      this.state = "confirm-remove";
    },

    handleCancelRemoveEmployee() {
      this.state = "view";
    },

    handleEnableEditRemarks() {
      if (!this.enableUpdateFlightAssignment) {
        return;
      }

      if (
        !this.user.securityGroup.companies.includes(
          this.flightAssignment.assignedToCompany
        )
      ) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        return;
      }

      if (this.flightAssignment.remarks) {
        this.flightAssignmentRemarks = this.flightAssignment.remarks.slice();
      } else {
        this.flightAssignmentRemarks = " ";
      }

      this.editRemarks = true;
    },

    async handleSaveRemarks() {
      const flightAssignment = {
        ...this.flightAssignment,
        remarks: this.flightAssignmentRemarks,
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "saveFlightAssignmentRemarks",
        flightAssignment
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.editRemarks = false;

      this.$emit("close", { refreshData: true });
    },

    handleAssignFlight() {
      this.$emit("close", { refreshData: false });
      this.$store.commit(
        "updateFlightId",
        this.flightAssignment.flight.flightId
      );
      this.$router.push({ name: "flightAssignmentBoard" });
    },

    handleCancelEditRemarks() {
      this.editRemarks = false;
    },

    close() {
      this.$emit("close", { refreshData: false });
    },
  },
};
</script>

<style scoped>
.remarks {
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.red-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.assignment-warning {
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 5px;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  background-color: var(--amber);
  color: #000;
}

.assignment-warning div {
  font-size: 1.1rem;
}

.assignment-warning ul {
  list-style-type: decimal-leading-zero;
}

/* .assignment-warning li {
  border-bottom: 1px solid var(--gray);
} */

p {
  width: 100%;
  min-height: 100px;
}

i {
  cursor: pointer;
}

.fa-trash-can {
  color: var(--red);
}

.fa-trash-check {
  color: var(--purple);
}

.fa-user-slash {
  color: var(--amber);
}

.fa-envelope {
  color: var(--blue);
}

.fa-triangle-exclamation {
  color: var(--amber);
  cursor: pointer;
}

.fa-person-walking-luggage {
  color: var(--blue);
}
</style>
