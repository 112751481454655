<template>
  <div>
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <div class="bottom-frame-no-toolbar">
      <v-container fluid>
        <v-form @submit.prevent="onSubmit" class="font-abel-16px" ref="form">
          <v-card class="mb-2" elevation="2" outlined shaped tile>
            <v-card-text>
              <!-- ROW 1 -->
              <v-row dense align="start">
                <v-col cols="10" sm="5">
                  <div class="d-flex align-center">
                    <v-text-field
                      outlined
                      dense
                      label="Active Directory User Name"
                      v-model="userPrincipalName"
                      :rules="userNameRules"
                      required
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col cols="2" sm="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="getUserFromActiveDirectory"
                        ><v-icon color="blue">search</v-icon></v-btn
                      >
                    </template>
                    <span>Search Active Directory</span>
                  </v-tooltip>
                </v-col>

                <v-col v-if="employee" cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Employee ID"
                    v-model="employee.employeeId"
                    :rules="employeeIdRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <div v-if="employee">
                <!-- ROW 2 -->
                <v-row dense align="center">
                  <!-- FIRST NAME -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      dense
                      label="First Name"
                      v-model="employee.givenName"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <!-- LAST NAME -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      dense
                      label="Last Name"
                      v-model="employee.surname"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <!-- JOB TITLE -->
                  <v-col cols="12" sm="4">
                    <v-select
                      outlined
                      dense
                      label="Job Title"
                      v-model="employee.jobTitle"
                      :items="user.securityGroup.jobTitles"
                      :rules="jobTitleRules"
                      required
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- ROW 3 -->
                <v-row dense align="center">
                  <!-- COMPANY NAME -->
                  <v-col cols="12" sm="4">
                    <v-select
                      outlined
                      dense
                      label="Company Name"
                      v-model="employee.companyName"
                      :items="userCompanies"
                      :rules="companyNameRules"
                      required
                    ></v-select>
                  </v-col>

                  <!-- GATEWAY -->
                  <v-col cols="12" sm="1">
                    <v-text-field
                      outlined
                      dense
                      label="Gateway"
                      v-model="employee.gatewayCode"
                      :rules="gatewayCodeRules"
                      required
                    ></v-text-field>
                  </v-col>

                  <!-- SUB-GATEWAYS -->
                  <v-col cols="12" sm="3">
                    <v-text-field
                      outlined
                      dense
                      label="Sub-Gateways"
                      v-model="subGatewayCodesString"
                    ></v-text-field>
                  </v-col>

                  <!-- SENIORITY NUMBER -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      label="Seniority No."
                      v-model="employee.seniorityNumber"
                      :rules="seniorityNumberRules"
                      required
                    ></v-text-field>
                  </v-col>

                  <!-- DATE OF HIRE  -->
                  <v-col cols="12" sm="2">
                    <div>
                      <v-menu
                        v-model="showDateOfHireCalendar"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="employee.dateOfHire"
                            outlined
                            dense
                            label="Date of Hire"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="employee.dateOfHire"
                          no-title
                          @input="showDateOfHireCalendar = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>

                <!-- ROW 4 -->
                <v-row dense align="center">
                  <!-- EMAIL ADDRESS -->
                  <v-col cols="12" sm="8">
                    <v-text-field
                      outlined
                      dense
                      label="Email"
                      v-model="employee.mail"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <!-- MOBILE PHONE -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      dense
                      label="Mobile Phone"
                      v-model="employee.mobilePhone"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- ROW 5 -->
                <v-row dense align="center">
                  <!-- EMERGENCVY CONTACT NAME -->
                  <v-col cols="12" sm="8">
                    <v-text-field
                      outlined
                      dense
                      label="Emergency Contact Name"
                      v-model="employee.emergencyContact.name"
                    ></v-text-field>
                  </v-col>

                  <!-- EMERGENCY CONTACT NUMBER -->
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      dense
                      label="Emergency Contact Number"
                      v-model="employee.emergencyContact.number"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- ROW 6 -->
                <v-row dense align="center">
                  <!-- STREET ADDRESS -->
                  <v-col cols="12" sm="5">
                    <v-text-field
                      outlined
                      dense
                      label="Street Address"
                      v-model="employee.address.street"
                    ></v-text-field>
                  </v-col>

                  <!-- CITY -->
                  <v-col cols="12" sm="3">
                    <v-text-field
                      outlined
                      dense
                      label="City"
                      v-model="employee.address.city"
                    ></v-text-field>
                  </v-col>

                  <!-- ZIP CODE -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      outlined
                      dense
                      label="ZIP"
                      v-model="employee.address.postalCode"
                    ></v-text-field>
                  </v-col>

                  <!-- STATE -->
                  <v-col cols="12" sm="2">
                    <v-text-field
                      outlined
                      dense
                      label="State"
                      v-model="employee.address.state"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- ROW 7 -->
                <v-row dense align="center">
                  <!-- COUNTRY -->
                  <v-col cols="8">
                    <v-select
                      :items="countries"
                      outlined
                      dense
                      label="Country"
                      v-model="employee.address.country"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>

              <div
                v-if="activeDirectoryEmployee"
                class="font-abel-12px  grey-text"
              >
                {{
                  `UPN: ${activeDirectoryEmployee.userPrincipalName} - ID: ${activeDirectoryEmployee.id}`
                }}
              </div>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-end align-center mt-2">
            <v-btn
              :disabled="!employee"
              @click="createEmployee"
              small
              dark
              color="green"
              >save</v-btn
            >
            <v-btn class="ml-2" @click="goBack()" small dark color="grey"
              >cancel</v-btn
            >
          </div>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import axios from "axios";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";

export default {
  name: "employeeCreate",
  components: { AutoLogout, Navbar },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      isMobile: false,
      showDateOfHireCalendar: false,
      userNameRules: [(v) => !!v || "User Name is required"],
      employeeIdRules: [(v) => !!v || "Employee ID is required"],
      jobTitleRules: [(v) => !!v || "Job Title is required"],
      companyNameRules: [(v) => !!v || "Company Name is required"],
      seniorityNumberRules: [
        (v) => !!v || "Seniority number is required",
        (v) => (v && v >= 0) || "Seniority number should be above 0",
        (v) => (v && v <= 1000) || "Seniority numbershould not be above 1,000",
      ],
      gatewayCodeRules: [
        (v) => !!v || "Code is required",
        (v) => (v && v.length == 3) || "Enter a three letter Airport Code",
      ],
      userPrincipalName: "",
      employee: null,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      activeDirectoryEmployee: null,
      subGatewayCodesString: "",
    };
  },

  created() {},
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    userCompanies() {
      let companies = [];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },
    onSubmit() {
      this.getUserFromActiveDirectory();
    },

    async getUserFromActiveDirectory() {
      if (this.userPrincipalName.length < 1) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please enter a valid username";
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getAccessTokenSilent();

        const res = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${this.userPrincipalName}`,
          {
            headers: {
              Authorization: "Bearer " + token.accessToken,
            },
          }
        );

        this.loading = false;

        this.activeDirectoryEmployee = {
          id: res.data.id,
          userPrincipalName: res.data.userPrincipalName,
        };

        this.employee = {
          azureId: res.data.id,
          userPrincipalName: res.data.userPrincipalName,
          employeeId: "",
          displayName: res.data.displayName,
          givenName: res.data.givenName,
          surname: res.data.surname,
          jobTitle: this.user.securityGroup.jobTitles.includes(
            res.data.jobTitle
          )
            ? res.data.jobTitle
            : null,
          companyName: "Atlas Air",
          dateOfHire: new Date().toISOString().substring(0, 10),
          seniorityNumber: 1,
          mail: res.data.mail,
          mobilePhone: res.data.mobilePhone,
          address: {
            street: "",
            city: "",
            state: "FL",
            country: "United States",
            postalCode: "",
          },
          emergencyContact: {
            name: "",
            number: "",
          },
        };
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async createEmployee() {
      if (this.$refs.form.validate() === false) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please correct higlighted values";
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        this.employee.createdBy = this.user.name;
        this.employee.updatedBy = this.user.name;
        this.employee.gatewayCode = this.employee.gatewayCode.toUpperCase();

        const token = await aad.getIdTokenSilent();

        //Sub-Gateway Codes
        const codes = this.subGatewayCodesString.split(",");
        let cleanCode;
        let cleanCodes = [];

        codes.forEach((item) => {
          cleanCode = item.trim().toUpperCase();

          if (
            cleanCode !== this.employee.gatewayCode &&
            cleanCode.length === 3
          ) {
            cleanCodes.push(cleanCode);
          }
        });

        const subGatewayCodes = [...new Set(cleanCodes)];

        if (subGatewayCodes.length > 10) {
          this.snackbarColor = "#F44336";
          this.snackbarText = "The maximum number of Sub-Gateways is 10";
          this.snackbarVisible = true;
          return;
        }

        this.employee.subGatewayCodes = subGatewayCodes;
        this.subGatewayCodesString = subGatewayCodes.toString();

        const res = await api.post(`/employees`, this.employee, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.$router.push({ name: "administration" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
