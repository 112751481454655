<template>
  <div class="r-dialog-grey">
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <!-- EMPLOYEE NAME -->
    <div class="d-flex justify-space-between">
      <div class="font-abel-24px-bold">
        {{ employee.surname }}, {{ employee.givenName }}
      </div>
      <v-btn @click="hide" small icon><v-icon>close</v-icon></v-btn>
    </div>

    <v-divider></v-divider>

    <v-text-field
      v-model="flightSearch"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      :style="{
        maxWidth: '200px',
        marginLeft: '10px',
        marginBottom: '10px',
      }"
    ></v-text-field>

    <v-data-table
      dense
      :headers="flightHeaders"
      v-model="selectedFlightAssignments"
      :items="employee.flightAssignments"
      :search="flightSearch"
      show-select
      :footer-props="{
        itemsPerPageOptions: [10, 20, -1],
      }"
      item-key="_id"
    >
      <template v-slot:[`item.role`]="{ item }">
        <span>{{ getFlightRole(item.role).description }}</span>
      </template>
      <template v-slot:[`item.flight.effectiveTimeOut`]="{ item }">
        <span>{{ formatDateTime(item.flight.effectiveTimeOut) }}</span>
      </template>

      <template v-slot:[`item.flight.effectiveTimeIn`]="{ item }">
        <span>{{ formatDateTime(item.flight.effectiveTimeIn) }}</span>
      </template>
    </v-data-table>

    <div class="d-flex justify-end align-center font-abel-16px mt-1">
      <v-btn @click="print" small dark color="blue">print</v-btn>
      <v-btn @click="hide" class="ml-1" small dark color="grey">cancel</v-btn>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
export default {
  name: "employeeAssignmentsList",
  props: ["employee"],
  mixins: [mixin],
  data() {
    return {
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      flightSearch: "",
      flightHeaders: [
        {
          text: "Flight Role",
          align: "start",
          sortable: true,
          value: "role",
        },
        {
          text: "Registration",
          align: "start",
          sortable: true,
          value: "flight.aircraftRegistration",
        },
        {
          text: "From",
          align: "center",
          sortable: true,
          value: "flight.originIata",
        },
        {
          text: "To",
          align: "center",
          sortable: true,
          value: "flight.destinationIata",
        },
        {
          text: "OUT",
          align: "center",
          sortable: true,
          value: "flight.effectiveTimeOut",
        },
        {
          text: "IN",
          align: "center",
          sortable: true,
          value: "flight.effectiveTimeIn",
        },
      ],
      selectedFlightAssignments: [],
    };
  },
  computed: {
    dateFilter() {
      return this.$store.getters.dateFilter;
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    async print() {
      if (!this.selectedFlightAssignments.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please select some assignments to print";
        return (this.snackbarVisible = true);
      }
      try {
        const pdfDoc = await PDFDocument.create();

        const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);
        pdfDoc.setTitle(
          `${this.formatName(
            this.employee.givenName,
            this.employee.surname,
            "l"
          )} - Flight Assignments Report`
        );

        pdfDoc.addPage();
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let flightAssignment;
        let options = {
          x: 50,
          y: 750,
          size: 16,
          font: courierFont,
          color: rgb(0, 0, 0),
        };

        page.drawText(
          `${this.formatName(
            this.employee.givenName,
            this.employee.surname,
            "l"
          )}`,
          options
        );
        options.y -= 15;
        options.size = 12;
        page.drawText(
          `Flight Assignments: ${this.formatDate(
            this.dateFilter.startDate
          )} - ${this.formatDate(this.dateFilter.endDate)}`,
          options
        );

        options.size = 10;
        options.y -= 15;
        page.drawText(
          `${
            this.selectedFlightAssignments.length
          } flight assignment(s) - Printed: ${this.formatDateTime(new Date())}`,
          options
        );
        options.y -= 30;

        for (let i = 0; i < this.selectedFlightAssignments.length; i++) {
          flightAssignment = this.selectedFlightAssignments[i];

          options.color = rgb(0.15, 0.09, 0.81);

          //---------------------------------------------------ROW 1
          // Flight No.
          options.x = 50;
          page.drawText(
            `Flight Role: ${
              this.getFlightRole(flightAssignment.role).description
            }`,
            options
          );

          //---------------------------------------------------ROW 2
          options.y -= 15;
          // Flight No.
          options.x = 50;
          page.drawText(
            `Flight No: ${flightAssignment.flight.flightNumber}`,
            options
          );
          // Date,
          options.x = 225;
          page.drawText(
            `Date: ${this.formatDate(
              flightAssignment.flight.effectiveTimeOut
            )}`,
            options
          );

          // Route
          options.x = 400;
          page.drawText(
            `Route: ${flightAssignment.flight.originIata} - ${flightAssignment.flight.destinationIata}`,
            options
          );

          //---------------------------------------------------ROW 3
          options.y -= 15;
          //Customer
          options.x = 50;
          page.drawText(
            `Customer: ${flightAssignment.flight.customerName}`,
            options
          );

          // Registration
          options.x = 400;
          page.drawText(
            `Aircraft: ${flightAssignment.flight.aircraftRegistration}`,
            options
          );

          //---------------------------------------------------ROW 4
          options.y -= 15;

          // OUT
          options.x = 50;
          page.drawText(
            `OUT: ${this.formatTime(
              flightAssignment.flight.effectiveTimeOut
            )} Z`,
            options
          );

          // IN
          options.x = 225;
          page.drawText(
            `IN: ${this.formatTime(flightAssignment.flight.effectiveTimeIn)} Z`,
            options
          );

          //Block
          options.x = 400;
          page.drawText(
            `Block: ${this.formatMinutes(
              flightAssignment.flight.effectiveBlockTime
            )}`,
            options
          );
          //---------------------------------------------------Separator
          options.y -= 15;
          options.x = 50;
          page.drawText(
            `----------------------------------------------------------------------------`,
            options
          );
          options.y -= 15;
          if (options.y - 150 < 50 && i <= this.flightAssignments.length) {
            options.x = 450;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `page ${pages.length} of ${pdfDoc.pageCount}`,
              options
            );

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 750;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 450;
        options.color = rgb(0, 0, 0);
        page.drawText(`page ${pages.length} of ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.hide();
        this.selectedFlightAssignments = [];
      }
    },
  },
};
</script>
