<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- ADD EXEMPTION DIALOG-->
    <v-dialog v-model="showAddExemptionDialog" max-width="1200px">
      <div class="blue-frame">
        <div class="d-flex justify-space-between">
          <div v-if="!loading" class="font-abel-20px">
            Loadmaster Requirement Exemptions
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="handleHideLoadmasterRequirementDialog"
                small
                icon
                class="ml-3"
              >
                <i class="fa-solid fa-xmark"></i>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <div class="row-container">
          <v-switch
            v-model="selectAllAirports"
            :label="
              selectAllAirports ? 'Select All Stations' : 'Select Stations'
            "
            dense
          ></v-switch>
          <v-autocomplete
            v-if="!selectAllAirports"
            v-model="selectedAirports"
            :items="companyAirports"
            label="IATA Codes"
            item-text="iata"
            item-value="iata"
            chips
            small-chips
            clearable
            deletable-chips
            multiple
          ></v-autocomplete>
          <p>{{ stationsDisclamer }}</p>
        </div>

        <div class="row-container">
          <v-switch
            v-model="selectAllAircraftTypes"
            :label="
              selectAllAircraftTypes
                ? 'Select All Aircraft Types'
                : 'Select Aircraft Types'
            "
            dense
          ></v-switch>

          <div v-if="!selectAllAircraftTypes" class="aircraft-types">
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="744"
              value="744"
            ></v-checkbox>

            <v-checkbox
              v-model="selectedAircraftTypes"
              label="748"
              value="748"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="77F"
              value="77F"
            ></v-checkbox>

            <v-checkbox
              v-model="selectedAircraftTypes"
              label="737"
              value="737"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="738"
              value="738"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="747"
              value="747"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="74Y"
              value="74Y"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="763"
              value="763"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="76F"
              value="76F"
            ></v-checkbox>

            <v-checkbox
              v-model="selectedAircraftTypes"
              label="LCF"
              value="LCF"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedAircraftTypes"
              label="PAX"
              value="PAX"
            ></v-checkbox>
          </div>

          <p>{{ aircraftTypesDisclamer }}</p>
        </div>

        <div class="row-container">
          <v-switch
            dense
            v-model="isPermanent"
            :label="isPermanent ? 'Permanent' : 'Select Start and End Dates'"
          ></v-switch>

          <div v-if="!isPermanent" class="d-flex justify-start align-center">
            <div class="d-flex justify-center px-5 ">
              <!-- Start Date -->
              <v-menu
                v-model="showStartCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedStartDate"
                    label="Exemption Start"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedStartDate"
                  no-title
                  @input="showStartCalendar = false"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="d-flex justify-center px-5">
              <!-- End Date -->
              <v-menu
                v-model="showEndCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="selectedEndDate"
                    label="Exemption End"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="selectedEndDate"
                  @input="showEndCalendar = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <p>{{ datesDisclaimer }}</p>
        </div>
        <div class="d-flex justify-space-between align-center mt-2">
          <div v-if="loading">
            {{
              `Adding ${exemptions.length -
                exemptionsBeforeAdding} of ${exemptionsToAdd}`
            }}
          </div>
          <div v-else></div>

          <v-btn
            @click="handleAddLoadmasterRequirementExemption"
            :disabled="!isValid"
            small
            dark
            color="blue"
            >Add Exemptions</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <div class="main-frame">
      <div class="d-flex justify-space-between align-center pa-3">
        <div class="font-abel-20px indigo--text">
          The Airport-Aircraft listed below are exempted from the minimum
          loadmaster requirement.
        </div>
        <i
          @click="handleShowLoadmasterRequirementDialog"
          class="fa-solid fa-circle-plus"
        ></i>
      </div>

      <div class="search">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>

      <v-data-table
        dense
        :headers="exemptionsHeaders"
        :items="exemptions"
        v-model="selectedExemptions"
        :search="search"
        show-select
        item-key="key"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, 200, -1],
        }"
      >
        <template v-slot:[`item.start`]="{ item }">
          <span>{{ formatStart(item) }}</span>
        </template>

        <template v-slot:[`item.end`]="{ item }">
          <span>{{ formatEnd(item) }}</span>
        </template>
      </v-data-table>

      <div
        class="d-flex justify-space-between align-center mt-2 mx-3 font-abel-16px"
      >
        <div class="d-flex align-center">
          <div class="remove-selected" v-if="selectedExemptions.length">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="handleRemoveSelectedExemptions()"
                  small
                  v-on="on"
                  icon
                >
                  <i class="fa-solid fa-trash-can"></i>
                </v-btn>
              </template>
              <span>Remove Selected Exemptions</span>
            </v-tooltip>
          </div>
          <div
            class="remove-all"
            v-if="!selectedExemptions.length && exemptions.length"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="handleRemoveAllExemptions()"
                  small
                  icon
                  v-on="on"
                >
                  <i class="fa-solid fa-trash-can"></i>
                </v-btn>
              </template>
              <span>Remove All Exemptions</span>
            </v-tooltip>
          </div>
          <div class="recycle-bin" v-if="recyclingBinExemptions.length">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click="handleRestoreExemptions()" small v-on="on" icon>
                  <i class="fa-solid fa-trash-can-undo"></i>
                </v-btn>
              </template>
              <span>Restore Removed Exemptions</span>
            </v-tooltip>
          </div>
        </div>

        <div class="d-flex justify-end align-center">
          <v-btn
            @click="handleSaveLoadmasterRequirementExemptions"
            small
            dark
            color="green"
            >save</v-btn
          >
          <v-btn class="ml-1" @click="handleGoHome" small dark color="grey"
            >close</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import aad from "../services/aad";
// import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";

export default {
  name: "crewRequirements",
  components: { AutoLogout, Navbar },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      isMobile: false,
      airport: null,
      airportPeriods: [],
      airportDialog: false,
      editDialog: false,
      selectedExemptions: [],
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      companyCountries: [],
      exemptions: [],
      country: "",
      airportSearch: "",
      airportsHeaders: [
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "iata",
        },
        {
          text: "ICAO Code",
          align: "start",
          sortable: true,
          value: "icao",
        },
        {
          text: "Country",
          align: "start",
          sortable: true,
          value: "country",
        },
      ],
      search: "",
      exemptionsHeaders: [
        {
          text: "Airport Code",
          align: "start",
          sortable: true,
          value: "airportCode",
        },
        {
          text: "Aircraft Type",
          align: "start",
          sortable: true,
          value: "aircraftType",
        },
        {
          text: "Exemption Start",
          align: "center",
          sortable: true,
          value: "start",
        },
        {
          text: "Exemption End",
          align: "center",
          sortable: true,
          value: "end",
        },
      ],
      showAddExemptionDialog: false,
      showStartCalendar: false,
      showEndCalendar: false,
      selectAllAirports: false,
      selectAllAircraftTypes: false,
      selectedAirports: [],
      selectedAircraftTypes: ["744", "748", "77F"],
      isPermanent: false,
      selectedStartDate: new Date().toISOString().substring(0, 10),
      selectedEndDate: new Date().toISOString().substring(0, 10),
      recyclingBinExemptions: [],
      exemptionsBeforeAdding: 0,
      exemptionsToAdd: 0,
    };
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  watch: {
    company: {
      immediate: true,
      handler() {
        this.exemptions = [...this.company.loadmasterRequirementExemptions];
      },
    },
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    airports() {
      return this.$store.getters.companyAirports;
    },

    errors() {
      return this.$store.getters.errors;
    },

    isValid() {
      if (!this.selectAllAirports && !this.selectedAirports.length) {
        return false;
      }

      if (!this.selectAllAircraftTypes && !this.selectedAircraftTypes.length) {
        return false;
      }

      if (!this.isPermanent) {
        const start = `${this.selectedStartDate}T00:00:00.000+00:00`;
        const end = `${this.selectedEndDate}T23:59:59.999+00:00`;

        const diff = new Date(end).getTime() - new Date(start).getTime();

        if (diff < 1) {
          return false;
        }
      }

      return true;
    },

    stationsDisclamer() {
      if (this.selectAllAirports) {
        return "Exemptions will apply to the all stations, combined with the selected aircraft types.";
      } else {
        if (this.selectedAirports.length) {
          return "Exemptions will apply to the selected stations, combined with the selected aircraft types.";
        } else {
          return 'Please select some stations, or change the "Select Stations" switch to "Select All Stations"';
        }
      }
    },

    aircraftTypesDisclamer() {
      if (this.selectAllAircraftTypes) {
        return "Exemptions will apply to the all aircraft types, combined with the selected stations.";
      } else {
        if (this.selectedAircraftTypes.length) {
          return "Exemptions will apply to the selected aircraft types, combined with the selected stations.";
        } else {
          return 'Please select some aircraft types, or change the "Select Aircraft Types" switch to "Select All Aircraft Types"';
        }
      }
    },

    datesDisclaimer() {
      if (this.isPermanent) {
        return "Exemptions will be permanent.";
      } else {
        return 'Exemptions will start on the selected "Exemption Start" date at 00:00:00 UTC and end on the selected "Exemption End" date at 23:59:59 UTC.';
      }
    },
  },

  methods: {
    handleGoHome() {
      this.$router.push({ name: "home" });
    },
    formatStart(exemption) {
      if (exemption.isPermanent) {
        return "Permanent";
      } else {
        const d1 = new Date(exemption.start).toUTCString().toString();
        return `${d1.substring(5, 17)} @ ${d1.substring(16, 22)} Z`;
      }
    },

    formatEnd(exemption) {
      if (exemption.isPermanent) {
        return "Permanent";
      } else {
        const d1 = new Date(exemption.end).toUTCString().toString();
        return `${d1.substring(5, 17)} @ ${d1.substring(16, 22)} Z`;
      }
    },

    async handleAddLoadmasterRequirementExemption() {
      let start;
      let end;

      if (this.isPermanent) {
        start = `1899-01-01T00:00:00.000+00:00`;
        end = `2999-12-31T23:59:59.999+00:00`;
      } else {
        start = `${this.selectedStartDate}T00:00:00.000+00:00`;
        end = `${this.selectedEndDate}T23:59:59.999+00:00`;
      }

      const diff = new Date(end).getTime() - new Date(start).getTime();

      if (diff < 1) {
        this.snackbarColor = "var(--red)";
        this.snackbarText =
          "Please check times, the selected period must be at least 1 day";
        this.snackbarVisible = true;
        return;
      }

      this.loading = true;

      if (this.selectAllAirports) {
        this.selectedAirports = [];
        this.airports.forEach((airport) => {
          this.selectedAirports.push(airport.iata);
        });
      }

      if (this.selectAllAircraftTypes) {
        this.selectedAircraftTypes = [];
        this.aircraftTypes.forEach((type) => {
          this.selectedAircraftTypes.push(type);
        });
      }

      this.exemptionsToAdd =
        this.selectedAirports.length * this.selectedAircraftTypes.length;
      this.exemptionsBeforeAdding = this.exemptions.length;

      let i;
      let key;
      let code;
      let type;

      for (let x = 0; x < this.selectedAirports.length; x++) {
        code = this.selectedAirports[x];

        for (let y = 0; y < this.selectedAircraftTypes.length; y++) {
          type = this.selectedAircraftTypes[y];

          key = `${code}-${type}`;

          if (this.exemptions.length) {
            i = this.exemptions.findIndex((item) => {
              return item.key === key;
            });

            if (i < 0) {
              this.exemptions.push({
                airportCode: code,
                aircraftType: type,
                key,
                isPermanent: this.isPermanent,
                start,
                end,
              });
            } else {
              this.exemptions[i].code = code;
              this.exemptions[i].aircraftType = type;
              this.exemptions[i].key = key;
              this.exemptions[i].isPermanent = this.isPermanent;
              this.exemptions[i].start = start;
              this.exemptions[i].end = end;
            }
          } else {
            this.exemptions.push({
              airportCode: code,
              aircraftType: type,
              key,
              isPermanent: this.isPermanent,
              start,
              end,
            });
          }
        }

        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      this.loading = false;

      this.showAddExemptionDialog = false;
      this.selectedAirports = [];
      this.selectedAircraftTypes = ["744", "748", "77F"];
      this.selectAllAirports = false;
      this.selectAllAircraftTypes = false;
      this.isPermanent = false;
      this.search = "";
      this.exemptionsToAdd = 0;

      this.snackbarColor = "var(--green)";
      this.snackbarText = `Exemptions were added.`;
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    handleRemoveSelectedExemptions() {
      this.recyclingBinExemptions = [];
      let i;
      this.selectedExemptions.forEach((exemption) => {
        i = this.exemptions.findIndex((item) => {
          return item.key === exemption.key;
        });

        if (i >= 0) {
          this.recyclingBinExemptions.push({ ...this.exemptions[i] });
          this.exemptions.splice(i, 1);
        }
      });

      this.selectedExemptions = [];

      this.snackbarColor = "var(--green)";
      this.snackbarText = `Exemptions were removed.`;
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    handleRemoveAllExemptions() {
      this.recyclingBinExemptions = [...this.exemptions];

      this.exemptions = [];
      this.selectedExemptions = [];

      this.snackbarColor = "var(--green)";
      this.snackbarText = `Exemptions were removed.`;
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    handleRestoreExemptions() {
      this.exemptions = [...this.exemptions, ...this.recyclingBinExemptions];
      this.recyclingBinExemptions = [];

      this.snackbarColor = "var(--green)";
      this.snackbarText = `Exemptions were restored.`;
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },
    handleShowLoadmasterRequirementDialog() {
      this.selectedStartDate = new Date().toISOString().substring(0, 10);
      this.selectedEndDate = new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .substring(0, 10); //1 Momth from start
      this.showAddExemptionDialog = true;
    },
    handleHideLoadmasterRequirementDialog() {
      this.showAddExemptionDialog = false;
    },

    async handleSaveLoadmasterRequirementExemptions() {
      this.loading = true;

      const res = await this.$store.dispatch(
        "updateLoadmasterRequirementExemptions",

        this.exemptions
      );

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      const company = res.company;

      this.$store.commit("updateCompany", company);

      this.loading = false;

      this.$router.go(-1);
    },

    onResize() {
      this.isMobile = window.innerWidth < 650;
    },
  },
};
</script>

<style scoped>
.main-frame {
  position: relative;
  top: 48px;
  width: 100%;
  background-color: white;
  padding: 5px;
}

.row-container {
  padding: 0 20px;
  margin: 0 0 3px 0;
  border: 1px solid var(--gray);
  border-radius: 5px;
}

.row-container p {
  font-size: 0.9rem;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  color: var(--dark-grey);
}

.v-switch {
  color: red;
  margin: 0;
}

.aircraft-types {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.blue-frame {
  border-style: solid;
  border-color: var(--blue);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 5px;
  padding: 3px;
  max-width: 250px;
  margin: 0 0 10px 15px;
}

.fa-circle-plus {
  font-size: 2rem;
  color: var(--blue);
  cursor: pointer;
}

.remove-all i {
  font-size: 1.5rem;
  color: var(--red);
  cursor: pointer;
}

.remove-selected i {
  font-size: 1.5rem;
  color: var(--orange);
  cursor: pointer;
}

.recycle-bin i {
  font-size: 1.5rem;
  color: var(--green);
  cursor: pointer;
}
</style>
