<template>
  <div class="outer-frame">
    <div class="font-abel-10px text-truncate">
      {{ `${groundAssignment.category} ` }}
    </div>

    <div
      class="inner-frame d-flex justify-center align-center"
      :style="getGroundAssignmentLabelStyle(groundAssignment)"
      @click="clicked()"
    >
      <div
        class="d-flex justify-start align-center text-truncate font-abel-14px"
      >
        {{ `${formatGroundAssignmentSnapshot(groundAssignment)}` }}
      </div>
    </div>

    <div class="d-flex justify-space-between text-truncate font-abel-10px">
      <div>{{ groundAssignment.startTime.substring(11, 16) }}</div>
      <div>{{ groundAssignment.endTime.substring(11, 16) }}</div>
    </div>
  </div>
</template>
<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "groundAssignmentLabel",
  mixins: [mixin],
  data() {
    return {};
  },
  props: ["groundAssignment"],
  methods: {
    clicked() {
      this.$emit("clicked", this.groundAssignment._id);
    },
  },
};
</script>
<style scoped>
.outer-frame {
  position: absolute;
  height: 59px;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.inner-frame {
  position: relative;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  background-color: #939790;
  height: 30px;
  cursor: pointer;
}
</style>
