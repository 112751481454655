<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <v-btn @click="monthFilterBack" x-small icon dark :disabled="loading"
        ><v-icon>chevron_left</v-icon></v-btn
      >

      <v-btn
        :disabled="loading"
        class="mx-2"
        @click="monthFilterDialog = true"
        small
        outlined
        color="white"
        >{{ formatMonthShort(selectedMonth) }} - {{ selectedYear }}</v-btn
      >

      <v-btn @click="monthFilterForward" x-small icon dark :disabled="loading"
        ><v-icon>chevron_right</v-icon></v-btn
      >

      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>
        <!-- Company Selector -->
        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>
      <v-spacer></v-spacer>

      <v-slider
        v-if="boardStyle === 'Flow Chart'"
        @change="scrollBoard"
        class="ml-1"
        v-model="sliderX"
        :max="panelWidth"
        :min="0"
        hide-details
      >
      </v-slider>
      <v-spacer></v-spacer>

      <!-- Refresh -->
      <div>
        <v-btn :disabled="loading" @click="getData" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>
      </div>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#D2D6D7">
          <!-- Flow Chart CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getFlowChartCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="indigo">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Flow Chart - CSV</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Loadmasters CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLoadmastersCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="blue">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Loadmasters Monthly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <!-- SPSR CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLeadPSRsCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="green">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Senior PSRs Monthly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Loadmasters Yearly CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLoadmastersYearlyCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="blue">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Loadmasters Yearly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <!-- SPSR Yearly CSV-->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLeadPSRsYearlyCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="green">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Senior PSRs Yearly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!--Print Loadmasters Yearly  -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="printLoadmastersYearly"
            link
          >
            <v-list-item-icon>
              <v-icon color="blue">picture_as_pdf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Loadmasters Yearly - PDF</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--Print SPSR Yearly -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="printLeadPSRsYearly"
            link
          >
            <v-list-item-icon>
              <v-icon color="green">picture_as_pdf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Senior PSRs Yearly - PDF</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>
        <!-- Company Selector -->
        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>
      <v-spacer></v-spacer>

      <!-- Refresh -->
      <div>
        <v-btn :disabled="loading" @click="getData" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>
      </div>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#D2D6D7">
          <!-- Month Picker -->
          <v-list-item @click="monthFilterDialog = true" link>
            <v-list-item-icon>
              <v-icon color="white">event</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Select Month</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Flow Chart CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getFlowChartCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="indigo">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Flow Chart - CSV</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Loadmasters CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLoadmastersCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="blue">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Loadmasters Monthly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <!-- SPSR CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLeadPSRsCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="green">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Senior PSRs Monthly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Loadmasters Yearly CSV -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLoadmastersYearlyCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="blue">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Loadmasters Yearly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <!-- SPSR Yearly CSV-->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="getLeadPSRsYearlyCsv"
            link
          >
            <v-list-item-icon>
              <v-icon color="green">article</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Senior PSRs Yearly Report - CSV</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!--Print Loadmasters Yearly  -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="printLoadmastersYearly"
            link
          >
            <v-list-item-icon>
              <v-icon color="blue">picture_as_pdf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Loadmasters Yearly - PDF</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--Print SPSR Yearly -->
          <v-list-item
            v-if="enableExportPayrollReportsToCsv"
            @click="printLeadPSRsYearly"
            link
          >
            <v-list-item-icon>
              <v-icon color="green">picture_as_pdf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Senior PSRs Yearly - PDF</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog v-model="employeeFilterDialog" persistent max-width="450px">
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- DAY DETAILS DIALOG -->
    <v-dialog
      v-if="dateData"
      v-model="dayDetailsDialog"
      persistent
      max-width="1200px"
    >
      <div class="yellow-frame font-abel-16px">
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            {{
              `${formatName(
                dateData.employee.givenName,
                dateData.employee.surname,
                "l"
              )} - ${dateData.date}`
            }}
          </div>
          <v-btn @click="dayDetailsDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div v-if="dateData.assignments.length">
          <v-data-table
            dense
            :headers="assignmentHeaders"
            :items="dateData.assignments"
            :footer-props="{
              itemsPerPageOptions: [5, 10],
            }"
          >
            <template v-slot:[`item._id`]="{ item }">
              <span>{{ formatAssignmentSnapshot(item) }}</span>
            </template>

            <template v-slot:[`item.startTime`]="{ item }">
              <span>{{ formatDateTime(item.startTime) }}</span>
            </template>

            <template v-slot:[`item.endTime`]="{ item }">
              <span>{{ formatDateTime(item.endTime) }}</span>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          <p>No assignments</p>
        </div>

        <div class="d-flex justify-end align-center my-2">
          <v-btn @click="dayDetailsDialog = false" small dark color="grey"
            >close</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- CONFIRMATION DIALOG -->
    <v-dialog v-model="confirmationDialog" persistent max-width="300px">
      <ConfirmDelete
        v-if="confirmation"
        :message="confirmation.message"
        @confirm="confirm"
        @cancel="cancelConfirmation"
      />
    </v-dialog>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <v-dialog v-model="specialAirportsDialog" persistent max-width="800px">
      <SpecialAirports @hide="specialAirportsDialog = false" />
    </v-dialog>

    <!-- MONTH FILTER DIALOG-->
    <v-dialog v-model="monthFilterDialog" max-width="500px" persistent>
      <div class="yellow-frame">
        <div class="d-flex justify-space-between font-abel-20px mb-5">
          <div>Reporting</div>
          <v-btn @click="monthFilterDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="d-flex justify-space-between px-5">
          <v-select
            dense
            class="mr-3"
            label="Select View"
            v-model="selectedBoardStyle"
            :items="boardStyleOptions"
          ></v-select>

          <v-select
            dense
            class="ml-3"
            label="Period"
            v-model="selectedPeriod"
            :items="periodOptions"
          ></v-select>
        </div>

        <div class="mb-2" v-if="selectedBoardStyle === 'Reports'">
          <div
            v-if="selectedPeriod === 'First'"
            class="font-abel-20px-bold ml-2"
          >
            These reports cover the first 15 days of the month.
          </div>
          <div v-else class="font-abel-20px-bold ml-2">
            These reports cover the entire month.
          </div>
        </div>

        <v-date-picker v-model="monthPicker" type="month"></v-date-picker>

        <div class="d-flex justify-end align-center my-2">
          <div class="d-flex">
            <v-btn @click="saveMonthFilter" small dark color="blue"
              >enter</v-btn
            >
            <v-btn
              class="ml-1"
              @click="monthFilterDialog = false"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- ACTIVITY REPORT DIALOG -->
    <v-dialog
      v-model="activityReportDialog"
      persistent
      max-width="1200"
      transition="dialog-bottom-transition"
    >
      <div v-if="employee" class="r-dialog-grey font-abel-16px">
        <div class="d-flex justify-end mb-2 ml-2">
          <v-btn @click="hideActivityReportDialog" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="report-panel my-1">
          <div class="d-flex justify-space-between align-center my-2">
            <div class="font-abel-20px ml-2">
              {{ `${employee.surname}, ${employee.givenName}` }}
            </div>

            <div class="font-abel-16px mr-3">
              {{
                `${formatMonthShort(
                  selectedMonth
                )} - ${selectedYear} - ${selectedPeriod} Period`
              }}
            </div>
          </div>
          <div class="font-abel-16px ml-2">
            {{ `Employee ID: ${employee.employeeId}` }}
          </div>

          <v-container>
            <!-- Employee  -->
            <v-row class="font-abel-14px">
              <v-col cols="12" class="report-panel-column">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex justify-start align-center">
                    <div class="font-abel-16px-bold mr-2">
                      Confirmed by Employee
                    </div>
                    <div
                      v-if="employee.employeeReport"
                      class="font-abel-14px indigo--text"
                    >
                      {{
                        ` ${
                          employee.employeeReport.confirmation.confirmedBy
                        } - ${formatDateTime(
                          employee.employeeReport.confirmation.timestamp
                        )}`
                      }}
                    </div>
                  </div>
                  <v-btn
                    v-if="
                      employee.employeeReport &&
                        enableDeleteEmployeePayrollReport
                    "
                    @click="
                      showConfirmationDialog(
                        'deleteActivityReport',
                        employee.employeeReport._id
                      )
                    "
                    icon
                  >
                    <v-icon class="remove-icon">delete</v-icon>
                  </v-btn>
                </div>

                <div v-if="employee.employeeReport">
                  <!-- ROW 1 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Work Days 01 - 15:
                        <span class="font-weight-bold">
                          {{ employee.employeeReport.workDaysFirstHalf }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        {{ `Work Days ${this.secondPeriod}:` }}
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.workDaysSecondHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Work Days Total:
                        <span class="font-weight-bold">
                          {{ employee.employeeReport.workDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 2 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Vacation:
                        <span class="font-weight-bold">
                          {{ employee.employeeReport.vacationDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Floating Holidays:<span class="font-weight-bold"
                          >{{ employee.employeeReport.floatingHolidayDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Holidays:
                        <span class="font-weight-bold">
                          {{ employee.employeeReport.holidayDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 3 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Sick Days:
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.sickDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        LOA:
                        <span class="font-weight-bold">
                          {{ employee.employeeReport.loaDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Leave:
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.leaveDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 4 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        TOTAL DAYS:
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.totalDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <!-- DOMESTIC PERDIEM -->
                      <div>
                        Perdiem:
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.perdiemDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <!-- INTERNATIONAL PERDIEM -->
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        International Perdiem:
                        <span class="font-weight-bold"
                          >{{
                            employee.employeeReport.internationalPerdiemDays
                          }}
                        </span>
                      </div> -->
                    </v-col>
                  </v-row>

                  <!-- ROW 4-A -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Days OFF:
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.daysOff }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        STBY:
                        <span class="font-weight-bold"
                          >{{ employee.employeeReport.standByDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 5 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Mission Hazardous :<span
                          class="font-weight-bold red--text"
                        >
                          {{ employee.employeeReport.missionHazard }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        COVID 19 Days:
                        <span class="font-weight-bold red--text">
                          {{ employee.employeeReport.healthRiskDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        COVID 19 Allowance:
                        <span class="font-weight-bold red--text">
                          {{
                            employee.employeeReport.healthRiskAllowance
                          }}</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="font-abel-14px red--text">
                  No report for the selected period
                </div>
              </v-col>
            </v-row>

            <!-- Manager -->
            <v-row class="mt-1">
              <v-col cols="12" class="report-panel-column">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex justify-start align-center">
                    <div class="font-abel-16px-bold mr-2">
                      Confirmed by Manager
                    </div>
                    <div
                      v-if="employee.managerReport"
                      class="font-abel-14px indigo--text"
                    >
                      {{
                        ` ${
                          employee.managerReport.confirmation.confirmedBy
                        } - ${formatDateTime(
                          employee.managerReport.confirmation.timestamp
                        )}`
                      }}
                    </div>
                  </div>
                  <v-btn
                    v-if="
                      employee.managerReport && enableDeleteManagerPayrollReport
                    "
                    @click="
                      showConfirmationDialog(
                        'deleteActivityReport',
                        employee.managerReport._id
                      )
                    "
                    icon
                  >
                    <v-icon class="remove-icon">delete</v-icon>
                  </v-btn>
                </div>

                <div v-if="employee.managerReport">
                  <!-- ROW 1 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Work Days 01 - 15:
                        <span class="font-weight-bold">
                          {{ employee.managerReport.workDaysFirstHalf }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        {{ `Work Days ${this.secondPeriod}:` }}
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.workDaysSecondHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Work Days Total:
                        <span class="font-weight-bold">
                          {{ employee.managerReport.workDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 2 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Vacation:
                        <span class="font-weight-bold">
                          {{ employee.managerReport.vacationDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Floating Holidays:<span class="font-weight-bold"
                          >{{ employee.managerReport.floatingHolidayDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Holidays:
                        <span class="font-weight-bold">
                          {{ employee.managerReport.holidayDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 3 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Sick Days:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.sickDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        LOA:
                        <span class="font-weight-bold">
                          {{ employee.managerReport.loaDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Leave:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.leaveDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 4 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        TOTAL DAYS:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.totalDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        <!-- DOMESTIC PERDIEM -->
                        Perdiem:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.perdiemDays }}
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <!-- INTERNATIONAL PERDIEM -->
                      <!-- TEMP DISABLED -->
                      <!-- <div>                 
                        International Perdiem:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.internationalPerdiemDays }}
                        </span>
                      </div> -->
                    </v-col>
                  </v-row>

                  <!-- ROW 4-A-->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Days OFF:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.daysOff }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        STBY:
                        <span class="font-weight-bold"
                          >{{ employee.managerReport.standByDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 5 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Mission Hazardous :<span
                          class="font-weight-bold red--text"
                        >
                          {{ employee.managerReport.missionHazard }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        COVID 19 Days:
                        <span class="font-weight-bold red--text">
                          {{ employee.managerReport.healthRiskDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        COVID 19 Allowance:
                        <span class="font-weight-bold red--text">
                          {{ employee.managerReport.healthRiskAllowance }}</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="font-abel-14px red--text">
                  No report for the selected period
                </div>
              </v-col>
            </v-row>

            <!-- Administrator -->
            <v-row
              v-if="this.enableDeleteAdministratorPayrollReport"
              class="mt-1"
            >
              <v-col cols="12" class="report-panel-column">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex justify-start align-center">
                    <div class="font-abel-16px-bold mr-2">
                      Confirmed by Administrator
                    </div>
                    <div
                      v-if="employee.adminReport"
                      class="font-abel-14px indigo--text"
                    >
                      {{
                        ` ${
                          employee.adminReport.confirmation.confirmedBy
                        } - ${formatDateTime(
                          employee.adminReport.confirmation.timestamp
                        )}`
                      }}
                    </div>
                  </div>
                  <v-btn
                    v-if="
                      employee.adminReport &&
                        enableDeleteAdministratorPayrollReport
                    "
                    @click="
                      showConfirmationDialog(
                        'deleteActivityReport',
                        employee.adminReport._id
                      )
                    "
                    icon
                  >
                    <v-icon class="remove-icon">delete</v-icon>
                  </v-btn>
                </div>

                <div v-if="employee.adminReport">
                  <!-- ROW 1 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Work Days 01 - 15:
                        <span class="font-weight-bold">
                          {{ employee.adminReport.workDaysFirstHalf }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        {{ `Work Days ${this.secondPeriod}:` }}
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.workDaysSecondHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Work Days Total:
                        <span class="font-weight-bold">
                          {{ employee.adminReport.workDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 2 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Vacation:
                        <span class="font-weight-bold">
                          {{ employee.adminReport.vacationDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Floating Holidays:<span class="font-weight-bold"
                          >{{ employee.adminReport.floatingHolidayDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Holidays:
                        <span class="font-weight-bold">
                          {{ employee.adminReport.holidayDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 3 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Sick Days:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.sickDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        LOA:
                        <span class="font-weight-bold">
                          {{ employee.adminReport.loaDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Leave:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.leaveDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 4 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        TOTAL DAYS:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.totalDays }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        <!-- PERDIEM -->
                        Perdiem:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.perdiemDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 4-A-->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Days OFF:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.daysOff }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        STBY:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.standByDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 5 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Mission Hazardous :<span
                          class="font-weight-bold red--text"
                        >
                          {{ employee.adminReport.missionHazard }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        COVID 19 Days:
                        <span class="font-weight-bold red--text">
                          {{ employee.adminReport.healthRiskDays }}</span
                        >
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        COVID 19 Allowance:
                        <span class="font-weight-bold red--text">
                          {{ employee.adminReport.healthRiskAllowance }}</span
                        >
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <!-- ROW 6 -->
                  <v-row dense>
                    <!-- DOMESTIC PERDIEM -->
                    <v-col cols="4">
                      <div>
                        Perdiem P-1:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.perdiemDaysFirstHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Perdiem P-2:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.perdiemDaysSecondHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Perdiem:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.perdiemDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 7 -->
                  <v-row dense>
                    <!-- INTERNATIONAL PERDIEM -->
                    <v-col cols="4">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        International Perdiem P-1:
                        <span class="font-weight-bold"
                          >{{
                            employee.adminReport
                              .internationalPerdiemDaysFirstHalf
                          }}
                        </span>
                      </div> -->
                    </v-col>
                    <v-col cols="4">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        International Perdiem P-2:
                        <span class="font-weight-bold"
                          >{{
                            employee.adminReport
                              .internationalPerdiemDaysSecondHalf
                          }}
                        </span>
                      </div> -->
                    </v-col>
                    <v-col cols="4">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        International Perdiem:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.internationalPerdiemDays }}
                        </span>
                      </div> -->
                    </v-col>
                  </v-row>

                  <!-- ROW 8 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Days over 20:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.daysOver20 }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Days over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.daysOverGuarrantee }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Hours over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.daysOverGuarrantee * 8 }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- ROW 9 -->
                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        <!-- PERDIEM -->
                        Adjusted Perdiem Period 2:
                        <span class="font-weight-bold"
                          >{{
                            employee.adminReport.adjustedPerdiemDaysSecondHalf
                          }}
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div>
                        Adjusted Days over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ employee.adminReport.adjustedDaysOverGuarrantee }}
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div>
                        Adjusted Hours over Guarrantee:
                        <span class="font-weight-bold"
                          >{{
                            employee.adminReport.adjustedDaysOverGuarrantee * 8
                          }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>

                  <div v-if="employee.adminReport.remarks">
                    <p class="indigo--text">
                      {{ employee.adminReport.remarks }}
                    </p>
                  </div>
                </div>
                <div v-else class="font-abel-14px red--text">
                  No report for the selected period
                </div>
              </v-col>
            </v-row>

            <!-- Calculated -->
            <v-row class="mt-1">
              <v-col cols="12" class="report-panel-column-green">
                <div class="d-flex justify-space-between align center">
                  <div class="font-abel-16px-bold mr-2">
                    Calculated
                  </div>
                  <v-btn
                    @click="saveBoardStatusAndPrintActivityReport(employee)"
                    small
                    icon
                    color="blue"
                  >
                    <v-icon>print</v-icon>
                  </v-btn>
                </div>

                <v-row dense>
                  <v-col cols="4">
                    <div>
                      Work Days 01 - 15:
                      <span class="font-weight-bold">
                        {{ employee.activity.workDaysFirstHalf }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      {{ `Work Days ${this.secondPeriod}:` }}
                      <span class="font-weight-bold"
                        >{{ employee.activity.workDaysSecondHalf }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      Work Days Total:
                      <span class="font-weight-bold">
                        {{ employee.activity.workDays }}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <div>
                      Vacation:
                      <span class="font-weight-bold">
                        {{ employee.activity.vacationDays }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      Floating Holidays:<span class="font-weight-bold"
                        >{{ employee.activity.floatingHolidayDays }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      Holidays:
                      <span class="font-weight-bold">
                        {{ employee.activity.holidayDays }}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <div>
                      Sick Days:
                      <span class="font-weight-bold"
                        >{{ employee.activity.sickDays }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      LOA:
                      <span class="font-weight-bold">
                        {{ employee.activity.loaDays }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      Leave:
                      <span class="font-weight-bold"
                        >{{ employee.activity.leaveDays }}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <div>
                      TOTAL DAYS:
                      <span class="font-weight-bold"
                        >{{ employee.activity.totalDays }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      Perdiem :
                      <span class="font-weight-bold"
                        >{{ employee.activity.perdiemDays }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <!-- TEMP DISABLED -->
                    <!-- <div>
                      International Perdiem :
                      <span class="font-weight-bold"
                        >{{ employee.activity.internationalPerdiemDays }}
                      </span>
                    </div> -->
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <div>
                      Days OFF:
                      <span class="font-weight-bold"
                        >{{ employee.activity.daysOff }}
                      </span>
                    </div>
                  </v-col>

                  <v-col cols="4">
                    <div>
                      STBY:
                      <span class="font-weight-bold"
                        >{{ employee.activity.standByDays }}
                      </span>
                    </div>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4">
                    <div>
                      Mission Hazardous :<span
                        class="font-weight-bold red--text"
                      >
                        {{ employee.activity.missionHazard }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      COVID 19 Days:
                      <span class="font-weight-bold red--text">
                        {{ employee.activity.healthRiskDays }}</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div>
                      COVID 19 Allowance:
                      <span class="font-weight-bold red--text">
                        {{ employee.activity.healthRiskAllowance }}</span
                      >
                    </div>
                  </v-col>
                </v-row>

                <div v-if="this.enableReadEmployeePayrollReport">
                  <v-divider class="white my-2"></v-divider>

                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Perdiem P-1:
                        <span class="font-weight-bold ml-1"
                          >{{ employee.activity.perdiemDaysFirstHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Perdiem P-2:
                        <span class="font-weight-bold ml-1"
                          >{{ employee.activity.perdiemDaysSecondHalf }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Total Perdiem :
                        <span class="font-weight-bold ml-2"
                          >{{ employee.activity.perdiemDays }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="4">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        International Perdiem P-1:
                        <span class="font-weight-bold ml-1"
                          >{{
                            employee.activity.internationalPerdiemDaysFirstHalf
                          }}
                        </span>
                      </div> -->
                    </v-col>
                    <v-col cols="4">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        International Perdiem P-2:
                        <span class="font-weight-bold ml-1"
                          >{{
                            employee.activity.internationalPerdiemDaysSecondHalf
                          }}
                        </span>
                      </div> -->
                    </v-col>
                    <v-col cols="4">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        Total International Perdiem :
                        <span class="font-weight-bold ml-1"
                          >{{ employee.activity.internationalPerdiemDays }}
                        </span>
                      </div> -->
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="4">
                      <div>
                        Days over 20:
                        <span class="font-weight-bold"
                          >{{ employee.activity.daysOver20 }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Days over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ employee.activity.daysOverGuarrantee }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <div>
                        Hours over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ employee.activity.daysOverGuarrantee * 8 }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-divider class="white my-2"></v-divider>

                  <v-row dense v-if="this.enableUpdateEmployeePayrollReport">
                    <v-col cols="3">
                      <v-text-field
                        label="Perdiem Adjustment"
                        class="mr-5"
                        type="Number"
                        v-model="perdiemAdjustment"
                        maxlength="2"
                        min="-99"
                        max="99"
                        step="1"
                      >
                      </v-text-field>
                    </v-col>

                    <!-- <v-col cols="3">
                      <v-text-field
                        label="International Perdiem Adjustment"
                        class="mr-5"
                        type="Number"
                        v-model="internationalPerdiemAdjustment"
                        maxlength="2"
                        min="-99"
                        max="99"
                        step="1"
                      >
                      </v-text-field>
                    </v-col> -->

                    <v-col cols="3">
                      <v-text-field
                        @input="adjustMissionHazard"
                        label="Mission Hazard Adjustment"
                        class="mr-5"
                        type="Number"
                        v-model="missionHazardAdjustment"
                        maxlength="2"
                        min="-99"
                        max="99"
                        step="1"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-text-field
                        @input="adjustHealthRisk"
                        label="COVID 19 Adjustment"
                        class="mr-5"
                        type="Number"
                        v-model="healthRiskAdjustment"
                        maxlength="2"
                        min="-99"
                        max="99"
                        step="0.5"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="3">
                      <div>
                        Adjusted Perdiem P-2:
                        <span class="font-weight-bold"
                          >{{ adjustedPerdiemDaysSecondHalf }}
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="3">
                      <!-- TEMP DISABLED -->
                      <!-- <div>
                        Adjusted Intl. Perdiem P-2:
                        <span class="font-weight-bold"
                          >{{ adjustedInternationalPerdiemDaysSecondHalf }}
                        </span>
                      </div> -->
                    </v-col>

                    <v-col cols="3">
                      <div>
                        Adjusted Days over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ adjustedDaysOverGuarrantee }}
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="3">
                      <div>
                        Adjusted Hours over Guarrantee:
                        <span class="font-weight-bold"
                          >{{ adjustedDaysOverGuarrantee * 8 }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <div>
                    <v-textarea
                      dense
                      class="mt-2"
                      outlined
                      v-model="reportRemarks"
                      :rules="remarksRules"
                      label="Remarks"
                      no-resize
                      rows="3"
                    >
                    </v-textarea>
                  </div>
                </div>

                <div
                  v-if="this.enableUpdateEmployeePayrollReport"
                  class="d-flex  justify-space-between align-center"
                >
                  <v-checkbox
                    v-model="confirmed"
                    label="I Confirm that this report is accurate"
                  ></v-checkbox>

                  <v-btn
                    :disabled="!confirmed"
                    @click="submitReport"
                    small
                    dark
                    color="blue"
                    class="ml-1"
                    >submit</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-dialog>

    <!-- FLOW CHART -->
    <div v-if="boardStyle === 'Flow Chart'">
      <div class="top-frame">
        <div class="top-panel-left">
          <!-- Search -->
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loading"
                  @click="handleOpenEmployeeFilter"
                  small
                  icon
                >
                  <i class="fa-solid fa-filter"></i>
                </v-btn>
              </template>
              <span>Filter Employees by Job Title</span>
            </v-tooltip>
            <v-text-field
              v-model="lastName"
              dense
              label="Enter Last Name"
              single-line
              hide-details
              @input="filterEmployees"
            ></v-text-field>
          </div>
        </div>
        <div class="top-panel-right" ref="topPanelRight">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${panelWidth}px`,
              left: `${scrollX}px`,
            }"
          >
            <div
              v-for="(day, index) in monthFilter.days"
              :key="index"
              class="report-top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}
            </div>

            <div class="d-flex">
              <div
                v-for="(column, index) in reportColumns"
                :key="index"
                class="report-top-panel-column-title font-abel-10px-bold"
                :style="{ width: `${columnWidth}px` }"
              >
                <div>
                  {{ column.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading">
        <div v-if="filteredEmployees.length" class="bottom-frame">
          <!-- LEFT PANEL -->
          <div class="bottom-panel-left">
            <div
              v-for="(employee, index) in filteredEmployees"
              :key="employee._id"
              class="bottom-panel-left-row font-abel-14px hand-cursor"
              @click="saveBoardStatusAndPrintActivityReport(employee)"
            >
              <div
                class="bottom-panel-left-row-section truncate font-abel-12px-bold"
                :style="{ cursor: 'pointer' }"
              >
                {{ index + 1 }} - {{ employee.surname }},
                {{ employee.givenName.substring(0, 1) }}
              </div>

              <div
                class="bottom-panel-left-row-section truncate font-abel-10px"
              >
                {{ employee.companyName }} - {{ employee.jobTitle }}
              </div>
              <div
                class=" font-abel-12px bottom-panel-left-row-section truncate"
              >
                {{ employee.gatewayCode }} - {{ employee.employeeId }}
              </div>
            </div>

            <!-- TOTALS -->
            <div class="bottom-panel-left-row font-abel-14px">
              <div
                class="bottom-panel-left-row-section truncate font-abel-12px-bold"
              >
                Report
              </div>
              <div
                class="bottom-panel-left-row-section truncate font-abel-12px-bold"
              >
                TOTALS >>>
              </div>
            </div>
          </div>

          <!-- RIGHT PANEL -->
          <div
            class="bottom-panel-right"
            @scroll="scrollHorizontal"
            ref="bottomPanelRight"
          >
            <div
              class="bottom-panel-right-inner"
              :style="{
                width: `${panelWidth}px`,
                height: `${panelHeight}px`,
              }"
            >
              <div
                v-for="employee in filteredEmployees"
                :key="employee._id"
                class="bottom-panel-right-row"
                :style="{
                  width: `${panelWidth}px`,
                }"
              >
                <div>
                  <div
                    v-for="(day, index) in employee.activity.days"
                    :key="index"
                    class="bottom-panel-day"
                    :style="{
                      width: `${1440 / scale}px`,
                      left: `${index * (1440 / scale)}px`,
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }"
                  >
                    <div
                      @click="showdayDetails(employee, day)"
                      class="font-abel-10px-bold  activity-day"
                      :class="{ 'red-border': day.data.color === '#FF0000' }"
                      :style="{
                        backgroundColor: day.data.backgroundColor,
                        color: day.data.color,
                      }"
                    >
                      {{ day.data.displayCode }}
                    </div>
                  </div>

                  <!-- Work days 1 - 15 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale)}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.workDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Work days 16 - End -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.workDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- Work days total -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 2}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px grey lighten-3">
                      {{ employee.activity.workDays }}
                    </div>
                  </div>

                  <!-- Vacation -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 3}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.vacationDays }}
                    </div>
                  </div>

                  <!-- Sick -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 4}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.sickDays }}
                    </div>
                  </div>

                  <!-- Days Off -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 5}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.daysOff }}
                    </div>
                  </div>

                  <!-- STBY Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 6}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.standByDays }}
                    </div>
                  </div>

                  <!-- LOA -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 7}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.loaDays }}
                    </div>
                  </div>

                  <!-- Leave -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 8}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.leaveDays }}
                    </div>
                  </div>

                  <!-- Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 9}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.holidayDays }}
                    </div>
                  </div>

                  <!-- Floating Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 10}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.floatingHolidayDays }}
                    </div>
                  </div>

                  <!-- Mission Hazardous -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 11}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div
                      class="activity-summary font-abel-16px red lighten-1  "
                    >
                      {{ employee.activity.missionHazard }}
                    </div>
                  </div>

                  <!-- COVID Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 12}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.healthRiskDays }}
                    </div>
                  </div>

                  <!-- COVID Allowance -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 13}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div
                      class="activity-summary font-abel-16px red lighten-1  "
                    >
                      {{ employee.activity.healthRiskAllowance }}
                    </div>
                  </div>

                  <!-- Total Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 14}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.totalDays }}
                    </div>
                  </div>

                  <!-- Days Over 20 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 15}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px">
                      {{ employee.activity.daysOver20 }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 1 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 16}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px ">
                      {{ employee.activity.perdiemDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- International Perdiem Period 1 -->
                  <!-- TEMP DISABLED -->
                  <!-- <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 17}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px ">
                      {{ employee.activity.internationalPerdiemDaysFirstHalf }}
                    </div>
                  </div> -->

                  <!-- Domestic Perdiem Period 2 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 17}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px ">
                      {{ employee.activity.perdiemDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- International Perdiem Period 2 -->
                  <!-- TEMP DISABLED -->
                  <!-- <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 19}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px ">
                      {{ employee.activity.internationalPerdiemDaysSecondHalf }}
                    </div>
                  </div> -->

                  <!-- Days Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 18}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px ">
                      {{ employee.activity.daysOverGuarrantee }}
                    </div>
                  </div>

                  <!-- Hours Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 19}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px ">
                      {{ employee.activity.daysOverGuarrantee * 8 }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- TOTALS -->
              <div
                class="bottom-panel-right-row"
                :style="{
                  width: `${panelWidth}px`,
                }"
              >
                <div>
                  <div
                    v-for="(day, index) in monthFilter.days"
                    :key="index"
                    class="bottom-panel-day"
                    :style="{
                      width: `${1440 / scale}px`,
                      left: `${index * (1440 / scale)}px`,
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }"
                  >
                    <div class="font-abel-10px  activity-day">
                      ---
                    </div>
                  </div>

                  <!-- Work days 1 - 15 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale)}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.workDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Work days 16 - End -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.workDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- Work days total -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 2}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div
                      class="activity-summary font-abel-16px-bold grey lighten-2"
                    >
                      {{ summary.workDays }}
                    </div>
                  </div>

                  <!-- Vacation -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 3}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.vacationDays }}
                    </div>
                  </div>

                  <!-- Sick -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 4}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.sickDays }}
                    </div>
                  </div>

                  <!-- Days Off -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 5}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.daysOff }}
                    </div>
                  </div>

                  <!-- STBY Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 6}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.standByDays }}
                    </div>
                  </div>

                  <!-- LOA -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 7}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.loaDays }}
                    </div>
                  </div>

                  <!-- Leave -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 8}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.leaveDays }}
                    </div>
                  </div>

                  <!-- Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 9}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.holidayDays }}
                    </div>
                  </div>

                  <!-- Floating Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 10}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.floatingHolidayDays }}
                    </div>
                  </div>

                  <!-- Mission Hazardous -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 11}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div
                      class="activity-summary font-abel-16px-box red lighten-1"
                    >
                      {{ summary.missionHazard }}
                    </div>
                  </div>

                  <!-- COVID Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 12}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.healthRiskDays }}
                    </div>
                  </div>

                  <!-- COVID Allowance -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 13}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div
                      class="activity-summary font-abel-16px-box red lighten-1  "
                    >
                      {{ summary.healthRiskAllowance }}
                    </div>
                  </div>

                  <!-- Total Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 14}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-box">
                      {{ summary.totalDays }}
                    </div>
                  </div>

                  <!-- Days Over 20 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 15}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold ">
                      {{ summary.daysOver20 }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 1 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 16}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.perdiemDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 2 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 17}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold ">
                      {{ summary.perdiemDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- International Perdiem Period 1 -->
                  <!-- TEMP DISABLED -->
                  <!-- <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 18}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.internationalPerdiemDaysFirstHalf }}
                    </div>
                  </div> -->

                  <!-- International Perdiem Period 2 -->
                  <!-- TEMP DISABLED -->
                  <!-- <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 19}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold ">
                      {{ summary.internationalPerdiemDaysSecondHalf }}
                    </div>
                  </div> -->

                  <!-- Days Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 18}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.daysOverGuarrantee }}
                    </div>
                  </div>

                  <!-- Hours Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale) +
                        columnWidth * 19}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary font-abel-16px-bold">
                      {{ summary.daysOverGuarrantee * 8 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="bottom-frame font-abel-16px">
          <div class="r-frame-rounded-yellow  indigo--text">
            No employees found with the selected filters.
          </div>
        </div>
      </div>
    </div>

    <!-- REPORTS -->
    <div v-if="boardStyle === 'Reports'">
      <div id="top-banner-reports">
        <!-- Search -->
        <div class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="loading"
                @click="handleOpenEmployeeFilter"
                small
                icon
              >
                <i class="fa-solid fa-filter"></i>
              </v-btn>
            </template>
            <span>Filter Employees by Job Title</span>
          </v-tooltip>
          <v-text-field
            v-model="lastName"
            dense
            label="Enter Last Name"
            single-line
            hide-details
            @input="filterEmployees"
          ></v-text-field>
        </div>
        <div class=" indigo--text  ml-10 my-auto">
          {{
            `${formatMonthShort(selectedMonth)} - ${selectedYear} - ${
              this.selectedPeriod
            } period`
          }}
        </div>
      </div>
      <div id="bottom-frame">
        <v-container fluid>
          <v-col cols="12">
            <v-data-table
              dense
              :headers="reportHeaders"
              :items="filteredEmployees"
              :search="search"
              :footer-props="{
                itemsPerPageOptions: [50, 100, 200],
              }"
              @click:row="showEmployeeActivityReport"
              item-key="employee"
            >
              <template v-slot:[`item.employeeReport`]="{ item }">
                <span>{{ formatTimestamp(item.employeeReport) }}</span>
              </template>

              <template v-slot:[`item.managerReport`]="{ item }">
                <span>{{ formatTimestamp(item.managerReport) }}</span>
              </template>

              <template v-slot:[`item.adminReport`]="{ item }">
                <span>{{ formatTimestamp(item.adminReport) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import AutoLogout from "../components/AutoLogout";
import ConfirmDelete from "../components/ConfirmDelete.vue";
import Navbar from "../components/Navbar.vue";
import { json2csv } from "json-2-csv";
import SpecialAirports from "../components/SpecialAirports.vue";
import EmployeeFilter from "../components/EmployeeFilter.vue";

export default {
  name: "reporting",
  components: {
    AutoLogout,
    ConfirmDelete,
    Navbar,
    SpecialAirports,
    EmployeeFilter,
  },
  data() {
    return {
      loading: false,
      employeeFilterDialog: false,
      dayDetailsDialog: false,
      dateData: null,
      boardStyle: "Reports",
      boardStyleOptions: ["Flow Chart", "Reports"],
      selectedBoardStyle: "Reports",
      isMobile: false,
      activityReports: [],
      confirmationDialog: false,
      confirmation: null,
      reportRemarks: "",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      perdiemAdjustment: 0,
      internationalPerdiemAdjustment: 0,
      missionHazardAdjustment: 0,
      healthRiskAdjustment: 0,
      adjustedMissionHazard: 0,
      adjustedHealthRisk: 0,
      search: "",
      activityReportDialog: false,
      confirmed: false,
      reportSummary: null,
      sliderX: 0,
      employees: [],
      employee: null,
      filteredEmployees: [],
      lastName: "",
      scrollX: 0,
      monthFilterDialog: false,
      monthPicker: new Date().toISOString().substring(0, 10),
      selectedMonth: new Date().getUTCMonth(),
      selectedYear: new Date().getUTCFullYear(),
      employeeDialog: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      scale: 60,
      currentTime: "",
      reportColumns: [
        { title: "Work Days 01-15", backgroundColor: "#FFFFFF" },
        { title: "Work Days 16-31", backgroundColor: "#FFFFFF" },
        { title: "Work Days Total", backgroundColor: "#FFFFFF" },
        { title: "VAC", backgroundColor: "#FFFFFF" },
        { title: "SICK", backgroundColor: "#FFFFFF" },
        { title: "OFF", backgroundColor: "#FFFFFF" },
        { title: "STBY", backgroundColor: "#FFFFFF" },
        { title: "LOA", backgroundColor: "#FFFFFF" },
        { title: "LEAVE", backgroundColor: "#FFFFFF" },
        { title: "HOL", backgroundColor: "#FFFFFF" },
        { title: "FH", backgroundColor: "#FFFFFF" },
        { title: "Mission Haz", backgroundColor: "#FFFFFF" },
        { title: "COVID 19 Days", backgroundColor: "#FFFFFF" },
        { title: "COVID 19 Allowance", backgroundColor: "#FFFFFF" },
        { title: "TOTAL DAYS", backgroundColor: "#FFFFFF" },
        { title: "DAYS OVER 20", backgroundColor: "#FFFFFF" },
        { title: "Perdiem 1", backgroundColor: "#FFFFFF" },
        // { title: "Perdiem 1 Intl", backgroundColor: "#FFFFFF" },//TEMP DISABLED
        { title: "Perdiem 2", backgroundColor: "#FFFFFF" },
        // { title: "Perdiem 2 Intl", backgroundColor: "#FFFFFF" },//TEMP DISABLED
        { title: "Days Over Guarrantee", backgroundColor: "#FFFFFF" },
        { title: "Hours Over Guarrantee", backgroundColor: "#FFFFFF" },
      ],
      columnWidth: 45,
      specialAirportsDialog: false,
      specialAiportsHeaders: [
        {
          text: "Category",
          align: "start",
          sortable: true,
          value: "category",
        },
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "code",
        },
      ],
      reportHeaders: [
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "surname",
        },
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "givenName",
        },
        {
          text: "Job Title",
          align: "start",
          sortable: true,
          value: "jobTitle",
        },
        {
          text: "Company",
          align: "start",
          sortable: true,
          value: "companyName",
        },
        {
          text: "Employee ID",
          align: "start",
          sortable: true,
          value: "employeeId",
        },
        {
          text: "Employee Confirmation",
          align: "start",
          sortable: true,
          value: "employeeReport",
        },
        {
          text: "Manager Confirmation",
          align: "start",
          sortable: true,
          value: "managerReport",
        },
        {
          text: "Administrator Confirmation",
          align: "start",
          sortable: true,
          value: "adminReport",
        },
      ],
      summary: null,
      selectedPeriod: "Second",
      periodOptions: ["First", "Second"],
      employeeReports: [],
      selectedReport: null,
      assignmentHeaders: [
        {
          sortable: false,
          text: "Assignment",
          align: "start",
          value: "_id",
        },
        {
          sortable: false,
          text: "Start",
          align: "center",
          value: "startTime",
        },
        {
          sortable: false,
          text: "End",
          align: "center",
          value: "endTime",
        },

        {
          sortable: true,
          text: "Assignment Type",
          align: "start",
          value: "type",
        },
        {
          sortable: true,
          text: "Assigned By",
          align: "start",
          value: "assignedBy",
        },
      ],
      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
    };
  },
  mixins: [mixin],
  created() {
    this.selectedCompany = this.user.securityGroup.companies[0];
    this.selectedBoardType = this.board.type;
    this.getData();
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },
    specialAirports() {
      let airports = [];

      this.company.specialAirports.hazardous.forEach((code) => {
        airports.push({
          category: "Hazardous",
          code,
        });
      });

      this.company.specialAirports.unhealthy.forEach((code) => {
        airports.push({
          category: "COVID 19",
          code,
        });
      });

      return airports.sort(this.compareSpecialAiports);
    },

    monthFilter() {
      const filterStart = new Date(
        Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0, 0)
      ).getTime();
      const numberOfDays = new Date(
        this.selectedYear,
        this.selectedMonth + 1,
        0
      ).getDate();

      let days = [];

      const fullDay = 24 * 60 * 60 * 1000; // 24 Hours
      const endOfDay = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

      for (let i = 0; i < numberOfDays; i++) {
        if (i <= numberOfDays - 2) {
          days.push(new Date(filterStart + i * fullDay).toISOString());
        } else {
          days.push(
            new Date(filterStart + (i * fullDay + endOfDay)).toISOString()
          );
        }
      }

      const filter = {
        start: days[0],
        end: days[days.length - 1],
        startDate: days[0].substring(0, 10),
        endDate: days[days.length - 1].substring(0, 10),
        days,
      };

      return filter;
    },

    secondPeriod() {
      return `16 - ${this.monthFilter.days.length}`;
    },
    company() {
      return this.$store.getters.company;
    },

    board() {
      return this.$store.getters.board;
    },
    panelWidth() {
      return (
        this.monthFilter.days.length * (1440 / this.scale) +
        this.reportColumns.length * this.columnWidth
      );
    },
    panelHeight() {
      return (this.filteredEmployees.length + 1) * 60 + 50;
    },

    topPanelRight() {
      return this.$refs["topPanelRight"];
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    adjustedPerdiemDaysSecondHalf() {
      //DOMESTIC PERDIEM
      let t = 0;

      if (!isNaN(this.perdiemAdjustment)) {
        t = parseInt(this.perdiemAdjustment);
      }

      if (isNaN(t)) {
        return parseInt(this.employee.activity.perdiemDaysSecondHalf);
      } else {
        return (
          parseInt(t) + parseInt(this.employee.activity.perdiemDaysSecondHalf)
        );
      }
    },

    adjustedInternationalPerdiemDaysSecondHalf() {
      //INTERNATIONAL PERDIEM
      let t = 0;

      if (!isNaN(this.internationalPerdiemAdjustment)) {
        t = parseInt(this.internationalPerdiemAdjustment);
      }

      if (isNaN(t)) {
        return parseInt(
          this.employee.activity.internationalPerdiemDaysSecondHalf
        );
      } else {
        return (
          parseInt(t) +
          parseInt(this.employee.activity.internationalPerdiemDaysSecondHalf)
        );
      }
    },

    adjustedDaysOverGuarrantee() {
      let v1 = 0;
      let v2 = 0;
      let t = 0;

      if (!isNaN(this.missionHazardAdjustment)) {
        v1 = parseInt(this.missionHazardAdjustment);
      }

      if (!isNaN(this.healthRiskAdjustment)) {
        v2 = parseFloat(this.healthRiskAdjustment);
      }

      t = v1 + v2;

      if (isNaN(t)) {
        return parseFloat(this.employee.activity.daysOverGuarrantee);
      } else {
        return (
          parseFloat(t) + parseFloat(this.employee.activity.daysOverGuarrantee)
        );
      }
    },
  },

  methods: {
    //----------------------Employee Filter
    handleOpenEmployeeFilter() {
      this.employeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.employeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.employeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getData();
    },
    handleChangeBoardType(boardType) {
      let board = { ...this.board };
      board.type = boardType;
      this.$store.commit("updateBoard", board);
      this.getData();
    },
    saveBoardStatusAndPrintActivityReport(employee) {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
      };

      this.$store.commit("updateBoard", board);

      this.printActivityReport(employee);
    },
    showdayDetails(employee, day) {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
      };

      this.$store.commit("updateBoard", board);

      this.dateData = {
        employee: { ...employee },
        date: day.date.slice(),
        ...day.data,
      };

      this.dayDetailsDialog = true;
    },

    formatAssignmentSnapshot(assignment) {
      let snapshot;

      if (assignment.type === "Ground") {
        switch (assignment.category) {
          case "Station Assignment":
            snapshot = ` ${assignment.category} . ${assignment.subCategory} . ${assignment.group.stationIata}`;
            break;
          case "Special Assignment":
            snapshot = ` ${assignment.category}  . ${assignment.originIata}`;

            break;
          case "Leave":
          case "LOA":
            snapshot = ` ${assignment.category} . ${assignment.subCategory}`;
            break;

          case "Layover":
            snapshot = ` ${assignment.category} . ${assignment.originIata}`;
            break;

          case "Quarantine":
            snapshot = ` ${assignment.category} . ${assignment.originIata}`;
            break;

          case "Sick":
            snapshot = ` ${assignment.category} . ${assignment.originIata}`;
            break;
          case "Training":
            snapshot = ` ${assignment.category} . ${assignment.subCategory} . ${assignment.originIata}`;
            break;
          case "Travel":
            snapshot = ` ${assignment.category} . ${assignment.originIata} - ${assignment.destinationIata}`;
            break;

          default:
            snapshot = ` ${assignment.category}`;

            break;
        }
      } else {
        snapshot = `${assignment.flight.flightNumber} . ${assignment.flight.aircraftRegistration} . ${assignment.flight.originIata}-${assignment.flight.destinationIata}`;
      }

      return snapshot;
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    async deleteActivityReport(_id) {
      this.confirmationDialog = false;
      this.activityReportDialog = false;

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(`/reports/monthly-activity/${_id}`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.activityReport = null;
        this.loading = false;
        this.getData();
      } catch (error) {
        this.activityReport = null;
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async getData() {
      this.loading = true;

      this.employees = [];
      this.filteredEmployees = [];
      let res;

      try {
        const token = await aad.getIdTokenSilent();

        //--------------------------------Get Assignments
        res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            filterStart: this.monthFilter.start,
            filterEnd: this.monthFilter.end,
          },
        });

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        let employees = res.data.employees;

        const allAssignments = res.data.assignments;

        let assignments = [];

        //-----------------------------Get Reports
        res = await api.get(`/reports/monthly-activity/`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            year: this.selectedYear,
            month: this.selectedMonth,
            period: this.selectedPeriod,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        const activityReports = res.data.activityReports;
        let employeeReport;
        let managerReport;
        let adminReport;

        employees.forEach((employee) => {
          employeeReport = activityReports.find((activityReport) => {
            return (
              activityReport.employee._id === employee._id &&
              activityReport.confirmation.level === "Employee"
            );
          });
          managerReport = activityReports.find((activityReport) => {
            return (
              activityReport.employee._id === employee._id &&
              activityReport.confirmation.level === "Manager"
            );
          });
          adminReport = activityReports.find((activityReport) => {
            return (
              activityReport.employee._id === employee._id &&
              activityReport.confirmation.level === "Administrator"
            );
          });

          assignments = allAssignments.filter((item) => {
            if (item.employee) {
              return item.employee._id === employee._id;
            }
          });

          employee.assignments = this.mergeAssignments([...assignments]);
          employee.activity = this.getEmployeeActivity(employee);
          employee.employeeReport = employeeReport;
          employee.managerReport = managerReport;
          employee.adminReport = adminReport;
        });

        this.employees = [...employees];

        //------------------------------Filter Employees

        //PERDIEM
        this.filterEmployees();

        let summary = {
          numberOfEmployees: 0,
          daysInThisMonth: this.monthFilter.days.length,
          daysOff: 0,
          standByDays: 0,
          daysOver20: 0,
          daysOverGuarrantee: 0,
          floatingHolidayDays: 0,
          healthRiskAllowance: 0,
          healthRiskDays: 0,
          holidayDays: 0,
          leaveDays: 0,
          loaDays: 0,
          missionHazard: 0,
          perdiemDays: 0,
          perdiemDaysFirstHalf: 0,
          perdiemDaysSecondHalf: 0,
          internationalPerdiemDays: 0,
          internationalPerdiemDaysFirstHalf: 0,
          internationalPerdiemDaysSecondHalf: 0,
          sickDays: 0,
          totalDays: 0,
          vacationDays: 0,
          workDays: 0,
          workDaysFirstHalf: 0,
          workDaysSecondHalf: 0,
        };

        this.filteredEmployees.forEach(({ activity }) => {
          summary.numberOfEmployees++;
          summary.daysOff += activity.daysOff;
          summary.standByDays += activity.standByDays;
          summary.daysOver20 += activity.daysOver20;
          summary.daysOverGuarrantee += activity.daysOverGuarrantee;
          summary.floatingHolidayDays += activity.floatingHolidayDays;
          summary.healthRiskAllowance += activity.healthRiskAllowance;
          summary.healthRiskDays += activity.healthRiskDays;
          summary.holidayDays += activity.holidayDays;
          summary.leaveDays += activity.leaveDays;
          summary.loaDays += activity.loaDays;
          summary.missionHazard += activity.missionHazard;
          summary.perdiemDays += activity.perdiemDays;
          summary.perdiemDaysFirstHalf += activity.perdiemDaysFirstHalf;
          summary.perdiemDaysSecondHalf += activity.perdiemDaysSecondHalf;
          summary.internationalPerdiemDays += activity.internationalPerdiemDays;
          summary.internationalPerdiemDaysFirstHalf +=
            activity.internationalPerdiemDaysFirstHalf;
          summary.internationalPerdiemDaysSecondHalf +=
            activity.internationalPerdiemDaysSecondHalf;
          summary.sickDays += activity.sickDays;
          summary.totalDays += activity.totalDays;
          summary.vacationDays += activity.vacationDays;
          summary.workDays += activity.workDays;
          summary.workDaysFirstHalf += activity.workDaysFirstHalf;
          summary.workDaysSecondHalf += activity.workDaysSecondHalf;
        });

        this.summary = summary;

        if (this.boardStyle === "Flow Chart") {
          this.$nextTick(() => {
            this.scrollX = 0;
            this.sliderX = 0;
          });
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    getFirstPeriodReports() {
      this.selectedPeriod = "First";
      this.getData();
    },

    getSecondPeriodReports() {
      this.selectedPeriod = "Second";
      this.getData();
    },

    downloadCsvFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    getFlowChartCsv() {
      const employees = [...this.filteredEmployees];

      if (!employees.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No employees selected";
        return (this.snackbarVisible = true);
      }

      let csvRows = [];
      let csvRow;

      //PERDIEM

      let WorkDays01_15_Total = 0;
      let WorkDays16_31_Total = 0;
      let WorkDaysTotal_Total = 0;
      let VAC_Total = 0;
      let SICK_Total = 0;
      let OFF_Total = 0;
      let STBY_Total = 0;
      let LOA_Total = 0;
      let LEAVE_Total = 0;
      let HOL_Total = 0;
      let FH_Total = 0;
      let MissionHaz_Total = 0;
      let COVID19Days_Total = 0;
      let COVID19Allowance_Total = 0;
      let TOTALDAYS_Total = 0;
      let DAYSOVER20_Total = 0;
      let PerdiemPeriod1_Total = 0;
      let PerdiemPeriod2_Total = 0;
      let InternationalPerdiemPeriod1_Total = 0;
      let InternationalPerdiemPeriod2_Total = 0;
      let DaysOverGuarrantee_Total = 0;
      let HoursOverGuarrantee_Total = 0;

      employees.forEach((employee) => {
        WorkDays01_15_Total += employee.activity.workDaysFirstHalf;
        WorkDays16_31_Total += employee.activity.workDaysSecondHalf;
        WorkDaysTotal_Total += employee.activity.workDays;
        VAC_Total += employee.activity.vacationDays;
        SICK_Total += employee.activity.sickDays;
        OFF_Total += employee.activity.daysOff;
        STBY_Total += employee.activity.standByDays;
        LOA_Total += employee.activity.loaDays;
        LEAVE_Total += employee.activity.leaveDays;
        HOL_Total += employee.activity.holidayDays;
        FH_Total += employee.activity.floatingHolidayDays;
        MissionHaz_Total += employee.activity.missionHazard;
        COVID19Days_Total += employee.activity.healthRiskDays;
        COVID19Allowance_Total += employee.activity.healthRiskAllowance;
        TOTALDAYS_Total += employee.activity.totalDays;
        DAYSOVER20_Total += employee.activity.daysOver20;
        PerdiemPeriod1_Total += employee.activity.perdiemDaysFirstHalf;
        PerdiemPeriod2_Total += employee.activity.perdiemDaysSecondHalf;
        InternationalPerdiemPeriod1_Total +=
          employee.activity.internationalPerdiemDaysFirstHalf;
        InternationalPerdiemPeriod2_Total +=
          employee.activity.internationalPerdiemDaysSecondHalf;
        DaysOverGuarrantee_Total += employee.activity.daysOverGuarrantee;
        HoursOverGuarrantee_Total += employee.activity.daysOverGuarrantee * 8;

        csvRow = {
          Employee: `${employee.surname}, ${employee.givenName} (${employee.gatewayCode})`,
          Gateway_EmployeeID: `${employee.gatewayCode} - ${employee.employeeId}`,
        };

        for (let i = 0; i < 28; i++) {
          switch (i) {
            case 0:
              csvRow.Day_01 = employee.activity.days[i].data.displayCode;
              break;

            case 1:
              csvRow.Day_02 = employee.activity.days[i].data.displayCode;
              break;

            case 2:
              csvRow.Day_03 = employee.activity.days[i].data.displayCode;
              break;
            case 3:
              csvRow.Day_04 = employee.activity.days[i].data.displayCode;
              break;
            case 4:
              csvRow.Day_05 = employee.activity.days[i].data.displayCode;
              break;
            case 5:
              csvRow.Day_06 = employee.activity.days[i].data.displayCode;
              break;
            case 6:
              csvRow.Day_07 = employee.activity.days[i].data.displayCode;
              break;
            case 7:
              csvRow.Day_08 = employee.activity.days[i].data.displayCode;
              break;
            case 8:
              csvRow.Day_09 = employee.activity.days[i].data.displayCode;
              break;
            case 9:
              csvRow.Day_10 = employee.activity.days[i].data.displayCode;
              break;
            case 10:
              csvRow.Day_11 = employee.activity.days[i].data.displayCode;
              break;
            case 11:
              csvRow.Day_12 = employee.activity.days[i].data.displayCode;
              break;

            case 12:
              csvRow.Day_13 = employee.activity.days[i].data.displayCode;
              break;
            case 13:
              csvRow.Day_14 = employee.activity.days[i].data.displayCode;
              break;
            case 14:
              csvRow.Day_15 = employee.activity.days[i].data.displayCode;
              break;
            case 15:
              csvRow.Day_16 = employee.activity.days[i].data.displayCode;
              break;
            case 16:
              csvRow.Day_17 = employee.activity.days[i].data.displayCode;
              break;
            case 17:
              csvRow.Day_18 = employee.activity.days[i].data.displayCode;
              break;
            case 18:
              csvRow.Day_19 = employee.activity.days[i].data.displayCode;
              break;
            case 19:
              csvRow.Day_20 = employee.activity.days[i].data.displayCode;
              break;
            case 20:
              csvRow.Day_21 = employee.activity.days[i].data.displayCode;
              break;

            case 21:
              csvRow.Day_22 = employee.activity.days[i].data.displayCode;
              break;

            case 22:
              csvRow.Day_23 = employee.activity.days[i].data.displayCode;
              break;
            case 23:
              csvRow.Day_24 = employee.activity.days[i].data.displayCode;
              break;
            case 24:
              csvRow.Day_25 = employee.activity.days[i].data.displayCode;
              break;
            case 25:
              csvRow.Day_26 = employee.activity.days[i].data.displayCode;
              break;
            case 26:
              csvRow.Day_27 = employee.activity.days[i].data.displayCode;
              break;
            case 27:
              csvRow.Day_28 = employee.activity.days[i].data.displayCode;
              break;

            default:
              break;
          }
        }

        switch (employee.activity.days.length) {
          case 29:
            csvRow.Day_29 = employee.activity.days[28].data.displayCode;
            break;

          case 30:
            csvRow.Day_29 = employee.activity.days[28].data.displayCode;
            csvRow.Day_30 = employee.activity.days[29].data.displayCode;

            break;

          case 31:
            csvRow.Day_29 = employee.activity.days[28].data.displayCode;
            csvRow.Day_30 = employee.activity.days[29].data.displayCode;
            csvRow.Day_31 = employee.activity.days[30].data.displayCode;
            break;

          default:
            break;
        }

        csvRow.WorkDays01_15 = employee.activity.workDaysFirstHalf;
        csvRow.WorkDays16_31 = employee.activity.workDaysSecondHalf;
        csvRow.WorkDaysTotal = employee.activity.workDays;
        csvRow.VAC = employee.activity.vacationDays;
        csvRow.SICK = employee.activity.sickDays;
        csvRow.OFF = employee.activity.daysOff;
        csvRow.STBY = employee.activity.standByDays;
        csvRow.LOA = employee.activity.loaDays;
        csvRow.LEAVE = employee.activity.leaveDays;
        csvRow.HOL = employee.activity.holidayDays;
        csvRow.FH = employee.activity.floatingHolidayDays;
        csvRow.MissionHaz = employee.activity.missionHazard;
        csvRow.COVID19Days = employee.activity.healthRiskDays;
        csvRow.COVID19Allowance = employee.activity.healthRiskAllowance;
        csvRow.TOTALDAYS = employee.activity.totalDays;
        csvRow.DAYSOVER20 = employee.activity.daysOver20;
        csvRow.PerdiemPeriod1 = employee.activity.perdiemDaysFirstHalf;
        csvRow.PerdiemPeriod2 = employee.activity.perdiemDaysSecondHalf;
        csvRow.InternationalPerdiemPeriod1 =
          employee.activity.internationalPerdiemDaysFirstHalf;
        csvRow.InternationalPerdiemPeriod2 =
          employee.activity.internationalPerdiemDaysSecondHalf;
        csvRow.DaysOverGuarrantee = employee.activity.daysOverGuarrantee;
        csvRow.HoursOverGuarrantee = employee.activity.daysOverGuarrantee * 8;

        csvRows.push(csvRow);
      });

      csvRow = {
        Employee: "Totals",
        Gateway_EmployeeID: " ",
        Day_01: " ",
        Day_02: " ",
        Day_03: " ",
        Day_04: " ",
        Day_05: " ",
        Day_06: " ",
        Day_07: " ",
        Day_08: " ",
        Day_09: " ",
        Day_10: " ",
        Day_11: " ",
        Day_12: " ",
        Day_13: " ",
        Day_14: " ",
        Day_15: " ",
        Day_16: " ",
        Day_17: " ",
        Day_18: " ",
        Day_19: " ",
        Day_20: " ",
        Day_21: " ",
        Day_22: " ",
        Day_23: " ",
        Day_24: " ",
        Day_25: " ",
        Day_26: " ",
        Day_27: " ",
        Day_28: " ",
      };

      switch (this.monthFilter.days.length) {
        case 29:
          csvRow.Day_29 = " ";
          break;

        case 30:
          csvRow.Day_29 = " ";
          csvRow.Day_30 = " ";

          break;

        case 31:
          csvRow.Day_29 = " ";
          csvRow.Day_30 = " ";
          csvRow.Day_31 = " ";
          break;

        default:
          break;
      }

      csvRow.WorkDays01_15 = WorkDays01_15_Total;
      csvRow.WorkDays16_31 = WorkDays16_31_Total;
      csvRow.WorkDaysTotal = WorkDaysTotal_Total;
      csvRow.VAC = VAC_Total;
      csvRow.SICK = SICK_Total;
      csvRow.OFF = OFF_Total;
      csvRow.STBY = STBY_Total;
      csvRow.LOA = LOA_Total;
      csvRow.LEAVE = LEAVE_Total;
      csvRow.HOL = HOL_Total;
      csvRow.FH = FH_Total;
      csvRow.MissionHaz = MissionHaz_Total;
      csvRow.COVID19Days = COVID19Days_Total;
      csvRow.COVID19Allowance = COVID19Allowance_Total;
      csvRow.TOTALDAYS = TOTALDAYS_Total;
      csvRow.DAYSOVER20 = DAYSOVER20_Total;
      csvRow.PerdiemPeriod1 = PerdiemPeriod1_Total;
      csvRow.PerdiemPeriod2 = PerdiemPeriod2_Total;
      csvRow.InternationalPerdiemPeriod1 = InternationalPerdiemPeriod1_Total;
      csvRow.InternationalPerdiemPeriod2 = InternationalPerdiemPeriod2_Total;
      csvRow.DaysOverGuarrantee = DaysOverGuarrantee_Total;
      csvRow.HoursOverGuarrantee = HoursOverGuarrantee_Total;

      csvRows.push(csvRow);

      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ",", // Comma field delimiter
          eol: "\n", // Newline delimiter
        },
        trimHeaderFields: true,
        trimFieldValues: true,
        keys: [
          "Employee",
          "Gateway_EmployeeID",
          "Day_01",
          "Day_02",
          "Day_03",
          "Day_04",
          "Day_05",
          "Day_06",
          "Day_07",
          "Day_08",
          "Day_09",
          "Day_10",
          "Day_11",
          "Day_12",
          "Day_13",
          "Day_14",
          "Day_15",
          "Day_16",
          "Day_17",
          "Day_18",
          "Day_19",
          "Day_20",
          "Day_21",
          "Day_22",
          "Day_23",
          "Day_24",
          "Day_25",
          "Day_26",
          "Day_27",
          "Day_28",
        ],
      };

      switch (this.monthFilter.days.length) {
        case 29:
          options.keys.push("Day_29");
          break;

        case 30:
          options.keys.push("Day_29");
          options.keys.push("Day_30");

          break;

        case 31:
          options.keys.push("Day_29");
          options.keys.push("Day_30");
          options.keys.push("Day_31");
          break;

        default:
          break;
      }

      options.keys.push("WorkDays01_15");
      options.keys.push("WorkDays16_31");
      options.keys.push("WorkDaysTotal");
      options.keys.push("VAC");
      options.keys.push("SICK");
      options.keys.push("OFF");
      options.keys.push("STBY");
      options.keys.push("LOA");
      options.keys.push("LEAVE");
      options.keys.push("HOL");
      options.keys.push("FH");
      options.keys.push("MissionHaz");
      options.keys.push("COVID19Days");
      options.keys.push("COVID19Allowance");
      options.keys.push("TOTALDAYS");
      options.keys.push("DAYSOVER20");
      options.keys.push("PerdiemPeriod1");
      options.keys.push("PerdiemPeriod2");
      options.keys.push("InternationalPerdiemPeriod1");
      options.keys.push("InternationalPerdiemPeriod2");
      options.keys.push("DaysOverGuarrantee");
      options.keys.push("HoursOverGuarrantee");

      json2csv(
        csvRows,
        (error, csv) => {
          if (error) {
            this.snackbarColor = "#F44336";
            this.snackbarText = error;
            this.snackbarVisible = true;
          } else {
            this.downloadCsvFile(csv);
          }
        },
        options
      );
    },

    getLeadPSRsCsv() {
      const jobTitles = ["PSR", "Check PSR", "Lead PSR", "Senior PSR"];

      const spsrs = this.filteredEmployees.filter((employee) => {
        return jobTitles.includes(employee.jobTitle);
      });

      if (!spsrs.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No SPRs found in selected employees";
        return (this.snackbarVisible = true);
      }

      let reports = [];
      let report;
      let hours3Code;
      let hours3Amount;
      let adjustDedCode;
      let adjustDedAmount;
      // let intAdjustDedCode;// TEMP DISABLED
      // let intAdjustDedAmount;// TEMP DISABLED

      //PERDIEM

      spsrs.forEach((employee) => {
        if (employee.adminReport) {
          if (this.selectedPeriod === "Second") {
            if (employee.adminReport.daysOverGuarrantee > 0) {
              hours3Code = "129";
              hours3Amount = employee.adminReport.daysOverGuarrantee * 8;
            } else {
              hours3Code = " ";
              hours3Amount = " ";
            }

            if (employee.adminReport.perdiemDaysSecondHalf > 0) {
              //Domestic Perdiem
              adjustDedCode = "301";
              adjustDedAmount =
                (employee.adminReport.perdiemDaysSecondHalf +
                  employee.adminReport.perdiemAdjustment) *
                60 *
                -1;
              //International Perdiem
              // TEMP DISABLED
              // intAdjustDedCode = "302";
              // intAdjustDedAmount =
              //   (employee.adminReport.internationalPerdiemDaysSecondHalf +
              //     employee.adminReport.internationalPerdiemAdjustment) *
              //   60 *
              //   -1;
            } else {
              adjustDedCode = " ";
              adjustDedAmount = " ";
              // intAdjustDedCode = " ";// TEMP DISABLED
              // intAdjustDedAmount = " ";// TEMP DISABLED
            }
          } else {
            hours3Code = " ";
            hours3Amount = " ";

            if (employee.adminReport.perdiemDaysFirstHalf > 0) {
              //Domestic Perdiem
              adjustDedCode = "301";
              adjustDedAmount =
                (employee.adminReport.perdiemDaysFirstHalf +
                  employee.adminReport.perdiemAdjustment) *
                60 *
                -1;
              //International Perdiem
              // TEMP DISABLED
              // intAdjustDedCode = "302";
              // intAdjustDedAmount =
              //   (employee.adminReport.InternationalPerdiemDaysSecondHalf +
              //     employee.adminReport.internationalPerdiemAdjustment) *
              //   60 *
              //   -1;
            } else {
              adjustDedCode = " ";
              adjustDedAmount = " ";
              // intAdjustDedCode = " ";// TEMP DISABLED
              // intAdjustDedAmount = " ";// TEMP DISABLED
            }
          }

          report = {
            CoCode: "K6J",
            BatchID: "LPSR-LS",
            FileNumber: employee.employeeId,
            Hours3Code: hours3Code,
            Hours3Amount: hours3Amount,
            AdjustDedCode: adjustDedCode,
            AdjustDedAmount: adjustDedAmount,
            // IntAdjustDedCode: intAdjustDedCode,// TEMP DISABLED
            // IntAdjustDedAmount: intAdjustDedAmount,// TEMP DISABLED
          };

          reports.push(report);
        }
      });

      if (!reports.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No submited reports found in selected employees";
        return (this.snackbarVisible = true);
      }

      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ",", // Comma field delimiter
          eol: "\n", // Newline delimiter
        },
        trimHeaderFields: true,
        trimFieldValues: true,
        keys: [
          "CoCode",
          "BatchID",
          "FileNumber",
          "Hours3Code",
          "Hours3Amount",
          "AdjustDedCode",
          "AdjustDedAmount",
          "IntAdjustDedCode",
          "IntAdjustDedAmount",
        ],
      };

      json2csv(
        reports,
        (error, csv) => {
          if (error) {
            this.snackbarColor = "#F44336";
            this.snackbarText = error;
            this.snackbarVisible = true;
          } else {
            this.downloadCsvFile(csv);
          }
        },
        options
      );
    },

    getLoadmastersCsv() {
      const jobTitles = ["Loadmaster", "Check Loadmaster", "Senior Loadmaster"];

      const loadmasters = this.filteredEmployees.filter((employee) => {
        return jobTitles.includes(employee.jobTitle);
      });

      if (!loadmasters.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No Loadmasters found in selected employees";
        return (this.snackbarVisible = true);
      }

      let reports = [];
      let report;
      let hours3Code;
      let hours3Amount;
      let adjustDedCode;
      let adjustDedAmount;
      // let intAdjustDedCode;
      // let intAdjustDedAmount;

      loadmasters.forEach((employee) => {
        if (employee.adminReport) {
          if (this.selectedPeriod === "Second") {
            if (employee.adminReport.daysOverGuarrantee > 0) {
              hours3Code = "129";
              hours3Amount = employee.adminReport.daysOverGuarrantee * 8;
            } else {
              hours3Code = " ";
              hours3Amount = " ";
            }

            if (employee.adminReport.perdiemDaysSecondHalf > 0) {
              //Domestic Perdiem
              adjustDedCode = "301";
              adjustDedAmount =
                (employee.adminReport.perdiemDaysSecondHalf +
                  employee.adminReport.perdiemAdjustment) *
                60 *
                -1;
              //International Perdiem
              // TEMP DISABLED
              // intAdjustDedCode = "302";
              // intAdjustDedAmount =
              //   (employee.adminReport.internationalPerdiemDaysSecondHalf +
              //     employee.adminReport.internationalPerdiemAdjustment) *
              //   60 *
              //   -1;
            } else {
              adjustDedCode = " ";
              adjustDedAmount = " ";
              // intAdjustDedCode = " ";// TEMP DISABLED
              // intAdjustDedAmount = " ";// TEMP DISABLED
            }
          } else {
            hours3Code = " ";
            hours3Amount = " ";

            if (employee.adminReport.perdiemDaysFirstHalf > 0) {
              //Domestic Perdiem
              adjustDedCode = "301";
              adjustDedAmount =
                (employee.adminReport.perdiemDaysFirstHalf +
                  employee.adminReport.perdiemAdjustment) *
                60 *
                -1;
              //International Perdiem
              // TEMP DISABLED
              // intAdjustDedCode = "302";
              // intAdjustDedAmount =
              //   (employee.adminReport.internationalPerdiemDaysSecondHalf +
              //     employee.adminReport.internationalPerdiemAdjustment) *
              //   60 *
              //   -1;
            } else {
              adjustDedCode = " ";
              adjustDedAmount = " ";
              // intAdjustDedCode = " ";// TEMP DISABLED
              // intAdjustDedAmount = " ";// TEMP DISABLED
            }
          }

          report = {
            CoCode: "K6J",
            BatchID: "LMPay-LS",
            FileNumber: employee.employeeId,
            Hours3Code: hours3Code,
            Hours3Amount: hours3Amount,
            AdjustDedCode: adjustDedCode,
            AdjustDedAmount: adjustDedAmount,
            // IntAdjustDedCode: intAdjustDedCode,// TEMP DISABLED
            // IntAdjustDedAmount: intAdjustDedAmount,// TEMP DISABLED
          };

          reports.push(report);
        }
      });

      if (!reports.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No submited reports found in selected employees";
        return (this.snackbarVisible = true);
      }

      let options = {
        delimiter: {
          wrap: '"', // Double Quote (") character
          field: ",", // Comma field delimiter
          eol: "\n", // Newline delimiter
        },
        trimHeaderFields: true,
        trimFieldValues: true,
        keys: [
          "CoCode",
          "BatchID",
          "FileNumber",
          "Hours3Code",
          "Hours3Amount",
          "AdjustDedCode",
          "AdjustDedAmount",
          // "IntAdjustDedCode",// TEMP DISABLED
          // "IntAdjustDedAmount",// TEMP DISABLED
        ],
      };

      json2csv(
        reports,
        (error, csv) => {
          if (error) {
            this.snackbarColor = "#F44336";
            this.snackbarText = error;
            this.snackbarVisible = true;
          } else {
            this.downloadCsvFile(csv);
          }
        },
        options
      );
    },

    async getLoadmastersYearlyCsv() {
      const jobTitles = ["Loadmaster", "Check Loadmaster", "Senior Loadmaster"];

      const loadmasters = this.filteredEmployees.filter((employee) => {
        return jobTitles.includes(employee.jobTitle);
      });

      if (!loadmasters.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No Loadmasters found in selected employees";
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        //-----------------------------Get Reports
        const res = await api.get(`/reports/yearly-activity/`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            year: this.selectedYear,
          },
        });

        this.loading = false;

        const activityReports = res.data.activityReports;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText =
            "An error ocurred while trying to get the reports";
          this.snackbarVisible = true;
          return;
        }

        loadmasters.forEach((employee) => {
          employee.yearReports = activityReports.filter((report) => {
            return report.employee === employee._id;
          });
        });

        let report;
        let csvRows = [];
        let csvRow;
        let Total_MissionHazard;
        let Total_COVID19Allowance;

        let JAN_Total_MissionHazard = 0;
        let JAN_Total_COVID19Allowance = 0;

        let FEB_Total_MissionHazard = 0;
        let FEB_Total_COVID19Allowance = 0;

        let MAR_Total_MissionHazard = 0;
        let MAR_Total_COVID19Allowance = 0;

        let APR_Total_MissionHazard = 0;
        let APR_Total_COVID19Allowance = 0;

        let MAY_Total_MissionHazard = 0;
        let MAY_Total_COVID19Allowance = 0;

        let JUN_Total_MissionHazard = 0;
        let JUN_Total_COVID19Allowance = 0;

        let JUL_Total_MissionHazard = 0;
        let JUL_Total_COVID19Allowance = 0;

        let AUG_Total_MissionHazard = 0;
        let AUG_Total_COVID19Allowance = 0;

        let SEP_Total_MissionHazard = 0;
        let SEP_Total_COVID19Allowance = 0;

        let OCT_Total_MissionHazard = 0;
        let OCT_Total_COVID19Allowance = 0;

        let NOV_Total_MissionHazard = 0;
        let NOV_Total_COVID19Allowance = 0;

        let DEC_Total_MissionHazard = 0;
        let DEC_Total_COVID19Allowance = 0;

        let YEAR_Total_MissionHazard = 0;
        let YEAR_Total_COVID19Allowance = 0;

        loadmasters.forEach((employee) => {
          Total_MissionHazard = 0;
          Total_COVID19Allowance = 0;

          csvRow = {
            Employee: `${employee.surname}, ${employee.givenName} (${employee.gatewayCode})`,
            EmployeeID: employee.employeeId,
          };

          for (let m = 0; m < 12; m++) {
            report = employee.yearReports.find((r) => {
              return r.month === m;
            });

            if (report) {
              YEAR_Total_MissionHazard += report.missionHazard;
              YEAR_Total_COVID19Allowance += report.healthRiskAllowance;

              switch (m) {
                case 0:
                  csvRow.JAN_MissionHazard = report.missionHazard;
                  csvRow.JAN_COVID19Allowance = report.healthRiskAllowance;

                  JAN_Total_MissionHazard += report.missionHazard;
                  JAN_Total_COVID19Allowance += report.healthRiskAllowance;

                  break;
                case 1:
                  csvRow.FEB_MissionHazard = report.missionHazard;
                  csvRow.FEB_COVID19Allowance = report.healthRiskAllowance;

                  FEB_Total_MissionHazard += report.missionHazard;
                  FEB_Total_COVID19Allowance += report.healthRiskAllowance;

                  break;
                case 2:
                  csvRow.MAR_MissionHazard = report.missionHazard;
                  csvRow.MAR_COVID19Allowance = report.healthRiskAllowance;

                  MAR_Total_MissionHazard += report.missionHazard;
                  MAR_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 3:
                  csvRow.APR_MissionHazard = report.missionHazard;
                  csvRow.APR_COVID19Allowance = report.healthRiskAllowance;

                  APR_Total_MissionHazard += report.missionHazard;
                  APR_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 4:
                  csvRow.MAY_MissionHazard = report.missionHazard;
                  csvRow.MAY_COVID19Allowance = report.healthRiskAllowance;

                  MAY_Total_MissionHazard += report.missionHazard;
                  MAY_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 5:
                  csvRow.JUN_MissionHazard = report.missionHazard;
                  csvRow.JUN_COVID19Allowance = report.healthRiskAllowance;

                  JUN_Total_MissionHazard += report.missionHazard;
                  JUN_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 6:
                  csvRow.JUL_MissionHazard = report.missionHazard;
                  csvRow.JUL_COVID19Allowance = report.healthRiskAllowance;

                  JUL_Total_MissionHazard += report.missionHazard;
                  JUL_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 7:
                  csvRow.AUG_MissionHazard = report.missionHazard;
                  csvRow.AUG_COVID19Allowance = report.healthRiskAllowance;

                  AUG_Total_MissionHazard += report.missionHazard;
                  AUG_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 8:
                  csvRow.SEP_MissionHazard = report.missionHazard;
                  csvRow.SEP_COVID19Allowance = report.healthRiskAllowance;

                  SEP_Total_MissionHazard += report.missionHazard;
                  SEP_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 9:
                  csvRow.OCT_MissionHazard = report.missionHazard;
                  csvRow.OCT_COVID19Allowance = report.healthRiskAllowance;

                  OCT_Total_MissionHazard += report.missionHazard;
                  OCT_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 10:
                  csvRow.NOV_MissionHazard = report.missionHazard;
                  csvRow.NOV_COVID19Allowance = report.healthRiskAllowance;

                  NOV_Total_MissionHazard += report.missionHazard;
                  NOV_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 11:
                  csvRow.DEC_MissionHazard = report.missionHazard;
                  csvRow.DEC_COVID19Allowance = report.healthRiskAllowance;

                  DEC_Total_MissionHazard += report.missionHazard;
                  DEC_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;

                default:
                  break;
              }

              Total_MissionHazard += report.missionHazard;
              Total_COVID19Allowance += report.healthRiskAllowance;
            } else {
              switch (m) {
                case 0:
                  csvRow.JAN_MissionHazard = 0;
                  csvRow.JAN_COVID19Allowance = 0;
                  break;
                case 1:
                  csvRow.FEB_MissionHazard = 0;
                  csvRow.FEB_COVID19Allowance = 0;

                  break;
                case 2:
                  csvRow.MAR_MissionHazard = 0;
                  csvRow.MAR_COVID19Allowance = 0;

                  break;
                case 3:
                  csvRow.APR_MissionHazard = 0;
                  csvRow.APR_COVID19Allowance = 0;

                  break;
                case 4:
                  csvRow.MAY_MissionHazard = 0;
                  csvRow.MAY_COVID19Allowance = 0;

                  break;
                case 5:
                  csvRow.JUN_MissionHazard = 0;
                  csvRow.JUN_COVID19Allowance = 0;

                  break;
                case 6:
                  csvRow.JUL_MissionHazard = 0;
                  csvRow.JUL_COVID19Allowance = 0;

                  break;
                case 7:
                  csvRow.AUG_MissionHazard = 0;
                  csvRow.AUG_COVID19Allowance = 0;

                  break;
                case 8:
                  csvRow.SEP_MissionHazard = 0;
                  csvRow.SEP_COVID19Allowance = 0;

                  break;
                case 9:
                  csvRow.OCT_MissionHazard = 0;
                  csvRow.OCT_COVID19Allowance = 0;

                  break;
                case 10:
                  csvRow.NOV_MissionHazard = 0;
                  csvRow.NOV_COVID19Allowance = 0;

                  break;
                case 11:
                  csvRow.DEC_MissionHazard = 0;
                  csvRow.DEC_COVID19Allowance = 0;

                  break;

                default:
                  break;
              }
            }
          }

          csvRow.Total_MissionHazard = Total_MissionHazard;
          csvRow.Total_COVID19Allowance = Total_COVID19Allowance;

          csvRows.push(csvRow);
        });

        csvRow = {
          Employee: "Totals",
          EmployeeID: " ",
          JAN_MissionHazard: JAN_Total_MissionHazard,
          JAN_COVID19Allowance: JAN_Total_COVID19Allowance,
          FEB_MissionHazard: FEB_Total_MissionHazard,
          FEB_COVID19Allowance: FEB_Total_COVID19Allowance,
          MAR_MissionHazard: MAR_Total_MissionHazard,
          MAR_COVID19Allowance: MAR_Total_COVID19Allowance,
          APR_MissionHazard: APR_Total_MissionHazard,
          APR_COVID19Allowance: APR_Total_COVID19Allowance,
          MAY_MissionHazard: MAY_Total_MissionHazard,
          MAY_COVID19Allowance: MAY_Total_COVID19Allowance,
          JUN_MissionHazard: JUN_Total_MissionHazard,
          JUN_COVID19Allowance: JUN_Total_COVID19Allowance,
          JUL_MissionHazard: JUL_Total_MissionHazard,
          JUL_COVID19Allowance: JUL_Total_COVID19Allowance,
          AUG_MissionHazard: AUG_Total_MissionHazard,
          AUG_COVID19Allowance: AUG_Total_COVID19Allowance,
          SEP_MissionHazard: SEP_Total_MissionHazard,
          SEP_COVID19Allowance: SEP_Total_COVID19Allowance,
          OCT_MissionHazard: OCT_Total_MissionHazard,
          OCT_COVID19Allowance: OCT_Total_COVID19Allowance,
          NOV_MissionHazard: NOV_Total_MissionHazard,
          NOV_COVID19Allowance: NOV_Total_COVID19Allowance,
          DEC_MissionHazard: DEC_Total_MissionHazard,
          DEC_COVID19Allowance: DEC_Total_COVID19Allowance,
          Total_MissionHazard: YEAR_Total_MissionHazard,
          Total_COVID19Allowance: YEAR_Total_COVID19Allowance,
        };

        csvRows.push(csvRow);

        let options = {
          delimiter: {
            wrap: '"', // Double Quote (") character
            field: ",", // Comma field delimiter
            eol: "\n", // Newline delimiter
          },
          trimHeaderFields: true,
          trimFieldValues: true,
          keys: [
            "Employee",
            "EmployeeID",
            "JAN_MissionHazard",
            "JAN_COVID19Allowance",
            "FEB_MissionHazard",
            "FEB_COVID19Allowance",
            "MAR_MissionHazard",
            "MAR_COVID19Allowance",
            "APR_MissionHazard",
            "APR_COVID19Allowance",
            "MAY_MissionHazard",
            "MAY_COVID19Allowance",
            "JUN_MissionHazard",
            "JUN_COVID19Allowance",
            "JUL_MissionHazard",
            "JUL_COVID19Allowance",
            "AUG_MissionHazard",
            "AUG_COVID19Allowance",
            "SEP_MissionHazard",
            "SEP_COVID19Allowance",
            "OCT_MissionHazard",
            "OCT_COVID19Allowance",
            "NOV_MissionHazard",
            "NOV_COVID19Allowance",
            "DEC_MissionHazard",
            "DEC_COVID19Allowance",
            "Total_MissionHazard",
            "Total_COVID19Allowance",
          ],
        };

        json2csv(
          csvRows,
          (error, csv) => {
            if (error) {
              this.snackbarColor = "#F44336";
              this.snackbarText = error;
              this.snackbarVisible = true;
            } else {
              this.downloadCsvFile(csv);
            }
          },
          options
        );
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async getLeadPSRsYearlyCsv() {
      const jobTitles = ["PSR", "Check PSR", "Lead PSR", "Senior PSR"];

      const leadPSRs = this.filteredEmployees.filter((employee) => {
        return jobTitles.includes(employee.jobTitle);
      });

      if (!leadPSRs.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "No Senior PSRs found in selected employees";
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        //-----------------------------Get Reports
        const res = await api.get(`/reports/yearly-activity/`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            year: this.selectedYear,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText =
            "An error ocurred while trying to get the reports";
          this.snackbarVisible = true;

          return;
        }

        const activityReports = res.data.activityReports;

        leadPSRs.forEach((employee) => {
          employee.yearReports = activityReports.filter((report) => {
            return report.employee === employee._id;
          });
        });

        let report;
        let csvRows = [];
        let csvRow;
        let Total_COVID19Allowance;

        let JAN_Total_COVID19Allowance = 0;

        let FEB_Total_COVID19Allowance = 0;

        let MAR_Total_COVID19Allowance = 0;

        let APR_Total_COVID19Allowance = 0;

        let MAY_Total_COVID19Allowance = 0;

        let JUN_Total_COVID19Allowance = 0;

        let JUL_Total_COVID19Allowance = 0;

        let AUG_Total_COVID19Allowance = 0;

        let SEP_Total_COVID19Allowance = 0;

        let OCT_Total_COVID19Allowance = 0;

        let NOV_Total_COVID19Allowance = 0;

        let DEC_Total_COVID19Allowance = 0;

        let YEAR_Total_COVID19Allowance = 0;

        leadPSRs.forEach((employee) => {
          Total_COVID19Allowance = 0;

          csvRow = {
            Employee: `${employee.surname}, ${employee.givenName} (${employee.gatewayCode})`,
            EmployeeID: employee.employeeId,
          };

          for (let m = 0; m < 12; m++) {
            report = employee.yearReports.find((r) => {
              return r.month === m;
            });

            if (report) {
              YEAR_Total_COVID19Allowance += report.healthRiskAllowance;

              switch (m) {
                case 0:
                  csvRow.JAN_COVID19Allowance = report.healthRiskAllowance;
                  JAN_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 1:
                  csvRow.FEB_COVID19Allowance = report.healthRiskAllowance;

                  FEB_Total_COVID19Allowance += report.healthRiskAllowance;

                  break;
                case 2:
                  csvRow.MAR_COVID19Allowance = report.healthRiskAllowance;

                  MAR_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 3:
                  csvRow.APR_COVID19Allowance = report.healthRiskAllowance;

                  APR_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 4:
                  csvRow.MAY_COVID19Allowance = report.healthRiskAllowance;

                  MAY_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 5:
                  csvRow.JUN_COVID19Allowance = report.healthRiskAllowance;

                  JUN_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 6:
                  csvRow.JUL_COVID19Allowance = report.healthRiskAllowance;

                  JUL_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 7:
                  csvRow.AUG_COVID19Allowance = report.healthRiskAllowance;

                  AUG_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 8:
                  csvRow.SEP_COVID19Allowance = report.healthRiskAllowance;

                  SEP_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 9:
                  csvRow.OCT_COVID19Allowance = report.healthRiskAllowance;

                  OCT_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 10:
                  csvRow.NOV_COVID19Allowance = report.healthRiskAllowance;

                  NOV_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 11:
                  csvRow.DEC_COVID19Allowance = report.healthRiskAllowance;

                  DEC_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;

                default:
                  break;
              }

              Total_COVID19Allowance += report.healthRiskAllowance;
            } else {
              switch (m) {
                case 0:
                  csvRow.JAN_COVID19Allowance = 0;
                  break;
                case 1:
                  csvRow.FEB_COVID19Allowance = 0;

                  break;
                case 2:
                  csvRow.MAR_COVID19Allowance = 0;

                  break;
                case 3:
                  csvRow.APR_COVID19Allowance = 0;

                  break;
                case 4:
                  csvRow.MAY_COVID19Allowance = 0;

                  break;
                case 5:
                  csvRow.JUN_COVID19Allowance = 0;

                  break;
                case 6:
                  csvRow.JUL_COVID19Allowance = 0;

                  break;
                case 7:
                  csvRow.AUG_COVID19Allowance = 0;

                  break;
                case 8:
                  csvRow.SEP_COVID19Allowance = 0;

                  break;
                case 9:
                  csvRow.OCT_COVID19Allowance = 0;

                  break;
                case 10:
                  csvRow.NOV_COVID19Allowance = 0;

                  break;
                case 11:
                  csvRow.DEC_COVID19Allowance = 0;

                  break;

                default:
                  break;
              }
            }
          }

          csvRow.Total_COVID19Allowance = Total_COVID19Allowance;

          csvRows.push(csvRow);
        });

        csvRow = {
          Employee: "Totals",
          EmployeeID: " ",

          JAN_COVID19Allowance: JAN_Total_COVID19Allowance,

          FEB_COVID19Allowance: FEB_Total_COVID19Allowance,

          MAR_COVID19Allowance: MAR_Total_COVID19Allowance,

          APR_COVID19Allowance: APR_Total_COVID19Allowance,

          MAY_COVID19Allowance: MAY_Total_COVID19Allowance,

          JUN_COVID19Allowance: JUN_Total_COVID19Allowance,

          JUL_COVID19Allowance: JUL_Total_COVID19Allowance,

          AUG_COVID19Allowance: AUG_Total_COVID19Allowance,

          SEP_COVID19Allowance: SEP_Total_COVID19Allowance,

          OCT_COVID19Allowance: OCT_Total_COVID19Allowance,

          NOV_COVID19Allowance: NOV_Total_COVID19Allowance,

          DEC_COVID19Allowance: DEC_Total_COVID19Allowance,

          Total_COVID19Allowance: YEAR_Total_COVID19Allowance,
        };

        csvRows.push(csvRow);

        let options = {
          delimiter: {
            wrap: '"', // Double Quote (") character
            field: ",", // Comma field delimiter
            eol: "\n", // Newline delimiter
          },
          trimHeaderFields: true,
          trimFieldValues: true,
          keys: [
            "Employee",
            "EmployeeID",
            "JAN_COVID19Allowance",
            "FEB_COVID19Allowance",
            "MAR_COVID19Allowance",
            "APR_COVID19Allowance",
            "MAY_COVID19Allowance",
            "JUN_COVID19Allowance",
            "JUL_COVID19Allowance",
            "AUG_COVID19Allowance",
            "SEP_COVID19Allowance",
            "OCT_COVID19Allowance",
            "NOV_COVID19Allowance",
            "DEC_COVID19Allowance",
            "Total_COVID19Allowance",
          ],
        };

        json2csv(
          csvRows,
          (error, csv) => {
            if (error) {
              this.snackbarColor = "#F44336";
              this.snackbarText = error;
              this.snackbarVisible = true;
            } else {
              this.downloadCsvFile(csv);
            }
          },
          options
        );
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async printLoadmastersYearly() {
      try {
        const jobTitles = [
          "Loadmaster",
          "Check Loadmaster",
          "Senior Loadmaster",
        ];

        const loadmasters = this.filteredEmployees.filter((employee) => {
          return jobTitles.includes(employee.jobTitle);
        });

        if (!loadmasters.length) {
          this.snackbarColor = "#F44336";
          this.snackbarText = "No Loadmasters found in selected employees";
          return (this.snackbarVisible = true);
        }

        this.loading = true;

        const token = await aad.getIdTokenSilent();

        //-----------------------------Get Reports
        const res = await api.get(`/reports/yearly-activity/`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            year: this.selectedYear,
          },
        });

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText =
            "An error ocurred while trying to get the reports";
          this.snackbarVisible = true;
          return;
        }

        const activityReports = res.data.activityReports;

        loadmasters.forEach((employee) => {
          employee.yearReports = activityReports.filter((report) => {
            return report.employee === employee._id;
          });
        });

        let report;
        let totalMissionHazard;
        let totalHealthRiskAllowance;

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        this.loading = false;

        const title = `Atlas Air Loadmasters ${this.selectedYear} Hazardous`;

        pdfDoc.setTitle(title);

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(title, options);

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 20;

        let employee;

        for (let i = 0; i < loadmasters.length; i++) {
          employee = loadmasters[i];

          totalMissionHazard = 0;
          totalHealthRiskAllowance = 0;

          // Employee Name and Gateway
          options.x = 50;
          options.size = 12;
          options.color = rgb(0.15, 0.09, 0.81);

          page.drawText(
            `${employee.surname}, ${employee.givenName} (${employee.gatewayCode})`,
            options
          );

          options.color = rgb(0, 0, 0);
          options.size = 10;

          for (let m = 0; m < 12; m++) {
            options.x = 50;
            options.y -= 15;

            report = employee.yearReports.find((r) => {
              return r.month === m;
            });

            if (report) {
              totalMissionHazard += report.missionHazard;
              totalHealthRiskAllowance += report.healthRiskAllowance;

              page.drawText(
                `${this.formatMonthShort(m)} - ${this.selectedYear}`,
                options
              );

              options.x = 150;
              page.drawText(
                `Mission Hazard:  ${report.missionHazard.toString()} `,
                options
              );

              options.x = 350;
              page.drawText(
                `Health Risk Allowance:  ${report.healthRiskAllowance.toString()} `,
                options
              );
            } else {
              page.drawText(
                `${this.formatMonthShort(m)} - ${this.selectedYear}`,
                options
              );

              options.x = 150;
              page.drawText(`Mission Hazard: 0 `, options);

              options.x = 350;
              page.drawText(`Health Risk Allowance: 0 `, options);
            }
          }

          options.x = 50;
          options.y -= 15;
          options.size = 12;

          page.drawText(`TOTALS - ${this.selectedYear}`, options);

          options.x = 150;
          page.drawText(`Mission Hazard: ${totalMissionHazard} `, options);

          options.x = 350;
          page.drawText(
            `Health Risk Allowance: ${totalHealthRiskAllowance} `,
            options
          );

          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `______________________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === loadmasters.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 200 < 50 && i <= loadmasters.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async printLeadPSRsYearly() {
      try {
        const jobTitles = ["PSR", "Check PSR", "Lead PSR", "Senior PSR"];

        const leadPSRs = this.filteredEmployees.filter((employee) => {
          return jobTitles.includes(employee.jobTitle);
        });

        if (!leadPSRs.length) {
          this.snackbarColor = "#F44336";
          this.snackbarText = "No Senior PSRs found in selected employees";
          return (this.snackbarVisible = true);
        }

        this.loading = true;

        const token = await aad.getIdTokenSilent();

        //-----------------------------Get Reports
        const res = await api.get(`/reports/yearly-activity/`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            year: this.selectedYear,
          },
        });

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText =
            "An error ocurred while trying to get the reports";
          this.snackbarVisible = true;

          return;
        }

        const activityReports = res.data.activityReports;

        leadPSRs.forEach((employee) => {
          employee.yearReports = activityReports.filter((report) => {
            return report.employee === employee._id;
          });
        });

        let report;
        let totalHealthRiskAllowance;

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        this.loading = false;

        const title = `Atlas Air Lead PSRs ${this.selectedYear} Hazardous`;

        pdfDoc.setTitle(title);

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(title, options);

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 20;

        let employee;

        for (let i = 0; i < leadPSRs.length; i++) {
          employee = leadPSRs[i];

          totalHealthRiskAllowance = 0;

          // Employee Name and Gateway
          options.x = 50;
          options.size = 12;
          options.color = rgb(0.15, 0.09, 0.81);

          page.drawText(
            `${employee.surname}, ${employee.givenName} (${employee.gatewayCode})`,
            options
          );

          options.color = rgb(0, 0, 0);
          options.size = 10;

          for (let m = 0; m < 12; m++) {
            options.x = 50;
            options.y -= 15;

            report = employee.yearReports.find((r) => {
              return r.month === m;
            });

            if (report) {
              totalHealthRiskAllowance += report.healthRiskAllowance;

              page.drawText(
                `${this.formatMonthShort(m)} - ${this.selectedYear}`,
                options
              );

              options.x = 350;
              page.drawText(
                `Health Risk Allowance:  ${report.healthRiskAllowance.toString()} `,
                options
              );
            } else {
              page.drawText(
                `${this.formatMonthShort(m)} - ${this.selectedYear}`,
                options
              );

              options.x = 350;
              page.drawText(`Health Risk Allowance: 0 `, options);
            }
          }

          options.x = 50;
          options.y -= 15;
          options.size = 12;

          page.drawText(`TOTALS - ${this.selectedYear}`, options);

          options.x = 350;
          page.drawText(
            `Health Risk Allowance: ${totalHealthRiskAllowance} `,
            options
          );

          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `______________________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === leadPSRs.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 200 < 50 && i <= leadPSRs.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    adjustMissionHazard() {
      if (isNaN(this.missionHazardAdjustment)) {
        this.adjustedMissionHazard = 0;
      } else {
        this.adjustedMissionHazard = parseInt(this.missionHazardAdjustment);
      }
    },

    adjustHealthRisk() {
      if (isNaN(this.healthRiskAdjustment)) {
        this.adjustedhealthRisk = 0;
      } else {
        this.adjustedhealthRisk = parseInt(this.healthRiskAdjustment);
      }
    },

    showEmployeeActivityReport(employee) {
      this.reportRemarks = "";
      this.employee = { ...employee };
      this.activityReportDialog = true;
    },

    hideEmployeeActivityReport() {
      this.confirmed = false;
      this.employee = null;
      this.employeeActivityReport = null;
      this.managerActivityReport = null;
      this.administratorActivityReport = null;
      this.perdiemAdjustment = 0;
      this.internationalPerdiemAdjustment = 0;
      this.missionHazardAdjustment = 0;
      this.healthRiskAdjustment = 0;
      this.adjustedMissionHazard = 0;
      this.adjustedHealthRisk = 0;
      this.reportViewMode = "selection";
    },

    async submitReport() {
      let report = {
        employee: this.employee._id,
        year: this.selectedYear,
        month: this.selectedMonth,
        period: this.selectedPeriod,
        daysInThisMonth: this.employee.activity.daysInThisMonth,
        daysOff: this.employee.activity.daysOff,
        daysOver20: this.employee.activity.daysOver20,
        daysOverGuarrantee: this.employee.activity.daysOverGuarrantee,
        floatingHolidayDays: this.employee.activity.floatingHolidayDays,
        healthRiskAllowance: this.employee.activity.healthRiskAllowance,
        healthRiskDays: this.employee.activity.healthRiskDays,
        holidayDays: this.employee.activity.holidayDays,
        leaveDays: this.employee.activity.leaveDays,
        loaDays: this.employee.activity.loaDays,
        missionHazard: this.employee.activity.missionHazard,

        //Calculated Perdiem
        perdiemDays: this.employee.activity.perdiemDays,
        perdiemDaysFirstHalf: this.employee.activity.perdiemDaysFirstHalf,
        perdiemDaysSecondHalf: this.employee.activity.perdiemDaysSecondHalf,

        //Adjustment added by user
        perdiemAdjustment: this.perdiemAdjustment,
        internationalPerdiemAdjustment: this.internationalPerdiemAdjustment,

        //Total Ajusted Perdiem
        adjustedPerdiemDaysSecondHalf: this.adjustedPerdiemDaysSecondHalf,

        internationalPerdiemDays: this.employee.activity
          .internationalPerdiemDays,
        internationalPerdiemDaysFirstHalf: this.employee.activity
          .internationalPerdiemDaysFirstHalf,
        internationalPerdiemDaysSecondHalf: this.employee.activity
          .internationalPerdiemDaysSecondHalf,

        //Total Ajusted International Perdiem
        adjustedInternationalPerdiemDaysSecondHalf: this
          .adjustedInternationalPerdiemDaysSecondHalf,

        sickDays: this.employee.activity.sickDays,
        totalDays: this.employee.activity.totalDays,
        vacationDays: this.employee.activity.vacationDays,
        workDays: this.employee.activity.workDays,
        standByDays: this.employee.activity.standByDays,
        workDaysFirstHalf: this.employee.activity.workDaysFirstHalf,
        workDaysSecondHalf: this.employee.activity.workDaysSecondHalf,

        missionHazardAdjustment: this.missionHazardAdjustment,
        healthRiskAdjustment: this.healthRiskAdjustment,

        adjustedDaysOverGuarrantee: this.adjustedDaysOverGuarrantee,
        assignments: this.employee.assignments,
        remarks: this.reportRemarks,
      };

      try {
        this.loading = true;

        const token = await aad.getIdTokenSilent();

        const res = await api.put(`/reports/monthly-activity`, report, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.data.status === "OK") {
          this.snackbarColor = "#66BB6A";
          this.snackbarText = `Your report was submited`;
          this.snackbarVisible = true;

          this.getData();
          this.activityReportDialog = false;
        } else {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.confirmed = false;
        this.perdiemAdjustment = 0;
        this.internationalPerdiemAdjustment = 0;
        this.missionHazardAdjustment = 0;
        this.healthRiskAdjustment = 0;
      }
    },

    showActivityReports() {
      this.activityReportDialog = true;
    },

    hideActivityReportDialog() {
      this.confirmed = false;
      this.employee = null;
      this.perdiemAdjustment = 0;
      this.internationalPerdiemAdjustment = 0;
      this.missionHazardAdjustment = 0;
      this.healthRiskAdjustment = 0;
      this.adjustedMissionHazard = 0;
      this.adjustedHealthRisk = 0;
      this.activityReportDialog = false;
    },

    filterEmployees() {
      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];

      if (this.selectedCompany !== "All Companies") {
        filterOne = this.employees.filter((employee) => {
          return this.selectedCompany === employee.companyName;
        });
      } else {
        filterOne = [...this.employees];
      }

      if (this.lastName.length) {
        filterTwo = filterOne.filter((employee) => {
          return employee.surname
            .toUpperCase()
            .startsWith(this.lastName.toUpperCase());
        });
      } else {
        filterTwo = [...filterOne];
      }

      switch (this.board.type) {
        case "Cargo":
          filterThree = filterTwo.filter((item) => {
            return this.cargoJobTitles.includes(item.jobTitle);
          });
          break;
        case "Passenger":
          filterThree = filterTwo.filter((item) => {
            return this.passengerJobTitles.includes(item.jobTitle);
          });
          break;

        default:
          filterThree = [...filterTwo];
          break;
      }

      this.filteredEmployees = [...filterThree];
    },

    saveMonthFilter() {
      this.selectedMonth = new Date(this.monthPicker).getUTCMonth();
      this.selectedYear = new Date(this.monthPicker).getUTCFullYear();
      this.boardStyle = this.selectedBoardStyle.slice();

      this.monthFilterDialog = false;
      this.getData();
    },

    monthFilterBack() {
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11;
        this.selectedYear -= 1;
      } else {
        this.selectedMonth -= 1;
      }

      this.getData();
    },

    monthFilterForward() {
      if (this.selectedMonth < 11) {
        this.selectedMonth += 1;
      } else {
        this.selectedMonth = 0;
        this.selectedYear += 1;
      }

      this.getData();
    },

    formatTimestamp(report) {
      if (report) {
        const d1 = new Date(report.confirmation.timestamp)
          .toUTCString()
          .toString();

        return `${d1.substring(5, 17)} - ${d1.substring(16, 22)} Z`;
      } else {
        return "Not Confirmed";
      }
    },

    showConfirmationDialog(action, _id) {
      switch (action) {
        case "deleteActivityReport":
          this.confirmation = {
            message: `Delete Activity Report? This cannot be undone.`,
            action,
            _id,
          };
          this.confirmationDialog = true;

          break;

        default:
          break;
      }
    },

    confirm() {
      switch (this.confirmation.action) {
        case "deleteActivityReport":
          this.deleteActivityReport(this.confirmation._id);
          break;

        default:
          break;
      }
    },

    cancelConfirmation() {
      this.confirmation = null;
      this.confirmationDialog = false;
    },

    scrollBoard() {
      //This fires when the slider in the toolbar is used
      this.bottomPanelRight.scrollLeft = this.sliderX;
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },
  },
};
</script>

<style scoped>
.selection-panel {
  border: 1px solid #bebebe;
  padding: 5px;
  margin: 0 5px 0 5px;
  border-radius: 3px;
  max-height: 500px;
  overflow-y: scroll;
}

#top-banner-reports {
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 96px;
  width: 100%;
  border-bottom: 1px solid #bebebe;
  background-color: white;
  padding: 7px;
}

#top-banner-flow-chart {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 96px;
  width: 100%;
  border-bottom: 1px solid #bebebe;
  background-color: white;
}

#bottom-frame {
  position: relative;
  display: flex;
  top: 147px;
  width: 100%;
  background-color: white;
}

.report-panel {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
}

.report-panel-column {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
  background-color: #ffffff;
}

.report-panel-column-green {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
  background-color: #91e67c;
}

.activity-day {
  margin: 1px;
  width: 100%;
  padding: 23px 0;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

.activity-summary {
  margin: 1px;
  height: 57px;
  width: 100%;
  padding: 23px 0;
  background-color: #ffffff;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

.report-top-panel-day {
  position: static;
  padding: 10px 0;
  text-align: center;
  word-wrap: unset;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;
  -webkit-user-select: none;
}

.report-top-panel-column-title {
  position: static;
  padding: 10px 0;
  text-align: center;
  word-wrap: unset;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;

  -webkit-user-select: none;
}

.remove-icon:hover {
  color: red;
}

.red-border {
  border: 1px dashed red;
}
</style>
