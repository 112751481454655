<template>
  <div>
    <Navbar />
    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" @click="goBack" v-on="on" icon dark small
            ><v-icon>arrow_back</v-icon></v-btn
          >
        </template>
        <span>Go back to Flight Board</span>
      </v-tooltip>

      <!-- This Station Assignment -->
      <div
        v-if="stationAssignment"
        class="d-flex justify-start align-center text-truncate font-abel-10px mx-1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="loading" small icon v-on="on"
              ><v-icon color="white">apps</v-icon></v-btn
            >
          </template>
          <div class="tooltip font-abel-12px">
            <div>
              {{
                `Station: ${stationAssignment.stationIata} - Assignments: ${
                  stationAssignment.flights.length
                } . ${formatMinutes(stationAssignment.durationInMinutes)}`
              }}
            </div>
          </div>
        </v-tooltip>
      </div>

      <!--Filters  -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-slider
        @change="scrollBoard"
        class="ml-1"
        v-model="slideX"
        :max="dateFilter.days.length - 1"
        :min="0"
        hide-details
      >
      </v-slider>

      <v-spacer></v-spacer>

      <div>
        <!-- Refresh -->
        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>

        <!-- Zoom In -->
        <v-btn :disabled="loading" @click="zoomIn" small icon class="ml-1">
          <v-icon color="blue">zoom_in</v-icon>
        </v-btn>

        <!-- Zoom Out -->
        <v-btn :disabled="loading" @click="zoomOut" small icon>
          <v-icon color="white">zoom_out</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" @click="goBack" v-on="on" icon dark small
            ><v-icon>arrow_back</v-icon></v-btn
          >
        </template>
        <span>Go back to Flight Board</span>
      </v-tooltip>

      <!-- This Station Assignment -->
      <div
        class="d-flex justify-start align-center text-truncate font-abel-10px mx-1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="loading" small icon v-on="on"
              ><v-icon color="white">apps</v-icon></v-btn
            >
          </template>
          <div class="tooltip font-abel-12px">
            {{
              `Station: ${stationAssignment.stationIata} - Assignments: ${
                stationAssignment.flights.length
              } . ${formatMinutes(stationAssignment.durationInMinutes)}`
            }}
          </div>
        </v-tooltip>
      </div>

      <!--Filters  -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- Refresh -->
          <v-list-item @click="getAssignments" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom In -->
          <v-list-item @click="zoomIn" link>
            <v-list-item-icon>
              <v-icon color="blue">zoom_in</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom Out -->
          <v-list-item @click="zoomOut" link>
            <v-list-item-icon>
              <v-icon color="white">zoom_out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- NEW STATION ASSIGNMENT DIALOG-->
    <v-dialog
      v-if="showStationAssignmentDialog"
      v-model="showStationAssignmentDialog"
      persistent
      max-width="800px"
    >
      <station-assignment
        :stationAssignment="stationAssignment"
        :employee="employee"
        :assignedToCompany="assignedToCompany"
        @hide="handleHideStationAssignmentDialog($event)"
      >
      </station-assignment>
    </v-dialog>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-if="showStationAssignmentInformationDialog"
      v-model="showStationAssignmentInformationDialog"
      max-width="800px"
    >
      <station-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </v-dialog>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog
      v-if="showEmployeeFilterDialog"
      v-model="showEmployeeFilterDialog"
      persistent
      max-width="450px"
    >
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <v-dialog
      v-if="showEmployeeInformationDialog"
      v-model="showEmployeeInformationDialog"
      persistent
      max-width="800px"
    >
      <employee-information
        :employee="employee"
        @close="handleHideEmployeeInformationDialog"
      ></employee-information>
    </v-dialog>

    <!-- NEW GROUND ASSIGNMENT DIALOG -->
    <v-dialog
      v-if="showNewGroundAssignmentDialog"
      v-model="showNewGroundAssignmentDialog"
      max-width="800px"
    >
      <ground-assignment
        :groundAssignmentStart="groundAssignmentStart"
        :employee="employee"
        @hide="handleHideGroundAssignmentDialog($event)"
      >
      </ground-assignment>
    </v-dialog>

    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showFlightAssignmentInformationDialog" max-width="800px">
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </v-dialog>

    <!-- GROUND ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showGroundAssignmentInformationDialog" max-width="800px">
      <ground-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @hide="hideGroundAssignmentInformationDialog($event)"
      >
      </ground-assignment-information>
    </v-dialog>

    <!-- FLIGHT BOARD -->
    <div v-if="!loading">
      <div class="top-frame font-abel-16px">
        <div class="top-panel-left">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="loading"
                @click="handleOpenEmployeeFilter"
                small
                icon
              >
                <i class="fa-solid fa-filter"></i>
              </v-btn>
            </template>
            <span>Filter Employees by Job Title</span>
          </v-tooltip>
          <!-- Search by last name -->
          <v-text-field
            v-model="lastName"
            dense
            label="Employee"
            single-line
            hide-details
            :style="{ maxWidth: '120px', marginLeft: '5px' }"
            @input="filterEmployees"
          ></v-text-field>
        </div>
        <div class="top-panel-right">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${dateFilter.days.length * (1440 / scale)}px`,
              left: `${scrollX}px`,
            }"
          >
            <div
              v-for="(day, index) in dateFilter.days"
              :key="index"
              class="top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}

              <div v-if="scale < 8" class="d-flex font-abel-10px">
                <div
                  v-bind:style="{
                    width: `${30 / scale}px`,
                  }"
                ></div>

                <div
                  v-for="tick in timeTicks"
                  :key="tick.index"
                  v-bind:style="{
                    width: `${60 / scale}px`,
                  }"
                >
                  <div>{{ tick.text }}</div>
                </div>
              </div>
            </div>

            <div
              v-if="currentTimeX > 0"
              class="current-time-box d-flex justify-center align-center font-abel-16px"
              v-bind:style="{
                left: `${currentTimeX - 21}px`,
                width: '42px',
              }"
            >
              {{ formatTime(new Date()) }}
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-frame">
        <div class="bottom-panel-left">
          <!-- Company Assignment -->
          <div
            v-if="enableCreateGroundAssignments"
            class="bottom-panel-left-row font-abel-14px"
          >
            <!--Company Name -->
            <v-select
              v-model="assignedToCompany"
              label="Assign to Company"
              :items="assignToCompanies"
            ></v-select>
          </div>
          <!-- Employee Assignment -->
          <div v-if="filteredEmployees.length">
            <div
              v-for="(employee, index) in filteredEmployees"
              @click="handleShowEmployeeInformationDialog(employee)"
              :key="employee._id"
              class="bottom-panel-left-row font-abel-14px cursor-hand"
            >
              <div class="bottom-panel-left-row-section truncate ">
                {{ index + 1 }} - {{ employee.surname }},
                {{ employee.givenName.substring(0, 1) }}
              </div>

              <div
                class="bottom-panel-left-row-section truncate font-abel-10px"
              >
                {{ employee.companyName }} - {{ employee.jobTitle }}
              </div>
              <div
                class=" font-abel-12px bottom-panel-left-row-section truncate"
              >
                {{ employee.gatewayCode }} - {{ employee.employeeId }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="bottom-panel-right"
          @scroll="scrollHorizontal"
          ref="bottomPanelRight"
        >
          <div
            class="bottom-panel-right-inner"
            :style="{
              width: `${boardWidth}px`,
              height: `${boardHeight}px`,
            }"
          >
            <!-- Company Assignments -->
            <div
              v-if="enableCreateGroundAssignments"
              class="bottom-panel-right-row"
              :style="{ width: `${boardWidth}px` }"
            >
              <div
                v-for="(day, index) in dateFilter.days"
                :key="index"
                class="bottom-panel-day"
                :style="{
                  width: `${1440 / scale}px`,
                  left: `${index * (1440 / scale)}px`,
                  backgroundColor: 'white',
                  opacity: 0.5,
                }"
              >
                <div v-if="scale < 8" class="d-flex font-abel-10px">
                  <div
                    class="hour-line"
                    v-for="tick in timeTicks"
                    :key="tick.index"
                    v-bind:style="{
                      width: `${60 / scale}px`,
                    }"
                  ></div>
                </div>
              </div>
              <div
                v-if="currentTimeX > 0"
                class="current-time"
                v-bind:style="{
                  left: `${currentTimeX}px`,
                }"
              ></div>

              <!-- Assignment Shadow  -->
              <div
                v-if="stationAssignment"
                class="flight-shadow"
                @click="handleShowNewStationAssignmentDialog()"
                :style="{
                  width: `${(stationAssignment.durationInMinutes +
                    paddingBefore +
                    paddingAfter) /
                    scale}px`,
                  left: `${getFlightShadowLocationLeft(
                    stationAssignment.startTime,
                    paddingBefore,
                    dateFilter.days[0],
                    scale
                  )}px`,
                  height: '60px',
                }"
              ></div>
            </div>
            <!-- Employee Assignment -->
            <div v-if="filteredEmployees.length">
              <div
                v-for="employee in filteredEmployees"
                :key="employee._id"
                class="bottom-panel-right-row"
                :style="{
                  width: `${dateFilter.days.length * (1440 / scale)}px`,
                }"
              >
                <div
                  v-for="(day, index) in dateFilter.days"
                  :key="index"
                  @click="handleShowNewGroundAssignmentDialog(employee, $event)"
                  class="bottom-panel-day"
                  :style="{
                    width: `${1440 / scale}px`,
                    left: `${index * (1440 / scale)}px`,
                    backgroundColor: getBidDayStyle(employee.bidDays, day),
                    opacity: 0.5,
                  }"
                >
                  <div v-if="scale < 8" class="d-flex font-abel-10px">
                    <div
                      class="hour-line"
                      v-for="tick in timeTicks"
                      :key="tick.index"
                      v-bind:style="{
                        width: `${60 / scale}px`,
                      }"
                    ></div>
                  </div>
                </div>

                <div
                  v-if="currentTimeX > 0"
                  class="current-time"
                  v-bind:style="{
                    left: `${currentTimeX}px`,
                  }"
                ></div>

                <!-- Assignments -->
                <div
                  v-for="assignment in employee.assignments"
                  :key="assignment._id"
                >
                  <div
                    v-if="assignment.type === 'Flight'"
                    class="flight-label-frame"
                    :style="{
                      width: `${assignment.flight.effectiveBlockTime /
                        scale}px`,
                      left: `${getLocationLeft(
                        assignment.flight.effectiveTimeOut,
                        dateFilter.days[0],
                        scale
                      )}px`,
                    }"
                  >
                    <FlightLabel
                      :flight="assignment.flight"
                      @clicked="
                        handleShowFlightAssignmentInformationDialog(assignment)
                      "
                    />
                  </div>
                  <div
                    v-else
                    class="flight-label-frame"
                    v-bind:style="{
                      width: `${assignment.duration / scale}px`,
                      left: `${getLocationLeft(
                        assignment.startTime,
                        dateFilter.days[0],
                        scale
                      )}px`,
                    }"
                  >
                    <GroundAssignmentLabel
                      :groundAssignment="assignment"
                      @clicked="
                        handleShowGroundAssignmentInformationDialog(assignment)
                      "
                    />
                  </div>
                </div>

                <!-- Assignment Shadow  -->
                <div
                  v-if="stationAssignment"
                  class="flight-shadow"
                  @click="handleShowNewStationAssignmentDialog(employee)"
                  :style="{
                    width: `${(stationAssignment.durationInMinutes +
                      paddingBefore +
                      paddingAfter) /
                      scale}px`,
                    left: `${getFlightShadowLocationLeft(
                      stationAssignment.startTime,
                      paddingBefore,
                      dateFilter.days[0],
                      scale
                    )}px`,
                    height: '60px',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import FlightLabel from "../components/FlightLabel";
import GroundAssignmentLabel from "../components/GroundAssignmentLabel";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import EmployeeFilter from "../components/EmployeeFilter.vue";
import GroundAssignment from "../components/GroundAssignment";
import StationAssignment from "../components/StationAssignment";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import GroundAssignmentInformation from "../components/GroundAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import EmployeeInformation from "../components/EmployeeInformation.vue";

export default {
  name: "stationAssignmentBoard",
  components: {
    FlightAssignmentInformation,
    GroundAssignment,
    StationAssignment,
    GroundAssignmentInformation,
    StationAssignmentInformation,
    EmployeeInformation,
    FlightLabel,
    EmployeeFilter,
    GroundAssignmentLabel,
    AutoLogout,
    Navbar,
  },
  data() {
    return {
      loading: false,
      showEmployeeInformationDialog: false,
      showNewGroundAssignmentDialog: false,
      showGroundAssignmentInformationDialog: false,
      showStationAssignmentInformationDialog: false,
      showFlightAssignmentInformationDialog: false,
      isMobile: false,
      slideX: 0,
      employee: null,
      employees: [],
      filteredEmployees: [],
      groundAssignment: null,
      groundAssignmentStation: null,
      stationAssignmentStart: null,
      flightAssignment: null,
      showAssignmentWarningsDialog: false,
      paddingBefore: 0,
      paddingAfter: 0,
      lastName: "",
      scrollX: 0,
      companyDialog: false,
      showStationAssignmentDialog: false,
      showEmployeeFilterDialog: false,
      assignmentStartLimit: null,
      assignmentEndLimit: null,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      scale: 4,
      currentTime: "",
      selectedCompany: "",
      selectedBoardType: "",
      assignedToCompany: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      warnings: [],
    };
  },
  mixins: [mixin],
  created() {
    const company = this.user.securityGroup.companies[0];
    this.selectedCompany = company.slice();
    this.assignedToCompany = company.slice();
    this.selectedBoardType = this.board.type;

    this.getAssignments();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },

    assignToCompanies() {
      let companies = [];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },

    user() {
      return this.$store.getters.user;
    },
    assignmentWarnings() {
      return this.$store.getters.assignmentWarnings;
    },

    board() {
      return this.$store.getters.board;
    },

    company() {
      return this.$store.getters.company;
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    stationAssignment() {
      return this.$store.getters.stationAssignment;
    },

    searchByStationIconColor() {
      if (this.searchByStation) {
        return "yellow";
      } else {
        return "#E0E0E0";
      }
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardHeight() {
      if (this.enableCreateGroundAssignments) {
        return (this.filteredEmployees.length + 1) * 60 + 50;
      } else {
        return this.filteredEmployees.length * 60 + 50;
      }
    },
  },
  methods: {
    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.enableReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleHideEmployeeInformationDialog() {
      this.showEmployeeInformationDialog = false;
    },

    //-------------------------New Station Assignment
    handleShowNewStationAssignmentDialog(employee) {
      if (employee) {
        this.employee = { ...employee };
      } else {
        this.employee = null;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.showStationAssignmentDialog = true;
    },

    handleHideStationAssignmentDialog(refresh) {
      this.showStationAssignmentDialog = false;
      if (refresh) {
        this.getAssignments();
      }
    },

    //-------------------------Flight Assignment Information
    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.flightAssignment = {
        ...flightAssignment,
      };

      this.showFlightAssignmentInformationDialog = true;
    },

    hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;
      this.flightAssignment = null;
      if (e.refreshData) {
        this.getAssignments();
      }
    },

    //-----------------------New Ground Assignment
    handleShowNewGroundAssignmentDialog(employee, e) {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.employee = { ...employee };

      const minutes =
        (e.clientX - 100) * this.scale + Math.abs(this.scrollX * this.scale);

      this.groundAssignmentStart =
        new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000;

      this.showNewGroundAssignmentDialog = true;
    },

    handleHideGroundAssignmentDialog(refresh) {
      this.showNewGroundAssignmentDialog = false;
      if (refresh) {
        this.getAssignments();
      }
    },

    //-----------------------Ground Assignment Information
    async handleShowGroundAssignmentInformationDialog(groundAssignment) {
      try {
        if (groundAssignment.category === "Station Assignment") {
          const token = await aad.getIdTokenSilent();

          const res = await api.get("/flights/assignment-flights", {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
            params: {
              flightIds: groundAssignment.group.flightIds,
            },
          });

          if (res.data.status !== "OK") {
            this.snackbarColor = "#F44336";
            this.snackbarText = res.data.message;
            this.snackbarVisible = true;
            return;
          }

          const flights = res.data.flights;

          this.groundAssignment = {
            ...groundAssignment,
            flights,
          };

          this.showStationAssignmentInformationDialog = true;
        } else {
          this.groundAssignment = {
            ...groundAssignment,
          };

          this.showGroundAssignmentInformationDialog = true;
        }
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async hideGroundAssignmentInformationDialog(refresh) {
      this.showGroundAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (refresh) {
        await this.getAssignments();
      }
    },

    async hideStationAssignmentInformationDialog(refresh) {
      this.showStationAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (refresh) {
        await this.getAssignments();
      }
    },

    //----------------------Employee Filter
    handleOpenEmployeeFilter() {
      this.showEmployeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.showEmployeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.showEmployeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;

        return;
      }

      this.getAssignments();
    },

    handleChangeBoardType(boardType) {
      let board = { ...this.board };
      board.type = boardType;
      this.$store.commit("updateBoard", board);

      this.filterEmployees();
    },

    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    compareStationFlights(a, b) {
      //Flights need to be order based on if they are an arrival or departure assignment
      let aRefTime;
      let bRefTime;

      if (a.originIata === this.stationIata) {
        aRefTime = new Date(a.effectiveTimeOut).getTime();
      } else if (a.destinationIata === this.stationIata) {
        aRefTime = new Date(a.effectiveTimeIn).getTime();
      }

      if (b.originIata === this.stationIata) {
        bRefTime = new Date(b.effectiveTimeOut).getTime();
      } else if (b.destinationIata === this.stationIata) {
        bRefTime = new Date(b.effectiveTimeIn).getTime();
      }

      if (aRefTime < bRefTime) {
        return -1;
      }
      if (bRefTime < aRefTime) {
        return 1;
      }
      return 0;
    },

    handleCloseStationAssignmentDialog() {
      this.showStationAssignmentDialog = false;
      this.remarks = " ";
    },

    async getAssignments() {
      this.loading = true;
      this.employees = [];
      this.filteredEmployees = [];
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            filterStart: this.dateFilter.start,
            filterEnd: this.dateFilter.end,
          },
        });

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        let employees = res.data.employees;

        const allAssignments = res.data.assignments;
        const bidDays = res.data.bidDays;
        let assignments = [];
        employees.forEach((emp) => {
          assignments = allAssignments.filter((a) => {
            if (a.employee) {
              return a.employee._id === emp._id;
            }
          });
          emp.assignments = this.mergeAssignments(assignments);
          emp.bidDays = bidDays.filter((bidDay) => {
            return bidDay.employee === emp._id;
          });
          this.employees.push(emp);
        });

        this.filterEmployees();

        if (this.filteredEmployees.length && this.boardStyle === "Board") {
          this.$nextTick(() => {
            window.scrollTo(0, this.board.employeeScrollY);
            const panel = this.$refs["bottomPanelRight"];
            if (panel) {
              panel.scrollLeft = Math.abs(this.board.scrollX);
              this.scale = this.board.scale;
            }
          });
        }
        this.loading = false;
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
        this.loading = false;
      }
    },

    scrollBoard() {
      //This fires when the slider in the toolbar is used
      this.bottomPanelRight.scrollLeft = this.sliderX;
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },

    // showGroundAssignmentDialog(employee, e) {
    //   const board = {
    //     ...this.board,
    //     employeeScrollY: window.scrollY,
    //     scrollX: this.scrollX,
    //     scale: this.scale,
    //   };

    //   this.$store.commit("updateBoard", board);

    //   if (!this.enableCreateGroundAssignments) {
    //     this.snackbarColor = "#F44336";
    //     this.snackbarText = "Not authorized. Please contact your administrator";
    //     return (this.snackbarVisible = true);
    //   }
    //   this.employee = { ...employee };

    //   const minutes =
    //     (e.clientX - 100) * this.scale + Math.abs(this.scrollX * this.scale);

    //   this.stationAssignmentStart =
    //     new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000;

    //   this.groundAssignmentDialog = true;
    // },

    // hideGroundAssignmentDialog(e) {
    //   this.groundAssignmentDialog = false;
    //   if (e.refreshData) {
    //     this.getAssignments();
    //   }
    // },

    getFlightShadowLocationLeft(
      effectiveTimeOut,
      paddingBefore,
      filterStart,
      scale
    ) {
      const minutes =
        (new Date(effectiveTimeOut).getTime() -
          new Date(filterStart).getTime()) /
        1000 /
        60;
      return (minutes - paddingBefore) / scale;
    },

    showBidDayDetails(bidline, day) {
      let bidDay;

      if (bidline) {
        bidDay = bidline.days.find((d) => {
          return d.day === new Date(day).getUTCDate();
        });

        this.snackbarColor = `#2196F3`;
        this.snackbarText = `${
          bidline.employee.displayName
        } . ${this.formatDate(day)} . ${bidDay.status.description}`;
        this.snackbarVisible = true;
      } else {
        this.snackbarColor = `#FF6F00`;
        this.snackbarText = "Not bidline information";
        this.snackbarVisible = true;
      }
    },

    filterEmployees() {
      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];
      let filterFour = [];

      if (this.searchByStation) {
        let lastAssignment;
        let priorAssignments;

        this.employees.forEach((employee) => {
          if (employee.assignments.length) {
            priorAssignments = employee.assignments.filter((assignment) => {
              return (
                new Date(assignment.endTime).getTime() <
                new Date(this.flight.effectiveTimeOut).getTime()
              );
            });

            if (priorAssignments.length) {
              lastAssignment = priorAssignments[priorAssignments.length - 1];

              if (lastAssignment.destinationIata === this.flight.originIata) {
                filterOne.push(employee);
              }
            }
          }
        });

        if (this.lastName.length) {
          filterTwo = filterOne.filter((employee) => {
            return employee.surname
              .toUpperCase()
              .startsWith(this.lastName.toUpperCase());
          });
        } else {
          filterTwo = [...filterOne];
        }

        this.searchByStation = true;
      } else {
        if (this.lastName.length) {
          filterTwo = this.employees.filter((employee) => {
            return employee.surname
              .toUpperCase()
              .startsWith(this.lastName.toUpperCase());
          });
        } else {
          filterTwo = [...this.employees];
        }
      }

      if (this.selectedCompany !== "All Companies") {
        filterThree = filterTwo.filter((employee) => {
          return this.selectedCompany === employee.companyName;
        });
      } else {
        filterThree = [...filterTwo];
      }

      switch (this.board.type) {
        case "Cargo":
          filterFour = filterThree.filter((item) => {
            return this.cargoJobTitles.includes(item.jobTitle);
          });
          break;
        case "Passenger":
          filterFour = filterThree.filter((item) => {
            return this.passengerJobTitles.includes(item.jobTitle);
          });
          break;

        default:
          filterFour = [...filterThree];
          break;
      }

      this.filteredEmployees = [...filterFour];
    },

    toggleSearchByStation() {
      this.searchByStation = !this.searchByStation;
      this.filterEmployees();
    },

    getBidDayStyle(bidDays, day) {
      //bidDays are the employee bid days as an array of objects
      //day is the day of the board as a ISO string in zulu

      let bidDay;
      let backgroundColor = "white";

      if (bidDays.length) {
        bidDay = bidDays.find((bidDay) => {
          return (
            new Date(
              Date.UTC(bidDay.year, bidDay.month, bidDay.day)
            ).getTime() === new Date(day).getTime()
          );
        });

        if (bidDay) {
          switch (bidDay.code) {
            case "DO":
              backgroundColor = "#E74C3C";
              break;
            case "RES":
              backgroundColor = "#2ECC71";
              break;
            case "VAC":
              backgroundColor = "#A569BD";
              break;
            default:
              break;
          }
        }
      }

      return backgroundColor;
    },

    getStatusText(day) {
      //Bidline
      if (this.scale <= 16) {
        return day.status.description;
      } else {
        return day.status.code;
      }
    },

    goBack() {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.is-assigned {
  background-color: #c3f5c3;
  font-weight: 700;
}

.crew-frame {
  border: 1px solid #bebebe;
  overflow: auto;
}

.remove-icon:hover {
  color: red;
}

.r-row {
  border-bottom: 1px solid #bebebe;
  margin: auto;
}

.this-flight {
  padding: 0 5px;
  cursor: pointer;
}

/* .flight-shadow {
  border-left: 1px dashed blue;
  border-right: 1px dashed blue;
  background-color: #a8a8f5b2;
  opacity: 0.5;
  position: absolute;
  cursor: pointer;
} */
</style>
