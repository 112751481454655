<template>
  <div v-if="employee" class="r-dialog">
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <div v-if="loading">
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <div class="d-flex justify-center">
            <v-progress-circular
              :size="60"
              :width="7"
              color="#6495ED"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-dialog>
    </div>
    <div v-else>
      <div class="assignment-frame" v-if="!warnings">
        <div class="d-flex justify-space-between">
          <h2 class="header-title">NEW ASSIGNMENT</h2>
          <v-btn @click="handleCancel" small icon><v-icon>close</v-icon></v-btn>
        </div>
        <v-container>
          <v-form ref="form" v-model="isValid">
            <div class="d-flex align-center my-1">
              <h2 class="font-weight-bold">
                {{ `${employee.surname}, ${employee.givenName}` }}
              </h2>
              <h3 class="font-weight-bold indigo--text ml-3">
                {{ `(${employee.companyName} - ${employee.employeeId})` }}
              </h3>
            </div>

            <!-- GROUND ASSIGNMENT CATEGORY AND SUBCATEGORY -->
            <v-row dense>
              <v-col cols="6">
                <v-select
                  v-model="groundAssignmentCategory"
                  @input="getGroundAssignmentSubCategories"
                  :items="groundAssignmentCategories"
                  :rules="groundAssignmentCategoryRules"
                  label="Category"
                ></v-select>
              </v-col>

              <v-col v-if="groundAssignmentSubCategories.length" cols="6">
                <v-select
                  v-model="groundAssignmentSubCategory"
                  :items="groundAssignmentSubCategories"
                  label="Sub-Category"
                ></v-select>
              </v-col>
            </v-row>

            <!-- GROUND ASSIGNMENT  LOCATIONS -->
            <v-row dense v-if="showStation">
              <v-col cols="6">
                <v-text-field
                  prepend-icon="location_on"
                  label="IATA Code"
                  placeholder="Airport Code"
                  v-model="groundAssignmentOriginIata"
                  maxlength="3"
                  :rules="originCodeRules"
                >
                </v-text-field>
              </v-col>

              <v-col v-if="groundAssignmentCategory === 'Travel'" cols="6">
                <v-text-field
                  prepend-icon="location_on"
                  label="IATA Code"
                  placeholder="Airport Code"
                  v-model="groundAssignmentDestinationIata"
                  maxlength="3"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <!-- START DATE AND TIME -->
            <v-row dense v-if="groundAssignmentCategory.length">
              <v-col cols="6">
                <v-menu
                  v-model="showGroundAssignmentStartCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="groundAssignmentStartDate"
                      label="Start Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="groundAssignmentStartDate"
                    no-title
                    @input="showGroundAssignmentStartCalendar = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col v-if="showTimes" cols="6">
                <v-text-field
                  v-model="groundAssignmentStartTime"
                  label="Start Time"
                  type="time"
                  suffix="UTC"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- END DATE AND TIME -->
            <v-row dense v-if="groundAssignmentCategory.length">
              <v-col cols="6">
                <v-menu
                  v-model="showGroundAssignmentEndCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="groundAssignmentEndDate"
                      label="End Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="groundAssignmentEndDate"
                    no-title
                    @input="showGroundAssignmentEndCalendar = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col v-if="showTimes" cols="6">
                <v-text-field
                  v-model="groundAssignmentEndTime"
                  label="End Time"
                  type="time"
                  suffix="UTC"
                ></v-text-field>
              </v-col>
            </v-row>

            <div
              v-if="
                groundAssignmentCategory === 'Travel' ||
                  groundAssignmentCategory === 'Layover'
              "
            >
              <v-checkbox
                v-model="groundAssignmentConfirmed"
                :label="confirmationMessage"
              ></v-checkbox>
            </div>

            <!-- ASSIGNMENT REMARKS -->
            <v-textarea
              class="mt-2"
              outlined
              v-model="assignmentRemarks"
              :rules="remarksRules"
              label="Remarks"
              no-resize
              rows="3"
            >
            </v-textarea>
          </v-form>
        </v-container>
        <div class="d-flex justify-end align-center font-abel-16px">
          <v-btn
            @click="handlePrecheckGroundAssignment"
            small
            dark
            color="green"
            :disabled="!isValid || snackbarVisible"
            >save</v-btn
          >
          <v-btn @click="handleCancel" class="ml-1" small dark color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
      <div v-else class="warnings-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            Warnings
          </h2>
          <v-btn @click="handleCancel" small icon><v-icon>close</v-icon></v-btn>
        </div>

        <div v-if="employee" class="font-abel-24px-bold mb-2">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>

        <!-- WARNINGS -->
        <v-text-field
          v-if="warnings.length > 1"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          :style="{
            maxWidth: '200px',
            marginLeft: '10px',
            marginBottom: '10px',
          }"
        ></v-text-field>

        <v-data-table
          dense
          :headers="headers"
          :search="search"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div v-if="hardWarnings.length > 0" class="disclaimer-text">
          <div
            v-if="
              user.securityGroup.authorizations.groundAssignment
                .overrideWarnings
            "
          >
            This assignment contains hard warnings.
          </div>
          <div v-else>
            This assignment contains hard warnings and cannot be saved.
          </div>
        </div>

        <div class="d-flex justify-space-between align-center font-abel-16px">
          <div class="d-flex justify-center font-abel-16px my-2">
            <span class="mr-2">Atlas Air Online Training Center</span>
            <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
          </div>

          <div v-if="hardWarnings.length > 0">
            <div
              v-if="
                user.securityGroup.authorizations.groundAssignment
                  .overrideWarnings
              "
            >
              <v-btn @click="handleSaveAssignment" small dark color="orange"
                >Acknowledge and Continue</v-btn
              >
            </div>
            <div v-else>
              <v-btn @click="handleCancel" small dark color="grey"
                >cancel</v-btn
              >
            </div>
          </div>
          <div v-else>
            <v-btn @click="handleSaveAssignment" small dark color="blue"
              >Acknowledge and Continue</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "groundAssignment",
  props: ["groundAssignmentStart", "employee"],
  mixins: [mixin],
  data() {
    return {
      loading: false,
      isValid: true,
      warnings: null,
      groundAssignment: null,
      showGroundAssignmentStartCalendar: false,
      showGroundAssignmentEndCalendar: false,
      groundAssignmentCategory: "",
      groundAssignmentConfirmed: false,
      groundAssignmentSubCategory: "",
      groundAssignmentStartDate: new Date().toISOString().substring(0, 10),
      groundAssignmentStartTime: "00:00",
      groundAssignmentEndDate: new Date().toISOString().substring(0, 10),
      groundAssignmentEndTime: "23:59",
      groundAssignmentStartDateTime: new Date(),
      groundAssignmentEndDateTime: new Date(),
      groundAssignmentDuration: 0,
      groundAssignmentOriginIata: "",
      groundAssignmentDestinationIata: "",
      groundAssignmentCategories: [
        "Stand By",
        "Special Assignment",
        "Travel",
        "Layover",
        "Training",
        "Sick",
        "Quarantine",
        "Quarantine - Day OFF",
        "Vacation",
        "Holiday",
        "Floating Holiday",
        "Leave",
        "LOA",
        "Do Not Use",
      ],
      groundAssignmentSubCategories: [],
      groundAssignmentCategoryRules: [
        (v) => !!v || "Assignment Type is required",
      ],
      assignmentRemarks: "",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      originCodeRules: [
        (v) => !!v || "IATA code is required",
        (v) => v.length === 3 || "Enter 3 characters",
      ],
      destinationCodeRules: [
        (v) => !!v || "IATA code is required",
        (v) => v.length === 3 || "Enter 3 characters",
      ],
      showStation: false,
      showTimes: false,
      minimumDuration: 10,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      search: "",
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
    };
  },
  created() {
    this.warnings = null;
    const groundAssignmentEnd = this.groundAssignmentStart + 8 * 60 * 60 * 1000;

    this.groundAssignmentStartDate = new Date(this.groundAssignmentStart)
      .toISOString()
      .substring(0, 10);
    this.groundAssignmentStartTime = new Date(this.groundAssignmentStart)
      .toISOString()
      .substring(11, 16);

    this.groundAssignmentEndDate = new Date(groundAssignmentEnd)
      .toISOString()
      .substring(0, 10);
    this.groundAssignmentEndTime = new Date(groundAssignmentEnd)
      .toISOString()
      .substring(11, 16);
  },

  computed: {
    confirmationMessage() {
      if (this.groundAssignmentCategory === "Travel") {
        if (this.groundAssignmentConfirmed) {
          return "Travel arrangements are confirmed.";
        } else {
          return "Check this box to confirm travel arrangements.";
        }
      } else if (this.groundAssignmentCategory === "Layover") {
        if (this.groundAssignmentConfirmed) {
          return "Accomodation arrangements are confirmed.";
        } else {
          return "Check this box to confirm accomodation arrangements.";
        }
      } else {
        return "";
      }
    },
    user() {
      return this.$store.getters.user;
    },
    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
  },

  methods: {
    async handlePrecheckGroundAssignment() {
      this.warnings = null;

      let d1;
      let d2;

      let origin;
      let destination;

      switch (this.groundAssignmentCategory) {
        case "Special Assignment":
        case "Layover":
        case "Training":
        case "Do Not Use":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;

          break;
        case "Stand By":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return airport.iata === this.employee.gatewayCode;
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };

          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;

          break;
        case "Sick":
        case "Quarantine":
        case "Quarantine - Day OFF":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };

          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;
          break;

        case "Travel":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Check Destination IATA
          destination = this.companyAirports.find((airport) => {
            return (
              airport.iata ===
              this.groundAssignmentDestinationIata.toUpperCase()
            );
          });

          if (!destination) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentDestinationIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;
          break;
        case "Vacation":
        case "Holiday":
        case "LOA":
        case "Floating Holiday":
        case "Leave":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return airport.iata === this.employee.gatewayCode;
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };
          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;
          break;

        default:
          break;
      }

      this.groundAssignmentStartDateTime = new Date(d1);
      this.groundAssignmentEndDateTime = new Date(d2);

      this.groundAssignmentDuration = Math.ceil(
        (this.groundAssignmentEndDateTime.getTime() -
          this.groundAssignmentStartDateTime.getTime()) /
          60 /
          1000
      );

      if (this.groundAssignmentDuration <= this.minimumDuration) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `The minimum duration for a ground assignment is ${this.minimumDuration} minutes`;
        this.snackbarVisible = true;
        this.loading = false;
        this.readOnly = true;
        return;
      }

      if (this.groundAssignmentCategory === "Travel") {
        if (this.groundAssignmentDuration > 1 * 24 * 60) {
          this.snackbarColor = "#F44336";
          this.snackbarText = `The maximum duration for a travel assignment is 1 day`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      } else {
        if (this.groundAssignmentDuration > 31 * 24 * 60) {
          this.snackbarColor = "#F44336";
          this.snackbarText = `The maximum duration for a ground assignment is 31 days`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      }

      this.groundAssignment = {
        category: this.groundAssignmentCategory,
        subCategory: this.groundAssignmentSubCategory,
        startTime: this.groundAssignmentStartDateTime,
        endTime: this.groundAssignmentEndDateTime,
        duration: this.groundAssignmentDuration,
        originIata: origin.iata,
        destinationIata: destination.iata,
        originCountry: origin.country,
        destinationCountry: destination.country,
        confirmed: this.groundAssignmentConfirmed,
        employee_id: this.employee._id,
        remarks: this.assignmentRemarks,
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "precheckGroundAssignment",
        this.groundAssignment
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (res.data.warnings.length) {
        this.warnings = res.data.warnings;
        return;
      } else {
        this.warnings = [];
        this.handleSaveAssignment();
      }
    },

    async handleSaveAssignment() {
      this.loading = true;
      const res = await this.$store.dispatch("saveGroundAssignment", {
        groundAssignment: this.groundAssignment,
        warnings: this.warnings,
      });

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("hide", true);
    },

    handleCancel() {
      this.$emit("hide", false);
    },

    getGroundAssignmentSubCategories() {
      switch (this.groundAssignmentCategory) {
        case "Special Assignment":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;
        case "Stand By":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;
        case "Floating Holiday":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;
        case "Leave":
          this.groundAssignmentSubCategories = [
            "FMLA",
            "Non-FMLA",
            "Medical",
            "Military",
            "Parental",
            "Personal",
            "Bereavement",
          ];
          this.showStation = false;
          this.showTimes = false;
          break;

        case "LOA":
          this.groundAssignmentSubCategories = [
            "Jury Duty",
            "Long Term Disability",
            "Short Term Disability",
            "Workers' Compensation",
          ];
          this.showStation = false;
          this.showTimes = false;
          break;
        case "Holiday":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;

        case "Layover":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;

        case "Do Not Use":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;

        case "Quarantine":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = false;
          break;

        case "Quarantine - Day OFF":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = false;
          break;

        case "Sick":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = false;
          break;
        case "Training":
          this.groundAssignmentSubCategories = ["Ground School", "SIM"];
          this.showStation = true;
          this.showTimes = true;
          break;
        case "Travel":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;
        case "Vacation":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;

        default:
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;
      }

      if (this.groundAssignmentSubCategories.length) {
        const i = this.groundAssignmentSubCategories.findIndex((item) => {
          return item === this.employee.jobTitle;
        });

        if (i >= 0) {
          this.groundAssignmentSubCategory = this.groundAssignmentSubCategories[
            i
          ];
        } else {
          this.groundAssignmentSubCategory = this.groundAssignmentSubCategories[0];
        }
      } else {
        this.groundAssignmentSubCategory = "NA";
      }
    },
  },
};
</script>

<style scoped>
.header-title {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: var(--dark-blue);
}

.disclaimer-text {
  border: 1px solid var(--red);
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--red);
}

.warnings-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.assignment-frame {
  border-style: solid;
  border-color: var(--brown);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}
</style>
