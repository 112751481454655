<template>
  <div class="r-dialog">
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <!-- LOADING -->
    <div v-if="loading">
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>
    <div v-else>
      <div v-if="state === 'view'" class="assignment-frame">
        <div class="d-flex justify-space-between">
          <div class="d-flex justify-start align-center">
            <h2 class="header-title mr-3">ASSIGNMENT</h2>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="loading">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list dense color="#D2D6D7">
                <!-- EDIT ASSIGNMENT -->
                <v-list-item
                  v-if="enableUpdateGroundAssignment"
                  @click="handleBeginEdit"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-pen"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- SEND NOTIFICATION -->
                <v-list-item
                  v-if="enableCreateAssignmentNotification"
                  @click="handleSendAssignmentNotification"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-envelope"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Send Notification</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- DELETE ASSIGNMENT -->
                <v-list-item
                  v-if="enableDeleteGroundAssignment"
                  @click="handleDeleteGroundAssignment()"
                  link
                >
                  <v-list-item-icon>
                    <i class="fa-solid fa-trash-can"></i>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-btn @click="handleCancel" small icon><v-icon>close</v-icon></v-btn>
        </div>

        <div class="d-flex align-center my-1">
          <h2 class="font-weight-bold">
            {{
              `${groundAssignment.employee.surname}, ${groundAssignment.employee.givenName}`
            }}
          </h2>
          <h3 class="font-weight-bold indigo--text ml-3">
            {{
              `(${groundAssignment.employee.companyName} - ${groundAssignment.employee.employeeId})`
            }}
          </h3>
        </div>

        <div class="d-flex justify-space-between align-center mb-2">
          <div class="font-abel-20px-bold" :style="confirmationStatusStyle">
            {{ `${formatGroundAssignmentSnapshot(groundAssignment)}` }}
          </div>
        </div>

        <v-divider class="my-1"></v-divider>

        <div>
          <span>Period:</span
          ><span class="font-abel-16px-bold ml-2">
            {{
              `${formatDateTime(
                groundAssignment.startTime
              )} -  ${formatDateTime(groundAssignment.endTime)}`
            }}</span
          >
        </div>

        <div>
          <span>Employee Phone:</span
          ><span class="font-abel-16px-bold ml-2">{{
            groundAssignment.employee.mobilePhone
          }}</span>
        </div>

        <div>
          <span>Assigned by:</span
          ><span class="font-abel-16px-bold ml-2">{{
            groundAssignment.assignedBy
          }}</span>
        </div>
        <div v-if="groundAssignment.warnings.length">
          <div class="assignment-warning" v-if="showAssignmentWarnings">
            <div class="flex-space-between">
              <div>
                The following warnings were active when the assignment was made:
              </div>
              <i
                @click="showAssignmentWarnings = false"
                class="fa-solid fa-xmark"
              ></i>
            </div>
            <ul>
              <li
                v-for="(warning, index) in groundAssignment.warnings"
                :key="index"
              >
                {{ warning.message }}
              </li>
            </ul>
          </div>
          <div v-else>
            <i
              @click="showAssignmentWarnings = true"
              class="fa-solid fa-triangle-exclamation mr-2"
            ></i>
            <span class="red--text font-weight-bold"
              >Assigned with Warnings</span
            >
          </div>
        </div>

        <div>
          <span>Last updated:</span
          ><span class="font-abel-16px-bold ml-2">{{
            formatDateTime(groundAssignment.updatedAt)
          }}</span>
        </div>

        <div
          v-if="!editRemarks"
          class="remarks"
          @click="handleEnableEditRemarks"
        >
          <p
            v-if="groundAssignment.remarks"
            class="user-select-text font-abel-14px  indigo--text mt-2"
          >
            {{ groundAssignment.remarks }}
          </p>
          <p v-else></p>
        </div>
        <div v-else>
          <v-textarea
            v-model="assignmentRemarks"
            no-resize
            rows="3"
            label="Remarks"
          ></v-textarea>
          <div class="d-flex justify-end">
            <v-btn @click="handleSaveRemarks" small dark color="green"
              >save remarks</v-btn
            >
            <v-btn
              @click="handleCancelEditRemarks"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else-if="state === 'confirm'" class="red-frame">
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            WARNING
          </h2>
          <v-btn @click="handleCancelDelete" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="my-5">
          <h2>
            Removing a Ground Assignment cannot be undone.
          </h2>
          <h4 class="grey--text">
            Please confirm to continue or cancel.
          </h4>
        </div>

        <div class="d-flex justify-space-between">
          <v-checkbox v-model="notify" label="Send Notification"></v-checkbox>
          <div class="d-flex justify-space-between align-center font-abel-16px">
            <v-btn @click="handleConfirmDelete" small dark color="red"
              >Confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleCancelDelete"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div class="edit-frame" v-if="!warnings">
          <div class="d-flex justify-space-between">
            <h2 class="header-title">EDIT ASSIGNMENT</h2>
            <v-btn @click="handleCancelEdit" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>
          <v-container>
            <v-form ref="form" v-model="isValid">
              <!-- GROUND ASSIGNMENT CATEGORY AND SUBCATEGORY -->
              <v-row dense>
                <v-col cols="6">
                  <v-select
                    v-model="groundAssignmentCategory"
                    @input="getGroundAssignmentSubCategories"
                    :items="groundAssignmentCategories"
                    :rules="groundAssignmentCategoryRules"
                    label="Category"
                  ></v-select>
                </v-col>

                <v-col v-if="groundAssignmentSubCategories.length" cols="6">
                  <v-select
                    v-model="groundAssignmentSubCategory"
                    :items="groundAssignmentSubCategories"
                    label="Sub-Category"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- GROUND ASSIGNMENT  LOCATIONS -->
              <v-row dense v-if="showStation">
                <v-col cols="6">
                  <v-text-field
                    prepend-icon="location_on"
                    label="IATA Code"
                    placeholder="Airport Code"
                    v-model="groundAssignmentOriginIata"
                    maxlength="3"
                    :rules="originCodeRules"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="groundAssignmentCategory === 'Travel'" cols="6">
                  <v-text-field
                    prepend-icon="location_on"
                    label="IATA Code"
                    placeholder="Airport Code"
                    v-model="groundAssignmentDestinationIata"
                    maxlength="3"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- START DATE AND TIME -->
              <v-row dense v-if="groundAssignmentCategory.length">
                <v-col cols="6">
                  <v-menu
                    v-model="showGroundAssignmentStartCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="groundAssignmentStartDate"
                        label="Start Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="groundAssignmentStartDate"
                      no-title
                      @input="showGroundAssignmentStartCalendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col v-if="showTimes" cols="6">
                  <v-text-field
                    v-model="groundAssignmentStartTime"
                    label="Start Time"
                    type="time"
                    suffix="UTC"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- END DATE AND TIME -->
              <v-row dense v-if="groundAssignmentCategory.length">
                <v-col cols="6">
                  <v-menu
                    v-model="showGroundAssignmentEndCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="groundAssignmentEndDate"
                        label="End Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="groundAssignmentEndDate"
                      no-title
                      @input="showGroundAssignmentEndCalendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col v-if="showTimes" cols="6">
                  <v-text-field
                    v-model="groundAssignmentEndTime"
                    label="End Time"
                    type="time"
                    suffix="UTC"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div
                v-if="
                  groundAssignmentCategory === 'Travel' ||
                    groundAssignmentCategory === 'Layover'
                "
              >
                <v-checkbox
                  v-model="groundAssignmentConfirmed"
                  :label="confirmationMessage"
                ></v-checkbox>
              </div>

              <!-- ASSIGNMENT REMARKS -->
              <v-textarea
                class="mt-2"
                outlined
                v-model="assignmentRemarks"
                :rules="remarksRules"
                label="Remarks"
                no-resize
                rows="3"
              >
              </v-textarea>
            </v-form>
          </v-container>

          <div class="flex-end font-abel-16px ">
            <v-btn
              @click="handlePrecheckGroundAssignment"
              small
              dark
              color="green"
              :disabled="!isValid || snackbarVisible"
              >save</v-btn
            >
            <v-btn
              @click="handleCancelEdit"
              class="ml-1"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
        <div v-else class="red-frame">
          <!-- TITLE -->
          <div class="d-flex justify-space-between">
            <h2 class="red--text">
              Warnings
            </h2>
            <v-btn @click="handleCancelEdit" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>

          <!-- WARNINGS -->
          <v-text-field
            v-if="warnings.length > 1"
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :style="{
              maxWidth: '200px',
              marginLeft: '10px',
              marginBottom: '10px',
            }"
          ></v-text-field>

          <v-data-table
            dense
            :headers="headers"
            :search="search"
            :items="warnings"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
          </v-data-table>

          <div v-if="hardWarnings.length > 0" class="disclaimer-text">
            <div
              v-if="
                user.securityGroup.authorizations.groundAssignment
                  .overrideWarnings
              "
            >
              This assignment contains hard warnings.
            </div>
            <div v-else>
              This assignment contains hard warnings and cannot be saved.
            </div>
          </div>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <div class="d-flex justify-center font-abel-16px my-2">
              <span class="mr-2">Atlas Air Online Training Center</span>
              <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
            </div>

            <div v-if="hardWarnings.length > 0">
              <div
                v-if="
                  user.securityGroup.authorizations.groundAssignment
                    .overrideWarnings
                "
              >
                <v-btn @click="handleSaveAssignment" small dark color="orange"
                  >Acknowledge and Continue</v-btn
                >
              </div>
              <div v-else>
                <v-btn @click="handleCancelEdit" small dark color="grey"
                  >cancel</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-btn @click="handleSaveAssignment" small dark color="blue"
                >Acknowledge and Continue</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "groundAssignmentInformation",
  mixins: [mixin],
  props: ["groundAssignment"],
  data() {
    return {
      loading: false,
      warnings: null,
      state: "view",
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      showAssignmentWarningsDialog: false,
      dialog: true,
      overrideWarnings: false,
      editRemarks: false,
      assignmentRemarks: "",
      sendNotification: true,
      readOnly: true,
      isValid: true,
      showOriginIata: false,
      showTimes: false,
      showConfirmationCheckBox: false,
      showGroundAssignmentStartCalendar: false,
      showGroundAssignmentEndCalendar: false,
      groundAssignmentCategory: "",
      groundAssignmentConfirmed: false,
      groundAssignmentSubCategory: "",
      groundAssignmentStartDate: new Date().toISOString().substring(0, 10),
      groundAssignmentStartTime: "00:00",
      groundAssignmentEndDate: new Date().toISOString().substring(0, 10),
      groundAssignmentEndTime: "23:59",
      groundAssignmentStartDateTime: new Date(),
      groundAssignmentEndDateTime: new Date(),
      groundAssignmentDuration: 0,
      groundAssignmentOriginIata: "",
      groundAssignmentDestinationIata: "",
      groundAssignmentCategories: [
        "Stand By",
        "Special Assignment",
        "Travel",
        "Layover",
        "Training",
        "Sick",
        "Quarantine",
        "Quarantine - Day OFF",
        "Vacation",
        "Holiday",
        "Floating Holiday",
        "Leave",
        "LOA",
        "Do Not Use",
      ],
      groundAssignmentSubCategories: [],
      groundAssignmentCategoryRules: [
        (v) => !!v || "Assignment Type is required",
      ],
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      originCodeRules: [
        (v) => !!v || "IATA code is required",
        (v) => v.length === 3 || "Enter 3 characters",
      ],
      destinationCodeRules: [
        (v) => !!v || "IATA code is required",
        (v) => v.length === 3 || "Enter 3 characters",
      ],
      showStation: false,
      search: "",
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      modifiedGroundAssignment: null,
      notify: false,
      showAssignmentWarnings: false,
    };
  },
  computed: {
    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
    confirmationStatusStyle() {
      switch (this.groundAssignment.category) {
        case "Travel":
        case "Layover":
          if (this.groundAssignment.confirmed) {
            return {
              color: "#66BB6A",
            };
          } else {
            return {
              color: "#F44336",
            };
          }

        default:
          return {
            color: "#000",
          };
      }
    },

    assignmentWarnings() {
      return this.$store.getters.assignmentWarnings;
    },
    confirmationMessage() {
      if (this.groundAssignmentCategory === "Travel") {
        if (this.groundAssignmentConfirmed) {
          return "Travel arrangements are confirmed.";
        } else {
          return "Check this box to confirm travel arrangements.";
        }
      } else if (this.groundAssignmentCategory === "Layover") {
        if (this.groundAssignmentConfirmed) {
          return "Accomodation arrangements are confirmed.";
        } else {
          return "Check this box to confirm accomodation arrangements.";
        }
      } else {
        return "";
      }
    },
  },
  created() {
    this.warnings = null;
  },
  methods: {
    handleBeginEdit() {
      if (
        !this.user.securityGroup.companies.includes(
          this.groundAssignment.employee.companyName
        )
      ) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "Not authorized. Please contact your administrator.";
        this.snackbarVisible = true;
        this.loading = false;
        this.readOnly = true;

        return;
      }

      this.warnings = null;
      this.state = "edit";

      this.groundAssignmentCategory = this.groundAssignment.category.slice();
      this.groundAssignmentSubCategory = this.groundAssignment.subCategory.slice();

      if (this.groundAssignment.remarks) {
        this.assignmentRemarks = this.groundAssignment.remarks.slice();
      } else {
        this.assignmentRemarks = " ";
      }

      this.getGroundAssignmentSubCategories();

      this.groundAssignmentConfirmed = this.groundAssignment.confirmed;
      this.groundAssignmentStartDate = new Date(this.groundAssignment.startTime)
        .toISOString()
        .substring(0, 10);
      this.groundAssignmentStartTime = new Date(this.groundAssignment.startTime)
        .toISOString()
        .substring(11, 16);
      this.groundAssignmentEndDate = new Date(this.groundAssignment.endTime)
        .toISOString()
        .substring(0, 10);
      this.groundAssignmentEndTime = new Date(this.groundAssignment.endTime)
        .toISOString()
        .substring(11, 16);
      this.groundAssignmentStartDateTime = new Date(
        this.groundAssignment.startTime
      );
      this.groundAssignmentEndDateTime = new Date(
        this.groundAssignment.endTime
      );
      this.groundAssignmentDuration = 0;
      this.groundAssignmentOriginIata = this.groundAssignment.originIata;
      this.groundAssignmentDestinationIata = this.groundAssignment.destinationIata;

      // this.readOnly = false;
    },

    handleCancelEdit() {
      this.state = "view";
    },

    async handleConfirmDelete() {
      this.loading = true;

      const res = await this.$store.dispatch("deleteGroundAssignment", {
        groundAssignment: { ...this.groundAssignment },
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (this.notify) {
        let payload = {
          assignment: {
            type: "Ground",
            employeeId: this.groundAssignment.employee._id,
            _id: this.groundAssignment._id,
            snapshot: this.formatGroundAssignmentNotification(
              this.groundAssignment
            ),
          },
          emailAddress: this.groundAssignment.employee.mail,
          secondaryMailAddress: this.groundAssignment.employee.secondaryMail,
          emailSubject: "Assignment Removed",
        };

        this.loading = true;

        const res = await this.$store.dispatch(
          "sendAssignmentNotification",
          payload
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
      }

      this.$emit("hide", true);
    },

    handleCancelDelete() {
      this.state = "view";
    },

    handleDeleteGroundAssignment() {
      this.state = "confirm";
    },

    getGroundAssignmentSubCategories() {
      switch (this.groundAssignmentCategory) {
        case "Special Assignment":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;
        case "Stand By":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;
        case "Floating Holiday":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;
        case "Leave":
          this.groundAssignmentSubCategories = [
            "FMLA",
            "Non-FMLA",
            "Medical",
            "Military",
            "Parental",
            "Personal",
            "Bereavement",
          ];
          this.showStation = false;
          this.showTimes = false;
          break;

        case "LOA":
          this.groundAssignmentSubCategories = [
            "Jury Duty",
            "Long Term Disability",
            "Short Term Disability",
            "Workers' Compensation",
          ];
          this.showStation = false;
          this.showTimes = false;
          break;
        case "Holiday":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;

        case "Layover":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;

        case "Do Not Use":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;

        case "Quarantine":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = false;
          break;

        case "Quarantine - Day OFF":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;

        case "Sick":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = false;
          break;
        case "Training":
          this.groundAssignmentSubCategories = ["Ground School", "SIM"];
          this.showStation = true;
          this.showTimes = true;
          break;
        case "Travel":
          this.groundAssignmentSubCategories = [];
          this.showStation = true;
          this.showTimes = true;
          break;
        case "Vacation":
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;

        default:
          this.groundAssignmentSubCategories = [];
          this.showStation = false;
          this.showTimes = false;
          break;
      }

      if (this.groundAssignmentSubCategories.length) {
        this.groundAssignmentSubCategory = this.groundAssignmentSubCategories[0];
      } else {
        this.groundAssignmentSubCategory = "NA";
      }
    },

    async handlePrecheckGroundAssignment() {
      this.warnings = null;

      let d1;
      let d2;

      let origin;
      let destination;

      switch (this.groundAssignmentCategory) {
        case "Special Assignment":
        case "Layover":
        case "Training":
        case "Do Not Use":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;

          break;
        case "Stand By":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return airport.iata === this.groundAssignment.employee.gatewayCode;
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };

          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;

          break;
        case "Sick":
        case "Quarantine":
        case "Quarantine - Day OFF":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };

          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;
          break;

        case "Travel":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return (
              airport.iata === this.groundAssignmentOriginIata.toUpperCase()
            );
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Check Destination IATA
          destination = this.companyAirports.find((airport) => {
            return (
              airport.iata ===
              this.groundAssignmentDestinationIata.toUpperCase()
            );
          });

          if (!destination) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentDestinationIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          d1 = `${this.groundAssignmentStartDate}T${this.groundAssignmentStartTime}:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T${this.groundAssignmentEndTime}:59.999+00:00`;
          break;
        case "Vacation":
        case "Holiday":
        case "LOA":
        case "Floating Holiday":
        case "Leave":
          //Check Origin IATA
          origin = this.companyAirports.find((airport) => {
            return airport.iata === this.groundAssignment.employee.gatewayCode;
          });

          if (!origin) {
            this.snackbarColor = "#F44336";
            this.snackbarText = `We couln't find an airport with IATA code ${this.groundAssignmentOriginIata.toUpperCase()}`;
            this.snackbarVisible = true;
            this.loading = false;
            this.readOnly = true;
            return;
          }

          //Make Destination equal to origin
          destination = { ...origin };
          d1 = `${this.groundAssignmentStartDate}T00:00:00.000+00:00`;
          d2 = `${this.groundAssignmentEndDate}T23:59:59.999+00:00`;
          break;

        default:
          break;
      }

      this.groundAssignmentStartDateTime = new Date(d1);
      this.groundAssignmentEndDateTime = new Date(d2);

      this.groundAssignmentDuration = Math.ceil(
        (this.groundAssignmentEndDateTime.getTime() -
          this.groundAssignmentStartDateTime.getTime()) /
          60 /
          1000
      );

      if (this.groundAssignmentDuration <= this.minimumDuration) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `The minimum duration for a ground assignment is ${this.minimumDuration} minutes`;
        this.snackbarVisible = true;
        this.loading = false;
        this.readOnly = true;
        return;
      }

      if (this.groundAssignmentCategory === "Travel") {
        if (this.groundAssignmentDuration > 1 * 24 * 60) {
          this.snackbarColor = "#F44336";
          this.snackbarText = `The maximum duration for a travel assignment is 1 day`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      } else {
        if (this.groundAssignmentDuration > 31 * 24 * 60) {
          this.snackbarColor = "#F44336";
          this.snackbarText = `The maximum duration for a ground assignment is 31 days`;
          this.snackbarVisible = true;
          this.loading = false;
          this.readOnly = true;
          return;
        }
      }

      this.modifiedGroundAssignment = {
        _id: this.groundAssignment._id,
        category: this.groundAssignmentCategory,
        subCategory: this.groundAssignmentSubCategory,
        startTime: this.groundAssignmentStartDateTime,
        endTime: this.groundAssignmentEndDateTime,
        duration: this.groundAssignmentDuration,
        originIata: origin.iata,
        destinationIata: destination.iata,
        originCountry: origin.country,
        destinationCountry: destination.country,
        confirmed: this.groundAssignmentConfirmed,
        employee_id: this.groundAssignment.employee._id,
        remarks: this.assignmentRemarks,
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "precheckGroundAssignment",
        this.modifiedGroundAssignment
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (res.data.warnings.length) {
        this.warnings = res.data.warnings;
        return;
      } else {
        this.warnings = [];
        this.handleSaveAssignment();
      }
    },

    async handleSaveAssignment() {
      this.loading = true;
      const res = await this.$store.dispatch("saveGroundAssignment", {
        groundAssignment: this.modifiedGroundAssignment,
        warnings: this.warnings,
      });

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("hide", true);
    },

    handleEnableEditRemarks() {
      if (!this.enableUpdateGroundAssignment) {
        return;
      }

      if (this.groundAssignment.remarks) {
        this.assignmentRemarks = this.groundAssignment.remarks.slice();
      } else {
        this.assignmentRemarks = " ";
      }

      this.editRemarks = true;
    },

    async handleSaveRemarks() {
      this.loading = true;

      const res = await this.$store.dispatch("saveGroundAssignmentRemarks", {
        _id: this.groundAssignment._id,
        remarks: this.assignmentRemarks,
      });

      this.loading = false;
      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("hide", true);
    },

    handleCancelEditRemarks() {
      this.editRemarks = false;
    },

    handleCancel() {
      this.$emit("hide", false);
    },
    async handleSendAssignmentNotification() {
      let payload = {
        assignment: {
          type: "Ground",
          employeeId: this.groundAssignment.employee._id,
          _id: this.groundAssignment._id,
          snapshot: this.formatGroundAssignmentNotification(
            this.groundAssignment
          ),
        },
        emailAddress: this.groundAssignment.employee.mail,
        secondaryMailAddress: this.groundAssignment.employee.secondaryMail,
        emailSubject: "Assignment",
      };

      this.loading = true;

      const res = await this.$store.dispatch(
        "sendAssignmentNotification",
        payload
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.snackbarColor = "#66BB6A";
      this.snackbarText = res.data.message;
      this.snackbarVisible = true;
    },
  },
};
</script>

<style scoped>
.remarks {
  background-color: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}

p {
  width: 100%;
  min-height: 100px;
}

.header-title {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: var(--dark-blue);
}

.disclaimer-text {
  border: 1px solid var(--red);
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--red);
}

.red-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.edit-frame {
  border-style: solid;
  border-color: var(--amber);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

.assignment-frame {
  border-style: solid;
  border-color: var(--brown);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

.fa-trash-can {
  color: var(--red);
}

.fa-trash-check {
  color: var(--purple);
}

.fa-envelope {
  color: var(--blue);
}

.fa-pen {
  color: var(--green);
}

i {
  cursor: pointer;
}

.fa-triangle-exclamation {
  color: var(--amber);
  cursor: pointer;
}

.assignment-warning {
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 5px;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  background-color: var(--amber);
  color: #000;
}

.assignment-warning div {
  font-size: 1.1rem;
}

.assignment-warning ul {
  list-style-type: decimal-leading-zero;
}
</style>
