<template>
  <div class="r-dialog-yellow">
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <div class="d-flex justify-space-between">
      <div class="font-abel-24px-bold mb-2">
        Employee Filter
      </div>
      <v-btn @click="hide" small icon><v-icon>close</v-icon></v-btn>
    </div>

    <div>
      <v-select
        v-model="selectedJobTitles"
        :items="availableJobTitles"
        :menu-props="{ maxHeight: '400' }"
        label="Job Titles"
        multiple
        clearable
      ></v-select>
    </div>

    <p v-if="!selectedJobTitles.length">
      Please select at least one Job Title to save the filter
    </p>

    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <v-btn
          @click="selectAll"
          small
          dark
          color="blue "
          class="text-capitalize"
          >Select All</v-btn
        >
        <v-btn
          @click="selectNone"
          small
          dark
          color="blue darken-2"
          class="text-capitalize ml-1"
          >Select None</v-btn
        >
      </div>

      <div class="d-flex">
        <v-btn
          @click="save"
          small
          dark
          color="green"
          :disabled="selectedJobTitles.length === 0"
          >save</v-btn
        >

        <v-btn @click="hide" class="ml-1" small dark color="grey">cancel</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
//import { mixin } from "../mixins/mixin";

export default {
  name: "employeeFilter",
  //mixins: [mixin],
  data() {
    return {
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      availableJobTitles: [],
      selectedJobTitles: [],
    };
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.availableJobTitles = [...this.user.securityGroup.jobTitles];
        this.selectedJobTitles = [];

        this.user.settings.filters.employees.jobTitles.forEach((item) => {
          if (this.user.securityGroup.jobTitles.includes(item)) {
            this.selectedJobTitles.push(item);
          }
        });
      },
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    selectAll() {
      this.availableJobTitles = [...this.user.securityGroup.jobTitles];
      this.selectedJobTitles = [];

      this.user.securityGroup.jobTitles.forEach((item) => {
        this.selectedJobTitles.push(item);
      });
    },
    selectNone() {
      this.selectedJobTitles = [];
    },
    hide() {
      this.$emit("hide");
    },

    save() {
      this.$emit("save", {
        selectedJobTitles: this.selectedJobTitles,
      });
    },
  },
};
</script>

<style scoped>
p {
  color: red;
  font-size: 0.9rem;
}
</style>
