<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <v-btn @click="monthFilterBack" x-small icon dark :disabled="loading"
        ><v-icon>chevron_left</v-icon></v-btn
      >

      <v-btn
        :disabled="loading"
        class="mx-2"
        @click="monthFilterDialog = true"
        small
        outlined
        color="white"
        >{{ formatMonthShort(selectedMonth) }} - {{ selectedYear }}</v-btn
      >

      <v-btn @click="monthFilterForward" x-small icon dark :disabled="loading"
        ><v-icon>chevron_right</v-icon></v-btn
      >

      <v-spacer></v-spacer>

      <v-slider
        @change="scrollBoard"
        class="ml-1"
        v-model="slider"
        :max="sliderMax"
        :min="0"
        hide-details
      >
      </v-slider>
      <v-spacer></v-spacer>

      <!-- Refresh -->
      <div>
        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>
      </div>

      <!-- Zoom -->
      <div class="d-flex">
        <v-btn :disabled="loading" @click="zoomIn" small icon class="ml-1">
          <v-icon color="blue">zoom_in</v-icon>
        </v-btn>

        <v-btn :disabled="loading" @click="zoomOut" small icon>
          <v-icon color="white">zoom_out</v-icon>
        </v-btn>
      </div>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#D2D6D7">
          <!-- Activity Report -->
          <v-list-item
            v-if="enableReadEmployeePayrollReport"
            @click="showActivityReport"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Activity Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-end">
      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- Month Picker -->
          <v-list-item @click="monthFilterDialog = true" link>
            <v-list-item-icon>
              <v-icon color="white">event</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Select Month</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Refresh -->
          <v-list-item @click="getAssignments" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom In -->
          <v-list-item @click="zoomIn" link>
            <v-list-item-icon>
              <v-icon color="blue">zoom_in</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom Out -->
          <v-list-item @click="zoomOut" link>
            <v-list-item-icon>
              <v-icon color="white">zoom_out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Activity Report -->
          <v-list-item
            v-if="enableReadEmployeePayrollReport"
            @click="showActivityReport"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Activity Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- ACTIVITY REPORT DIALOG -->
    <v-dialog
      v-model="activityReportDialog"
      persistent
      max-width="600px"
      transition="slide-x-transition"
    >
      <div
        v-if="employee && employee.activity"
        class="blue-frame font-abel-16px"
      >
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-30px">
            {{ `${employee.surname}, ${employee.givenName} ` }}
          </div>

          <v-btn @click="hideActivityReport" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <v-divider></v-divider>

        <div class="d-flex justify-space-between  align-center">
          <div class="font-abel-20px">
            {{ formatMonthShort(selectedMonth) }} - {{ selectedYear }}
          </div>
          <v-btn
            class="ml-2"
            @click="printActivityReport(employee)"
            small
            icon
            color="blue"
            ><v-icon>print</v-icon></v-btn
          >
        </div>

        <v-container>
          <v-row>
            <v-col cols="12" class="report-panel-column-green">
              <!-- ROW 1 -->
              <v-row dense>
                <v-col cols="4">
                  <div>
                    Work Days 01-15 :
                    <span class="font-weight-bold">
                      {{ employee.activity.workDaysFirstHalf }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    Work Days 16-31:
                    <span class="font-weight-bold"
                      >{{ employee.activity.workDaysSecondHalf }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    Work Days Total:
                    <span class="font-weight-bold">
                      {{ employee.activity.workDays }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <!-- ROW 2 -->
              <v-row dense>
                <v-col cols="4">
                  <div>
                    Vacation:
                    <span class="font-weight-bold">
                      {{ employee.activity.vacationDays }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    Floating Holidays:<span class="font-weight-bold"
                      >{{ employee.activity.floatingHolidayDays }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    Holidays:
                    <span class="font-weight-bold">
                      {{ employee.activity.holidayDays }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <!-- ROW 3 -->
              <v-row dense>
                <v-col cols="4">
                  <div>
                    Sick Days:
                    <span class="font-weight-bold"
                      >{{ employee.activity.sickDays }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    LOA:
                    <span class="font-weight-bold">
                      {{ employee.activity.loaDays }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    Leave:
                    <span class="font-weight-bold"
                      >{{ employee.activity.leaveDays }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <!-- ROW 4 -->
              <!-- PERDIEM -->
              <v-row dense>
                <v-col cols="4">
                  <div>
                    Perdiem :
                    <span class="font-weight-bold"
                      >{{ employee.activity.perdiemDays }}
                    </span>
                  </div>
                </v-col>

                <!-- TEMP DISABLED -->
                <!-- <v-col cols="4">
                  <div>
                    International Perdiem :
                    <span class="font-weight-bold"
                      >{{ employee.activity.internationalPerdiemDays }}
                    </span>
                  </div>
                </v-col> -->
              </v-row>

              <!-- ROW 5 -->
              <!-- PERDIEM -->
              <v-row dense>
                <v-col cols="4">
                  <div>
                    TOTAL DAYS:
                    <span class="font-weight-bold"
                      >{{ employee.activity.totalDays }}
                    </span>
                  </div>
                </v-col>

                <v-col cols="4">
                  <div>
                    Days OFF/STBY:
                    <span class="font-weight-bold"
                      >{{ employee.activity.daysOff }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <!-- ROW 6 -->
              <v-row dense>
                <v-col cols="4">
                  <div>
                    Mission Hazardous :<span class="font-weight-bold red--text">
                      {{ employee.activity.missionHazard }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    COVID 19 Days:
                    <span class="font-weight-bold red--text">
                      {{ employee.activity.healthRiskDays }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="4">
                  <div>
                    COVID 19 Allowance:
                    <span class="font-weight-bold red--text">
                      {{ employee.activity.healthRiskAllowance }}</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <div class="mb-2">
          <div class="font-abel-16px-bold ml-2 indigo--text">
            {{
              `This report covers the entire month, calculated as of ${formatDateTime(
                new Date()
              )}`
            }}
          </div>
          <div class="font-abel-14px ml-2 grey--text">
            {{
              `Valid submission period: ${formatDateTime(
                submissionStart
              )} to ${formatDateTime(submissionEnd)}`
            }}
          </div>
        </div>

        <div
          v-if="isValidSubmissionPeriod"
          class="d-flex justify-space-between align-center mb-2"
        >
          <v-checkbox
            v-model="confirmed"
            label="I Confirm that this report is accurate"
          ></v-checkbox>

          <div class="d-flex justify-end align-center">
            <v-btn
              :disabled="!confirmed"
              @click="submitReport"
              small
              dark
              color="blue"
              class="ml-1"
              >submit</v-btn
            >
            <v-btn
              @click="hideActivityReport"
              small
              dark
              color="grey"
              class="ml-1"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <v-dialog v-model="specialAirportsDialog" persistent max-width="800px">
      <SpecialAirports @hide="specialAirportsDialog = false" />
    </v-dialog>

    <!-- ASSIGNMENT LIST DIALOG-->
    <v-dialog v-model="assignmentsListDialog" max-width="1200px">
      <EmployeeAssignmentsList
        v-if="employee"
        :employee="employee"
        @hide="assignmentsListDialog = false"
      />
    </v-dialog>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <v-dialog
      v-model="showEmployeeInformationDialog"
      persistent
      max-width="800px"
    >
      <employee-information
        :employee="employee"
        @close="handleHideEmployeeInformationDialog"
      ></employee-information>
    </v-dialog>

    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showFlightAssignmentInformationDialog" max-width="800px">
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </v-dialog>

    <!-- GROUND ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showGroundAssignmentInformationDialog" max-width="800px">
      <ground-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @hide="hideGroundAssignmentInformationDialog($event)"
      >
      </ground-assignment-information>
    </v-dialog>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-if="showStationAssignmentInformationDialog"
      v-model="showStationAssignmentInformationDialog"
      max-width="800px"
    >
      <station-assignment-information
        v-if="groundAssignmentStation"
        :groundAssignment="groundAssignmentStation"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </v-dialog>

    <!-- MONTH FILTER DIALOG-->
    <v-dialog v-model="monthFilterDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="mx-3 mb-2 font-abel-24px text-center">Select Month</div>

        <v-date-picker v-model="monthPicker" type="month"></v-date-picker>

        <div class="d-flex justify-end align-center my-2">
          <v-btn @click="saveMonthFilter" small dark color="blue">select</v-btn>
        </div>
      </div>
    </v-dialog>

    <!-- FLIGHT BOARD -->
    <div>
      <div class="top-frame font-abel-16px">
        <div class="top-panel-left">Employee</div>
        <div class="top-panel-right">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${panelWidth}px`,
              left: `${daysX}px`,
            }"
          >
            <div
              v-for="(day, index) in monthFilter.days"
              :key="index"
              class="report-top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}

              <div v-if="scale < 8" class="d-flex font-abel-10px">
                <div
                  v-bind:style="{
                    width: `${30 / scale}px`,
                  }"
                ></div>

                <div
                  v-for="tick in timeTicks"
                  :key="tick.index"
                  v-bind:style="{
                    width: `${60 / scale}px`,
                  }"
                >
                  <div>{{ tick.text }}</div>
                </div>
              </div>
            </div>

            <div v-if="scale === 48" class="d-flex">
              <div
                v-for="(column, index) in reportColumns"
                :key="index"
                class="report-top-panel-column-title font-abel-10px"
                :style="{ width: `${columnWidth}px` }"
              >
                <div>
                  {{ column.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="employee" class="bottom-frame">
        <!-- LEFT PANEL -->
        <div class="bottom-panel-left">
          <div
            class="bottom-panel-left-row font-abel-14px cursor-hand"
            @click="handleShowEmployeeInformationDialog(employee)"
          >
            <div
              class="bottom-panel-left-row-section truncate font-abel-12px-bold"
            >
              {{ employee.surname }}, {{ employee.givenName.substring(0, 1) }}
            </div>

            <div class="bottom-panel-left-row-section truncate font-abel-10px">
              {{ employee.companyName }} - {{ employee.jobTitle }}
            </div>
            <div class=" font-abel-12px bottom-panel-left-row-section truncate">
              {{ employee.gatewayCode }} - {{ employee.employeeId }}
            </div>
          </div>
        </div>

        <!-- RIGHT PANEL -->
        <div
          class="bottom-panel-right"
          @scroll="scrollDays"
          ref="bottomPanelRight"
        >
          <div
            class="bottom-panel-right-inner"
            :style="{
              width: `${panelWidth}px`,
              height: `${panelHeight}px`,
            }"
          >
            <div
              class="bottom-panel-right-row"
              :style="{
                width: `${panelWidth}px`,
              }"
            >
              <div>
                <div
                  v-for="(day, index) in monthFilter.days"
                  :key="index"
                  class="bottom-panel-day"
                  :style="{
                    width: `${1440 / scale}px`,
                    left: `${index * (1440 / scale)}px`,
                    backgroundColor: getBidDayStyle(employee.bidDays, day),
                    opacity: 0.5,
                    cursor: 'pointer',
                  }"
                >
                  <div v-if="scale < 8" class="d-flex font-abel-10px">
                    <div
                      class="hour-line"
                      v-for="tick in timeTicks"
                      :key="tick.index"
                      v-bind:style="{
                        width: `${60 / scale}px`,
                      }"
                    ></div>
                  </div>
                </div>
                <!-- Assignments -->
                <div>
                  <div
                    v-for="assignment in employee.assignments"
                    :key="assignment._id"
                  >
                    <div
                      v-if="assignment.type === 'Flight'"
                      class="flight-label-frame"
                      :style="{
                        width: `${assignment.flight.effectiveBlockTime /
                          scale}px`,
                        left: `${getLocationLeft(
                          assignment.flight.effectiveTimeOut,
                          monthFilter.days[0],
                          scale
                        )}px`,
                      }"
                    >
                      <FlightLabel
                        :flight="assignment.flight"
                        @clicked="
                          handleShowFlightAssignmentInformationDialog(
                            assignment
                          )
                        "
                      />
                    </div>
                    <div
                      v-else
                      class="flight-label-frame"
                      v-bind:style="{
                        width: `${assignment.duration / scale}px`,
                        left: `${getLocationLeft(
                          assignment.startTime,
                          monthFilter.days[0],
                          scale
                        )}px`,
                      }"
                    >
                      <GroundAssignmentLabel
                        :groundAssignment="assignment"
                        @clicked="
                          handleShowGroundAssignmentInformationDialog(
                            assignment
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import FlightLabel from "../components/FlightLabel";
import GroundAssignmentLabel from "../components/GroundAssignmentLabel";
import EmployeeAssignmentsList from "../components/EmployeeAssignmentsList";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import SpecialAirports from "../components/SpecialAirports.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import GroundAssignmentInformation from "../components/GroundAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import EmployeeInformation from "../components/EmployeeInformation.vue";

export default {
  name: "myAssignments",
  components: {
    FlightAssignmentInformation,
    GroundAssignmentInformation,
    StationAssignmentInformation,
    EmployeeInformation,
    FlightLabel,
    GroundAssignmentLabel,
    EmployeeAssignmentsList,
    AutoLogout,
    Navbar,
    SpecialAirports,
  },
  data() {
    return {
      loading: false,
      showEmployeeInformationDialog: false,
      showStationAssignmentInformationDialog: false,
      isMobile: false,
      activityReportDialog: false,
      confirmed: false,
      slider: 0,
      groundAssignment: null,
      groundAssignmentStation: null,
      groundAssignmentDialog: false,
      employee: null,
      flightAssignmentDialog: false,
      assignmentsListDialog: false,
      lastName: "",
      daysX: 0,
      monthFilterDialog: false,
      monthPicker: new Date().toISOString().substring(0, 10),
      selectedMonth: new Date().getUTCMonth(),
      selectedYear: new Date().getUTCFullYear(),
      employeeDialog: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 10000,
      scale: 16,
      currentTime: "",
      flightAssignment: null,
      specialAirportsDialog: false,
      specialAiportsHeaders: [
        {
          text: "Category",
          align: "start",
          sortable: true,
          value: "category",
        },
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "code",
        },
      ],
      showFlightAssignmentInformationDialog: false,
      showGroundAssignmentInformationDialog: false,
    };
  },
  mixins: [mixin],
  created() {
    this.getAssignments();
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    specialAirports() {
      let airports = [];

      return airports;
    },

    monthFilter() {
      const filterStart = new Date(
        Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0, 0)
      ).getTime();
      const numberOfDays = new Date(
        this.selectedYear,
        this.selectedMonth + 1,
        0
      ).getDate();

      let days = [];

      const fullDay = 24 * 60 * 60 * 1000; // 24 Hours
      const endOfDay = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

      for (let i = 0; i < numberOfDays; i++) {
        if (i <= numberOfDays - 2) {
          days.push(new Date(filterStart + i * fullDay).toISOString());
        } else {
          days.push(
            new Date(filterStart + (i * fullDay + endOfDay)).toISOString()
          );
        }
      }

      const filter = {
        start: days[0],
        end: days[days.length - 1],
        startDate: days[0].substring(0, 10),
        endDate: days[days.length - 1].substring(0, 10),
        days,
      };

      return filter;
    },
    company() {
      return this.$store.getters.company;
    },
    panelWidth() {
      return this.monthFilter.days.length * (1440 / this.scale);
    },
    panelHeight() {
      return 60 + 50;
    },
    sliderMax() {
      if (this.scale < 48) {
        return this.monthFilter.days.length - 1;
      } else {
        return this.monthFilter.days.length - 1 + this.reportColumns.length;
      }
    },

    board() {
      return this.$store.getters.board;
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    submissionStart() {
      if (this.selectedMonth <= 11) {
        return new Date(
          Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0, 0)
        ).getTime();
      } else {
        return new Date(
          Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0, 0)
        ).getTime();
      }
    },

    submissionEnd() {
      if (this.selectedMonth <= 11) {
        return new Date(
          Date.UTC(this.selectedYear, this.selectedMonth + 1, 2, 13, 0, 0, 0)
        ).getTime();
      } else {
        return new Date(
          Date.UTC(this.selectedYear + 1, 0, 2, 13, 0, 0, 0)
        ).getTime();
      }
    },

    isValidSubmissionPeriod() {
      const dt = new Date().getTime();
      return dt >= this.submissionStart && dt <= this.submissionEnd;
    },
  },
  methods: {
    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.enableReadEmployeeContactInformation) {
        this.$store.commit("updateEmployee", { ...employee });
        this.showEmployeeInformationDialog = true;
      }
    },

    handleHideEmployeeInformationDialog() {
      this.showEmployeeInformationDialog = false;
      this.$store.commit("updateEmployee", null);
    },

    //-----------------------Flight Assignment
    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.flightAssignment = {
        ...flightAssignment,
      };

      this.showFlightAssignmentInformationDialog = true;
    },

    hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;
      this.flightAssignment = null;

      if (e.refreshData) {
        this.getAssignments();
      }
    },

    //-----------------------Ground Assignment Information
    async handleShowGroundAssignmentInformationDialog(groundAssignment) {
      try {
        this.loading = true;
        if (groundAssignment.category === "Station Assignment") {
          const token = await aad.getIdTokenSilent();

          const res = await api.get("/flights/station-assignment/flights", {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
            params: {
              flightIds: groundAssignment.group.flightIds,
            },
          });

          this.loading = false;

          if (res.data.status !== "OK") {
            this.snackbarColor = "#F44336";
            this.snackbarText = res.data.message;
            this.snackbarVisible = true;
            return;
          }

          const flights = res.data.flights;

          this.groundAssignmentStation = {
            ...groundAssignment,
            flights,
          };

          this.showStationAssignmentInformationDialog = true;
        } else {
          this.groundAssignment = {
            ...groundAssignment,
          };
          this.loading = false;
          this.showGroundAssignmentInformationDialog = true;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async hideGroundAssignmentInformationDialog(refresh) {
      this.showGroundAssignmentInformationDialog = false;
      this.groundAssignment = null;

      if (refresh) {
        await this.getAssignments();
      }
    },

    async hideStationAssignmentInformationDialog(e) {
      this.showStationAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (e.refreshData) {
        await this.getAssignments();
      }
    },

    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async submitReport() {
      const dt = new Date().getTime();

      if (dt < this.submissionStart) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Submission period begins on ${this.formatDateTime(
          this.submissionStart
        )}`;
        return (this.snackbarVisible = true);
      }

      if (dt > this.submissionEnd) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Submission period expired on ${this.formatDateTime(
          this.submissionEnd
        )}`;
        return (this.snackbarVisible = true);
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        let report = {
          employee: this.employee._id,
          year: this.selectedYear,
          month: this.selectedMonth,
          period: "Second",
          daysInThisMonth: this.employee.activity.daysInThisMonth,
          daysOff: this.employee.activity.daysOff,
          daysOver20: this.employee.activity.daysOver20,
          daysOverGuarrantee: this.employee.activity.daysOverGuarrantee,
          floatingHolidayDays: this.employee.activity.floatingHolidayDays,
          healthRiskAllowance: this.employee.activity.healthRiskAllowance,
          healthRiskDays: this.employee.activity.healthRiskDays,
          holidayDays: this.employee.activity.holidayDays,
          leaveDays: this.employee.activity.leaveDays,
          loaDays: this.employee.activity.loaDays,
          missionHazard: this.employee.activity.missionHazard,

          //Calculated Perdiem
          perdiemDays: this.employee.activity.perdiemDays,
          perdiemDaysFirstHalf: this.employee.activity.perdiemDaysFirstHalf,
          perdiemDaysSecondHalf: this.employee.activity.perdiemDaysSecondHalf,

          //Adjustment added by user
          perdiemAdjustment: 0,
          internationalPerdiemAdjustment: 0,

          //Total Ajusted Perdiem
          adjustedPerdiemDaysSecondHalf: 0,

          internationalPerdiemDays: this.employee.activity
            .internationalPerdiemDays,
          internationalPerdiemDaysFirstHalf: this.employee.activity
            .internationalPerdiemDaysFirstHalf,
          internationalPerdiemDaysSecondHalf: this.employee.activity
            .internationalPerdiemDaysSecondHalf,

          //Total Ajusted International Perdiem
          adjustedInternationalPerdiemDaysSecondHalf: 0,

          sickDays: this.employee.activity.sickDays,
          totalDays: this.employee.activity.totalDays,
          vacationDays: this.employee.activity.vacationDays,
          workDays: this.employee.activity.workDays,
          standByDays: this.employee.activity.standByDays,
          workDaysFirstHalf: this.employee.activity.workDaysFirstHalf,
          workDaysSecondHalf: this.employee.activity.workDaysSecondHalf,

          missionHazardAdjustment: 0,
          healthRiskAdjustment: 0,

          adjustedDaysOverGuarrantee: 0,
          assignments: this.employee.assignments,
          remarks: this.reportRemarks,
        };

        const res = await api.put(`/reports/monthly-activity`, report, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.data.status === "OK") {
          this.snackbarColor = "#66BB6A";
          this.snackbarText = `Your report was submited`;
          this.snackbarVisible = true;
        } else {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    showActivityReport() {
      this.employee.activity = this.getEmployeeActivity(this.employee);
      this.activityReportDialog = true;
    },

    hideActivityReport() {
      (this.confirmed = false), (this.activityReportDialog = false);
    },

    saveMonthFilter() {
      this.selectedMonth = new Date(this.monthPicker).getUTCMonth();
      this.selectedYear = new Date(this.monthPicker).getUTCFullYear();

      this.monthFilterDialog = false;

      this.getAssignments();
    },

    async getAssignments() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/assignments/${this.user._id}`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            filterStart: this.monthFilter.start,
            filterEnd: this.monthFilter.end,
            boardType: this.board.type,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        const assignments = res.data.assignments;
        const bidDays = res.data.bidDays;

        this.employee = { ...this.user };

        this.employee.assignments = this.mergeAssignments(assignments);
        this.employee.bidDays = [...bidDays];

        this.$nextTick(() => {
          const bottomPanelRight = this.$refs["bottomPanelRight"];
          bottomPanelRight.scrollLeft = 0;
          this.daysX = 0;
        });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    scrollBoard() {
      const bottomPanelRight = this.$refs["bottomPanelRight"];
      const scrollLeft = (this.slider * 1440) / this.scale;
      bottomPanelRight.scrollLeft = scrollLeft;
    },

    compareStationFlights(a, b) {
      //Flights need to be order based on if they are an arrival or departure assignment
      let aRefTime;
      let bRefTime;

      if (a.originIata === this.stationIata) {
        aRefTime = new Date(a.effectiveTimeOut).getTime();
      } else if (a.destinationIata === this.stationIata) {
        aRefTime = new Date(a.effectiveTimeIn).getTime();
      }

      if (b.originIata === this.stationIata) {
        bRefTime = new Date(b.effectiveTimeOut).getTime();
      } else if (b.destinationIata === this.stationIata) {
        bRefTime = new Date(b.effectiveTimeIn).getTime();
      }

      if (aRefTime < bRefTime) {
        return -1;
      }
      if (bRefTime < aRefTime) {
        return 1;
      }
      return 0;
    },

    showGroundAssignmentDialog(employee, e) {
      this.employee = { ...employee };

      const minutes =
        (e.clientX - 100) * this.scale + Math.abs(this.daysX * this.scale);

      this.groundAssignmentStart =
        new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000;

      this.groundAssignmentDialog = true;
    },

    hideGroundAssignmentDialog() {
      this.employee = null;
      this.newGroundAssignment = null;
      this.groundAssignmentDialog = false;
      this.getAssignments();
    },

    hideFlightAssignmentDialog(e) {
      if (e.refreshData) {
        this.getAssignments();
      }

      this.flightAssignmentDialog = false;
    },

    scrollDays(e) {
      this.daysX = e.target.scrollLeft * -1;
    },

    getBidDayStyle(bidDays, day) {
      //bidDays are the employee bid days as an array of objects
      //day is the day of the board as a ISO string in zulu

      let bidDay;
      let backgroundColor = "white";

      if (bidDays.length) {
        bidDay = bidDays.find((bidDay) => {
          return (
            new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day))
              .toISOString()
              .substring(0, 10) === day.substring(0, 10)
          );
        });

        if (bidDay) {
          switch (bidDay.code) {
            case "DO":
              backgroundColor = "#E74C3C";
              break;
            case "RES":
              backgroundColor = "#2ECC71";
              break;
            case "VAC":
              backgroundColor = "#A569BD";
              break;
            default:
              break;
          }
        }
      }

      return backgroundColor;
    },

    monthFilterBack() {
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11;
        this.selectedYear -= 1;
      } else {
        this.selectedMonth -= 1;
      }

      this.getAssignments();
    },

    monthFilterForward() {
      if (this.selectedMonth < 11) {
        this.selectedMonth += 1;
      } else {
        this.selectedMonth = 0;
        this.selectedYear += 1;
      }

      this.getAssignments();
    },
  },
};
</script>

<style scoped>
.activity-day {
  margin: 1px;
  width: 100%;
  padding: 23px 0;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

.activity-summary {
  margin: 1px;
  height: 57px;
  width: 100%;
  padding: 23px 0;
  background-color: #ffffff;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

.report-top-panel-day {
  position: static;
  text-align: center;
  word-wrap: unset;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;
  -webkit-user-select: none;
}

.report-top-panel-column-title {
  position: static;
  padding: 10px 0;
  text-align: center;
  word-wrap: unset;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;
  -webkit-user-select: none;
}
</style>
