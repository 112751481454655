var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"r-dialog-grey"},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.snackbarTimeout,"multi-line":"","transition":"scroll-x-transition","centered":""},model:{value:(_vm.snackbarVisible),callback:function ($$v) {_vm.snackbarVisible=$$v},expression:"snackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"font-abel-24px-bold"},[_vm._v(" "+_vm._s(_vm.employee.surname)+", "+_vm._s(_vm.employee.givenName)+" ")]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.hide}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('v-text-field',{style:({
      maxWidth: '200px',
      marginLeft: '10px',
      marginBottom: '10px',
    }),attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.flightSearch),callback:function ($$v) {_vm.flightSearch=$$v},expression:"flightSearch"}}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.flightHeaders,"items":_vm.employee.flightAssignments,"search":_vm.flightSearch,"show-select":"","footer-props":{
      itemsPerPageOptions: [10, 20, -1],
    },"item-key":"_id"},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getFlightRole(item.role).description))])]}},{key:"item.flight.effectiveTimeOut",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.flight.effectiveTimeOut)))])]}},{key:"item.flight.effectiveTimeIn",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.flight.effectiveTimeIn)))])]}}],null,true),model:{value:(_vm.selectedFlightAssignments),callback:function ($$v) {_vm.selectedFlightAssignments=$$v},expression:"selectedFlightAssignments"}}),_c('div',{staticClass:"d-flex justify-end align-center font-abel-16px mt-1"},[_c('v-btn',{attrs:{"small":"","dark":"","color":"blue"},on:{"click":_vm.print}},[_vm._v("print")]),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","dark":"","color":"grey"},on:{"click":_vm.hide}},[_vm._v("cancel")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }