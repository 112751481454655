<template>
  <div class="r-dialog">
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>
    <!-- LOADING -->
    <div v-if="loading">
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>
    <div v-else>
      <div v-if="!warnings" class="assignment-frame">
        <div class="d-flex justify-space-between">
          <h2 class="header-title">NEW STATION ASSIGNMENT</h2>
          <v-btn @click="handleCancel" small icon><v-icon>close</v-icon></v-btn>
        </div>

        <div class="d-flex align-center my-1" v-if="employee">
          <h2 class="font-weight-bold">
            {{ `${employee.surname}, ${employee.givenName}` }}
          </h2>
          <h3 class="font-weight-bold indigo--text ml-3">
            {{ `(${employee.companyName} - ${employee.employeeId})` }}
          </h3>
        </div>
        <div v-else class="font-abel-24px-bold">
          <span>Assign to:</span>
          <span class="indigo--text ml-2">{{ assignedToCompany }}</span>
        </div>

        <v-form v-model="isValid">
          <!-- STATION INFO -->
          <div class="font-abel-24px-bold">
            {{
              `${stationAssignment.stationIata} . ${
                stationAssignment.flights.length
              } Assignment(s) . ${formatMinutes(
                stationAssignment.durationInMinutes
              )}`
            }}
          </div>

          <v-divider class="my-3"></v-divider>

          <div class="font-abel-20px px-3">
            <div
              v-for="flight in stationAssignment.flights"
              :key="flight.flightId"
            >
              {{
                formatStationAssignmentFlight(
                  flight,
                  stationAssignment.stationIata
                )
              }}
            </div>
          </div>

          <v-container>
            <v-form ref="form" v-model="isValid">
              <!-- STATION ASSIGNMENT ROLE -->
              <v-row dense>
                <v-col cols="6">
                  <v-select
                    v-model="stationRole"
                    :items="stationRoles"
                    label="Assignment Role"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- START DATE AND TIME -->
              <v-row dense>
                <v-col cols="6">
                  <v-menu
                    v-model="showStationAssignmentStartCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="stationAssignmentStartDate"
                        label="Start Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="stationAssignmentStartDate"
                      no-title
                      @input="showStationAssignmentStartCalendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="stationAssignmentStartTime"
                    label="Start Time"
                    type="time"
                    suffix="UTC"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- END DATE AND TIME -->
              <v-row dense>
                <v-col cols="6">
                  <v-menu
                    v-model="showStationAssignmentEndCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="stationAssignmentEndDate"
                        label="End Date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="stationAssignmentEndDate"
                      no-title
                      @input="showStationAssignmentEndCalendar = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="stationAssignmentEndTime"
                    label="End Time"
                    type="time"
                    suffix="UTC"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- ASSIGNMENT REMARKS -->
              <v-textarea
                class="mt-2"
                outlined
                v-model="remarks"
                :rules="remarksRules"
                label="Remarks"
                no-resize
                rows="3"
              >
              </v-textarea>
            </v-form>
          </v-container>
        </v-form>

        <div class="d-flex justify-end align-center font-abel-16px">
          <v-btn
            :disabled="!stationRole"
            @click="handlePrecheckStationAssignment"
            small
            dark
            color="blue"
            >assign</v-btn
          >
          <v-btn @click="handleCancel" class="ml-1" small dark color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
      <div v-else class="warnings-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            Warnings
          </h2>
          <v-btn @click="handleCancel" small icon><v-icon>close</v-icon></v-btn>
        </div>

        <div v-if="employee" class="font-abel-24px-bold mb-2">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>

        <!-- WARNINGS -->
        <v-text-field
          v-if="warnings.length > 1"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          :style="{
            maxWidth: '200px',
            marginLeft: '10px',
            marginBottom: '10px',
          }"
        ></v-text-field>

        <v-data-table
          dense
          :headers="headers"
          :search="search"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div v-if="hardWarnings.length > 0" class="disclaimer-text">
          <div
            v-if="
              user.securityGroup.authorizations.groundAssignment
                .overrideWarnings
            "
          >
            This assignment contains hard warnings.
          </div>
          <div v-else>
            This assignment contains hard warnings and cannot be saved.
          </div>
        </div>

        <div class="d-flex justify-space-between align-center font-abel-16px">
          <div class="d-flex justify-center font-abel-16px my-2">
            <span class="mr-2">Atlas Air Online Training Center</span>
            <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
          </div>

          <div v-if="hardWarnings.length > 0">
            <div
              v-if="
                user.securityGroup.authorizations.groundAssignment
                  .overrideWarnings
              "
            >
              <v-btn @click="handleSaveAssignment" small dark color="orange"
                >Acknowledge and Continue</v-btn
              >
            </div>
            <div v-else>
              <v-btn @click="handleCancel" small dark color="grey"
                >cancel</v-btn
              >
            </div>
          </div>
          <div v-else>
            <v-btn @click="handleSaveAssignment" small dark color="blue"
              >Acknowledge and Continue</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "stationAssignment",
  props: ["stationAssignment", "employee", "assignedToCompany"],
  mixins: [mixin],
  data() {
    return {
      loading: false,
      warnings: null,
      isValid: true,
      showStationAssignmentStartCalendar: false,
      showStationAssignmentEndCalendar: false,
      stationAssignmentStartDate: new Date().toISOString().substring(0, 10),
      stationAssignmentStartTime: "00:00",
      stationAssignmentEndDate: new Date().toISOString().substring(0, 10),
      stationAssignmentEndTime: "23:59",
      stationRole: null,
      stationRoles: [],
      remarks: " ",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      stationRoleRules: [(v) => !!v || "Role is required"],
      assignedToCompanyRules: [(v) => !!v || "Company is required"],
      companies: ["Atlas Air", "AGS", "Aviablu", "Flightline"],
      searchByStation: false,
      stationIata: "", //Used to sort station assignment flights
      search: "",
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      newStationAssignment: null,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
  },
  created() {
    this.warnings = null;
    this.stationRoles = null;
    this.stationRole = null;

    //Set up available roles based on airctraft type
    const cargoFlights = this.stationAssignment.flights.filter((item) => {
      return item.aircraft.cargoOnly;
    });

    const passengerFlights = this.stationAssignment.flights.filter((item) => {
      return !item.aircraft.cargoOnly;
    });

    let cargoStationRoles = [];
    let passengerStationRoles = [];

    if (cargoFlights.length) {
      cargoStationRoles = [
        ...this.user.securityGroup.groundAssignmentCargoRoles,
      ];
    }

    if (passengerFlights.length) {
      cargoStationRoles = [
        ...this.user.securityGroup.groundAssignmentPassengerRoles,
      ];
    }

    this.stationRoles = [...cargoStationRoles, ...passengerStationRoles];

    if (this.employee) {
      //Setup defaul role based on selected employee's job title
      if (this.stationRoles.includes(this.employee.jobTitle)) {
        this.stationRole = this.employee.jobTitle.slice();
      }
    } else {
      this.stationRole = this.stationRoles[0];
    }

    if (this.stationAssignment.remarks) {
      this.remarks = this.stationAssignment.remarks.slice();
    } else {
      this.remarks = " ";
    }

    const d1 = new Date(this.stationAssignment.startTime);
    const d2 = new Date(this.stationAssignment.endTime);

    this.stationAssignmentStartDate = d1.toISOString().substring(0, 10);
    this.stationAssignmentStartTime = d1.toISOString().substring(11, 16);

    this.stationAssignmentEndDate = d2.toISOString().substring(0, 10);
    this.stationAssignmentEndTime = d2.toISOString().substring(11, 16);
  },
  methods: {
    async handlePrecheckStationAssignment() {
      const assignmentStartDateTimeUnix = new Date(
        `${this.stationAssignmentStartDate}T${this.stationAssignmentStartTime}:00.000+00:00`
      ).getTime();
      const assignmentEndDateTimeUnix = new Date(
        `${this.stationAssignmentEndDate}T${this.stationAssignmentEndTime}:00.000+00:00`
      ).getTime();

      //Check that the selected period hits all the flights in the assignment
      let count = 0;
      let timeOut;
      let timeIn;
      this.stationAssignment.flights.forEach((item) => {
        timeOut = new Date(item.effectiveTimeOut).getTime();
        timeIn = new Date(item.effectiveTimeIn).getTime();
        if (
          timeOut <= assignmentEndDateTimeUnix &&
          timeIn >= assignmentStartDateTimeUnix
        ) {
          count += 1;
        }
      });
      if (count !== this.stationAssignment.flights.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText =
          "The selected times do not cover the required period for the assigned flights, please check times.";
        this.snackbarVisible = true;
        return;
      }
      let duration = Math.ceil(
        (assignmentEndDateTimeUnix - assignmentStartDateTimeUnix) / 60 / 1000
      );
      //48 Hours
      if (duration > 2881) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Maximum duty time is 48 hours, please check times";
        this.snackbarVisible = true;
        return;
      }

      let flightIds = [];

      this.stationAssignment.flights.forEach((item) => {
        flightIds.push(item.flightId);
      });

      this.newStationAssignment = {
        role: this.stationRole,
        assignedToCompany: this.assignedToCompany,
        stationIata: this.stationAssignment.stationIata,
        startTime: new Date(assignmentStartDateTimeUnix).toISOString(),
        endTime: new Date(assignmentEndDateTimeUnix).toISOString(),
        duration,
        flightIds,
        remarks: this.remarks.slice(),
      };

      //If there is an employee then assign to employee.
      if (this.employee) {
        this.loading = true;

        this.newStationAssignment.employee_id = this.employee._id;

        const res = await this.$store.dispatch(
          "precheckStationAssignment",
          this.newStationAssignment
        );

        this.loading = false;

        if (res.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        if (res.data.warnings.length) {
          this.warnings = res.data.warnings;
          return;
        } else {
          this.handleSaveAssignment();
        }
      } else {
        this.handleSaveAssignment();
      }
    },

    async handleSaveAssignment() {
      this.loading = true;
      const res = await this.$store.dispatch("saveStationAssignment", {
        stationAssignment: this.newStationAssignment,
        warnings: this.warnings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("hide", true);
    },

    handleCancel() {
      this.$emit("hide", false);
    },
  },
};
</script>

<style scoped>
.header-title {
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: var(--dark-blue);
}

.disclaimer-text {
  border: 1px solid var(--red);
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--red);
}

.warnings-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.assignment-frame {
  border-style: solid;
  border-color: var(--brown);
  border-width: 3px 3px 3px 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}
</style>
