import Vue from "vue";
import Vuex from "vuex";
import aad from "../services/aad";
import api from "../apis/monarchApi";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    dateFilter: null,
    board: {
      type: "Combined",
      aircraftScrollY: 0,
      employeeScrollY: 0,
      scrollX: 0,
      scale: 4,
    },
    flight: null,
    flightId: null,
    flightAssignment: null,
    employee: null,
    tripFlightIds: [],
    stationAssignment: null,
    aircraft: null,
    company: null,
    companyAirports: [],
    assignmentWarnings: [],
    requiredTrainingCertificates: [],
  },
  //Note: state.stationAssignment is the station assignment created on the flight board and shared with station assignment board.
  getters: {
    assignmentWarnings(state) {
      return state.assignmentWarnings;
    },

    loading(state) {
      return state.loading;
    },

    flightAssignment(state) {
      return state.flightAssignment;
    },

    company(state) {
      return state.company;
    },

    companyAirports(state) {
      return state.companyAirports;
    },

    aircraft(state) {
      return state.aircraft;
    },

    board(state) {
      return state.board;
    },

    flight(state) {
      return state.flight;
    },

    flightId(state) {
      return state.flightId;
    },

    employee(state) {
      return state.employee;
    },

    tripFlightIds(state) {
      return state.tripFlightIds;
    },

    stationAssignment(state) {
      return state.stationAssignment;
    },

    dateFilter(state) {
      return state.dateFilter;
    },

    user(state) {
      return state.user;
    },
  },
  mutations: {
    updateRequiredTrainingCertificates(state, requiredTrainingCertificates) {
      state.requiredTrainingCertificates = requiredTrainingCertificates;
    },
    updateAssignmentWarnings(state, assignmentWarnings) {
      state.assignmentWarnings = assignmentWarnings;
    },

    updateFlightAssignment(state, flightAssignment) {
      state.flightAssignment = flightAssignment;
    },

    updateCompanyAirports(state, companyAirports) {
      state.companyAirports = companyAirports;
    },

    updateCompany(state, company) {
      state.company = company;
    },

    updateEmployee(state, employee) {
      state.employee = employee;
    },

    updateAircraft(state, aircraft) {
      state.aircraft = aircraft;
    },

    updateBoard(state, board) {
      state.board = board;
    },

    updateFlight(state, flight) {
      state.flight = flight;
    },

    updateFlightId(state, flightId) {
      state.flightId = flightId;
    },

    updateTripFlightIds(state, tripFlightIds) {
      state.tripFlightIds = tripFlightIds;
    },

    updateStationAssignment(state, stationAssignment) {
      state.stationAssignment = stationAssignment;
    },

    updateUser(state, user) {
      state.user = user;
    },

    updateDateFilter(state, { filterStart, numberOfDays }) {
      let days = [];

      const fullDay = 24 * 60 * 60 * 1000; // 24 Hours
      const endOfDay = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

      for (let i = 0; i < numberOfDays; i++) {
        if (i <= numberOfDays - 2) {
          days.push(new Date(filterStart + i * fullDay).toISOString());
        } else {
          days.push(
            new Date(filterStart + (i * fullDay + endOfDay)).toISOString()
          );
        }
      }

      const filter = {
        start: days[0],
        end: days[days.length - 1],
        startDate: days[0].substring(0, 10),
        endDate: days[days.length - 1].substring(0, 10),
        days,
      };

      state.dateFilter = filter;
    },
  },

  actions: {
    async getEmployees(_, activeOnly) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/employees", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            activeOnly,
          },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          employees: res.data.employees,
          warnings: res.data.warnings,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async updateUserSettings({ commit }, payload) {
      const { userId, settings } = payload;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/employees/settings/${userId}`,
          {
            settings,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        const employee = res.data.employee;

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        commit("updateUser", employee);

        return {
          status: "OK",
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async getFlight(_, flightId) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/flights/${flightId}`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          flight: res.data.flight,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async getAircraftScheduleConflicts() {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/flights/aircraft-schedule/conflicts`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          aircraftScheduleConflicts: res.data.aircraftScheduleConflicts,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async getPreviousFlight(_, flightId) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/flights/${flightId}/previous`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.message,
          };
        }

        return {
          status: "OK",
          flight: res.data.flight,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async getNextFlight(_, flightId) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get(`/flights/${flightId}/next`, {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.message,
          };
        }

        return {
          status: "OK",
          flight: res.data.flight,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async updateFlightRemarks(_, payload) {
      try {
        const { flightId, remarks } = payload;
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/flights/${flightId}/remarks`,
          {
            remarks,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.message,
          };
        }

        return {
          status: "OK",
          flight: res.data.flight,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async updateLoadmasterRequirementExemptions(
      { state },
      loadmasterRequirementExemptions
    ) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/companies/${state.company._id}`,
          {
            loadmasterRequirementExemptions,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.message,
          };
        }

        return {
          status: "OK",
          company: res.data.company,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async deleteFlightAssignment(_, crewmember) {
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.delete(`/assignments/flight/${crewmember._id}`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async removeEmployeeFromFlightAssignment(_, crewmember) {
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.put(
          `/assignments/flight-remove-employee/${crewmember._id}`,
          {
            companyName: crewmember.assignedToCompany,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async unableToCoverFlightAssignment(_, crewmember) {
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.put(
          `/assignments/flight-unable-to-cover/${crewmember._id}`,
          {
            companyName: crewmember.assignedToCompany,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async sendAssignmentNotification(_, payload) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/assignments/notifications/single`,
          {
            ...payload,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async sendAssignmentNotifications(_, employees) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/assignments/notifications/multi`,
          {
            employees,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async saveFlightAssignmentRemarks(_, flightAssignment) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/assignments/flight-remarks/${flightAssignment._id}`,
          {
            remarks: flightAssignment.remarks,
            companyName: flightAssignment.assignedToCompany,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    //----------------------Ground Assignments
    async precheckGroundAssignment(_, groundAssignment) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(
          `/assignments/precheck-ground`,
          {
            ...groundAssignment,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async deleteAssignments(_, { groundAssignmentIds, flightAssignmentIds }) {
      try {
        const token = await aad.getIdTokenSilent();
        let res;
        res = await api.delete(`/assignments`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          data: { groundAssignmentIds, flightAssignmentIds },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async deleteGroundAssignment(_, { groundAssignment }) {
      try {
        const token = await aad.getIdTokenSilent();
        let res;
        res = await api.delete(`/assignments/ground/${groundAssignment._id}`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async saveGroundAssignment(_, { groundAssignment, warnings }) {
      try {
        const token = await aad.getIdTokenSilent();
        let res;
        if (groundAssignment._id) {
          //Update
          res = await api.put(
            `/assignments/ground/${groundAssignment._id}`,
            {
              ...groundAssignment,
              warnings,
            },
            {
              headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
            }
          );
        } else {
          //Insert

          res = await api.post(
            `/assignments/ground`,
            {
              ...groundAssignment,
              warnings,
            },
            {
              headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
            }
          );
        }

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async saveGroundAssignmentRemarks(_, { _id, remarks }) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/assignments/ground/remarks/${_id}`,
          { remarks },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async unableToCoverStationAssignment(_, groundCrewmember) {
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.put(
          `/assignments/station-unable-to-cover/${groundCrewmember.group.id}`,
          {
            companyName: groundCrewmember.assignedToCompany,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    //-----------------------------Flight Assignments

    async precheckFlightAssignment(_, flightAssignment) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(
          `/assignments/precheck-flight`,
          {
            flightAssignment,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async saveFlightAssignment(_, { flightAssignment, warnings }) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(
          `/assignments/flight`,
          {
            flightAssignment,
            warnings,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async precheckStationAssignment(_, stationAssignment) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(
          `/assignments/precheck-station`,
          {
            stationAssignment,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }

        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async saveStationAssignment(_, { stationAssignment, warnings }) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(
          `/assignments/station`,
          {
            stationAssignment,
            warnings,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }
        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async updateStationAssignment(_, { stationAssignment, warnings }) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/assignments/station/${stationAssignment.groupId}`,
          {
            stationAssignment,
            warnings,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }
        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },

    async deleteStationAssignment(_, groupId) {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(`/assignments/station/${groupId}`, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        if (res.data.status !== "OK") {
          return {
            status: "Fail",
            message: res.data.message,
          };
        }
        return {
          status: "OK",
          data: res.data,
        };
      } catch (error) {
        let message;
        if (error.response) {
          message = `Error: ${error.response.data.message}`;
        } else {
          message = `Error: ${error}`;
        }

        return {
          status: "Fail",
          message,
        };
      }
    },
  },
});
