<template>
  <div>
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <Navbar />

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <v-btn
        :disabled="loading"
        class="mx-2"
        @click="monthFilterDialog = true"
        small
        outlined
        color="white"
        >{{ formatMonthShort(selectedMonth) }} - {{ selectedYear }}</v-btn
      >

      <!--Filters -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-slider
        @change="scrollBoard"
        class="ml-1"
        v-model="sliderX"
        :max="boardWidth"
        :min="0"
        hide-details
      >
      </v-slider>
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <!-- Refresh -->
      <div>
        <v-btn :disabled="loading" @click="getBidlines" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>

        <v-btn :disabled="loading" @click="zoomIn" small icon class="ml-1">
          <v-icon color="blue">zoom_in</v-icon>
        </v-btn>

        <v-btn :disabled="loading" @click="zoomOut" small icon>
          <v-icon color="white">zoom_out</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <v-btn
        :disabled="loading"
        class="mx-2"
        @click="monthFilterDialog = true"
        small
        outlined
        color="white"
        >{{ formatMonthShort(selectedMonth) }} - {{ selectedYear }}</v-btn
      >

      <!--Filters -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- Refresh -->
          <v-list-item @click="getBidlines" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom In -->
          <v-list-item @click="zoomIn" link>
            <v-list-item-icon>
              <v-icon color="blue">zoom_in</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom Out -->
          <v-list-item @click="zoomOut" link>
            <v-list-item-icon>
              <v-icon color="white">zoom_out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog v-model="employeeFilterDialog" persistent max-width="450px">
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- MONTH FILTER DIALOG-->
    <v-dialog v-model="monthFilterDialog" max-width="450px">
      <div class="yellow-frame">
        <div class="mx-3 mb-2 font-abel-24px text-center">Select Month</div>

        <v-date-picker v-model="monthPicker" type="month"></v-date-picker>

        <div class="d-flex justify-end align-center my-2">
          <v-btn @click="saveMonthFilter" small dark color="blue">select</v-btn>
        </div>
      </div>
    </v-dialog>

    <!-- BIDLINE DIALOG-->
    <v-dialog v-model="bidlineDialog" persistent max-width="450px">
      <div v-if="bidDays.length" class="yellow-frame">
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold">
            {{ bidlineTitle }}
          </div>
          <v-btn @click="bidlineDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <div class="d-flex justify-space-between align-center">
          <!-- Start Date -->
          <v-menu
            v-model="showStartCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedStartDate"
                label="From"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedStartDate"
              no-title
              :min="minDate"
              :max="maxDate"
              @input="showStartCalendar = false"
            ></v-date-picker>
          </v-menu>

          <!-- End Date -->
          <v-menu
            v-model="showEndCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedEndDate"
                label="To"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedEndDate"
              @input="showEndCalendar = false"
              :min="minDate"
              :max="maxDate"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex justify-space-between align-center">
          <!-- Status -->
          <v-select
            class="mr-5"
            v-model="selectedStatus"
            :items="statusOptions"
            label="Status"
          >
          </v-select>

          <v-btn @click="setStatus" small dark color="blue">set</v-btn>
        </div>

        <div class="d-flex flex-wrap">
          <div v-for="bidDay in bidDays" :key="bidDay.day">
            <div
              class="bid-day"
              :style="{ backgroundColor: getStatusColor(bidDay.code) }"
            >
              {{ bidDay.day }}
            </div>
          </div>
        </div>

        <div class="d-flex justify-end align-center my-2">
          <div>
            <v-btn @click="saveBidline" small dark color="green">save</v-btn>
            <v-btn
              @click="bidlineDialog = false"
              small
              dark
              color="grey"
              class="ml-2"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- FLIGHT BOARD -->
    <div v-if="!loading">
      <div class="top-frame font-abel-16px">
        <div class="top-panel-left">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="loading"
                @click="handleOpenEmployeeFilter"
                small
                icon
              >
                <i class="fa-solid fa-filter"></i>
              </v-btn>
            </template>
            <span>Filter Employees by Job Title</span>
          </v-tooltip>
          <!-- Search by last name -->
          <v-text-field
            v-model="lastName"
            dense
            label="Employee"
            single-line
            hide-details
            :style="{ maxWidth: '120px', marginLeft: '5px' }"
            @input="filterEmployees"
          ></v-text-field>
        </div>
        <div class="top-panel-right">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${boardWidth}px`,
              left: `${scrollX}px`,
            }"
          >
            <div
              v-for="(day, index) in monthFilter.days"
              :key="index"
              class="top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}

              <div v-if="scale < 8" class="d-flex font-abel-10px">
                <div
                  v-bind:style="{
                    width: `${30 / scale}px`,
                  }"
                ></div>

                <div
                  v-for="tick in timeTicks"
                  :key="tick.index"
                  v-bind:style="{
                    width: `${60 / scale}px`,
                  }"
                >
                  <div>{{ tick.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filteredEmployees.length" class="bottom-frame">
        <div class="bottom-panel-left">
          <div
            v-for="(employee, index) in filteredEmployees"
            :key="employee._id"
            class="bottom-panel-left-row"
          >
            <div
              class="bottom-panel-left-row-section truncate cursor-hand font-abel-12px-bold"
              @click="getEmployeeBidline(employee)"
            >
              {{ index + 1 }} - {{ employee.surname }},
              {{ employee.givenName.substring(0, 1) }}
            </div>

            <div class="bottom-panel-left-row-section truncate font-abel-10px">
              {{ employee.companyName }} - {{ employee.jobTitle }}
            </div>
            <div class=" font-abel-12px bottom-panel-left-row-section truncate">
              {{ employee.gatewayCode }} - {{ employee.employeeId }}
            </div>
          </div>
        </div>

        <div
          class="bottom-panel-right"
          ref="bottomPanelRight"
          @scroll="scrollHorizontal"
        >
          <div
            class="bottom-panel-right-inner"
            :style="{
              width: `${boardWidth}px`,
              height: `${boardHeight}px`,
            }"
          >
            <div
              v-for="employee in filteredEmployees"
              :key="employee._id"
              class="bottom-panel-right-row"
              :style="{
                width: `${boardWidth}px`,
              }"
            >
              <!-- BID LINES -->
              <div v-if="employee.bidDays.length">
                <div
                  v-for="bidDay in employee.bidDays"
                  :key="bidDay.day"
                  class="day-frame font-abel-16px"
                  :style="getBidlineDayStyle(bidDay)"
                >
                  {{ getStatusText(bidDay) }}
                </div>
              </div>

              <div
                v-for="(day, index) in monthFilter.days"
                :key="index"
                class="bottom-panel-day"
                :style="{
                  width: `${1440 / scale}px`,
                  left: `${index * (1440 / scale)}px`,
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bottom-frame font-abel-16px">
        <div class="r-frame-rounded-yellow red--text">
          No employees found with the selected filters.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import EmployeeFilter from "../components/EmployeeFilter.vue";
export default {
  name: "bidlines",
  mixins: [mixin],
  components: { AutoLogout, Navbar, EmployeeFilter },
  data() {
    return {
      loading: false,
      isMobile: false,
      sliderX: 0,
      lastName: "",
      scrollX: 0,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      employeeFilterDialog: false,
      employee: null,
      employees: [],
      filteredEmployees: [],
      scale: 16,
      monthFilterDialog: false,
      bidlineDialog: false,
      monthPicker: new Date().toISOString().substring(0, 10),
      selectedMonth: new Date().getUTCMonth(),
      selectedYear: new Date().getUTCFullYear(),
      selectedBidline: null,
      headers: [
        {
          text: "Day",
          align: "center",
          sortable: false,
          value: "day",
        },
        {
          text: "Code",
          align: "center",
          sortable: false,
          value: "status.code",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "status.description",
        },
      ],
      statusOptions: ["Day Off", "Reserve", "Vacation"],
      selectedStatus: "Day Off",
      selectedStartDate: "",
      selectedEndDate: "",
      showStartCalendar: false,
      showEndCalendar: false,
      bidDays: [],
      bidlineTitle: "",
      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
    };
  },
  created() {
    this.selectedCompany = this.user.securityGroup.companies[0];
    this.selectedBoardType = this.board.type;
    this.getBidlines();
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },
    monthFilter() {
      const filterStart = new Date(
        Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0, 0)
      ).getTime();
      const numberOfDays = new Date(
        this.selectedYear,
        this.selectedMonth + 1,
        0
      ).getDate();

      let days = [];

      const fullDay = 24 * 60 * 60 * 1000; // 24 Hours
      const endOfDay = 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000 + 999;

      for (let i = 0; i < numberOfDays; i++) {
        if (i <= numberOfDays - 2) {
          days.push(new Date(filterStart + i * fullDay).toISOString());
        } else {
          days.push(
            new Date(filterStart + (i * fullDay + endOfDay)).toISOString()
          );
        }
      }

      const filter = {
        start: days[0],
        end: days[days.length - 1],
        startDate: days[0].substring(0, 10),
        endDate: days[days.length - 1].substring(0, 10),
        days,
      };

      return filter;
    },

    minDate() {
      return this.monthFilter.days[0].substring(0, 10);
    },

    maxDate() {
      return this.monthFilter.days[this.monthFilter.days.length - 1].substring(
        0,
        10
      );
    },

    board() {
      return this.$store.getters.board;
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    boardWidth() {
      return this.monthFilter.days.length * (1440 / this.scale);
    },
    boardHeight() {
      return this.filteredEmployees.length * 60 + 50;
    },
  },
  methods: {
    handleChangeBoardType() {
      let board = { ...this.board };
      board.type = this.selectedBoardType;
      this.$store.commit("updateBoard", board);
      this.filterEmployees();
    },

    handleOpenEmployeeFilter() {
      this.employeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.employeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.employeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getBidlines();
    },

    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },
    setStatus() {
      const d1 = new Date(this.selectedStartDate);
      const d2 = new Date(this.selectedEndDate);

      const numberOfDays = (d2.getTime() - d1.getTime()) / 1000 / 60 / 1440 + 1;
      let firstDay = d1.getUTCDate();

      let code;
      let description;

      switch (this.selectedStatus) {
        case "Reserve":
          code = "RES";
          description = "Reserve";
          break;

        case "Day Off":
          (code = "DO"), (description = "Day Off");
          break;

        case "Vacation":
          (code = "VAC"), (description = "Vacation");
          break;

        default:
          break;
      }

      if (numberOfDays < 1) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please select at least 1 day";
        return (this.snackbarVisible = true);
      }

      for (let i = 0; i < numberOfDays; i++) {
        this.bidDays[firstDay - 1 + i].code = code;
        this.bidDays[firstDay - 1 + i].description = description;
      }
    },
    async getBidlines() {
      this.loading = true;

      this.employees = [];
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.get("/bidlines", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            year: this.selectedYear,
            month: this.selectedMonth,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        const employees = res.data.employees;
        const bidDays = res.data.bidDays;
        employees.forEach((emp) => {
          const employee = {
            ...emp,
            bidDays: bidDays.filter((bidDay) => {
              return bidDay.employee === emp._id;
            }),
          };
          this.employees.push(employee);
        });
        this.filterEmployees();

        this.$nextTick(() => {
          window.scrollTo(0, this.board.employeeScrollY);
          const panel = this.$refs["bottomPanelRight"];
          if (panel) {
            panel.scrollLeft = Math.abs(this.board.scrollX);
            this.scale = this.board.scale;
          }
        });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    scrollBoard() {
      //This fires when the slider in the toolbar is used
      this.bottomPanelRight.scrollLeft = this.sliderX;
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },

    getEmployeeBidline(employee) {
      this.bidDays = [];

      if (employee.bidDays.length) {
        employee.bidDays.forEach((bidDay) => {
          this.bidDays.push({ ...bidDay });
        });
      } else {
        let bidDay = {};

        for (let i = 0; i < this.monthFilter.days.length; i++) {
          bidDay = {
            employee: employee._id,
            year: this.selectedYear,
            month: this.selectedMonth,
            day: i + 1,
            code: "RES",
            description: "Reserve",
          };

          this.bidDays.push(bidDay);
        }
      }

      this.selectedStartDate = this.monthFilter.days[0].substring(0, 10);
      this.selectedEndDate = this.monthFilter.days[
        this.monthFilter.days.length - 1
      ].substring(0, 10);
      this.bidlineTitle = `${employee.surname}, ${employee.givenName}`;
      this.bidlineDialog = true;

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    async saveBidline() {
      this.bidlineDialog = false;

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.put(
          `/bidlines`,
          { bidDays: this.bidDays },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.snackbarColor = `#66BB6A`;
        this.snackbarText = `Bidline saved.`;
        this.snackbarVisible = true;

        this.selectedStatus = "Reserve";

        this.getBidlines();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    filterEmployees() {
      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];

      if (this.selectedCompany !== "All Companies") {
        filterOne = this.employees.filter((employee) => {
          return this.selectedCompany === employee.companyName;
        });
      } else {
        filterOne = [...this.employees];
      }

      if (this.lastName.length) {
        filterTwo = filterOne.filter((employee) => {
          return employee.surname
            .toUpperCase()
            .startsWith(this.lastName.toUpperCase());
        });
      } else {
        filterTwo = [...filterOne];
      }

      switch (this.board.type) {
        case "Cargo":
          filterThree = filterTwo.filter((item) => {
            return this.cargoJobTitles.includes(item.jobTitle);
          });
          break;
        case "Passenger":
          filterThree = filterTwo.filter((item) => {
            return this.passengerJobTitles.includes(item.jobTitle);
          });
          break;

        default:
          filterThree = [...filterTwo];
          break;
      }

      this.filteredEmployees = [...filterThree];
    },

    getBidlineDayStyle(bidDay) {
      let x = 0;
      let backgroundColor = "#FFFFFF";

      x = ((bidDay.day - 1) * 1440) / this.scale;

      switch (bidDay.code) {
        case "DO":
          backgroundColor = "#E74C3C";
          break;
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          backgroundColor = "white";
          break;
      }

      const style = {
        width: `${1440 / this.scale}px`,
        left: `${x}px`,
        backgroundColor: backgroundColor,
      };

      return style;
    },

    getStatusColor(code) {
      switch (code) {
        case "DO":
          return "#E74C3C";

        case "RES":
          return "#2ECC71";

        case "VAC":
          return "#A569BD";

        default:
          return "white";
      }
    },

    getStatusText(bidDay) {
      if (this.scale <= 16) {
        return bidDay.description;
      } else {
        return bidDay.code;
      }
    },

    saveMonthFilter() {
      this.selectedMonth = new Date(this.monthPicker).getUTCMonth();
      this.selectedYear = new Date(this.monthPicker).getUTCFullYear();

      this.monthFilterDialog = false;

      this.getBidlines();
    },

    monthFilterBack() {
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11;
        this.selectedYear -= 1;
      } else {
        this.selectedMonth -= 1;
      }

      this.getBidlines();
    },

    monthFilterForward() {
      if (this.selectedMonth < 11) {
        this.selectedMonth += 1;
      } else {
        this.selectedMonth = 0;
        this.selectedYear += 1;
      }

      this.getBidlines();
    },
  },
};
</script>

<style scoped>
.day-frame {
  position: absolute;
  border-right: 1px solid black;
  text-align: center;
  color: white;
  background-color: white;
  height: 59px;
}
.header-day-local {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #bebebe;
  background-color: #bfc9ce;
  align-items: center;
  justify-content: center;
}
.bid-day {
  border: 1px solid #bebebe;
  padding: 3px;
  min-width: 61px;
}
</style>
