<template>
  <div class="r-dialog">
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <div v-if="loading">
      <v-dialog v-model="loading" max-width="300" persistent>
        <div id="loading">
          <v-progress-circular
            :size="60"
            :width="7"
            color="#6495ED"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>
    <div v-else>
      <div class="assignment-frame" v-if="!warnings">
        <!-- EMPLOYEE NAME -->
        <div v-if="employee">
          <div class="d-flex justify-space-between">
            <div class="font-abel-24px-bold">
              {{ employee.surname }}, {{ employee.givenName }}
            </div>
            <v-btn @click="handleCancel" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-space-between  ">
            <div class="font-abel-24px-bold">
              <span>Assign to:</span>
              <span class="indigo--text ml-2">{{ assignedToCompany }}</span>
            </div>
            <v-btn @click="handleCancel" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>
        </div>

        <!-- EMPLOYEE POSITION -->
        <div
          v-if="employee"
          class="d-flex justify-space-between align-center font-abel-26px"
        >
          <p>{{ employee.companyName }} - {{ employee.jobTitle }}</p>
          <p>{{ employee.employeeId }}</p>
        </div>

        <v-form v-model="isValid">
          <div class="flight-route">
            {{ `${flight.originIata}-${flight.destinationIata}` }}
          </div>

          <div class="font-abel-20px text-center">
            {{ `${formatFullFlightSnapshot(flight)}` }}
          </div>

          <!--FLIGHT ROLES  -->
          <div class="crew-frame  my-2 font-abel-16px">
            <v-row
              class="r-row"
              dense
              v-for="flightRole in flightRoles"
              :key="flightRole.description"
            >
              <v-col cols="2">
                {{ flightRole.description }}
              </v-col>

              <v-col cols="10">
                <div class="d-flex align-center">
                  <v-btn
                    v-for="role in flightRole.roles"
                    :key="role.code"
                    @click="handlePrecheckFlightAssignment(role.code)"
                    :class="{
                      'is-assigned':
                        getEmployeeAssigned(role.code) !== 'Assign',
                    }"
                    class="text-capitalize mr-1 "
                    x-small
                    outlined
                    :disabled="!role.enabled"
                    >{{ `${getEmployeeAssigned(role.code)}` }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>

          <!-- ASSIGNMENT REMARKS -->
          <v-textarea
            class="mt-2"
            outlined
            v-model="assignmentRemarks"
            :rules="remarksRules"
            label="Remarks"
            no-resize
            rows="3"
          >
          </v-textarea>
        </v-form>
      </div>
      <div v-else class="warnings-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <h2 class="red--text">
            Warnings
          </h2>
          <v-btn @click="handleCancel" small icon><v-icon>close</v-icon></v-btn>
        </div>

        <div v-if="employee" class="font-abel-24px-bold mb-2">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>
        <div class="font-abel-20px  mb-5">
          {{ `${formatFullFlightSnapshot(flight)}` }}
        </div>

        <!-- WARNINGS -->
        <v-text-field
          v-if="warnings.length > 1"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          :style="{
            maxWidth: '200px',
            marginLeft: '10px',
            marginBottom: '10px',
          }"
        ></v-text-field>

        <v-data-table
          dense
          :headers="headers"
          :search="search"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div v-if="hardWarnings.length > 0" class="disclaimer-text">
          <div
            v-if="
              user.securityGroup.authorizations.flightAssignment
                .overrideWarnings
            "
          >
            This assignment contains hard warnings.
          </div>
          <div v-else>
            This assignment contains hard warnings and cannot be saved.
          </div>
        </div>

        <div class="d-flex justify-space-between align-center font-abel-16px">
          <div class="d-flex justify-center font-abel-16px my-2">
            <span class="mr-2">Atlas Air Online Training Center</span>
            <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
          </div>

          <div v-if="hardWarnings.length > 0">
            <div
              v-if="
                user.securityGroup.authorizations.flightAssignment
                  .overrideWarnings
              "
            >
              <v-btn @click="handleSaveAssignment" small dark color="orange"
                >Acknowledge and Continue</v-btn
              >
            </div>
            <div v-else>
              <v-btn @click="handleCancel" small dark color="grey"
                >cancel</v-btn
              >
            </div>
          </div>
          <div v-else>
            <v-btn @click="handleSaveAssignment" small dark color="blue"
              >Acknowledge and Continue</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightAssignment",
  props: ["employee", "flight", "assignedToCompany"],
  mixins: [mixin],
  data() {
    return {
      loading: false,
      isValid: true,
      warnings: null,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      flightAssignment: null,
      assignmentRemarks: "",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      flightRoles: [],
      flightRolesAssignedToCompanies: [],
      search: "",
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
  },
  created() {
    this.warnings = null;
    this.flightRolesAssignedToCompanies = [];

    this.flight.crewmembers.forEach((item) => {
      if (!item.employee) {
        this.flightRolesAssignedToCompanies.push({
          companyName: item.assignedToCompany,
          role: item.role,
        });
      }
    });

    // this.getFlightRoles();
    let flightRoles = [];
    let roles = [];
    let roleCode;

    if (this.flight.aircraft.cargoOnly) {
      this.user.securityGroup.flightAssignmentCargoRoles.forEach((role) => {
        switch (role) {
          case "Jumpseat":
            roles = [];
            for (let i = 1; i < 11; i++) {
              roleCode = `JS${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Jumpseat",
              roles: [...roles],
            });

            break;

          case "Mechanic":
            roleCode = "MX";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];
            for (let i = 2; i < 6; i++) {
              roleCode = `MX${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Mechanic",
              roles: [...roles],
            });

            break;

          case "Loadmaster":
            roleCode = "LM";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];
            for (let i = 2; i < 6; i++) {
              roleCode = `LM${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Loadmaster",
              roles: [...roles],
            });

            break;

          case "Senior Loadmaster":
            roleCode = "SLM";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];
            for (let i = 2; i < 4; i++) {
              roleCode = `SLM${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Senior Loadmaster",
              roles: [...roles],
            });

            break;

          case "Check Loadmaster":
            roleCode = "CLM";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];
            for (let i = 2; i < 3; i++) {
              roleCode = `CLM${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Check Loadmaster",
              roles: [...roles],
            });

            break;

          case "Loadmaster Training":
            roleCode = "LMT";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];
            for (let i = 2; i < 6; i++) {
              roleCode = `LMT${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Loadmaster Training",
              roles: [...roles],
            });

            break;

          default:
            break;
        }
      });
    } else {
      this.user.securityGroup.flightAssignmentPassengerRoles.forEach((role) => {
        switch (role) {
          case "Jumpseat":
            roles = [];
            for (let i = 1; i < 11; i++) {
              roleCode = `JS${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Jumpseat",
              roles: [...roles],
            });

            break;

          case "Mechanic":
            roleCode = "MX";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];
            for (let i = 2; i < 6; i++) {
              roleCode = `MX${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Mechanic",
              roles: [...roles],
            });

            break;

          case "GSC":
            roleCode = "GSC";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            flightRoles.push({
              description: "GSC",
              roles: [...roles],
            });

            break;

          case "POC":
            roleCode = "POC";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            flightRoles.push({
              description: "POC",
              roles: [...roles],
            });

            break;

          case "PAA Training":
            roleCode = "PAAT";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 6; i++) {
              roleCode = `PAAT${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "PAA Training",
              roles: [...roles],
            });

            break;

          case "PAA":
            roleCode = "PAA";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 6; i++) {
              (roleCode = `PAA${i}`),
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
            }

            flightRoles.push({
              description: "PAA",
              roles: [...roles],
            });

            break;

          case "PSR Training":
            roleCode = "PSRT";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 6; i++) {
              roleCode = `PSRT${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "PSR Training",
              roles: [...roles],
            });

            break;

          case "Lead PSR":
            roleCode = "LPSR";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 6; i++) {
              roleCode = `LPSR${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "LPSR",
              roles: [...roles],
            });

            break;

          case "Check PSR":
            roleCode = "CPSR";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 3; i++) {
              roleCode = `CPSR${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Check PSR",
              roles: [...roles],
            });

            break;

          case "Senior PSR":
            roleCode = "SPSR";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 3; i++) {
              roleCode = `SPSR${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "Senior PSR",
              roles: [...roles],
            });

            break;

          case "PSR":
            roleCode = "PSR";
            roles = [
              {
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              },
            ];

            for (let i = 2; i < 6; i++) {
              roleCode = `PSR${i}`;
              roles.push({
                code: roleCode,
                enabled: this.getRoleEnabledStatus(roleCode),
              });
            }

            flightRoles.push({
              description: "PSR",
              roles: [...roles],
            });

            break;

          default:
            break;
        }
      });
    }

    this.flightRoles = [...flightRoles];
  },

  methods: {
    async handlePrecheckFlightAssignment(role) {
      this.warnings = null;

      this.flightAssignment = {
        role,
        flightId: this.flight.flightId,
      };

      if (!this.employee) {
        //Save as company assignment
        this.flightAssignment.assignedToCompany = this.assignedToCompany;
        this.handleSaveAssignment();
        return;
      }

      this.flightAssignment.employee_id = this.employee._id;
      this.flightAssignment.assignedToCompany = this.employee.companyName;

      this.loading = true;
      const res = await this.$store.dispatch(
        "precheckFlightAssignment",
        this.flightAssignment
      );

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      if (res.data.warnings.length) {
        this.warnings = res.data.warnings;
        return;
      } else {
        this.handleSaveAssignment();
      }
    },

    async handleSaveAssignment() {
      this.loading = true;

      const res = await this.$store.dispatch("saveFlightAssignment", {
        flightAssignment: this.flightAssignment,
        warnings: this.warnings,
      });

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.$emit("hide", true);
    },

    getRoleEnabledStatus(roleCode) {
      if (this.enableCreateFlightAssignments) {
        return true;
      } else {
        let matchingRole = this.flightRolesAssignedToCompanies.find((item) => {
          return item.role === roleCode;
        });

        if (
          matchingRole &&
          this.user.securityGroup.companies.includes(matchingRole.companyName)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    getEmployeeAssigned(flightRole) {
      let a = "Assign";
      if (this.flight.crewmembers.length) {
        const crewmember = this.flight.crewmembers.find((c) => {
          return c.role === flightRole;
        });

        if (crewmember) {
          if (crewmember.employee) {
            a = this.formatName(
              crewmember.employee.givenName,
              crewmember.employee.surname,
              "sub"
            );
          } else {
            a = crewmember.assignedToCompany;
          }
        } else {
          a = "Assign";
        }
      }

      return a;
    },

    handleCancel() {
      this.$emit("hide", false);
    },
  },
};
</script>

<style scoped>
.flight-route {
  font-size: 2rem;
  text-align: center;
  color: var(--dark-blue);
}

.disclaimer-text {
  border: 1px solid var(--red);
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--red);
}

.warnings-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.assignment-frame {
  border-style: solid;
  border-color: var(--gray);
  border-width: 1px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

.is-assigned {
  background-color: #c3f5c3;
  font-weight: 700;
}
</style>
