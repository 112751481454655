var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar'),(_vm.user)?_c('AutoLogout',{on:{"logout":_vm.logout}}):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.snackbarTimeout,"multi-line":"","transition":"scroll-x-transition","centered":""},model:{value:(_vm.snackbarVisible),callback:function ($$v) {_vm.snackbarVisible=$$v},expression:"snackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-dialog',{attrs:{"max-width":"300","persistent":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"size":60,"width":7,"color":"#6495ED","indeterminate":""}})],1)]),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.airportDialog),callback:function ($$v) {_vm.airportDialog=$$v},expression:"airportDialog"}},[(_vm.airport)?_c('div',{staticClass:"blue-frame font-abel-16px"},[_c('div',{staticClass:"d-flex justify-space-between mb-2 ml-2"},[_c('div',{staticClass:"font-abel-30px"},[_vm._v(" "+_vm._s(((_vm.airport.iata) + " - " + (_vm.airport.icao)))+" ")]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.airportDialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('div',{staticClass:"my-2"},[_c('div',[_vm._v("Airport Name: "+_vm._s((" " + (_vm.airport.name))))]),_c('div',[_vm._v("Country: "+_vm._s((" " + (_vm.airport.country))))])]),_c('v-data-table',{staticClass:"my-2",attrs:{"dense":"","headers":_vm.periodsHeaders,"items":_vm.airportPeriods,"show-select":"","item-key":"_id","footer-props":{
          itemsPerPageOptions: [20, 50, 100, 200, -1],
        }},model:{value:(_vm.selectedPeriods),callback:function ($$v) {_vm.selectedPeriods=$$v},expression:"selectedPeriods"}}),_c('div',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{attrs:{"disabled":!_vm.selectedPeriods.length,"small":"","dark":"","color":"red"},on:{"click":_vm.deletePeriods}},[_vm._v("delete")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","dark":"","color":"grey"},on:{"click":function($event){_vm.airportDialog = false}}},[_vm._v("cancel")])],1)],1):_vm._e()]),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('div',{staticClass:"yellow-frame"},[_c('div',{staticClass:"mx-3 mb-2 font-abel-20px text-center"},[_vm._v(" Set COVID-19 / Hazard Status ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("health_and_safety")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-switch',{attrs:{"label":"COVID-19","color":"#FF0000"},model:{value:(_vm.isCovid19),callback:function ($$v) {_vm.isCovid19=$$v},expression:"isCovid19"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[(_vm.isCovid19)?_c('v-switch',{attrs:{"label":"Open-Ended","color":"#FF0000"},model:{value:(_vm.isCovid19OpenEnded),callback:function ($$v) {_vm.isCovid19OpenEnded=$$v},expression:"isCovid19OpenEnded"}}):_vm._e()],1)],1),(_vm.isCovid19)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"COVID-19 Start","prepend-icon":"event","readonly":""},model:{value:(_vm.selectedCovid19StartDate),callback:function ($$v) {_vm.selectedCovid19StartDate=$$v},expression:"selectedCovid19StartDate"}},on))]}}],null,false,2196126941),model:{value:(_vm.showCovid19StartCalendar),callback:function ($$v) {_vm.showCovid19StartCalendar=$$v},expression:"showCovid19StartCalendar"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showCovid19StartCalendar = false}},model:{value:(_vm.selectedCovid19StartDate),callback:function ($$v) {_vm.selectedCovid19StartDate=$$v},expression:"selectedCovid19StartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(!_vm.isCovid19OpenEnded)?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"COVID-19 End","prepend-icon":"event","readonly":""},model:{value:(_vm.selectedCovid19EndDate),callback:function ($$v) {_vm.selectedCovid19EndDate=$$v},expression:"selectedCovid19EndDate"}},on))]}}],null,false,3606447197),model:{value:(_vm.showCovid19EndCalendar),callback:function ($$v) {_vm.showCovid19EndCalendar=$$v},expression:"showCovid19EndCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showCovid19EndCalendar = false}},model:{value:(_vm.selectedCovid19EndDate),callback:function ($$v) {_vm.selectedCovid19EndDate=$$v},expression:"selectedCovid19EndDate"}})],1)],1):_c('div',[_vm._v(" ACTIVE (Open-Ended) ")])])],1):_vm._e(),_c('v-divider'),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("warning")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-switch',{attrs:{"label":"HAZARDOUS","color":"#FF0000"},model:{value:(_vm.isHazard),callback:function ($$v) {_vm.isHazard=$$v},expression:"isHazard"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[(_vm.isHazard)?_c('v-switch',{attrs:{"label":"Open-Ended","color":"#FF0000"},model:{value:(_vm.isHazardOpenEnded),callback:function ($$v) {_vm.isHazardOpenEnded=$$v},expression:"isHazardOpenEnded"}}):_vm._e()],1)],1),(_vm.isHazard)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hazard Start","prepend-icon":"event","readonly":""},model:{value:(_vm.selectedHazardStartDate),callback:function ($$v) {_vm.selectedHazardStartDate=$$v},expression:"selectedHazardStartDate"}},on))]}}],null,false,1974857072),model:{value:(_vm.showHazardStartCalendar),callback:function ($$v) {_vm.showHazardStartCalendar=$$v},expression:"showHazardStartCalendar"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showHazardStartCalendar = false}},model:{value:(_vm.selectedHazardStartDate),callback:function ($$v) {_vm.selectedHazardStartDate=$$v},expression:"selectedHazardStartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(!_vm.isHazardOpenEnded)?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Hazard End","prepend-icon":"event","readonly":""},model:{value:(_vm.selectedHazardEndDate),callback:function ($$v) {_vm.selectedHazardEndDate=$$v},expression:"selectedHazardEndDate"}},on))]}}],null,false,4285326064),model:{value:(_vm.showHazardEndCalendar),callback:function ($$v) {_vm.showHazardEndCalendar=$$v},expression:"showHazardEndCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showHazardEndCalendar = false}},model:{value:(_vm.selectedHazardEndDate),callback:function ($$v) {_vm.selectedHazardEndDate=$$v},expression:"selectedHazardEndDate"}})],1)],1):_c('div',[_vm._v(" ACTIVE (Open-Ended) ")])])],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{attrs:{"small":"","dark":"","color":"green"},on:{"click":_vm.save}},[_vm._v("save")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","dark":"","color":"grey"},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v("cancel")])],1)],1)]),_c('div',{staticClass:"frame"},[_c('div',{staticClass:"filter"},[_c('v-select',{attrs:{"items":_vm.companyCountries,"label":"Country"},on:{"change":_vm.filterAirports},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"d-flex justify-center px-10"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Check Date","prepend-icon":"event","readonly":""},model:{value:(_vm.targetDate),callback:function ($$v) {_vm.targetDate=$$v},expression:"targetDate"}},on))]}}]),model:{value:(_vm.showStartCalendar),callback:function ($$v) {_vm.showStartCalendar=$$v},expression:"showStartCalendar"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":_vm.changeCheckDate},model:{value:(_vm.targetDate),callback:function ($$v) {_vm.targetDate=$$v},expression:"targetDate"}})],1)],1),_c('v-checkbox',{attrs:{"label":"Active COVID-19"},on:{"change":_vm.filterAirports},model:{value:(_vm.showOnlyIfActiveCovid),callback:function ($$v) {_vm.showOnlyIfActiveCovid=$$v},expression:"showOnlyIfActiveCovid"}}),_c('v-checkbox',{staticClass:"ml-5",attrs:{"label":"Active HAZARD"},on:{"change":_vm.filterAirports},model:{value:(_vm.showOnlyIfActiveHazard),callback:function ($$v) {_vm.showOnlyIfActiveHazard=$$v},expression:"showOnlyIfActiveHazard"}})],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.airportsHeaders,"items":_vm.filteredAirports,"search":_vm.search,"show-select":"","item-key":"_id","footer-props":{
        itemsPerPageOptions: [20, 50, 100, 200, -1],
      }},on:{"click:row":_vm.showAirportDetail},scopedSlots:_vm._u([{key:"item.covid19Periods",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCovid19Status(item)))])]}},{key:"item.hazardPeriods",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatHazardStatus(item)))])]}}],null,true),model:{value:(_vm.selectedAirports),callback:function ($$v) {_vm.selectedAirports=$$v},expression:"selectedAirports"}}),(_vm.selectedAirports.length)?_c('div',{staticClass:"actions-filter"},[_c('v-select',{attrs:{"items":_vm.actionOptions,"label":"Apply to Selected Airports"},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),(_vm.showActionCalendar)?_c('div',{staticClass:"d-flex justify-center px-10"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End Date","prepend-icon":"event","readonly":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},on))]}}],null,false,2556702434),model:{value:(_vm.showFilterCalendar),callback:function ($$v) {_vm.showFilterCalendar=$$v},expression:"showFilterCalendar"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.showFilterCalendar = false}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1)],1):_vm._e(),_c('v-btn',{attrs:{"small":"","dark":"","color":"blue"},on:{"click":_vm.handleSelectedAction}},[_vm._v("enter")])],1):_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-end align-center my-2 font-abel-16px"},[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","dark":"","color":"grey"},on:{"click":function($event){return _vm.goHome()}}},[_vm._v("close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }