<template>
  <div>
    <Navbar />

    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <div class="bottom-frame-no-toolbar pa-5">
      <!-- CONFIRMATION DIALOG -->
      <v-dialog v-model="confirmationDialog" persistent max-width="450px">
        <div class="confirmation-dialog">
          <div class="d-flex justify-space-between font-abel-20px">
            <h3 class="font-weight-bold  red--text">WARNING</h3>
            <v-btn @click="hideConfirmationDialog" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>
          <v-divider></v-divider>
          <p class="my-2">{{ confirmationMessage }}</p>
          <div class="d-flex justify-end align-center">
            <v-btn @click="handleDeleteSecurityGroup" small dark color="red"
              >confirm</v-btn
            >
            <v-btn
              class="ml-1"
              @click="hideConfirmationDialog"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </v-dialog>
      <div v-if="!loading">
        <div class="d-flex align-center pa-3 ml-5">
          <i class="fa-duotone fa-shield-quartered"></i>
          <h2 class="indigo--text ml-6">Security Groups</h2>
        </div>

        <div v-if="editing">
          <div v-if="securityGroup">
            <v-container
              v-if="securityGroup"
              fluid
              class="font-abel-14px r-container"
            >
              <v-form ref="form" v-model="valid">
                <v-card class="mb-2" elevation="2" outlined shaped tile>
                  <v-card-text>
                    <div class="d-flex justify-space-between align-center mb-2">
                      <div class="d-flex align-center">
                        <div class="font-abel-24px indigo--text mr-5">
                          {{ securityGroup.name }}
                        </div>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              v-if="!cloning"
                              @click="handleCloneSecurityGroup"
                            >
                              <i class="fa-solid fa-clone"></i>
                            </v-btn>
                          </template>
                          <span>Clone this Security Group</span>
                        </v-tooltip>
                      </div>

                      <v-btn @click="handleCancelEdit" icon>
                        <i class="fa-solid fa-x"></i>
                      </v-btn>
                    </div>

                    <!-- ROW 1 -->
                    <v-row dense align="start">
                      <v-col cols="12" sm="6">
                        <v-text-field
                          outlined
                          dense
                          label="Security Group Name"
                          :rules="securityGroupNameRules"
                          v-model="securityGroup.name"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- ROW 2 -->
                    <v-row dense align="center">
                      <v-select
                        v-model="securityGroup.companies"
                        :items="companies"
                        :menu-props="{ maxHeight: '400' }"
                        label="Companies"
                        multiple
                        clearable
                      ></v-select>
                    </v-row>

                    <!-- ROW 3 -->
                    <v-row dense align="center">
                      <v-select
                        v-model="securityGroup.jobTitles"
                        :items="jobTitles"
                        :menu-props="{ maxHeight: '400' }"
                        label="Job Titles"
                        multiple
                        clearable
                      ></v-select>
                    </v-row>

                    <!-- ROW 4 -->
                    <v-row dense align="center">
                      <v-col cols="12">
                        <v-expansion-panels multiple>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <h3 class="indigo--text">
                                Authorizations
                              </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                class="d-flex justify-start align-center"
                              ></div>
                              <div>
                                <!-- Aircrafts -->
                                <div>
                                  <h4>Aircraft</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.aircraft
                                          .create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.aircraft
                                          .read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.aircraft
                                          .update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.aircraft
                                          .delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>
                                </div>

                                <v-divider class="my-2"></v-divider>
                                <!-- Airports -->
                                <div>
                                  <h4>Airports</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.airport
                                          .create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.airport
                                          .read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.airport
                                          .update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.airport
                                          .delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>
                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note:</span
                                    >Enabling "Update" allows the user to update
                                    the airport's Hazard, COVID-19, and Minimum
                                    Loadmaster Exemptions.
                                  </p>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Notifications -->
                                <div>
                                  <h4>Notifications</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .assignmentNotification.create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .assignmentNotification.read.enabled
                                      "
                                      disabled
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .assignmentNotification.update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      disabled
                                      v-model="
                                        securityGroup.authorizations
                                          .assignmentNotification.delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <div>
                                    <v-select
                                      :items="assignmentNotificationReadLevels"
                                      outlined
                                      dense
                                      label="Read Level"
                                      v-model="
                                        securityGroup.authorizations
                                          .assignmentNotification.read.level
                                      "
                                    ></v-select>
                                  </div>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Bidlines -->
                                <div>
                                  <h4>Bidlines</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations.bidline
                                          .create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.bidline
                                          .read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.bidline
                                          .update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.bidline
                                          .delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Employee -->
                                <div>
                                  <h4>Employees</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations.employee
                                          .create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.employee
                                          .read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.employee
                                          .update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.employee
                                          .delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>
                                  <div>
                                    <v-select
                                      :items="employeeUpdateLevels"
                                      outlined
                                      dense
                                      label="Update Level"
                                      v-model="
                                        securityGroup.authorizations.employee
                                          .update.level
                                      "
                                    ></v-select>
                                  </div>
                                  <div>
                                    <v-select
                                      :items="employeeReadLevels"
                                      outlined
                                      dense
                                      label="Read Level"
                                      v-model="
                                        securityGroup.authorizations.employee
                                          .read.level
                                      "
                                    ></v-select>
                                  </div>

                                  <v-checkbox
                                    v-model="
                                      securityGroup.authorizations.employee
                                        .update.securityGroup
                                    "
                                    label="Allow the user to change an emloyee's security group."
                                  ></v-checkbox>

                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note 1:</span
                                    >{{ employeeUpdateLevelDescription }}
                                  </p>

                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note 2:</span
                                    >{{ employeeReadLevelDescription }}
                                  </p>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Flights -->
                                <div>
                                  <h4>Flights</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.flight
                                          .create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.flight.read
                                          .enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations.flight
                                          .update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      disabled
                                      v-model="
                                        securityGroup.authorizations.flight
                                          .delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <div>
                                    <v-select
                                      v-model="
                                        securityGroup.authorizations.flight
                                          .update.allowedFields
                                      "
                                      :items="flightUpdateFields"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Allow the user to add or change"
                                      multiple
                                      clearable
                                    ></v-select>
                                  </div>

                                  <div class="d-flex justify-start">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations.flight.read
                                          .filterByGateway
                                      "
                                      label="
                                        Get flights filtered by Gateway.
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      class="ml-5"
                                      dense
                                      v-model="
                                        securityGroup.authorizations.flight.read
                                          .getAircraftScheduleConflicts
                                      "
                                      label="
                                      Allow the user to get Aircraft Schedule Conflict Reports.
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note 1:</span
                                    >Enabling "Update" allows the user to update
                                    the flight's payload information.
                                  </p>
                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note 2:</span
                                    >Enabling "Get flights filtered by Gateway"
                                    limits the flights to those flights where
                                    the origin or destination code matches the
                                    user's Gateway or any SubGateway.
                                  </p>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Flight Assignments -->
                                <div>
                                  <h4>Flight Assignments</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .flightAssignment.create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .flightAssignment.read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .flightAssignment.update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .flightAssignment.delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <div>
                                    <v-select
                                      v-model="
                                        securityGroup.flightAssignmentCargoRoles
                                      "
                                      :items="flightAssignmentCargoRoles"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Cargo Roles"
                                      multiple
                                      clearable
                                    ></v-select>
                                  </div>

                                  <div>
                                    <v-select
                                      v-model="
                                        securityGroup.flightAssignmentPassengerRoles
                                      "
                                      :items="flightAssignmentPassengerRoles"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Passenger Roles"
                                      multiple
                                      clearable
                                    ></v-select>
                                  </div>

                                  <div>
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .flightAssignment.overrideWarnings
                                      "
                                      label="
                                        Override Hard Level Warnings
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note:</span
                                    >Enabling "Override Warnings" allows the
                                    user to save a flight assignment with hard
                                    level warnings.
                                  </p>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Ground Assignments -->
                                <div>
                                  <h4>Ground Assignments</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .groundAssignment.create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .groundAssignment.read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .groundAssignment.update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .groundAssignment.delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <div>
                                    <v-select
                                      v-model="
                                        securityGroup.groundAssignmentCargoRoles
                                      "
                                      :items="groundAssignmentCargoRoles"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Cargo Roles"
                                      multiple
                                      clearable
                                    ></v-select>
                                  </div>

                                  <div>
                                    <v-select
                                      v-model="
                                        securityGroup.groundAssignmentPassengerRoles
                                      "
                                      :items="groundAssignmentPassengerRoles"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Passenger Roles"
                                      multiple
                                      clearable
                                    ></v-select>
                                  </div>

                                  <div>
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .groundAssignment.overrideWarnings
                                      "
                                      label="
                                        Override Warnings
                                      "
                                    ></v-checkbox>
                                  </div>

                                  <p>
                                    <span class="font-weight-bold mr-2"
                                      >Note:</span
                                    >Enabling "Override Hard Level Warnings"
                                    allows the user to save a ground assignment
                                    with hard level warnings.
                                  </p>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Payroll Reports -->
                                <div>
                                  <h4>Payroll Reports</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>

                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>
                                  <div>
                                    <v-select
                                      :items="payrollReportCreateLevels"
                                      outlined
                                      dense
                                      label="Create Level"
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.create.level
                                      "
                                    ></v-select>
                                  </div>
                                  <div>
                                    <v-select
                                      :items="payrollReportReadLevels"
                                      outlined
                                      dense
                                      label="Read Level"
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.read.level
                                      "
                                    ></v-select>
                                  </div>
                                  <div>
                                    <v-select
                                      :items="payrollReportUpdateLevels"
                                      outlined
                                      dense
                                      label="Update Level"
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.update.level
                                      "
                                    ></v-select>
                                  </div>
                                  <div>
                                    <v-select
                                      :items="payrollReportDeleteLevels"
                                      outlined
                                      dense
                                      label="Delete Level"
                                      v-model="
                                        securityGroup.authorizations
                                          .payrollReport.delete.level
                                      "
                                    ></v-select>
                                  </div>
                                </div>
                                <v-divider class="my-2"></v-divider>

                                <!-- Security Group -->
                                <div>
                                  <h4>Security Groups</h4>
                                  <div class="d-flex justify-space-between">
                                    <v-checkbox
                                      dense
                                      v-model="
                                        securityGroup.authorizations
                                          .securityGroup.create.enabled
                                      "
                                      label="
                                        Create
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      disabled
                                      v-model="
                                        securityGroup.authorizations
                                          .securityGroup.read.enabled
                                      "
                                      label="
                                        Read
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .securityGroup.update.enabled
                                      "
                                      label="
                                        Update
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-model="
                                        securityGroup.authorizations
                                          .securityGroup.delete.enabled
                                      "
                                      label="
                                        Delete
                                      "
                                    ></v-checkbox>
                                  </div>
                                </div>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>

                    <div
                      v-if="securityGroup._id"
                      class="font-abel-12px  grey-text"
                    >
                      {{ `Security Group ID:  ${securityGroup._id}` }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-form>
              <div
                class="d-flex justify-space-between align-center mt-2 font-abel-16px"
              >
                <div>
                  <v-btn
                    :disabled="snackbarVisible"
                    @click="showWarningBeforeDeletingSecurityGroup"
                    v-if="enableDeleteSecurityGroup"
                    icon
                  >
                    <i class="fa-solid fa-trash-can"></i>
                  </v-btn>
                </div>
                <div class="d-flex justify-end align-center">
                  <v-btn
                    :disabled="snackbarVisible"
                    v-if="enableUpdateSecurityGroup"
                    @click="handleUpsertSecurityGroup"
                    small
                    dark
                    color="green"
                    >save</v-btn
                  >
                  <v-btn
                    class="ml-1"
                    @click="handleCancelEdit"
                    small
                    dark
                    color="grey"
                    >cancel</v-btn
                  >
                </div>
              </div>
            </v-container>
          </div>
        </div>
        <div v-else class="frame">
          <v-container fluid>
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center">
                <div>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    :style="{
                      marginLeft: '10px',
                      marginBottom: '10px',
                    }"
                  ></v-text-field>
                </div>
                <div v-if="enableCreateSecurityGroup && !editing">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="handleCreateSecurityGroup" icon v-on="on"
                        ><i class="fa-solid fa-plus"></i
                      ></v-btn>
                    </template>
                    <span>New Security Group</span>
                  </v-tooltip>
                </div>
              </div>

              <v-data-table
                dense
                :headers="headers"
                :items="securityGroups"
                :search="search"
                :footer-props="{
                  itemsPerPageOptions: [50, 100, 200],
                }"
                @click:row="handleStartEdit"
                item-key="securityGroup.id"
              >
              </v-data-table>
            </v-col>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";

export default {
  name: "securityGroups",
  components: { AutoLogout, Navbar },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      valid: true,
      isMobile: false,
      editing: false,
      cloning: false,
      confirmationDialog: false,
      confirmationMessage: "",
      securityGroup: null,
      securityGroups: [],
      showAuthorizations: false,
      showAuthorizedCompanies: false,
      showAuthorizedJobTitles: false,
      showAuthorizedAssignmentRoles: false,
      securityGroupNameRules: [(v) => !!v || "Security Group Name is required"],
      flightUpdateFields: ["Remarks"],
      companies: [
        "Atlas Air",
        "AGS",
        "Aviablu",
        "CLC (Atlas Air)",
        "Flightline",
        "FEAM",
        "Encompass Air",
        "Connexus Air",
        "WFS",
      ],
      jobTitles: [
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
        "Contract Loadmaster",
        "Loadmaster/PSR",
        "Loadmaster Trainee",
        "PSR",
        "Check PSR",
        "Lead PSR",
        "Senior PSR",
        "PSR/PAA Trainee",
        "PAA",
        "Special Loads/CLC",
        "Mechanic",
        "Contract Mechanic",
        "Supervisor",
        "Manager",
        "Administrator",
        "Scheduler",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
        "GCC",
        "Other",
      ],
      flightAssignmentCargoRoles: [
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster Training",
        "Mechanic",
        "Jumpseat",
      ],
      flightAssignmentPassengerRoles: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "POC",
        "GSC",
        "Mechanic",
        "Jumpseat",
      ],
      groundAssignmentCargoRoles: [
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster Training",
        "Mechanic",
        "Station Rep.",
      ],
      groundAssignmentPassengerRoles: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "POC",
        "GSC",
        "Mechanic",
        "Station Rep.",
      ],
      employeeUpdateLevels: ["Employee", "Manager"],
      assignmentNotificationReadLevels: ["Basic", "Employee", "Manager"],
      employeeReadLevels: ["Employee", "Manager"],
      payrollReportCreateLevels: ["Employee", "Manager", "Administrator"],
      payrollReportReadLevels: ["Employee", "Manager", "Administrator"],
      payrollReportUpdateLevels: ["Employee", "Manager", "Administrator"],
      payrollReportDeleteLevels: ["Employee", "Manager", "Administrator"],
      search: "",
      headers: [
        {
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      enableGroupToChangeEmployeeSecurityGroup: false,
    };
  },

  created() {
    this.getSecurityGroups();
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    employeeUpdateLevelDescription() {
      if (!this.securityGroup) {
        return "";
      }

      switch (this.securityGroup.authorizations.employee.update.level) {
        case "Employee":
          return 'The "Employee" update level allows the user to access only his/her profile, and restricts the number of fields available for update.';
        case "Manager":
          return 'The "Manager" update level allows the user to access any employee profile (filtered by Company Name), and increases the number of fields available for update.';

        default:
          return "";
      }
    },
    employeeReadLevelDescription() {
      if (!this.securityGroup) {
        return "";
      }

      switch (this.securityGroup.authorizations.employee.read.level) {
        case "Employee":
          return "The \"Employee\" read level disables the feature that allows the user to view an employee's information on a pop-up window when clicking the employee's name. ";
        case "Manager":
          return "The \"Manager\" read level allows the user to view an employee's information on a pop-up window when clicking the employee's name.";

        default:
          return "";
      }
    },
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async getSecurityGroups() {
      this.loading = true;

      this.securityGroups = [];

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/security-groups", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        this.loading = false;

        this.securityGroups = res.data.securityGroups;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = `Error "${error}`;
        this.snackbarVisible = true;
      }
    },

    async handleCreateSecurityGroup() {
      this.securityGroup = {
        name: "Group Name",
        companies: [
          "Atlas Air",
          "AGS",
          "Aviablu",
          "Flightline",
          "FEAM",
          "Encompass Air",
          "Connexus Air",
        ],
        jobTitles: [
          "Loadmaster",
          "Check Loadmaster",
          "Senior Loadmaster",
          "PSR",
          "Check PSR",
          "Lead PSR",
          "Senior PSR",
          "PAA",
          "Mechanic",
          "Contract Mechanic",
          "Administrator",
          "Manager",
          "Scheduler",
          "Station Rep.",
          "Other",
        ],
        flightAssignmentCargoRoles: [
          "Check Loadmaster",
          "Senior Loadmaster",
          "Loadmaster",
          "Loadmaster Training",
          "Jumpseat",
        ],
        flightAssignmentPassengerRoles: [
          "PSR",
          "Lead PSR",
          "Senior PSR",
          "Check PSR",
          "PSR Training",
          "PAA",
          "PAA Training",
          "POC",
          "GSC",
          "Jumpseat",
        ],
        groundAssignmentCargoRoles: [
          "Check Loadmaster",
          "Senior Loadmaster",
          "Loadmaster",
          "Loadmaster Training",
          "Station Rep.",
        ],
        groundAssignmentPassengerRoles: [
          "PSR",
          "Lead PSR",
          "Senior PSR",
          "Check PSR",
          "PSR Training",
          "PAA",
          "PAA Training",
          "POC",
          "GSC",
          "Station Rep.",
        ],
        authorizations: {
          employee: {
            create: {
              enabled: true,
            },
            read: {
              enabled: true,
              level: "Employee",
              filterByGateway: false,
            },
            update: {
              enabled: true,
              level: "Employee",
              allowedFields: [],
            },
            delete: {
              enabled: true,
            },
          },
          flight: {
            create: {
              enabled: false,
            },
            read: {
              enabled: true,
              filterByGateway: false,
            },
            update: {
              enabled: true,
            },
            delete: {
              enabled: false,
            },
          },
          securityGroup: {
            create: {
              enabled: false,
            },
            read: {
              enabled: true,
            },
            update: {
              enabled: false,
            },
            delete: {
              enabled: false,
            },
          },
          airport: {
            create: {
              enabled: false,
            },
            read: {
              enabled: true,
            },
            update: {
              enabled: false,
            },
            delete: {
              enabled: false,
            },
          },
          aircraft: {
            create: {
              enabled: false,
            },
            read: {
              enabled: true,
            },
            update: {
              enabled: false,
            },
            delete: {
              enabled: false,
            },
          },
          flightAssignment: {
            create: {
              enabled: true,
            },
            read: {
              enabled: true,
              level: "Employee",
            },
            update: {
              enabled: true,
            },
            delete: {
              enabled: true,
            },
          },
          groundAssignment: {
            create: {
              enabled: true,
            },
            read: {
              enabled: true,
              level: "Employee",
            },
            update: {
              enabled: true,
            },
            delete: {
              enabled: true,
            },
          },
          assignmentNotification: {
            create: {
              enabled: false,
            },
            read: {
              enabled: true,
              level: "Basic",
            },
            update: {
              enabled: false,
            },
            delete: {
              enabled: false,
            },
          },
          bidline: {
            create: {
              enabled: false,
            },
            read: {
              enabled: true,
            },
            update: {
              enabled: true,
            },
            delete: {
              enabled: true,
            },
          },
          payrollReport: {
            create: {
              enabled: true,
              level: "Employee",
            },
            read: {
              enabled: true,
              level: "Employee",
            },
            update: {
              enabled: true,
              level: "Employee",
            },
            delete: {
              enabled: true,
              level: "Employee",
            },
          },
        },
      };

      this.editing = true;
    },

    async handleCloneSecurityGroup() {
      let clone = { ...this.securityGroup };
      clone._id = null;
      clone.id = null;
      clone.name = `Clone of ${this.securityGroup.name}`;

      this.securityGroup = { ...clone };

      this.editing = true;
      this.cloning = true;
    },

    handleStartEdit(securityGroup) {
      this.securityGroup = { ...securityGroup };
      this.editing = true;
    },

    handleCancelEdit() {
      this.securityGroup = null;
      this.editing = false;
      this.cloning = false;
    },

    async handleUpsertSecurityGroup() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();
        let res;

        if (this.securityGroup._id) {
          res = await api.put(
            `/security-groups/${this.securityGroup.id}`,
            {
              securityGroup: this.securityGroup,
            },
            {
              headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
            }
          );

          this.loading = false;
        } else {
          res = await api.post(
            `/security-groups`,
            {
              securityGroup: this.securityGroup,
            },
            {
              headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
            }
          );
          this.loading = false;
        }

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.getSecurityGroups();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.editing = false;
        this.cloning = false;
      }
    },

    async showWarningBeforeDeletingSecurityGroup() {
      this.loading = true;

      const res = await this.$store.dispatch("getEmployees", true);

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.data.message;
        this.snackbarVisible = true;
        return;
      }

      const employees = res.employees;

      let count = employees.filter((item) => {
        return item.securityGroupId === this.securityGroup.id;
      });

      if (count.length) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Security Group "${this.securityGroup.name}" is assigned to ${count.length} employee(s) and cannot be deleted`;
        this.snackbarVisible = true;
        return;
      }

      this.confirmationMessage = `Removing a Security Group cannot be undone.
       Any employee that belongs to this Security Group will loose all security settings.
       Please confirm to continue.`;
      this.confirmationDialog = true;
    },

    hideConfirmationDialog() {
      this.confirmationMessage = "";
      this.confirmationDialog = false;
    },

    async handleDeleteSecurityGroup() {
      this.confirmationMessage = "";
      this.confirmationDialog = false;
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(
          `/security-groups/${this.securityGroup._id}`,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );
        this.loading = false;

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        this.getSecurityGroups();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.editing = false;
      }
    },
  },
};
</script>

<style scoped>
.frame {
  padding: 10px;
}
.r-row {
  display: block;
  margin: 0 5px;
  border-bottom: 1px solid #bdbdbd;
  padding: 5px;
  cursor: pointer;
}

.r-row:hover {
  background-color: #f0efbb;
}

.fa-plus {
  font-size: 1.5rem;
  color: blue;
}

.fa-pen:hover {
  color: green;
}

h3 {
  color: #7b7b7b;
}

.fa-shield-quartered {
  font-size: 2.5rem;
  color: #f1560f;
}

.fa-x {
  font-size: 1.3rem;
  color: #bebebe;
}

.fa-x:hover {
  color: #000;
}

.fa-clone {
  font-size: 1.3rem;
  color: #bebebe;
}

.fa-clone:hover {
  color: #2196f3;
}

.fa-trash-can {
  font-size: 1.3rem;
  color: #bebebe;
}

.fa-trash-can:hover {
  color: #f44336;
}
</style>
