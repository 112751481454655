<template>
  <div>
    <Navbar />
    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" @click="goBack" v-on="on" icon dark small
            ><v-icon>arrow_back</v-icon></v-btn
          >
        </template>
        <span>Go back to Flight Board</span>
      </v-tooltip>

      <!-- This Trip -->
      <div
        class="d-flex justify-start align-center text-truncate font-abel-10px mx-1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="loading" small icon v-on="on"
              ><v-icon color="white">flight</v-icon></v-btn
            >
          </template>
          <div class="tooltip font-abel-12px">
            <div v-if="!loading">
              {{ trip.route }}
            </div>
          </div>
        </v-tooltip>
      </div>

      <!--Filters and  Warnings -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-slider
        @change="scrollBoard"
        class="ml-1"
        v-model="slideX"
        :max="dateFilter.days.length - 1"
        :min="0"
        hide-details
      >
      </v-slider>

      <v-spacer></v-spacer>

      <div class="font-abel-12px mr-2">
        {{ `${this.employees.length} employee(s)` }}
      </div>

      <div>
        <!-- Padding -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="loading"
              @click="paddingDialog = 'true'"
              small
              icon
              v-on="on"
            >
              <v-icon color="white">settings_ethernet</v-icon>
            </v-btn>
          </template>
          <span>Flight Assignment Padding </span>
        </v-tooltip>

        <!-- Search by station -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="loading"
              @click="toggleSearchByStation()"
              icon
              v-on="on"
              ><v-icon :color="searchByStationIconColor">place</v-icon></v-btn
            >
          </template>
          <span v-if="!loading"
            >{{
              `Show employees that landed in ${this.trip.flights[sequence].originIata} before this trip first flight's ETD`
            }}
          </span>
        </v-tooltip>

        <!-- Refresh -->
        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <v-icon color="green">refresh</v-icon>
        </v-btn>

        <!-- Zoom In -->
        <v-btn :disabled="loading" @click="zoomIn" small icon class="ml-1">
          <v-icon color="blue">zoom_in</v-icon>
        </v-btn>

        <!-- Zoom Out -->
        <v-btn :disabled="loading" @click="zoomOut" small icon>
          <v-icon color="white">zoom_out</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" @click="goBack" v-on="on" icon dark small
            ><v-icon>arrow_back</v-icon></v-btn
          >
        </template>
        <span>Go back to Flight Board</span>
      </v-tooltip>

      <!-- This Trip -->
      <div
        class="d-flex justify-start align-center text-truncate font-abel-10px mx-1"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="loading" small icon v-on="on"
              ><v-icon color="white">flight</v-icon></v-btn
            >
          </template>
          <div class="tooltip font-abel-12px">
            <div v-if="!loading">
              {{ trip.route }}
            </div>
          </div>
        </v-tooltip>
      </div>
      <!--Filters  -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>
      </div>

      <v-spacer></v-spacer>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- Refresh -->
          <v-list-item @click="getAssignments" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Padding -->
          <v-list-item @click="paddingDialog = 'true'" link>
            <v-list-item-icon>
              <v-icon color="white">settings_ethernet</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Conflict padding</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Search by Station -->
          <v-list-item @click="toggleSearchByStation()" link>
            <v-list-item-icon>
              <v-icon :color="searchByStationIconColor">place</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Last Arrival</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom In -->
          <v-list-item @click="zoomIn" link>
            <v-list-item-icon>
              <v-icon color="blue">zoom_in</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom Out -->
          <v-list-item @click="zoomOut" link>
            <v-list-item-icon>
              <v-icon color="white">zoom_out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog v-model="employeeFilterDialog" persistent max-width="450px">
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <v-dialog
      v-model="showEmployeeInformationDialog"
      persistent
      max-width="800px"
    >
      <employee-information
        :employee="employee"
        @close="handleHideEmployeeInformationDialog"
      ></employee-information>
    </v-dialog>

    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-model="showFlightAssignmentInformationDialog"
      persistent
      max-width="800px"
    >
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </v-dialog>

    <!-- GROUND ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showGroundAssignmentInformationDialog" max-width="800px">
      <ground-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @hide="hideGroundAssignmentInformationDialog($event)"
      >
      </ground-assignment-information>
    </v-dialog>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-if="showStationAssignmentInformationDialog"
      v-model="showStationAssignmentInformationDialog"
      max-width="800px"
    >
      <station-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </v-dialog>

    <!-- NEW FLIGHT ASSIGNMENT DIALOG -->
    <v-dialog v-model="showNewFlightAssignmentDialog" max-width="800px">
      <div v-if="!loading" class="r-dialog">
        <div class="assignment-frame" v-if="!warnings">
          <div v-if="employee" class="d-flex justify-space-between">
            <div class="font-abel-24px-bold">
              {{ employee.surname }}, {{ employee.givenName }}
            </div>
            <v-btn @click="handleCloseNewFlightAssignmentDialog" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>

          <div
            v-if="employee"
            class="d-flex justify-space-between align-center font-abel-26px"
          >
            <p>{{ employee.companyName }} - {{ employee.jobTitle }}</p>
            <p>{{ employee.employeeId }}</p>
          </div>

          <v-form v-model="isValid">
            <div class="d-flex justify-space-between">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on" @click="handleGetPreviousFlight">
                    <i class="fa-solid fa-left"></i>
                  </div>
                </template>
                <span>Previous Flight</span>
              </v-tooltip>

              <div class="flight-route">
                {{
                  `${trip.flights[sequence].originIata}-${trip.flights[sequence].destinationIata}`
                }}
              </div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on" @click="handleGetNextFlight">
                    <i class="fa-solid fa-right"></i>
                  </div>
                </template>
                <span>Next Flight</span>
              </v-tooltip>
            </div>

            <div class="font-abel-20px text-center">
              {{ `${formatFullFlightSnapshot(trip.flights[sequence])}` }}
            </div>

            <div class="font-abel-16px text-right">
              {{ `Leg ${sequence + 1} of ${trip.flights.length} ` }}
            </div>

            <div class="crew-frame  my-2 font-abel-16px">
              <v-row
                class="r-row"
                dense
                v-for="flightRole in flightRoles"
                :key="flightRole.description"
              >
                <v-col cols="2">
                  {{ flightRole.description }}
                </v-col>

                <v-col cols="10">
                  <div class="d-flex align-center">
                    <v-btn
                      v-for="role in flightRole.roles"
                      :key="role.code"
                      @click="handlePrecheckFlightAssignment(role.code)"
                      :class="{
                        'is-assigned':
                          getEmployeeAssigned(role.code) !== 'Assign',
                      }"
                      class="text-capitalize mr-1 "
                      x-small
                      outlined
                      :disabled="!role.enabled"
                      >{{ `${getEmployeeAssigned(role.code)}` }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-textarea
              class="mt-2"
              outlined
              v-model="assignmentRemarks"
              :rules="remarksRules"
              label="Remarks"
              no-resize
              rows="3"
            >
            </v-textarea>
          </v-form>
        </div>
        <div v-else class="warnings-frame">
          <!-- TITLE -->
          <div class="d-flex justify-space-between">
            <h2 class="red--text">
              Warnings
            </h2>
            <v-btn @click="handleCancel" small icon
              ><v-icon>close</v-icon></v-btn
            >
          </div>

          <div v-if="employee" class="font-abel-24px-bold mb-2">
            {{ `${employee.surname}, ${employee.givenName}` }}
          </div>
          <div class="font-abel-20px  mb-5">
            {{ `${formatFullFlightSnapshot(trip.flights[sequence])}` }}
          </div>

          <!-- WARNINGS -->
          <v-text-field
            v-if="warnings.length > 1"
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :style="{
              maxWidth: '200px',
              marginLeft: '10px',
              marginBottom: '10px',
            }"
          ></v-text-field>

          <v-data-table
            dense
            :headers="headers"
            :search="search"
            :items="warnings"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, -1],
            }"
          >
          </v-data-table>

          <div v-if="hardWarnings.length > 0" class="disclaimer-text">
            <div
              v-if="
                user.securityGroup.authorizations.flightAssignment
                  .overrideWarnings
              "
            >
              This assignment contains hard warnings.
            </div>
            <div v-else>
              This assignment contains hard warnings and cannot be saved.
            </div>
          </div>

          <div class="d-flex justify-space-between align-center font-abel-16px">
            <div class="d-flex justify-center font-abel-16px my-2">
              <span class="mr-2">Atlas Air Online Training Center</span>
              <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
            </div>

            <div v-if="hardWarnings.length > 0">
              <div
                v-if="
                  user.securityGroup.authorizations.flightAssignment
                    .overrideWarnings
                "
              >
                <v-btn @click="handleSaveAssignment" small dark color="orange"
                  >Acknowledge and Continue</v-btn
                >
              </div>
              <div v-else>
                <v-btn @click="handleCancel" small dark color="grey"
                  >cancel</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-btn @click="handleSaveAssignment" small dark color="blue"
                >Acknowledge and Continue</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- NEW GROUND ASSIGNMENT DIALOG -->
    <v-dialog
      v-if="showNewGroundAssignmentDialog"
      v-model="showNewGroundAssignmentDialog"
      max-width="800px"
    >
      <ground-assignment
        :groundAssignmentStart="groundAssignmentStart"
        :employee="employee"
        @hide="handleHideGroundAssignmentDialog($event)"
      >
      </ground-assignment>
    </v-dialog>

    <!-- PADDING DIALOG -->
    <v-dialog v-model="paddingDialog" max-width="350px">
      <div class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Flight Assignment Padding</div>
          <v-btn @click="paddingDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <v-select
          v-model="paddingBeforeText"
          label="Before assignment"
          :items="paddingOptions"
        ></v-select>

        <v-select
          v-model="paddingAfterText"
          label="After assignment"
          :items="paddingOptions"
        ></v-select>

        <div class="d-flex justify-end align-center my-1">
          <v-btn @click="saveConflictPadding" small dark color="green"
            >save</v-btn
          >
          <v-btn
            class="ml-1"
            @click="paddingDialog = false"
            small
            dark
            color="grey"
            >cancel</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- FLIGHT BOARD -->
    <div v-if="!loading">
      <div class="top-frame font-abel-16px">
        <div class="top-panel-left">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="loading"
                @click="handleOpenEmployeeFilter"
                small
                icon
              >
                <i class="fa-solid fa-filter"></i>
              </v-btn>
            </template>
            <span>Filter Employees by Job Title</span>
          </v-tooltip>
          <!-- Search by last name -->
          <v-text-field
            v-model="lastName"
            dense
            label="Employee"
            single-line
            hide-details
            :style="{ maxWidth: '120px', marginLeft: '5px' }"
            @input="filterEmployees"
          ></v-text-field>
        </div>

        <div class="top-panel-right">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${dateFilter.days.length * (1440 / scale)}px`,
              left: `${scrollX}px`,
            }"
          >
            <div
              v-for="(day, index) in dateFilter.days"
              :key="index"
              class="top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}

              <div v-if="scale < 8" class="d-flex font-abel-10px">
                <div
                  v-bind:style="{
                    width: `${30 / scale}px`,
                  }"
                ></div>

                <div
                  v-for="tick in timeTicks"
                  :key="tick.index"
                  v-bind:style="{
                    width: `${60 / scale}px`,
                  }"
                >
                  <div>{{ tick.text }}</div>
                </div>
              </div>
            </div>

            <div
              v-if="currentTimeX > 0"
              class="current-time-box d-flex justify-center align-center font-abel-16px"
              v-bind:style="{
                left: `${currentTimeX - 21}px`,
                width: '42px',
              }"
            >
              {{ formatTime(new Date()) }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="filteredEmployees.length" class="bottom-frame">
        <div class="bottom-panel-left">
          <div
            v-for="(employee, index) in filteredEmployees"
            @click="handleShowEmployeeInformationDialog(employee)"
            :key="employee._id"
            class="bottom-panel-left-row font-abel-14px cursor-hand"
          >
            <div class="bottom-panel-left-row-section truncate ">
              {{ index + 1 }} - {{ employee.surname }},
              {{ employee.givenName.substring(0, 1) }}
            </div>

            <div class="bottom-panel-left-row-section truncate font-abel-10px">
              {{ employee.companyName }} - {{ employee.jobTitle }}
            </div>
            <div class=" font-abel-12px bottom-panel-left-row-section truncate">
              {{ employee.gatewayCode }} - {{ employee.employeeId }}
            </div>
          </div>
        </div>

        <div
          class="bottom-panel-right"
          @scroll="scrollHorizontal"
          ref="bottomPanelRight"
        >
          <div
            class="bottom-panel-right-inner"
            :style="{
              width: `${dateFilter.days.length * (1440 / scale)}px`,
              height: `${filteredEmployees.length * 60 + 50}px`,
            }"
          >
            <div
              v-for="employee in filteredEmployees"
              :key="employee._id"
              class="bottom-panel-right-row"
              :style="{ width: `${dateFilter.days.length * (1440 / scale)}px` }"
            >
              <div
                v-for="(day, index) in dateFilter.days"
                :key="index"
                @click="handleShowNewGroundAssignmentDialog(employee, $event)"
                class="bottom-panel-day"
                :style="{
                  width: `${1440 / scale}px`,
                  left: `${index * (1440 / scale)}px`,
                  backgroundColor: getBidDayStyle(employee.bidDays, day),
                  opacity: 0.5,
                }"
              >
                <div v-if="scale < 8" class="d-flex font-abel-10px">
                  <div
                    class="hour-line"
                    v-for="tick in timeTicks"
                    :key="tick.index"
                    v-bind:style="{
                      width: `${60 / scale}px`,
                    }"
                  ></div>
                </div>
              </div>

              <div
                v-if="currentTimeX > 0"
                class="current-time"
                v-bind:style="{
                  left: `${currentTimeX}px`,
                }"
              ></div>

              <!-- Assignments -->
              <div
                v-for="assignment in employee.assignments"
                :key="assignment._id"
              >
                <!-- FLight Assignment -->
                <div
                  v-if="assignment.type === 'Flight'"
                  class="flight-label-frame"
                  :style="{
                    width: `${assignment.flight.effectiveBlockTime / scale}px`,
                    left: `${getLocationLeft(
                      assignment.flight.effectiveTimeOut,
                      dateFilter.days[0],
                      scale
                    )}px`,
                  }"
                >
                  <FlightLabel
                    :flight="assignment.flight"
                    @clicked="
                      handleShowFlightAssignmentInformationDialog(assignment)
                    "
                  />
                </div>

                <!-- Ground Assignment -->
                <div
                  v-else
                  class="flight-label-frame"
                  v-bind:style="{
                    width: `${assignment.duration / scale}px`,
                    left: `${getLocationLeft(
                      assignment.startTime,
                      dateFilter.days[0],
                      scale
                    )}px`,
                  }"
                >
                  <GroundAssignmentLabel
                    :groundAssignment="assignment"
                    @clicked="
                      handleShowGroundAssignmentInformationDialog(assignment)
                    "
                  />
                </div>
              </div>

              <!-- FLIGHTS SHADOW  -->
              <div
                v-if="!loading"
                class="flight-shadow"
                @click="handleStartNewTripAssignment(employee)"
                :style="{
                  width: `${(trip.durationInMinutes +
                    paddingBefore +
                    paddingAfter) /
                    scale}px`,
                  left: `${getFlightShadowLocationLeft(
                    trip.flights[0].effectiveTimeOut,
                    paddingBefore,
                    dateFilter.days[0],
                    scale
                  )}px`,
                  height: '60px',
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bottom-frame font-abel-16px">
        <div v-if="loading" class="r-frame-rounded-yellow  indigo--text">
          Searching ...
        </div>

        <div v-else class="r-frame-rounded-yellow red--text">
          No employees found with the selected filters.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import FlightLabel from "../components/FlightLabel";
import GroundAssignment from "../components/GroundAssignment";
import GroundAssignmentLabel from "../components/GroundAssignmentLabel";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import EmployeeFilter from "../components/EmployeeFilter.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import GroundAssignmentInformation from "../components/GroundAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import EmployeeInformation from "../components/EmployeeInformation.vue";

export default {
  name: "tripAssignmentBoard",
  components: {
    FlightAssignmentInformation,
    GroundAssignmentInformation,
    StationAssignmentInformation,
    EmployeeInformation,
    FlightLabel,
    GroundAssignment,
    EmployeeFilter,
    GroundAssignmentLabel,
    AutoLogout,
    Navbar,
  },
  data() {
    return {
      loading: false,
      warnings: null,
      showFlightAssignmentInformationDialog: false,
      showEmployeeInformationDialog: false,
      showGroundAssignmentInformationDialog: false,
      showStationAssignmentInformationDialog: false,
      trip: null,
      sequence: 0,
      isMobile: false,
      slideX: 0,
      employee: null,
      employees: [],
      filteredEmployees: [],
      showNewGroundAssignmentDialog: false,
      groundAssignment: null,
      groundAssignmentStation: null,
      groundAssignmentStart: null,
      groundAssignmentDialog: false,
      flightAssignment: null,
      showNewFlightAssignmentDialog: false,
      paddingBeforeText: "30 Minutes",
      paddingAfterText: "30 Minutes",
      paddingBefore: 30,
      paddingAfter: 30,
      paddingDialog: false,
      paddingOptions: ["None", "30 Minutes", "1 Hour", "2 Hours"],
      lastName: "",
      scrollX: 0,
      isValid: true,
      employeeDialog: false,
      employeeFilterDialog: false,
      showStartCalendar: false,
      showEndCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      scale: 4,
      currentTime: "",
      flightRoleRules: [(v) => !!v || "Flight Role is required"],
      companies: ["Atlas Air", "AGS", "Aviablu", "Flightline"],
      searchByStation: false,
      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      newFlightAssignment: null,
      assignmentRemarks: "",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      flightRoles: [],
      flightRolesAssignedToCompanies: [],
      search: "",
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
    };
  },
  mixins: [mixin],
  created() {
    const company = this.user.securityGroup.companies[0]; //The first item on the companies array should be the employee's main company
    this.selectedCompany = company.slice();
    this.selectedBoardType = this.board.type;
    this.getAssignments();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },

    user() {
      return this.$store.getters.user;
    },
    board() {
      return this.$store.getters.board;
    },

    company() {
      return this.$store.getters.company;
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    tripFlightIds() {
      return this.$store.getters.tripFlightIds;
    },

    searchByStationIconColor() {
      if (this.searchByStation) {
        return "yellow";
      } else {
        return "#E0E0E0";
      }
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    hardWarnings() {
      return this.warnings.filter((warning) => {
        return warning.level === "Hard";
      });
    },
  },

  methods: {
    async getAssignments() {
      this.loading = true;

      this.employees = [];
      this.filteredEmployees = [];
      this.trip = null;
      try {
        const token = await aad.getIdTokenSilent();

        let res = await api.get("/flights/assignment-flights", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            flightIds: this.tripFlightIds,
          },
        });

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        const flights = res.data.flights;

        let flight = flights[0];
        let route = `${flight.originIata}-${flight.destinationIata}`;

        if (flights.length > 1) {
          for (let i = 1; i < flights.length; i++) {
            flight = flights[i];
            route += `-${flight.destinationIata}`;
          }
        }

        const t1 = new Date(flights[0].effectiveTimeOut).getTime();
        const t2 = new Date(
          flights[flights.length - 1].effectiveTimeIn
        ).getTime();
        const durationInMinutes = (t2 - t1) / 1000 / 60;

        this.trip = {
          flights: [...flights],
          route,
          durationInMinutes,
        };

        res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            filterStart: this.dateFilter.start,
            filterEnd: this.dateFilter.end,
          },
        });

        if (res.data.status !== "OK") {
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        let employees = res.data.employees;

        const allAssignments = res.data.assignments;
        const bidDays = res.data.bidDays;
        let assignments = [];
        employees.forEach((emp) => {
          assignments = allAssignments.filter((a) => {
            if (a.employee) {
              return a.employee._id === emp._id;
            }
          });
          emp.assignments = this.mergeAssignments(assignments);
          emp.bidDays = bidDays.filter((bidDay) => {
            return bidDay.employee === emp._id;
          });
          this.employees.push(emp);
        });

        this.loading = false;
        this.filterEmployees();

        if (this.filteredEmployees.length && this.boardStyle === "Board") {
          this.$nextTick(() => {
            window.scrollTo(0, this.board.employeeScrollY);
            const panel = this.$refs["bottomPanelRight"];
            if (panel) {
              panel.scrollLeft = Math.abs(this.board.scrollX);
              this.scale = this.board.scale;
            }
          });
        }
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
        this.loading = false;
      }
    },

    //-----------------------New Ground Assignment
    handleShowNewGroundAssignmentDialog(employee, e) {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.employee = { ...employee };

      const minutes =
        (e.clientX - 100) * this.scale + Math.abs(this.scrollX * this.scale);

      this.groundAssignmentStart =
        new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000;

      this.showNewGroundAssignmentDialog = true;
    },

    handleHideGroundAssignmentDialog(refresh) {
      this.showNewGroundAssignmentDialog = false;
      if (refresh) {
        this.getAssignments();
      }
    },
    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.enableReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleHideEmployeeInformationDialog() {
      this.showEmployeeInformationDialog = false;
    },

    //-----------------------Trip Assignment
    handleGetPreviousFlight() {
      if (this.sequence > 0) {
        this.sequence -= 1;
      }
    },

    handleGetNextFlight() {
      if (this.sequence < this.trip.flights.length - 1) {
        this.sequence += 1;
      }
    },

    handleStartNewTripAssignment(employee) {
      this.warnings = null;
      if (!this.enableUpdateFlightAssignment) {
        return;
      }

      //Set employee data
      this.employee = { ...employee };

      //Send Board data to the store
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.flightRolesAssignedToCompanies = [];

      this.trip.flights[this.sequence].crewmembers.forEach((item) => {
        if (!item.employee) {
          this.flightRolesAssignedToCompanies.push({
            companyName: item.assignedToCompany,
            role: item.role,
          });
        }
      });

      // this.getFlightRoles();
      let flightRoles = [];
      let roles = [];
      let roleCode;

      if (this.trip.flights[this.sequence].aircraft.cargoOnly) {
        this.user.securityGroup.flightAssignmentCargoRoles.forEach((role) => {
          switch (role) {
            case "Jumpseat":
              roles = [];
              for (let i = 1; i < 11; i++) {
                roleCode = `JS${i}`;
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
              }

              flightRoles.push({
                description: "Jumpseat",
                roles: [...roles],
              });

              break;

            case "Mechanic":
              roleCode = "MX";
              roles = [
                {
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                },
              ];
              for (let i = 2; i < 6; i++) {
                roleCode = `MX${i}`;
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
              }

              flightRoles.push({
                description: "Mechanic",
                roles: [...roles],
              });

              break;

            case "Loadmaster":
              roleCode = "LM";
              roles = [
                {
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                },
              ];
              for (let i = 2; i < 6; i++) {
                roleCode = `LM${i}`;
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
              }

              flightRoles.push({
                description: "Loadmaster",
                roles: [...roles],
              });

              break;

            case "Senior Loadmaster":
              roleCode = "SLM";
              roles = [
                {
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                },
              ];
              for (let i = 2; i < 4; i++) {
                roleCode = `SLM${i}`;
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
              }

              flightRoles.push({
                description: "Senior Loadmaster",
                roles: [...roles],
              });

              break;

            case "Check Loadmaster":
              roleCode = "CLM";
              roles = [
                {
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                },
              ];
              for (let i = 2; i < 3; i++) {
                roleCode = `CLM${i}`;
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
              }

              flightRoles.push({
                description: "Check Loadmaster",
                roles: [...roles],
              });

              break;

            case "Loadmaster Training":
              roleCode = "LMT";
              roles = [
                {
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                },
              ];
              for (let i = 2; i < 6; i++) {
                roleCode = `LMT${i}`;
                roles.push({
                  code: roleCode,
                  enabled: this.getRoleEnabledStatus(roleCode),
                });
              }

              flightRoles.push({
                description: "Loadmaster Training",
                roles: [...roles],
              });

              break;

            default:
              break;
          }
        });
      } else {
        this.user.securityGroup.flightAssignmentPassengerRoles.forEach(
          (role) => {
            switch (role) {
              case "Jumpseat":
                roles = [];
                for (let i = 1; i < 11; i++) {
                  roleCode = `JS${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "Jumpseat",
                  roles: [...roles],
                });

                break;

              case "Mechanic":
                roleCode = "MX";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];
                for (let i = 2; i < 6; i++) {
                  roleCode = `MX${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "Mechanic",
                  roles: [...roles],
                });

                break;

              case "GSC":
                roleCode = "GSC";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                flightRoles.push({
                  description: "GSC",
                  roles: [...roles],
                });

                break;

              case "POC":
                roleCode = "POC";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                flightRoles.push({
                  description: "POC",
                  roles: [...roles],
                });

                break;

              case "PAA Training":
                roleCode = "PAAT";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 6; i++) {
                  roleCode = `PAAT${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "PAA Training",
                  roles: [...roles],
                });

                break;

              case "PAA":
                roleCode = "PAA";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 6; i++) {
                  (roleCode = `PAA${i}`),
                    roles.push({
                      code: roleCode,
                      enabled: this.getRoleEnabledStatus(roleCode),
                    });
                }

                flightRoles.push({
                  description: "PAA",
                  roles: [...roles],
                });

                break;

              case "PSR Training":
                roleCode = "PSRT";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 6; i++) {
                  roleCode = `PSRT${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "PSR Training",
                  roles: [...roles],
                });

                break;

              case "Lead PSR":
                roleCode = "LPSR";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 6; i++) {
                  roleCode = `LPSR${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "LPSR",
                  roles: [...roles],
                });

                break;

              case "Check PSR":
                roleCode = "CPSR";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 3; i++) {
                  roleCode = `CPSR${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "Check PSR",
                  roles: [...roles],
                });

                break;

              case "Senior PSR":
                roleCode = "SPSR";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 3; i++) {
                  roleCode = `SPSR${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "Senior PSR",
                  roles: [...roles],
                });

                break;

              case "PSR":
                roleCode = "PSR";
                roles = [
                  {
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  },
                ];

                for (let i = 2; i < 6; i++) {
                  roleCode = `PSR${i}`;
                  roles.push({
                    code: roleCode,
                    enabled: this.getRoleEnabledStatus(roleCode),
                  });
                }

                flightRoles.push({
                  description: "PSR",
                  roles: [...roles],
                });

                break;

              default:
                break;
            }
          }
        );
      }

      this.flightRoles = [...flightRoles];

      this.showNewFlightAssignmentDialog = true;
    },

    handleCloseNewFlightAssignmentDialog() {
      this.showNewFlightAssignmentDialog = false;
      this.sequence = 0;
    },

    async handlePrecheckFlightAssignment(role) {
      this.warnings = null;

      let flightAssignment = {
        role,
        flightId: this.trip.flights[this.sequence].flightId,
      };

      if (!this.employee) {
        //Save as company assignment
        flightAssignment.assignedToCompany = this.selectedCompany;
        this.newFlightAssignment = { ...flightAssignment };
        this.handleSaveAssignment();
        return;
      }

      flightAssignment.employee_id = this.employee._id;
      flightAssignment.assignedToCompany = this.employee.companyName;

      this.loading = true;
      const res = await this.$store.dispatch(
        "precheckFlightAssignment",
        flightAssignment
      );
      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.newFlightAssignment = { ...flightAssignment };
      if (res.data.warnings.length) {
        this.warnings = res.data.warnings;
        return;
      } else {
        this.handleSaveAssignment();
      }
    },

    async handleSaveAssignment() {
      this.loading = true;

      const res = await this.$store.dispatch("saveFlightAssignment", {
        flightAssignment: this.newFlightAssignment,
        warnings: this.warnings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }
      this.warnings = null;
      this.getAssignments();
    },

    handleCancel() {
      this.showNewFlightAssignmentDialog = false;
    },

    getRoleEnabledStatus(roleCode) {
      if (this.enableCreateFlightAssignments) {
        return true;
      } else {
        let matchingRole = this.flightRolesAssignedToCompanies.find((item) => {
          return item.role === roleCode;
        });

        if (
          matchingRole &&
          this.user.securityGroup.companies.includes(matchingRole.companyName)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    getEmployeeAssigned(flightRole) {
      let a = "Assign";
      if (this.trip.flights[this.sequence].crewmembers.length) {
        const crewmember = this.trip.flights[this.sequence].crewmembers.find(
          (c) => {
            return c.role === flightRole;
          }
        );

        if (crewmember) {
          if (crewmember.employee) {
            a = this.formatName(
              crewmember.employee.givenName,
              crewmember.employee.surname,
              "sub"
            );
          } else {
            a = crewmember.assignedToCompany;
          }
        } else {
          a = "Assign";
        }
      }

      return a;
    },

    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.flightAssignment = {
        ...flightAssignment,
      };
      this.showFlightAssignmentInformationDialog = true;
    },

    hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;
      this.flightAssignment = null;
      if (e.refreshData) {
        this.getAssignments();
      }
    },

    //-----------------------Ground Assignment Information
    async handleShowGroundAssignmentInformationDialog(groundAssignment) {
      try {
        if (groundAssignment.category === "Station Assignment") {
          const token = await aad.getIdTokenSilent();

          const res = await api.get("/flights/assignment-flights", {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
            params: {
              flightIds: groundAssignment.group.flightIds,
            },
          });

          if (res.data.status !== "OK") {
            this.snackbarColor = "#F44336";
            this.snackbarText = res.data.message;
            this.snackbarVisible = true;
            return;
          }

          const flights = res.data.flights;

          this.groundAssignment = {
            ...groundAssignment,
            flights,
          };

          this.showStationAssignmentInformationDialog = true;
        } else {
          this.groundAssignment = {
            ...groundAssignment,
          };

          this.showGroundAssignmentInformationDialog = true;
        }
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async hideGroundAssignmentInformationDialog(refresh) {
      this.showGroundAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (refresh) {
        await this.getAssignments();
      }
    },

    async hideStationAssignmentInformationDialog(refresh) {
      this.showStationAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (refresh) {
        await this.getAssignments();
      }
    },

    //-----------------------Employee Filter
    handleOpenEmployeeFilter() {
      this.employeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.employeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.employeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getAssignments();
    },

    handleChangeBoardType() {
      let board = { ...this.board };
      board.type = this.selectedBoardType;
      this.$store.commit("updateBoard", board);
      this.filterEmployees();
    },
    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },
    selectPreviousFlight() {
      this.sequence -= 1;
    },
    selectNextFlight() {
      this.sequence += 1;
    },

    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    selectEmployee(employee) {
      this.employee = { ...employee };

      if (!this.enableCreateTripAssignments) {
        this.snackbarColor = "#F44336";
        this.snackbarText = `Not authorized. Please contact your administrator.`;
        return (this.snackbarVisible = true);
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.employeeDialog = true;
    },

    closeEmployeeAssignment(refresh) {
      this.employeeDialog = false;
      this.sequence = 0;
      this.assignmentRemarks = "";

      if (refresh) {
        this.getAssignments();
      }
    },

    scrollBoard() {
      //This fires when the slider in the toolbar is used
      this.bottomPanelRight.scrollLeft = this.sliderX;
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },

    showGroundAssignmentDialog(employee, e) {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      if (!this.enableCreateGroundAssignments) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Not authorized. Please contact your administrator";
        return (this.snackbarVisible = true);
      }
      this.$store.commit("updateEmployee", { ...employee });

      const minutes =
        (e.clientX - 100) * this.scale + Math.abs(this.scrollX * this.scale);

      this.groundAssignmentStart =
        new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000;

      this.groundAssignmentDialog = true;
    },

    hideGroundAssignmentDialog(e) {
      this.groundAssignmentDialog = false;
      if (e.refreshData) {
        this.getAssignments();
      }
    },

    saveConflictPadding() {
      switch (this.paddingBeforeText) {
        case "30 Minutes":
          this.paddingBefore = 30;
          break;

        case "1 Hour":
          this.paddingBefore = 60;
          break;

        case "2 Hours":
          this.paddingBefore = 120;
          break;

        default:
          this.paddingBefore = 0;
          break;
      }

      switch (this.paddingAfterText) {
        case "30 Minutes":
          this.paddingAfter = 30;
          break;

        case "1 Hour":
          this.paddingAfter = 60;
          break;

        case "2 Hours":
          this.paddingAfter = 120;
          break;

        default:
          this.paddingAfter = 0;
          break;
      }

      this.paddingDialog = false;
    },

    getFlightShadowLocationLeft(
      effectiveTimeOut,
      paddingBefore,
      filterStart,
      scale
    ) {
      const minutes =
        (new Date(effectiveTimeOut).getTime() -
          new Date(filterStart).getTime()) /
        1000 /
        60;
      return (minutes - paddingBefore) / scale;
    },

    showBidDayDetails(bidline, day) {
      let bidDay;

      if (bidline) {
        bidDay = bidline.days.find((d) => {
          return d.day === new Date(day).getUTCDate();
        });

        this.snackbarColor = `#2196F3`;
        this.snackbarText = `${
          bidline.employee.displayName
        } . ${this.formatDate(day)} . ${bidDay.status.description}`;
        this.snackbarVisible = true;
      } else {
        this.snackbarColor = `#FF6F00`;
        this.snackbarText = "Not bidline information";
        this.snackbarVisible = true;
      }
    },

    filterEmployees() {
      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];
      let filterFour = [];

      if (this.searchByStation) {
        let lastAssignment;
        let priorAssignments;

        this.employees.forEach((employee) => {
          if (employee.assignments.length) {
            priorAssignments = employee.assignments.filter((assignment) => {
              return (
                new Date(assignment.endTime).getTime() <
                new Date(
                  this.trip.flights[this.sequence].effectiveTimeOut
                ).getTime()
              );
            });

            if (priorAssignments.length) {
              lastAssignment = priorAssignments[priorAssignments.length - 1];

              if (
                lastAssignment.destinationIata ===
                this.trip.flights[this.sequence].originIata
              ) {
                filterOne.push(employee);
              }
            }
          }
        });

        if (this.lastName.length) {
          filterTwo = filterOne.filter((employee) => {
            return employee.surname
              .toUpperCase()
              .startsWith(this.lastName.toUpperCase());
          });
        } else {
          filterTwo = [...filterOne];
        }

        this.searchByStation = true;
      } else {
        if (this.lastName.length) {
          filterTwo = this.employees.filter((employee) => {
            return employee.surname
              .toUpperCase()
              .startsWith(this.lastName.toUpperCase());
          });
        } else {
          filterTwo = [...this.employees];
        }
      }

      if (this.selectedCompany !== "All Companies") {
        filterThree = filterTwo.filter((employee) => {
          return this.selectedCompany === employee.companyName;
        });
      } else {
        filterThree = [...filterTwo];
      }

      switch (this.board.type) {
        case "Cargo":
          filterFour = filterThree.filter((item) => {
            return this.cargoJobTitles.includes(item.jobTitle);
          });
          break;
        case "Passenger":
          filterFour = filterThree.filter((item) => {
            return this.passengerJobTitles.includes(item.jobTitle);
          });
          break;

        default:
          filterFour = [...filterThree];
          break;
      }

      this.filteredEmployees = [...filterFour];
    },

    toggleSearchByStation() {
      this.searchByStation = !this.searchByStation;
      this.filterEmployees();
    },

    getBidDayStyle(bidDays, day) {
      //bidDays are the employee bid days as an array of objects
      //day is the day of the board as a ISO string in zulu

      let bidDay;
      let backgroundColor = "white";

      if (bidDays.length) {
        bidDay = bidDays.find((bidDay) => {
          return (
            new Date(
              Date.UTC(bidDay.year, bidDay.month, bidDay.day)
            ).getTime() === new Date(day).getTime()
          );
        });

        if (bidDay) {
          switch (bidDay.code) {
            case "DO":
              backgroundColor = "#E74C3C";
              break;
            case "RES":
              backgroundColor = "#2ECC71";
              break;
            case "VAC":
              backgroundColor = "#A569BD";
              break;
            default:
              break;
          }
        }
      }

      return backgroundColor;
    },

    getStatusText(day) {
      //Bidline
      if (this.scale <= 16) {
        return day.status.description;
      } else {
        return day.status.code;
      }
    },

    goBack() {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.is-assigned {
  background-color: #c3f5c3;
  font-weight: 700;
}

.crew-frame {
  border: 1px solid #bebebe;
  overflow: auto;
}

/* .remove-icon:hover {
  color: red;
} */

.r-row {
  border-bottom: 1px solid #bebebe;
  margin: auto;
}

/* .this-flight {
  padding: 0 5px;
  cursor: pointer;
} */

/* .flight-shadow {
  border-left: 1px dashed blue;
  border-right: 1px dashed blue;
  background-color: #a8a8f5b2;
  opacity: 0.5;
  position: absolute;
  cursor: pointer;
} */

.fa-left {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-left:hover {
  color: #1d8de9;
}

.fa-right {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-right:hover {
  color: #1d8de9;
}

.flight-route {
  font-size: 2rem;
  text-align: center;
  color: var(--dark-blue);
}

.warnings-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}

.assignment-frame {
  border-style: solid;
  border-color: var(--gray);
  border-width: 1px;
  border-radius: 5px;
  background-color: #e0e0e0;
  padding: 10px;
  background-color: var(--white);
}

.disclaimer-text {
  border: 1px solid var(--red);
  border-radius: 3px;
  padding: 0 5px;
  margin: 5px 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--red);
}
</style>
