<template>
  <div class="font-abel-12px">
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      vertical
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- MENU -->
    <v-app-bar
      fixed
      :color="navbarSettings.style.backgroundColor"
      dark
      dense
      flat
    >
      <div class="d-flex align-center" v-if="user">
        <v-menu bottom left :style="{ zIndex: 200 }">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>menu</v-icon>
            </v-btn>
          </template>

          <v-list dense color="#D2D6D7">
            <v-list-item v-if="user">
              <v-list-item-content>
                <v-list-item-title class="font-weight-black">{{
                  user.displayName
                }}</v-list-item-title>
                <v-list-item-title>{{
                  user.securityGroup.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <!-- Home -->
            <v-list-item link v-if="showHomeLink" @click="navigateToHome()">
              <v-list-item-icon>
                <i class="fa-solid fa-house"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Flight Board -->
            <v-list-item
              link
              v-if="showFlightBoardLink"
              @click="navigateToFlightBoard()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-plane-up"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Flight Board</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Assignments -->
            <v-list-item
              link
              v-if="showAssignmentBoardLink"
              @click="navigateToAssignmentBoard()"
            >
              <v-list-item-icon>
                <i
                  class="fa-solid fa-person-walking-luggage"
                  :style="{ color: '#F1560F' }"
                ></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Assignment Board</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Flight Assignment Notifications -->
            <v-list-item
              link
              v-if="showNotificationsLink"
              @click="navigateToNotifications()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-bell"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Notifications</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Administration -->
            <v-list-item
              link
              v-if="showAdministrationLink"
              @click="navigateToAdministration()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-user-group"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Administration</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Bidlines -->
            <v-list-item
              link
              v-if="showBidlinesLink"
              @click="navigateToBidlines()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-calendar-range"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Bidlines</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Reporting -->
            <v-list-item
              link
              v-if="showReportingLink"
              @click="navigateToReporting()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-file-lines"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Reporting</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Security Groups -->
            <v-list-item
              link
              v-if="showSecurityGroupsLink"
              @click="navigateToSecurityGroups()"
            >
              <v-list-item-icon>
                <i class="fa-duotone fa-shield-quartered"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Security Groups</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Company Airports -->
            <v-list-item
              link
              v-if="showAirportsLink"
              @click="navigateToAirports()"
            >
              <v-list-item-icon>
                <i class="fa-duotone fa-tower-control"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Airports</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Crew Requirements -->
            <v-list-item
              link
              v-if="showCrewRequirementsLink"
              @click="navigateToCrewRequirements()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-person-circle-check"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Crew Requirements</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <!-- My Assignments -->
            <v-list-item
              link
              v-if="showMyAssignmentsLink"
              @click="navigateToMyAssignments()"
            >
              <v-list-item-icon>
                <i
                  class="fa-solid fa-person-walking-luggage"
                  :style="{ color: '#054304' }"
                ></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>My Assignments</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- My Profile -->
            <v-list-item
              link
              v-if="showMyProfileLink"
              @click="navigateToMyProfile()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-id-card"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>My Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- My Notifications -->
            <v-list-item
              link
              v-if="showMyNotificationsLink"
              @click="navigateToMyNotifications()"
            >
              <v-list-item-icon>
                <i class="fa-solid fa-bell"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>My Notifications</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <!-- About -->
            <v-list-item link v-if="user" @click="showAbout">
              <v-list-item-icon>
                <i class="fa-solid fa-circle-info"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>About Monarch</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Help -->
            <v-list-item link v-if="user" @click="getManual">
              <v-list-item-icon>
                <i class="fa-solid fa-circle-question"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Instructions Manual</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Logout -->
            <v-list-item link @click="logout()">
              <v-list-item-icon>
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="mx-2" v-if="user">
          <span class="font-weight-black">{{ `${user.displayName}` }}</span>
          <span>{{ `  -  ${user.securityGroup.name}` }}</span>
        </div>
      </div>

      <div v-else>
        <v-btn :disabled="loading" @click="login()" small dark color="blue"
          >Login</v-btn
        >
      </div>

      <v-spacer></v-spacer>

      <div v-if="!isMobile" class="font-abel-20px  text-capitalize">
        {{ pageName }}
      </div>
      <div v-else class="font-abel-14px  text-capitalize">{{ pageName }}</div>

      <v-spacer></v-spacer>

      <span
        v-if="!isMobile"
        class="font-abel-20px mx-3"
        :style="navbarSettings.style"
        >{{ navbarSettings.text }}</span
      >
      <span v-else class="font-abel-14px mx-3" :style="navbarSettings.style">{{
        navbarSettings.text
      }}</span>
    </v-app-bar>

    <!-- ABOUT -->
    <v-dialog v-model="aboutDialog" max-width="450px">
      <div
        class="version-dialog font-abel-16px"
        :style="{ backgroundColor: '#EAF3FD', maxHeight: '400px' }"
      >
        <div class="d-flex justify-center mb-1">
          <v-img
            :max-width="150"
            :aspect-ratio="2.9"
            src="https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_logo.png"
            alt="Monarch logo"
          >
          </v-img>
        </div>

        <div v-if="!isMobile" class="d-flex justify-center font-abel-24px mb-5">
          Ground Ops Scheduling Software
        </div>
        <div v-else class="d-flex justify-center font-abel-16px mb-5">
          Ground Ops Scheduling Software
        </div>

        <div class="d-flex justify-center mb-1 font-abel-14px">
          Developed By
        </div>
        <div class="d-flex justify-center mb-2">
          <v-img
            :max-width="150"
            :aspect-ratio="6.3"
            src="https://s3.amazonaws.com/monarch.atlasair.com.resources/raiden_logo.png"
            alt="Raiden logo"
          >
          </v-img>
        </div>

        <!-- <a class="d-flex justify-center" href="https://raidensolutions.com"
          >www.RaidenSolutions.com</a
        > -->
        <!-- <a
          class="font-abel-14px d-flex justify-center"
          href="mailto:info@raidensolutions.com"
          >info@raidensolutions.com</a
        >
        <div class="font-abel-14px d-flex justify-center">
          1-1877-472-4336 / 1-877-4RAIDEN
        </div> -->

        <div class="d-flex justify-center font-abel-20px mt-5">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="showVersionHistory"
                v-on="on"
                class="text-capitalize"
                color="indigo"
                small
                text
                >{{ `Version ${version} - ${build}` }}</v-btn
              >
            </template>
            <span>See Version History</span>
          </v-tooltip>
        </div>
      </div>
    </v-dialog>

    <!-- VERSION -->
    <v-dialog v-model="versionDialog" max-width="600px">
      <div class="version-dialog font-abel-16px">
        <div v-if="selectedVersion">
          <div class="d-flex justify-space-between align-center font-abel-20px">
            <div class="d-flex align-center">
              <div class="d-flex justify-end align-center mt-2">
                <v-btn
                  @click="handleGetNextVersion"
                  small
                  icon
                  :disabled="selectedVersionIndex === versions.length - 1"
                  ><i class="fa-solid fa-arrow-left"></i
                ></v-btn>
                <h3 class="font-weight-bold  blue--text mx-2">
                  {{ `Version No. ${selectedVersion.name} ` }}
                </h3>
                <v-btn
                  @click="handleGetPreviousVersion"
                  small
                  icon
                  :disabled="selectedVersionIndex === 0"
                  ><i class="fa-solid fa-arrow-right"></i
                ></v-btn>
              </div>
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="handleHideSelectedVersion"
                  small
                  icon
                  class="ml-3"
                >
                  <i class="fa-solid fa-xmark"></i>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </div>
          <h5 class="indigo--text ml-5">
            {{ `Released on ${formatDate(selectedVersion.releaseDate)}` }}
          </h5>
          <v-divider class="my-3"></v-divider>
          <div class="frame">
            <div v-if="selectedVersion.improvements.length">
              <div class="font-abel-20px green--text">
                Improvements:
              </div>
              <ul class="font-abel-16px">
                <li
                  v-for="(improvement, i) in selectedVersion.improvements"
                  :key="i"
                >
                  {{ improvement.description }}
                </li>
              </ul>
            </div>

            <div v-if="selectedVersion.fixes.length">
              <v-divider
                v-if="selectedVersion.improvements.length"
                class="my-3"
              ></v-divider>
              <h3 class="font-abel-20px orange--text">
                Fixes:
              </h3>
              <ul class="font-abel-16px">
                <li v-for="(fix, i) in selectedVersion.fixes" :key="i">
                  {{ fix.description }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-center font-abel-24px mb-1">
            Monarch's Version History
          </div>
          <v-divider class="my-3"></v-divider>
          <v-data-table
            dense
            :headers="versionHeaders"
            :items="versions"
            :footer-props="{
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
            @click:row="handleSeeVersion"
            item-key="_id"
          >
            <template v-slot:[`item.releaseDate`]="{ item }">
              <span>{{ formatDate(item.releaseDate) }}</span>
            </template>
            <template v-slot:[`item.improvements`]="{ item }">
              <span>{{ item.improvements.length }}</span>
            </template>
            <template v-slot:[`item.fixes`]="{ item }">
              <span>{{ item.fixes.length }}</span>
            </template>
          </v-data-table>
          <div class="d-flex justify-end mt-3">
            <v-btn
              @click="showBackendEnvironmentData"
              color="grey"
              small
              text
              >{{ `${version} - ${build}` }}</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- WARNINGS -->
    <v-dialog v-model="showWarningsDialog" persistent max-width="1200px">
      <div class="red-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Warnings
          </div>
        </div>

        <!-- WARNINGS -->
        <v-data-table
          v-if="warnings"
          dense
          :headers="headers"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div class="d-flex justify-center font-abel-16px my-2">
          <span class="mr-2">Atlas Air Online Training Center</span>
          <a href="https://atlasairgo.pelesys.com" target="_blank">Login</a>
        </div>

        <div
          class="d-flex justify-space-between align-center font-abel-16px my-3"
        >
          <div>Please check your profile</div>
          <v-btn @click="handleAcknowledgeWarnings" small dark color="blue"
            >acknowledge</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- LOGIN FAILED  -->
    <v-dialog v-model="loginFailedDialog" persistent max-width="1200px">
      <div v-if="loginFailed" class="r-dialog-red font-abel-16px">
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Login Failed
          </div>
          <v-btn @click="loginFailedDialog = false" small icon
            ><v-icon>close</v-icon></v-btn
          >
        </div>

        <p>
          {{ loginFailedErrorMessage }}
        </p>

        <div class="d-flex justify-end align-center font-abel-16px my-3">
          <v-btn class="ml-2" @click="loginFailedLogOut" small dark color="blue"
            >logout</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- PENDING NOTIFICATIONS -->
    <v-dialog
      v-model="showPendingAssignmentsDialog"
      persistent
      max-width="1200px"
    >
      <div class="red-frame font-abel-16px">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Pending Notifications
          </div>
        </div>

        <v-container fluid v-if="!loading">
          <v-col cols="12">
            <v-data-table
              dense
              :headers="notificationsHeaders"
              :items="pendingAssignments"
              v-model="selectedAssignments"
              :footer-props="{
                itemsPerPageOptions: [20, 50, 100, 200],
              }"
              show-select
              item-key="_id"
            >
              <template v-slot:[`item._id`]="{ item }">
                <span>{{ formatAssignmentSnapshot(item) }}</span>
              </template>

              <template v-slot:[`item.notification`]="{ item }">
                <v-btn icon>
                  <v-icon :color="getNotificationStatusColor(item)"
                    >notifications</v-icon
                  >
                </v-btn>
              </template>

              <template v-slot:[`item.sentOn`]="{ item }">
                <span>{{ formatNotificationSentOn(item) }}</span>
              </template>
            </v-data-table>

            <div class="d-flex justify-space-between align-center">
              <div
                class="font-abel-16px"
                v-if="selectedAssignments.length !== pendingAssignments.length"
              >
                You must acknowledge all pending notifications before continuing
              </div>
              <div v-else></div>

              <div class="d-flex justify-end">
                <v-btn
                  @click="handleAcknowledgeNotifications"
                  small
                  dark
                  color="blue"
                  class="my-3"
                  :disabled="
                    selectedAssignments.length !== pendingAssignments.length
                  "
                  >Acknowledge</v-btn
                >
                <v-btn
                  @click="handleCancelLogin"
                  small
                  dark
                  color="grey"
                  class="ml-3 my-3"
                  >Cancel</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-container>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import { PDFDocument } from "pdf-lib";

export default {
  name: "navbar",
  mixins: [mixin],
  data() {
    return {
      loading: false,
      isMobile: false,
      selectedVersion: null,
      selectedVersionIndex: 0,
      versions: [],
      showWarningsDialog: false,
      warnings: [],
      aboutDialog: false,
      versionDialog: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 6000,
      versionHeaders: [
        {
          text: "Version No.",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Released Date",
          align: "start",
          sortable: false,
          value: "releaseDate",
        },
        {
          text: "Improvements",
          align: "center",
          sortable: false,
          value: "improvements",
        },
        {
          text: "Fixes",
          align: "center",
          sortable: false,
          value: "fixes",
        },
      ],
      headers: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      loginFailed: false,
      loginFailedDialog: false,
      loginFailedErrorMessage: "",
      userAuthorizations: [],
      authorizationsHeaders: [
        {
          text: "Authorization",
          align: "start",
          sortable: true,
          value: "text",
        },
      ],
      selectedAssignments: [],
      showPendingAssignmentsDialog: false,
      pendingAssignments: [],
      notificationsHeaders: [
        {
          text: "Last Name",
          align: "start",
          value: "employee.surname",
        },
        {
          sortable: true,
          text: "First Name",
          align: "start",
          value: "employee.givenName",
        },
        {
          sortable: true,
          text: "Company",
          align: "start",
          value: "employee.companyName",
        },
        {
          sortable: true,
          text: "Job Title",
          align: "start",
          value: "employee.jobTitle",
        },
        {
          sortable: false,
          text: "Assignment",
          align: "start",
          value: "_id",
        },

        {
          sortable: true,
          text: "Sent",
          align: "start",
          value: "sentOn",
        },
      ],
      employee: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    company() {
      return this.$store.getters.company;
    },
    pageName() {
      //const boardType = this.$store.getters.board.type;

      switch (this.$route.name) {
        case "reporting":
          return "Reporting";

        case "flightBoard":
          return `Flight Board`;

        case "flightAssignmentBoard":
          return `Flight Assignment`;

        case "tripAssignmentBoard":
          return "Trip Assignment";

        case "stationAssignmentBoard":
          return "Station Assignment";

        case "assignmentBoard":
          return `Assignment Board`;

        case "notifications":
          return "Notifications";

        case "myAssignments":
          return "My Assignments";

        case "myNotifications":
          return "My Notifications";

        case "bidlines":
          return "Bidlines";

        case "administration":
          return "Administration";

        case "employeeCreate":
          return "New Employee";

        case "employeeUpdate":
          return "Employee Profile";

        case "myProfile":
          return "My Profile";

        case "reports":
          return "Reports";

        case "crewRequirements":
          return "Loadmaster Requirements";

        default:
          return "";
      }
    },

    showHomeLink() {
      return this.$route.name !== "home";
    },

    showBidlinesLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableCreateBidlines) {
        return this.$route.name !== "bidlines";
      } else {
        return false;
      }
    },

    showAdministrationLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableCreateEmployee) {
        return this.$route.name !== "administration";
      } else {
        return false;
      }
    },

    showFlightBoardLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableReadFlight) {
        return this.$route.name !== "flightBoard";
      } else {
        return false;
      }
    },

    showAssignmentBoardLink() {
      if (!this.user) {
        return false;
      }

      if (
        this.enableReadFlightAssignments ||
        this.enableReadGroundAssignments
      ) {
        return this.$route.name !== "assignmentBoard";
      } else {
        return false;
      }
    },

    showSecurityGroupsLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableCreateSecurityGroup) {
        return this.$route.name !== "securityGroups";
      } else {
        return false;
      }
    },

    showReportingLink() {
      if (!this.user) {
        return false;
      }

      if (
        this.enableReadManagerPayrollReport ||
        this.enableReadAdministratorPayrollReport
      ) {
        return this.$route.name !== "reporting";
      } else {
        return false;
      }
    },

    showMyProfileLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableUpdateEmployee) {
        return this.$route.name !== "employeeUpdate";
      } else {
        return false;
      }
    },

    showMyAssignmentsLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableReadMyAssignments) {
        return this.$route.name !== "myAssignments";
      } else {
        return false;
      }
    },

    showNotificationsLink() {
      if (!this.user) {
        return false;
      }

      if (
        this.enableCreateAssignmentNotification &&
        this.enableReadAllAssignmentNotification
      ) {
        return this.$route.name !== "notifications";
      } else {
        return false;
      }
    },

    showMyNotificationsLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableReadMyAssignmentNotification) {
        return this.$route.name !== "myNotifications";
      } else {
        return false;
      }
    },

    showAirportsLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableUpdateAirport) {
        return this.$route.name !== "airports";
      } else {
        return false;
      }
    },

    showCrewRequirementsLink() {
      if (!this.user) {
        return false;
      }

      if (this.enableUpdateCrewRequirements) {
        return this.$route.name !== "crewRequirements";
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    handleCancelLogin() {
      this.pendingAssignments = [];
      this.showPendingAssignmentsDialog = false;
    },

    async handleAcknowledgeNotifications() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        let assignments = [];

        this.selectedAssignments.forEach((item) => {
          assignments.push({
            type: item.type,
            _id: item.id,
          });
        });

        const res = await api.put(
          `/assignments/notifications/acknowledge`,
          {
            assignments,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.showPendingAssignmentsDialog = false;
        //-------------------------------Update Store User
        this.$store.commit("updateUser", { ...this.employee });

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `Hi ${this.employee.givenName}, welcome back!`;
        this.snackbarVisible = true;

        this.selectedAssignments = [];
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatNotificationSentOn(item) {
      if (item.notification) {
        return `${this.formatDateTime(item.notification.sentOn)}`;
      } else {
        return "---";
      }
    },

    getNotificationStatusColor(assignment) {
      let color = "red";

      if (assignment.notification) {
        let etdChanged;
        let etaChanged;
        let originChanged;
        let destinationChanged;

        if (assignment.type === "Flight") {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.flight.estimatedDepartureTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.flight.estimatedArrivalTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.flight.originIata !==
              assignment.notification.originIata;
            destinationChanged =
              assignment.flight.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            color = "blue";
          }
        } else {
          if (assignment.notification.acknowledged) {
            etdChanged =
              new Date(assignment.startTime).getTime() -
                new Date(assignment.notification.startTime).getTime() !==
              0;

            etaChanged =
              new Date(assignment.endTime).getTime() -
                new Date(assignment.notification.endTime).getTime() !==
              0;

            originChanged =
              assignment.originIata !== assignment.notification.originIata;
            destinationChanged =
              assignment.destinationIata !==
              assignment.notification.destinationIata;

            if (
              etdChanged ||
              etaChanged ||
              originChanged ||
              destinationChanged
            ) {
              color = "orange";
            } else {
              color = "green";
            }
          } else {
            color = "blue";
          }
        }
      }

      return color;
    },

    formatNoticationType(notification) {
      switch (notification.notificationType) {
        case "Flight":
          return `Flight Assignment - ${
            this.getFlightRole(notification.flightAssignment.role).description
          }`;

        default:
          if (notification.groundAssignment.category === "Station Assignment") {
            return `${notification.groundAssignment.category} - ${notification.groundAssignment.subCategory}`;
          } else {
            return `${notification.groundAssignment.category}`;
          }
      }
    },
    handleAcknowledgePendingAssignments() {
      this.pendingAssignments = [];
      this.showPendingAssignmentsDialog = false;

      //-------------------------------Update Store User
      this.$store.commit("updateUser", { ...this.employee });

      this.snackbarColor = "#66BB6A";
      this.snackbarText = `Hi ${this.employee.givenName}, welcome back!`;
      this.snackbarVisible = true;
    },
    handleAcknowledgeWarnings() {
      this.showWarningsDialog = false;
      this.warnings = null;

      if (!this.pendingAssignments.length) {
        //There are no pending notifications
        //-------------------------------Update Store User
        this.$store.commit("updateUser", { ...this.employee });

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `Hi ${this.employee.givenName}, welcome back!`;
        this.snackbarVisible = true;
      } else {
        this.showPendingAssignmentsDialog = true;
      }
    },
    handleSeeVersion(v) {
      const i = this.versions.findIndex((item) => {
        return item.name === v.name;
      });

      this.selectedVersion = { ...v };
      this.selectedVersionIndex = i;
    },
    handleHideSelectedVersion() {
      this.selectedVersion = null;
    },
    handleGetPreviousVersion() {
      this.selectedVersionIndex -= 1;
      this.selectedVersion = this.versions[this.selectedVersionIndex];
    },
    handleGetNextVersion() {
      this.selectedVersionIndex += 1;
      this.selectedVersion = this.versions[this.selectedVersionIndex];
    },
    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async login() {
      try {
        this.loading = true;
        this.loginFailed = false;
        this.pendingAssignments = [];

        //Get auth from Active Directory
        const authResult = await aad.login();

        if (!authResult) {
          this.loading = false;
          this.loginFailed = true;
          this.loginFailedErrorMessage = `Authentication failed, please contact your administrator`;
          return (this.loginFailedDialog = true);
        }

        //Continue only if account exists in Active Directory
        if (authResult.account) {
          const dt1 = new Date();
          const yy = dt1.getUTCFullYear();
          const mm = dt1.getUTCMonth();
          const dd = dt1.getUTCDate();

          const filterStart = new Date(
            Date.UTC(yy, mm, dd, 0, 0, 0, 0)
          ).getTime();

          this.$store.commit("updateDateFilter", {
            filterStart,
            numberOfDays: 7,
          });

          if (this.$route.name !== "home") {
            this.$router.push({ name: "home" });
          }

          //Login to server with AD token
          const token = await aad.getIdTokenSilent();

          let res = await api.get(
            `/employees/user/${authResult.account.userName}`,
            {
              headers: {
                Authorization: "Bearer " + token.idToken.rawIdToken,
              },
            }
          );

          if (res.data.status !== "OK") {
            this.loading = false;
            this.loginFailed = true;
            this.loginFailedErrorMessage = res.data.message;
            this.loginFailedDialog = true;
            return;
          }

          const employee = res.data.employee;

          if (!employee) {
            this.loading = false;
            this.loginFailed = true;
            this.loginFailedErrorMessage = `Employee profile for ${authResult.account.userName} not found, please contact your administrator.`;
            this.loginFailedDialog = true;
            return;
          }

          if (employee.status !== "Active") {
            this.loading = false;
            this.loginFailed = true;
            this.loginFailedErrorMessage = `Employee status for ${authResult.account.userName} is marked as "${employee.status}", please contact your administrator.`;
            this.loginFailedDialog = true;
            return;
          }

          if (!employee.securityGroup) {
            this.loading = false;
            this.loginFailed = true;
            this.loginFailedErrorMessage = `Employee ${authResult.account.userName} not authorized, please contact your administrator.`;
            this.loginFailedDialog = true;
            return;
          }

          this.employee = { ...employee };

          //---------------------------------Company
          const company = res.data.company;

          if (!company) {
            this.loading = false;
            this.snackbarColor = "#F44336";
            this.snackbarText = "Unable to load company data";
            this.snackbarVisible = true;
            return;
          }

          this.$store.commit("updateCompany", company);

          //-----------------------------------Company Airports
          const airports = res.data.airports;

          this.$store.commit("updateCompanyAirports", airports);

          this.warnings = res.data.warnings;

          if (employee.imageUrl === "profile-default.png") {
            this.warnings.push({
              type: "Profile Image",
              level: "Soft",
              message:
                "A current photo has not been uploaded into Employee Profile",
            });
          }

          //---------------------------------Get Notifications

          res = await api.get("/assignments/notifications/pending", {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
          });

          if (res.data.status !== "OK") {
            this.loading = false;
            this.loginFailed = true;
            this.loginFailedErrorMessage = res.data.message;
            this.loginFailedDialog = true;
            return;
          }

          this.pendingAssignments = res.data.assignments;

          if (this.warnings.length) {
            this.loading = false;
            this.showWarningsDialog = true;
          } else {
            if (this.pendingAssignments.length) {
              this.loading = false;
              this.showPendingAssignmentsDialog = true;
            } else {
              this.loading = false;
              //There are no warnings or pending notifications
              //-------------------------------Update Store User
              this.$store.commit("updateUser", { ...employee });

              this.snackbarColor = "#66BB6A";
              this.snackbarText = `Hi ${employee.givenName}, welcome back!`;
              this.snackbarVisible = true;
            }
          }
        } else {
          this.loading = false;
          this.loginFailed = true;
          this.loginFailedErrorMessage = `Active Directory account not found, please contact your administrator`;
          this.loginFailedDialog = true;
        }
      } catch (error) {
        this.loading = false;
        let message = error.message;
        if (error.message.includes("404")) {
          message = `Employee profile not found, please contact your administrator.`;
        }
        this.loginFailed = true;
        this.loginFailedErrorMessage = message;
        this.loginFailedDialog = true;
      }
    },

    async loginFailedLogOut() {
      this.loginFailedDialog = false;
      this.logout();
    },

    async showBackendEnvironmentData() {
      try {
        const url = api.defaults.baseURL;
        const res = await api.get(url.substring(0, url.length - 7));

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `${res.data.version} - ${res.data.status}`;
        this.snackbarVisible = true;
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async showVersionHistory() {
      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.get("/versions", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
        });

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.versions = res.data.versions;

        this.versionDialog = true;
        this.aboutDialog = false;
      } catch (error) {
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    showAbout() {
      this.aboutDialog = true;
    },

    navigateToHome() {
      this.$router.push({ name: "home" });
    },

    navigateToFlightBoard() {
      this.$router.push({ name: "flightBoard" });
    },

    navigateToAssignmentBoard() {
      this.$router.push({ name: "assignmentBoard" });
    },

    navigateToNotifications() {
      this.$router.push({ name: "notifications" });
    },

    navigateToAdministration() {
      this.$router.push({ name: "administration" });
    },

    navigateToReports() {
      this.$router.push({ name: "reports" });
    },

    navigateToBidlines() {
      this.$router.push({ name: "bidlines" });
    },

    navigateToReporting() {
      this.$router.push({ name: "reporting" });
    },

    navigateToAirports() {
      this.$router.push({ name: "airports" });
    },

    navigateToCrewRequirements() {
      this.$router.push({ name: "crewRequirements" });
    },

    navigateToSecurityGroups() {
      this.$router.push({ name: "securityGroups" });
    },

    navigateToMyAssignments() {
      this.$router.push({ name: "myAssignments" });
    },

    navigateToMyNotifications() {
      this.$router.push({ name: "myNotifications" });
    },

    async navigateToMyProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.user._id },
      });
    },

    async getManual() {
      let url = "";

      if (this.enableSeeAdminManual) {
        url =
          "https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_admin_manual.pdf";
      } else {
        url =
          "https://s3.amazonaws.com/monarch.atlasair.com.resources/monarch_user_manual.pdf";
      }

      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      pdfDoc.setTitle("Monarch User Manual");

      const pdfUrl = URL.createObjectURL(
        new Blob([await pdfDoc.save()], { type: "application/pdf" })
      );
      window.open(pdfUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.version-dialog {
  border-style: solid;
  border-color: rgb(66, 144, 158);
  border-width: 3px 3px 3px 10px;
  border-radius: 10x;
  background-color: #ffffff;
  padding: 10px;
}

.frame {
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
}

.fa-tower-control {
  font-size: 1.3rem;
  color: rgb(0, 85, 255);
}

.fa-plane-up {
  font-size: 1.3rem;
  color: #589ee9;
}

.fa-house {
  font-size: 1.3rem;
  color: #0b789b;
}

.fa-shield-quartered {
  font-size: 1.3rem;
  color: #f1560f;
}

.fa-user-group {
  font-size: 1.3rem;
  color: #002663;
}

.fa-file-lines {
  font-size: 1.3rem;
  color: #209225;
}

.fa-bell {
  font-size: 1.3rem;
  color: #f1560f;
}

.fa-circle-info {
  font-size: 1.3rem;
  color: #2196f3;
}

.fa-circle-question {
  font-size: 1.3rem;
  color: #2196f3;
}

.fa-arrow-right-from-bracket {
  font-size: 1.3rem;
  color: #f1560f;
}

.fa-calendar-range {
  font-size: 1.3rem;
  color: #7b32f2;
}

.fa-id-card {
  font-size: 1.3rem;
  color: #054304;
}

.fa-person-circle-check {
  font-size: 1.3rem;
  color: var(--dark-blue);
}
</style>
