<template>

     <v-snackbar
      v-model="warningZone"
      :color="snackbarColor"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}

    </v-snackbar>


</template>

<script>

export default {
    name:"autoLogout",

    data(){
        return{
            events:["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
            warningTimer:null,
            logoutTimer: null,
            warningZone: false,
            snackbarText: "",
            snackbarColor: "white"
            
        }
    },

    mounted(){
        this.events.forEach(event => {
            window.addEventListener(event, this.resetTimer);
        });

        this.setTimers();
    },

    destroyed(){
        this.events.forEach(event => {
            window.removeEventListener(event, this.resetTimer);
        });

        this.resetTimer();
    },
  

    methods:{

        setTimers(){
            this.warningTimer = setTimeout(this.warningMessage, 59 * 60 *  1000);//59 Minutes
            this.logoutTimer = setTimeout(this.logoutUser, 60 * 60 * 1000);//60 Minutes
            this.warningZone = false;
        },

        resetTimer(){
            clearTimeout(this.warningTimer)
            clearTimeout(this.logoutTimer)
            this.setTimers()
        },

        warningMessage(){
            this.snackbarColor = "#F44336";
            this.snackbarText = "Are you still there?";
            this.warningZone = true;
        },

        logoutUser(){
            
            this.$emit("logout")
        }




    }
}
</script>