<template>
  <div>
    <Navbar />
    <AutoLogout v-if="user" @logout="logout"></AutoLogout>

    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      multi-line
      transition="scroll-x-transition"
      centered
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- LOADING -->
    <v-dialog v-model="loading" max-width="300" persistent>
      <div id="loading">
        <v-progress-circular
          :size="60"
          :width="7"
          color="#6495ED"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>

    <!-- WARNINGS -->
    <v-dialog v-model="showWarningsDialog" persistent max-width="1200px">
      <div class="red-frame">
        <!-- TITLE -->
        <div class="d-flex justify-space-between">
          <div class="font-abel-24px-bold mb-2">
            Warnings
          </div>
        </div>

        <!-- WARNINGS -->
        <v-data-table
          v-if="warnings"
          dense
          :headers="warningsHeaders"
          :items="warnings"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, -1],
          }"
        >
        </v-data-table>

        <div
          class="d-flex justify-space-between align-center font-abel-16px my-3"
        >
          <div></div>
          <v-btn @click="handleAcknowledgeWarnings" small dark color="blue"
            >acknowledge</v-btn
          >
        </div>
      </div>
    </v-dialog>

    <!-- TOOLBAR -->
    <div v-if="!isMobile" class="r-toolbar font-abel-16px">
      <!-- Date Filter  -->
      <div class="d-flex align-center">
        <v-btn
          @click="dateFilterBlockBack"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>first_page</v-icon></v-btn
        >
        <v-btn @click="dateFilterBack" x-small icon dark :disabled="loading"
          ><v-icon>chevron_left</v-icon></v-btn
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="openDateFilter"
              small
              icon
              :disabled="loading"
            >
              <i class="fa-solid fa-calendar-days"></i>
            </v-btn>
          </template>
          <span
            >{{ formatDate(dateFilter.startDate) }} -
            {{ formatDate(dateFilter.endDate) }}</span
          >
        </v-tooltip>
        <v-btn @click="dateFilterForward" x-small icon dark :disabled="loading"
          ><v-icon>chevron_right</v-icon></v-btn
        >
        <v-btn
          @click="dateFilterBlockForward"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>last_page</v-icon></v-btn
        >
      </div>
      <!--Filters and  Warnings -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <!-- Company Selector -->
        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>

        <v-badge
          v-if="enableSeeAssignmentWarnings"
          :content="warnings.length"
          :value="warnings.length"
          color="red"
          overlap
        >
          <v-btn
            :disabled="loading"
            @click="getWarnings(true)"
            small
            icon
            class="ml-1"
          >
            <i
              class="fa-solid fa-triangle-exclamation"
              :class="{ 'warning-state': warnings.length }"
            ></i>
          </v-btn>
        </v-badge>
      </div>

      <v-spacer></v-spacer>

      <v-slider
        @change="scrollBoard"
        class="ml-1"
        v-model="sliderX"
        :max="boardWidth"
        :min="0"
        hide-details
      >
      </v-slider>

      <v-spacer></v-spacer>

      <!-- Search by station -->
      <v-btn :disabled="loading" @click="handleShowSearchByStationDialog" icon
        ><v-icon :color="searchByStationIconColor">place</v-icon></v-btn
      >

      <!-- Refresh -->
      <div class="d-flex align-center">
        <v-btn :disabled="loading" @click="getAssignments" small icon>
          <i class="fa-regular fa-rotate"></i>
        </v-btn>
      </div>

      <div v-if="boardStyle === 'Board'">
        <v-btn @click="zoomIn" small icon class="ml-1" :disabled="loading">
          <i class="fa-regular fa-magnifying-glass-plus"></i>
        </v-btn>

        <v-btn @click="zoomOut" small icon :disabled="loading">
          <i class="fa-regular fa-magnifying-glass-minus"></i>
        </v-btn>
      </div>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#D2D6D7">
          <!-- SWITCH TO BOARD -->
          <v-list-item
            v-if="boardStyle !== 'Board'"
            @click="switchToBoardMode"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to Board</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- SWITCH TO LIST -->
          <v-list-item
            v-if="boardStyle === 'Board'"
            @click="switchToListMode"
            link
          >
            <v-list-item-icon>
              <v-icon>list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else class="r-toolbar font-abel-16px justify-space-between">
      <!-- Date Filter  -->
      <div class="d-flex align-center">
        <v-btn
          @click="dateFilterBlockBack"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>first_page</v-icon></v-btn
        >
        <v-btn @click="dateFilterBack" x-small icon dark :disabled="loading"
          ><v-icon>chevron_left</v-icon></v-btn
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="openDateFilter"
              small
              icon
              :disabled="loading"
            >
              <i class="fa-solid fa-calendar-days"></i>
            </v-btn>
          </template>
          <span
            >{{ formatDate(dateFilter.startDate) }} -
            {{ formatDate(dateFilter.endDate) }}</span
          >
        </v-tooltip>
        <v-btn @click="dateFilterForward" x-small icon dark :disabled="loading"
          ><v-icon>chevron_right</v-icon></v-btn
        >
        <v-btn
          @click="dateFilterBlockForward"
          x-small
          icon
          dark
          :disabled="loading"
          ><v-icon>last_page</v-icon></v-btn
        >
      </div>
      <!--Filters and  Warnings -->
      <div class="d-flex align-center">
        <div class="max-width-100px mx-1">
          <v-select
            @change="handleChangeBoardType"
            dense
            dark
            :items="boardTypes"
            v-model="selectedBoardType"
          >
          </v-select>
        </div>

        <!-- Company Selector -->
        <div class="max-width-150px mx-1">
          <v-select
            @change="filterEmployees"
            dense
            dark
            :items="userCompanies"
            v-model="selectedCompany"
          >
          </v-select>
        </div>

        <v-badge
          v-if="enableSeeAssignmentWarnings"
          :content="warnings.length"
          :value="warnings.length"
          color="red"
          overlap
        >
          <v-btn
            :disabled="loading"
            @click="getWarnings(true)"
            small
            icon
            class="ml-1"
          >
            <i
              class="fa-solid fa-triangle-exclamation"
              :class="{ 'warning-state': warnings.length }"
            ></i>
          </v-btn>
        </v-badge>
      </div>

      <v-spacer></v-spacer>

      <!-- THREE DOT MENU -->
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list dense color="#98DBFA">
          <!-- SWITCH TO BOARD -->
          <v-list-item
            v-if="boardStyle !== 'Board'"
            @click="switchToBoardMode"
            link
          >
            <v-list-item-icon>
              <v-icon>date_range</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to Board</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- SWITCH TO LIST -->
          <v-list-item
            v-if="boardStyle === 'Board'"
            @click="switchToListMode"
            link
          >
            <v-list-item-icon>
              <v-icon>list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Change view to List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Special Airports -->
          <v-list-item @click="specialAirportsDialog = true" link>
            <v-list-item-icon>
              <v-icon color="blue">info</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Special Airports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Search by Station -->
          <v-list-item @click="handleShowSearchByStationDialog" link>
            <v-list-item-icon>
              <v-icon :color="searchByStationIconColor">place</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Search by Station</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <!-- Refresh -->
          <v-list-item @click="getAssignments" link>
            <v-list-item-icon>
              <v-icon color="green">refresh</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Refresh</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom In -->
          <v-list-item v-if="boardStyle === 'Board'" @click="zoomIn" link>
            <v-list-item-icon>
              <v-icon color="blue">zoom_in</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Zoom Out -->
          <v-list-item v-if="boardStyle === 'Board'" @click="zoomOut" link>
            <v-list-item-icon>
              <v-icon color="white">zoom_out</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Zoom Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- EMPLOYEE FILTER DIALOG -->
    <v-dialog v-model="employeeFilterDialog" persistent max-width="450px">
      <div class="yellow-frame">
        <employee-filter
          @hide="handleCloseEmployeeFilter()"
          @save="handleSaveEmployeeFilter($event)"
        ></employee-filter>
      </div>
    </v-dialog>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <v-dialog v-model="specialAirportsDialog" persistent max-width="800px">
      <SpecialAirports @hide="specialAirportsDialog = false" />
    </v-dialog>

    <!-- ASSIGNMENT LIST DIALOG-->
    <v-dialog v-model="assignmentsListDialog" max-width="1200px">
      <EmployeeAssignmentsList
        v-if="employee"
        :employee="employee"
        @hide="assignmentsListDialog = false"
      />
    </v-dialog>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <v-dialog
      v-model="showEmployeeInformationDialog"
      persistent
      max-width="800px"
    >
      <employee-information
        :employee="employee"
        @close="handleHideEmployeeInformationDialog"
      ></employee-information>
    </v-dialog>

    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showFlightAssignmentInformationDialog" max-width="800px">
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </v-dialog>

    <!-- GROUND ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog v-model="showGroundAssignmentInformationDialog" max-width="800px">
      <ground-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @hide="hideGroundAssignmentInformationDialog($event)"
      >
      </ground-assignment-information>
    </v-dialog>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <v-dialog
      v-if="showStationAssignmentInformationDialog"
      v-model="showStationAssignmentInformationDialog"
      max-width="800px"
    >
      <station-assignment-information
        v-if="groundAssignmentStation"
        :groundAssignment="groundAssignmentStation"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </v-dialog>

    <!-- DATE FILTER DIALOG-->
    <v-dialog
      v-model="dateFilterDialog"
      max-width="450px"
      v-if="showDateFilterDialog"
    >
      <div class="yellow-frame">
        <div class="mx-3 mb-2 font-abel-24px text-center">
          Select Date Range
        </div>

        <div class="d-flex justify-center px-10">
          <!-- Start Date -->
          <v-menu
            v-model="showStartCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedStartDate"
                label="Start"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedStartDate"
              no-title
              @input="showStartCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex justify-center px-10">
          <!-- End Date -->
          <v-menu
            v-model="showEndCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedEndDate"
                label="End"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedEndDate"
              @input="showEndCalendar = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="d-flex justify-end align-center">
          <v-btn @click="saveDateFilter" small dark color="green">save</v-btn>
        </div>
      </div>
    </v-dialog>

    <!-- SEARCH BY STATION DIALOG -->
    <v-dialog v-model="searchByStationDialog" max-width="700px">
      <div v-if="searchByStationDialog" class="yellow-frame">
        <div class="d-flex justify-space-between mb-2 ml-2">
          <div class="font-abel-24px">Filter by Station</div>
          <v-btn @click="handleHideSearchByStationDialog" small icon
            ><i class="fa-solid fa-xmark"></i
          ></v-btn>
        </div>

        <v-container>
          <!-- Stations -->
          <v-row align-center>
            <v-col cols="12" sm="4">
              <v-select
                v-model="searchByStationCriteria"
                :items="searchByStationOptions"
                label="Match"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="searchByStationCode"
                append-icon="search"
                label="Station Code"
                single-line
                hide-details
                max-length="3"
                :style="{
                  maxWidth: '200px',
                  marginLeft: '10px',
                  marginBottom: '10px',
                }"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <p v-if="searchByStationCode.length === 3" class="pa-3 indigo--text">
          {{ searchByStationDescription }}
        </p>
        <p v-else class="pa-3 red--text">
          {{ searchByStationDescription }}
        </p>

        <div class="d-flex justify-space-between">
          <v-btn
            v-if="searchByStation"
            @click="handleClearSearchByStation"
            small
            dark
            color="blue"
            >clear filter</v-btn
          >
          <div v-else></div>
          <div class="d-flex justify-end">
            <v-btn @click="handleSaveSearchByStation" small dark color="green"
              >save</v-btn
            >
            <v-btn
              class="ml-1"
              @click="handleHideSearchByStationDialog"
              small
              dark
              color="grey"
              >cancel</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- NEW GROUND ASSIGNMENT DIALOG -->
    <v-dialog
      v-if="showNewGroundAssignmentDialog"
      v-model="showNewGroundAssignmentDialog"
      max-width="800px"
    >
      <ground-assignment
        :groundAssignmentStart="groundAssignmentStart"
        :employee="employee"
        @hide="handleHideGroundAssignmentDialog($event)"
      >
      </ground-assignment>
    </v-dialog>

    <!-- FLIGHT BOARD -->
    <div v-if="!loading">
      <div v-if="boardStyle === 'Board'">
        <div class="top-frame font-abel-16px">
          <div class="top-panel-left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="loading"
                  @click="handleOpenEmployeeFilter"
                  small
                  icon
                >
                  <i class="fa-solid fa-filter"></i>
                </v-btn>
              </template>
              <span>Filter Employees by Job Title</span>
            </v-tooltip>
            <!-- Search by last name -->
            <v-text-field
              v-model="lastName"
              dense
              label="Employee"
              single-line
              hide-details
              :style="{ maxWidth: '120px', marginLeft: '5px' }"
              @input="filterEmployees"
            ></v-text-field>
          </div>
          <div class="top-panel-right">
            <div
              class="top-panel-right-inner"
              :style="{
                width: `${boardWidth}px`,
                left: `${scrollX}px`,
              }"
            >
              <div
                v-for="(day, index) in dateFilter.days"
                :key="index"
                class="top-panel-day"
                :style="{ width: `${1440 / scale}px` }"
              >
                {{ formatDay(day, scale) }}

                <div v-if="scale < 8" class="d-flex font-abel-10px">
                  <div
                    v-bind:style="{
                      width: `${30 / scale}px`,
                    }"
                  ></div>

                  <div
                    v-for="tick in timeTicks"
                    :key="tick.index"
                    v-bind:style="{
                      width: `${60 / scale}px`,
                    }"
                  >
                    <div>{{ tick.text }}</div>
                  </div>
                </div>
              </div>

              <div
                v-if="currentTimeX > 0"
                class="current-time-box d-flex justify-center align-center font-abel-16px"
                v-bind:style="{
                  left: `${currentTimeX - 21}px`,
                  width: '42px',
                }"
              >
                {{ formatTime(new Date()) }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="filteredEmployees.length" class="bottom-frame">
          <div class="bottom-panel-left">
            <div
              v-for="(employee, index) in filteredEmployees"
              @click="handleShowEmployeeInformationDialog(employee)"
              :key="employee._id"
              class="bottom-panel-left-row font-abel-14px cursor-hand"
            >
              <div
                class="bottom-panel-left-row-section truncate font-abel-12px-bold"
              >
                {{ index + 1 }} - {{ employee.surname }},
                {{ employee.givenName.substring(0, 1) }}
              </div>

              <div
                class="bottom-panel-left-row-section truncate font-abel-10px"
              >
                {{ employee.companyName }} - {{ employee.jobTitle }}
              </div>
              <div
                class=" font-abel-12px bottom-panel-left-row-section truncate"
              >
                {{ employee.gatewayCode }} - {{ employee.employeeId }}
              </div>
            </div>
          </div>

          <div
            class="bottom-panel-right"
            @scroll="scrollHorizontal"
            ref="bottomPanelRight"
          >
            <div
              class="bottom-panel-right-inner"
              :style="{
                width: `${boardWidth}px`,
                height: `${boardHeight}px`,
              }"
            >
              <div
                v-for="employee in filteredEmployees"
                :key="employee._id"
                class="bottom-panel-right-row"
                :style="{
                  width: `${boardWidth}px`,
                }"
              >
                <div
                  v-for="(day, index) in dateFilter.days"
                  :key="index"
                  @click="handleShowNewGroundAssignmentDialog(employee, $event)"
                  class="bottom-panel-day"
                  :style="{
                    width: `${1440 / scale}px`,
                    left: `${index * (1440 / scale)}px`,
                    backgroundColor: getBidDayStyle(employee.bidDays, day),
                    opacity: 0.5,
                    cursor: 'pointer',
                  }"
                >
                  <div v-if="scale < 8" class="d-flex font-abel-10px">
                    <div
                      class="hour-line"
                      v-for="tick in timeTicks"
                      :key="tick.index"
                      v-bind:style="{
                        width: `${60 / scale}px`,
                      }"
                    ></div>
                  </div>
                </div>

                <div
                  v-if="currentTimeX > 0"
                  class="current-time"
                  v-bind:style="{
                    left: `${currentTimeX}px`,
                  }"
                ></div>

                <!-- Assignments -->
                <div
                  v-for="assignment in employee.assignments"
                  :key="assignment._id"
                >
                  <!-- FLight Assignment -->
                  <div
                    v-if="assignment.type === 'Flight'"
                    class="flight-label-frame"
                    :style="{
                      width: `${assignment.flight.effectiveBlockTime /
                        scale}px`,
                      left: `${getLocationLeft(
                        assignment.flight.effectiveTimeOut,
                        dateFilter.days[0],
                        scale
                      )}px`,
                    }"
                  >
                    <FlightLabel
                      :flight="assignment.flight"
                      @clicked="
                        handleShowFlightAssignmentInformationDialog(assignment)
                      "
                    />
                  </div>

                  <!-- Ground Assignment -->
                  <div
                    v-else
                    class="flight-label-frame"
                    v-bind:style="{
                      width: `${assignment.duration / scale}px`,
                      left: `${getLocationLeft(
                        assignment.startTime,
                        dateFilter.days[0],
                        scale
                      )}px`,
                    }"
                  >
                    <GroundAssignmentLabel
                      :groundAssignment="assignment"
                      @clicked="
                        handleShowGroundAssignmentInformationDialog(assignment)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="bottom-frame font-abel-16px">
          <div class="r-frame-rounded-yellow red--text">
            No employees found with the selected filters.
          </div>
        </div>
      </div>
      <div
        class="bottom-frame"
        v-if="boardStyle === 'List'"
        :style="{ backgroundColor: '#bebebe' }"
      >
        <v-container fluid>
          <v-col cols="12">
            <v-data-table
              dense
              :headers="assignmentHeaders"
              :items="filteredAssignments"
              v-model="selectedAssignments"
              :search="assignmentSearch"
              :footer-props="{
                itemsPerPageOptions: [20, 50, 100, 200],
              }"
              show-select
              item-key="_id"
            >
              <template v-slot:[`item._id`]="{ item }">
                <span>{{ formatAssignmentSnapshot(item) }}</span>
              </template>
            </v-data-table>
            <div v-if="selectedAssignments.length" class="d-flex justify-end">
              <v-btn
                @click="handleDeleteAssignments"
                small
                dark
                color="red"
                class="mr-3 my-3"
                >Remove</v-btn
              >
            </div>
          </v-col>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import aad from "../services/aad";
import api from "../apis/monarchApi";
import { mixin } from "../mixins/mixin";
import FlightLabel from "../components/FlightLabel";

import GroundAssignment from "../components/GroundAssignment";
import GroundAssignmentLabel from "../components/GroundAssignmentLabel";
import EmployeeAssignmentsList from "../components/EmployeeAssignmentsList";
import AutoLogout from "../components/AutoLogout";
import Navbar from "../components/Navbar";
import SpecialAirports from "../components/SpecialAirports.vue";
import EmployeeFilter from "../components/EmployeeFilter.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import GroundAssignmentInformation from "../components/GroundAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import EmployeeInformation from "../components/EmployeeInformation.vue";

export default {
  name: "assignmentBoard",
  components: {
    FlightAssignmentInformation,
    GroundAssignmentInformation,
    StationAssignmentInformation,
    EmployeeInformation,
    FlightLabel,
    GroundAssignmentLabel,
    GroundAssignment,
    EmployeeAssignmentsList,
    EmployeeFilter,
    AutoLogout,
    Navbar,
    SpecialAirports,
  },
  data() {
    return {
      loading: false,
      showEmployeeInformationDialog: false,
      showStationAssignmentInformationDialog: false,
      boardStyle: "Board",
      warningsAcknowledged: false,
      isMobile: false,
      employeeFilterDialog: false,
      sliderX: 0,
      employee: null,
      employees: [],
      filteredEmployees: [],
      workMonth: null,
      groundAssignment: null,
      groundAssignmentStation: null,
      showNewGroundAssignmentDialog: false,
      groundAssignmentDetailDialog: false,
      groundAssignmentStart: "",
      assignmentsListDialog: false,
      lastName: "",
      scrollX: 0,
      dateFilterDialog: false,
      showDateFilterDialog: false,
      selectedStartDate: "",
      selectedEndDate: "",
      employeeDialog: false,
      showStartCalendar: false,
      showEndCalendar: false,
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      scale: 8,
      currentTime: "",
      flightAssignment: null,
      assignmentSearch: "",
      assignmentHeaders: [
        {
          text: "Last Name",
          align: "start",
          value: "employee.surname",
        },
        {
          sortable: true,
          text: "First Name",
          align: "start",
          value: "employee.givenName",
        },
        {
          sortable: true,
          text: "Company",
          align: "start",
          value: "employee.companyName",
        },
        {
          sortable: true,
          text: "Job Title",
          align: "start",
          value: "employee.jobTitle",
        },
        {
          sortable: false,
          text: "Assignment",
          align: "start",
          value: "_id",
        },
        {
          sortable: true,
          text: "Assignment Type",
          align: "start",
          value: "type",
        },
        {
          sortable: true,
          text: "Assigned By",
          align: "start",
          value: "assignedBy",
        },
      ],
      selectedAssignments: [],
      filteredAssignments: [],
      warnings: [],
      showWarningsDialog: false,
      warningsHeaders: [
        {
          text: "Employee",
          align: "start",
          sortable: true,
          value: "employeeName",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: "level",
        },
        {
          text: "Message",
          align: "start",
          sortable: true,
          value: "message",
        },
      ],
      specialAirportsDialog: false,
      specialAiportsHeaders: [
        {
          text: "Category",
          align: "start",
          sortable: true,
          value: "category",
        },
        {
          text: "IATA Code",
          align: "start",
          sortable: true,
          value: "code",
        },
      ],
      stationIata: "", //Used to sort station assignment flights
      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      showFlightAssignmentInformationDialog: false,
      showGroundAssignmentInformationDialog: false,
      searchByStation: false,
      searchByStationCode: "",
      searchByStationCriteria: "Origin or Destination",
      searchByStationDialog: false,
      searchByStationOptions: [
        "Origin",
        "Destination",
        "Origin or Destination",
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.selectedCompany = this.user.securityGroup.companies[0];
    this.selectedBoardType = this.board.type;
    this.getAssignments();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    searchByStationIconColor() {
      if (this.searchByStation) {
        return "yellow";
      } else {
        return "#E0E0E0";
      }
    },
    searchByStationDescription() {
      if (this.searchByStationCode.length !== 3) {
        return "Please enter a valid IATA code";
      }
      let msg = `Show only employees whith a flight or ground assignment that starts or ends within the selected date range and either the Origin or Destination IATA code matches "${this.searchByStationCode.toUpperCase()}"`;
      switch (this.searchByStationCriteria) {
        case "Origin":
          msg = `Show only employees whith a flight or ground assignment that starts within the selected date range and the Origin IATA code matches "${this.searchByStationCode.toUpperCase()}"`;
          break;

        case "Destination":
          msg = `Show only employees whith a flight or ground assignment that starts or ends within the selected date range and the Destination IATA code matches "${this.searchByStationCode.toUpperCase()}"`;
          break;

        default:
          break;
      }
      return msg;
    },

    userCompanies() {
      let companies = ["All Companies"];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },

    stationAssignment() {
      return this.$store.getters.stationAssignment;
    },
    flight() {
      return this.$store.getters.flight;
    },
    company() {
      return this.$store.getters.company;
    },

    board() {
      return this.$store.getters.board;
    },
    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },
    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },
    boardHeight() {
      return this.filteredEmployees.length * 60 + 50;
    },
  },
  methods: {
    handleShowSearchByStationDialog() {
      this.searchByStationDialog = true;
    },
    handleHideSearchByStationDialog() {
      this.searchByStationDialog = false;
    },

    handleSaveSearchByStation() {
      if (this.searchByStationCode.length !== 3) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please enter a valid IATA code";
        this.snackbarVisible = true;
        return;
      }

      this.searchByStation = true;

      this.searchByStationDialog = false;

      this.filterEmployees();
    },

    handleClearSearchByStation() {
      this.searchByStation = false;
      this.searchByStationCode = "";
      this.searchByStationDialog = false;

      this.filterEmployees();
    },

    filterEmployees() {
      let filterOne = [];
      let filterTwo = [];
      let filterThree = [];
      let filterFour = [];

      const code = this.searchByStationCode.toUpperCase();

      if (this.searchByStation) {
        let priorAssignments;

        this.employees.forEach((employee) => {
          if (employee.assignments.length) {
            switch (this.searchByStationCriteria) {
              case "Origin":
                priorAssignments = employee.assignments.filter((assignment) => {
                  return (
                    new Date(assignment.startTime).getTime() <
                      new Date(this.dateFilter.end).getTime() &&
                    assignment.originIata === code
                  );
                });

                if (priorAssignments.length) {
                  filterOne.push(employee);
                }

                break;

              case "Destination":
                priorAssignments = employee.assignments.filter((assignment) => {
                  return (
                    new Date(assignment.endTime).getTime() <
                      new Date(this.dateFilter.end).getTime() &&
                    assignment.destinationIata === code
                  );
                });

                if (priorAssignments.length) {
                  filterOne.push(employee);
                }

                break;

              default:
                priorAssignments = employee.assignments.filter((assignment) => {
                  return (
                    new Date(assignment.endTime).getTime() <
                      new Date(this.dateFilter.end).getTime() &&
                    (assignment.originIata === code ||
                      assignment.destinationIata === code)
                  );
                });

                if (priorAssignments.length) {
                  filterOne.push(employee);
                }
                //"both"

                break;
            }
          }
        });
      } else {
        filterOne = [...this.employees];
      }

      if (this.selectedCompany !== "All Companies") {
        filterTwo = filterOne.filter((employee) => {
          return this.selectedCompany === employee.companyName;
        });
      } else {
        filterTwo = [...filterOne];
      }

      if (this.lastName.length) {
        filterThree = filterTwo.filter((employee) => {
          return employee.surname
            .toUpperCase()
            .startsWith(this.lastName.toUpperCase());
        });
      } else {
        filterThree = [...filterTwo];
      }

      switch (this.board.type) {
        case "Cargo":
          filterFour = filterThree.filter((item) => {
            return this.cargoJobTitles.includes(item.jobTitle);
          });
          break;
        case "Passenger":
          filterFour = filterThree.filter((item) => {
            return this.passengerJobTitles.includes(item.jobTitle);
          });
          break;

        default:
          filterFour = [...filterThree];
          break;
      }

      this.filteredEmployees = [...filterFour];

      this.getWarnings(false);
    },

    handleAcknowledgeWarnings() {
      this.showWarningsDialog = false;
    },
    async handleDeleteAssignments() {
      let groundAssignmentIds = [];
      let flightAssignmentIds = [];

      this.selectedAssignments.forEach((item) => {
        if (item.type === "Ground") {
          groundAssignmentIds.push(item._id);
        } else {
          flightAssignmentIds.push(item._id);
        }
      });

      this.loading = true;

      const res = await this.$store.dispatch("deleteAssignments", {
        groundAssignmentIds,
        flightAssignmentIds,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getAssignments();
      this.boardStyle = "Board";
    },
    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.enableReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleHideEmployeeInformationDialog() {
      this.showEmployeeInformationDialog = false;
    },

    //-----------------------Flight Assignment
    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.flightAssignment = {
        ...flightAssignment,
      };

      this.showFlightAssignmentInformationDialog = true;
    },

    hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;
      this.flightAssignment = null;
      if (e.refreshData) {
        this.getAssignments();
      }
    },

    //-----------------------New Ground Assignment
    handleShowNewGroundAssignmentDialog(employee, e) {
      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);

      this.employee = { ...employee };

      const minutes =
        (e.clientX - 100) * this.scale + Math.abs(this.scrollX * this.scale);

      this.groundAssignmentStart =
        new Date(this.dateFilter.start).getTime() + minutes * 60 * 1000;

      this.showNewGroundAssignmentDialog = true;
    },

    handleHideGroundAssignmentDialog(refresh) {
      this.showNewGroundAssignmentDialog = false;

      if (refresh) {
        this.getAssignments();
      }
    },

    //-----------------------Ground Assignment Information
    async handleShowGroundAssignmentInformationDialog(groundAssignment) {
      try {
        if (groundAssignment.category === "Station Assignment") {
          this.loading = true;
          const token = await aad.getIdTokenSilent();

          const res = await api.get("/flights/assignment-flights", {
            headers: {
              Authorization: "Bearer " + token.idToken.rawIdToken,
            },
            params: {
              flightIds: groundAssignment.group.flightIds,
            },
          });

          this.loading = false;

          if (res.data.status !== "OK") {
            this.loading = false;
            this.snackbarColor = "#F44336";
            this.snackbarText = res.data.message;
            this.snackbarVisible = true;

            return;
          }

          const flights = res.data.flights;

          this.groundAssignmentStation = {
            ...groundAssignment,
            flights,
          };

          this.showStationAssignmentInformationDialog = true;
        } else {
          this.groundAssignment = {
            ...groundAssignment,
          };

          this.showGroundAssignmentInformationDialog = true;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async hideGroundAssignmentInformationDialog(refreshData) {
      this.showGroundAssignmentInformationDialog = false;
      this.groundAssignment = null;

      if (refreshData) {
        await this.getAssignments();
      }
    },

    async hideStationAssignmentInformationDialog(refreshData) {
      this.showStationAssignmentInformationDialog = false;
      this.groundAssignment = null;

      if (refreshData) {
        await this.getAssignments();
      }
    },

    //----------------------Employee Filter
    handleOpenEmployeeFilter() {
      this.employeeFilterDialog = true;
    },

    handleCloseEmployeeFilter() {
      this.employeeFilterDialog = false;
    },

    async handleSaveEmployeeFilter(e) {
      this.employeeFilterDialog = false;
      this.loading = true;

      let settings = { ...this.user.settings };
      settings.filters.employees.jobTitles = [...e.selectedJobTitles];

      const res = await this.$store.dispatch("updateUserSettings", {
        userId: this.user._id,
        settings,
      });

      this.loading = false;

      if (res.status !== "OK") {
        this.snackbarColor = "#F44336";
        this.snackbarText = res.message;
        this.snackbarVisible = true;
        return;
      }

      this.getAssignments();
    },

    handleChangeBoardType() {
      let board = { ...this.board };
      board.type = this.selectedBoardType;
      this.$store.commit("updateBoard", board);
      this.filterEmployees();
    },

    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }

      const board = {
        ...this.board,
        employeeScrollY: window.scrollY,
        scrollX: this.scrollX,
        scale: this.scale,
      };

      this.$store.commit("updateBoard", board);
    },

    getWarnings(showDialog) {
      this.warnings = [];
      let priorAssignment;
      let assignment;
      let timeOut;
      let timeIn;
      let timeNow = new Date().getTime();
      let ts = 0;

      this.filteredEmployees.forEach((employee) => {
        if (employee.assignments.length) {
          // Check Overlap and Discontinuity
          priorAssignment = employee.assignments[0];

          for (let i = 0; i < employee.assignments.length; i++) {
            assignment = employee.assignments[i];

            if (i > 0) {
              //Check discontinuity starting on the second assignment

              timeOut = new Date(assignment.startTime).getTime();
              timeIn = new Date(priorAssignment.endTime).getTime();

              if (assignment.originIata !== priorAssignment.destinationIata) {
                this.warnings.push({
                  assignment: { ...assignment },
                  level: "Soft",
                  type: "Route Discontinuity",
                  employeeName: this.formatName(
                    employee.givenName,
                    employee.surname,
                    "l"
                  ),
                  message: `Assignment starts in ${
                    assignment.originIata
                  } on ${this.formatDateTime(
                    assignment.startTime
                  )}, prior assignment ended in ${
                    priorAssignment.destinationIata
                  } on ${this.formatDateTime(priorAssignment.endTime)}`,
                });
              }

              if (timeOut < timeIn) {
                this.warnings.push({
                  assignment: { ...assignment },
                  level: "Soft",
                  type: "Time Overlap",
                  employeeName: this.formatName(
                    employee.givenName,
                    employee.surname,
                    "l"
                  ),
                  message: `Assignment starts in ${
                    assignment.originIata
                  } on ${this.formatDateTime(
                    assignment.startTime
                  )}, prior assignment ended in ${
                    priorAssignment.destinationIata
                  } on ${this.formatDateTime(priorAssignment.endTime)}`,
                });
              }

              priorAssignment = { ...assignment };
            }

            // Check Travel/Layover Confirmation

            if (
              (assignment.category === "Travel" ||
                assignment.category === "Layover") &&
              !assignment.confirmed
            ) {
              timeOut = new Date(assignment.startTime).getTime();
              ts = (timeOut - timeNow) / (1000 * 60 * 60);

              //Add warning if unconfirmed in the nexr 72 hours
              if (ts > 0 && ts < 72) {
                this.warnings.push({
                  assignment: { ...assignment },
                  level: "Soft",
                  type: `Unconfirmed ${assignment.category}`,
                  employeeName: this.formatName(
                    employee.givenName,
                    employee.surname,
                    "l"
                  ),
                  message: `Unconfirmed ${
                    assignment.category
                  } on ${this.formatDateTime(assignment.startTime)}`,
                });
              }
            }
          }
        }
      });

      if (this.warnings.length) {
        this.showWarningsDialog = showDialog;
      } else {
        if (showDialog) {
          this.snackbarColor = "#66BB6A";
          this.snackbarText = "No warnings at this time";
          this.snackbarVisible = true;
        }
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 650;
    },

    async getAssignments() {
      this.loading = true;

      this.employees = [];
      this.filteredEmployees = [];
      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token.idToken.rawIdToken,
          },
          params: {
            filterStart: this.dateFilter.start,
            filterEnd: this.dateFilter.end,
          },
        });

        this.loading = false;

        if (res.data.status !== "OK") {
          this.loading = false;
          this.snackbarColor = "#F44336";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        let employees = res.data.employees;

        const allAssignments = res.data.assignments;

        const bidDays = res.data.bidDays;

        let assignments = [];
        employees.forEach((emp) => {
          assignments = allAssignments.filter((a) => {
            if (a.employee) {
              return a.employee._id === emp._id;
            }
          });

          emp.assignments = this.mergeAssignments(assignments);
          emp.bidDays = bidDays.filter((bidDay) => {
            return bidDay.employee === emp._id;
          });
          this.employees.push(emp);
        });

        this.filterEmployees();

        if (this.filteredEmployees.length && this.boardStyle === "Board") {
          this.$nextTick(() => {
            window.scrollTo(0, this.board.employeeScrollY);
            const panel = this.$refs["bottomPanelRight"];
            if (panel) {
              panel.scrollLeft = Math.abs(this.board.scrollX);
              this.scale = this.board.scale;
            }
          });
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "#F44336";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    switchToBoardMode() {
      this.boardStyle = "Board";
    },

    switchToListMode() {
      this.filteredAssignments = [];

      this.filteredEmployees.forEach((employee) => {
        employee.assignments.forEach((item) => {
          this.filteredAssignments.push(item);
        });
      });
      this.boardStyle = "List";
    },

    scrollBoard() {
      //This fires when the slider in the toolbar is used
      this.bottomPanelRight.scrollLeft = this.sliderX;
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },

    compareStationFlights(a, b) {
      //Flights need to be order based on if they are an arrival or departure assignment
      let aRefTime;
      let bRefTime;

      if (a.originIata === this.stationIata) {
        aRefTime = new Date(a.effectiveTimeOut).getTime();
      } else if (a.destinationIata === this.stationIata) {
        aRefTime = new Date(a.effectiveTimeIn).getTime();
      }

      if (b.originIata === this.stationIata) {
        bRefTime = new Date(b.effectiveTimeOut).getTime();
      } else if (b.destinationIata === this.stationIata) {
        bRefTime = new Date(b.effectiveTimeIn).getTime();
      }

      if (aRefTime < bRefTime) {
        return -1;
      }
      if (bRefTime < aRefTime) {
        return 1;
      }
      return 0;
    },

    showBidDayDetails(bidline, day) {
      let bidDay;

      if (bidline) {
        bidDay = bidline.days.find((d) => {
          return d.day === new Date(day).getUTCDate();
        });

        this.snackbarColor = `#2196F3`;
        this.snackbarText = `${
          bidline.employee.displayName
        } . ${this.formatDate(day)} . ${bidDay.status.description}`;
        this.snackbarVisible = true;
      } else {
        this.snackbarColor = `#FF6F00`;
        this.snackbarText = "Not bidline information";
        this.snackbarVisible = true;
      }
    },

    dateFilterBlockBack() {
      const numberOfDays = 14;
      const filterStart =
        new Date(this.dateFilter.start).getTime() -
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    dateFilterBack() {
      const numberOfDays = 7;
      const filterStart =
        new Date(this.dateFilter.start).getTime() -
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    dateFilterForward() {
      const numberOfDays = 7;
      const filterStart =
        new Date(this.dateFilter.start).getTime() +
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    dateFilterBlockForward() {
      const numberOfDays = 14;
      const filterStart =
        new Date(this.dateFilter.start).getTime() +
        numberOfDays * 24 * 60 * 60 * 1000;

      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });

      this.getAssignments();
    },

    openDateFilter() {
      this.selectedStartDate = this.dateFilter.startDate.slice();
      this.selectedEndDate = this.dateFilter.endDate.slice();
      this.dateFilterDialog = true;
      this.showDateFilterDialog = true;
    },

    saveDateFilter() {
      this.dateFilter.startDate = this.selectedStartDate.slice();
      this.dateFilter.endDate = this.selectedEndDate.slice();

      let yy;
      let mm;
      let dd;

      const dt1 = new Date(this.dateFilter.startDate);
      yy = dt1.getUTCFullYear();
      mm = dt1.getUTCMonth();
      dd = dt1.getUTCDate();
      const filterStart = new Date(Date.UTC(yy, mm, dd, 0, 0, 0, 0)).getTime();

      const dt2 = new Date(this.dateFilter.endDate);
      yy = dt2.getUTCFullYear();
      mm = dt2.getUTCMonth();
      dd = dt2.getUTCDate();
      const filterEnd = new Date(
        Date.UTC(yy, mm, dd, 23, 59, 59, 999)
      ).getTime();

      const numberOfDays = Math.ceil(
        (filterEnd - filterStart) / 1000 / 60 / 60 / 24
      );

      if (numberOfDays < 5 || numberOfDays > 31) {
        this.snackbarColor = "#F44336";
        this.snackbarText = "Please select a range between 5 and 31 days";
        this.snackbarVisible = true;
        return;
      }

      this.dateFilterDialog = false;
      this.showDateFilterDialog = false;
      this.$store.commit("updateDateFilter", { filterStart, numberOfDays });
      this.getAssignments();
    },

    getBidDayStyle(bidDays, day) {
      //bidDays are the employee bid days as an array of objects
      //day is the day of the board as a ISO string in zulu

      let bidDay;
      let backgroundColor = "white";

      if (bidDays.length) {
        bidDay = bidDays.find((bidDay) => {
          return (
            new Date(
              Date.UTC(bidDay.year, bidDay.month, bidDay.day)
            ).getTime() === new Date(day.substring(0, 10)).getTime()
          );
        });

        if (bidDay) {
          switch (bidDay.code) {
            case "DO":
              backgroundColor = "#E74C3C";
              break;
            case "RES":
              backgroundColor = "#2ECC71";
              break;
            case "VAC":
              backgroundColor = "#A569BD";
              break;
            default:
              break;
          }
        }
      }

      return backgroundColor;
    },
  },
};
</script>

<style scoped>
.red-frame {
  border-style: solid;
  border-color: var(--red);
  border-width: 3px 3px 3px 10px;
  padding: 10px;
  background-color: var(--white);
}
</style>
